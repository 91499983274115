import dayjs from 'dayjs';
import { ISkuApplicationStateTransition } from 'app/shared/model/sku-application-state-transition.model';
import { ISku } from 'app/shared/model/sku.model';
import { IUser } from 'app/shared/model/user.model';
import { IForm } from 'app/shared/model/form.model';

export interface ISkuApplication {
  id?: number;
  formData?: string | null;
  createdAt?: string;
  updatedAt?: string;
  stateTransitions?: ISkuApplicationStateTransition[] | null;
  sku?: ISku | null;
  user?: IUser | null;
  form?: IForm | null;
}

export const defaultValue: Readonly<ISkuApplication> = {};
