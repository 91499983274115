import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getEntity } from './course-discussion.reducer';
import ReactJson from 'react-json-view';
import { nullifyEmptyString } from 'app/shared/util/utils';

export const CourseDiscussionDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [resourcesData, setResourcesData] = useState(null);
  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const getResDescriptions = async () => {
    if (courseDiscussionEntity.id) {
      try {
        const apiUrl = `api/res-discussions?courseDiscussionId.equals=${courseDiscussionEntity.id}`;
        const { data } = await axios.get(apiUrl);
        if (data.length === 0) {
          toast.error('No Resources available');
        } else {
          setResourcesData(data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const courseDiscussionEntity = useAppSelector(state => state.courseDiscussion.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="courseDiscussionDetailsHeading">
          <Translate contentKey="alippoApp.courseDiscussion.detail.title">CourseDiscussion</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{courseDiscussionEntity.id}</dd>
          <dt>
            <span id="discussionLink">
              <Translate contentKey="alippoApp.courseDiscussion.discussionLink">Discussion Link</Translate>
            </span>
          </dt>
          <dd>{courseDiscussionEntity.discussionLink}</dd>
          <dt>
            <span id="discussionWebLink">
              <Translate contentKey="alippoApp.courseDiscussion.discussionWebLink">Discussion Web Link</Translate>
            </span>
          </dt>
          <dd>{courseDiscussionEntity.discussionWebLink}</dd>
          <dt>
            <span id="joined">
              <Translate contentKey="alippoApp.courseDiscussion.joined">Joined</Translate>
            </span>
          </dt>
          <dd>{courseDiscussionEntity.joined}</dd>
          <dt>
            <span id="disabled">
              <Translate contentKey="alippoApp.courseDiscussion.disabled">Disabled</Translate>
            </span>
          </dt>
          <dd>{courseDiscussionEntity.disabled ? 'true' : 'false'}</dd>
          <dt>
            <span id="discussionType">
              <Translate contentKey="alippoApp.courseDiscussion.discussionType">Discussion Type</Translate>
            </span>
          </dt>
          <dd>{courseDiscussionEntity.discussionType}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.courseDiscussion.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {courseDiscussionEntity.createdAt ? (
              <TextFormat value={courseDiscussionEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.courseDiscussion.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {courseDiscussionEntity.updatedAt ? (
              <TextFormat value={courseDiscussionEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="configPayload">
              <Translate contentKey="alippoApp.courseDiscussion.configPayload">Config Payload</Translate>
            </span>
          </dt>
          <dd>
            <ReactJson
              src={JSON.parse(nullifyEmptyString(courseDiscussionEntity.configPayload) ?? '{}')}
              displayObjectSize={false}
              displayDataTypes={false}
              collapsed
            />
          </dd>
          <dt>
            <span id="discussionSubType">
              <Translate contentKey="alippoApp.courseDiscussion.discussionSubType">Discussion Sub Type</Translate>
            </span>
          </dt>
          <dd>{courseDiscussionEntity.discussionSubType}</dd>
          <dt>
            <span id="vacant">
              <Translate contentKey="alippoApp.courseDiscussion.vacant">Vacant</Translate>
            </span>
          </dt>
          <dd>{courseDiscussionEntity.vacant ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="alippoApp.courseDiscussion.course">Course</Translate>
          </dt>
          <dd>{courseDiscussionEntity.course ? courseDiscussionEntity.course.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.courseDiscussion.category">Category</Translate>
          </dt>
          <dd>{courseDiscussionEntity.category ? courseDiscussionEntity.category.id : ''}</dd>
        </dl>
        <dl>
          <dt>Resources</dt>
          <Button onClick={getResDescriptions} color="primary">
            <span>Get Resources</span>
          </Button>
          &nbsp;
          <Link to={{ pathname: '/res-discussion/new', state: { courseDiscussionId: courseDiscussionEntity.id } }}>
            <Button className="rounded-circle" color="success">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Link>
          <dd>
            {resourcesData && resourcesData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">ID</th>
                    <th className="hand">Name</th>
                    <th className="hand">Description</th>
                    <th className="hand">Res State</th>
                    <th className="hand">Is Default</th>
                  </tr>
                </thead>
                <tbody>
                  {resourcesData.map((resource, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>
                        <a target="_blank" rel="noopener noreferrer" href={`/res-discussion/${resource.id}`}>
                          {resource.id}
                        </a>
                      </td>
                      <td>{resource.name}</td>
                      <td>{resource.description}</td>
                      <td>{resource.resState}</td>
                      <td>{String(resource.isDefault)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <dl>
          <dt>Discussion Members</dt>
          <a href={`discussion-member?page=1&sort=id,asc&discussionId.equals=${courseDiscussionEntity.id}`}>
            <Button type="button" color="primary">
              See Discussion Members
            </Button>
          </a>
        </dl>
        <dl>
          <dt>Session Logs</dt>
          <Link to={{ pathname: '/discussion-session-logs', state: { discussion: courseDiscussionEntity } }}>
            <Button className="mr-2" color="primary">
              Manage Session Logs
            </Button>
          </Link>
        </dl>
        <Button tag={Link} to="/course-discussion" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/course-discussion/${courseDiscussionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/course-discussion/${courseDiscussionEntity.id}/delete`} replace color="danger">
          <FontAwesomeIcon icon="trash" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.delete">Delete</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CourseDiscussionDetail;
