import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IDevice } from 'app/shared/model/device.model';
import { getEntities as getDevices } from 'app/entities/device/device.reducer';
import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntity, updateEntity, createEntity, reset } from './user-topic.reducer';
import { IUserTopic } from 'app/shared/model/user-topic.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { TopicType } from 'app/shared/model/enumerations/topic-type.model';

export const UserTopicUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const devices = useAppSelector(state => state.device.entities);
  const users = useAppSelector(state => state.userManagement.users);
  const userTopicEntity = useAppSelector(state => state.userTopic.entity);
  const loading = useAppSelector(state => state.userTopic.loading);
  const updating = useAppSelector(state => state.userTopic.updating);
  const updateSuccess = useAppSelector(state => state.userTopic.updateSuccess);
  const topicTypeValues = Object.keys(TopicType);
  const handleClose = () => {
    props.history.push('/user-topic' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getDevices({}));
    dispatch(getUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.subscriptionDate = convertDateTimeToServer(values.subscriptionDate);
    values.revocationDate = convertDateTimeToServer(values.revocationDate);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...userTopicEntity,
      ...values,
      device: { id: values.device },
      user: { id: values.user },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          subscriptionDate: displayDefaultDateTime(),
          revocationDate: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          type: 'PROMOTIONAL',
          ...userTopicEntity,
          subscriptionDate: convertDateTimeFromServer(userTopicEntity.subscriptionDate),
          revocationDate: convertDateTimeFromServer(userTopicEntity.revocationDate),
          createdAt: convertDateTimeFromServer(userTopicEntity.createdAt),
          updatedAt: convertDateTimeFromServer(userTopicEntity.updatedAt),
          device: userTopicEntity?.device?.id,
          user: userTopicEntity?.user?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.userTopic.home.createOrEditLabel" data-cy="UserTopicCreateUpdateHeading">
            <Translate contentKey="alippoApp.userTopic.home.createOrEditLabel">Create or edit a UserTopic</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="user-topic-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.userTopic.topicName')}
                id="user-topic-topicName"
                name="topicName"
                data-cy="topicName"
                type="text"
              />
              <ValidatedField label={translate('alippoApp.userTopic.type')} id="user-topic-type" name="type" data-cy="type" type="select">
                {topicTypeValues.map(topicType => (
                  <option value={topicType} key={topicType}>
                    {translate('alippoApp.TopicType' + topicType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.userTopic.active')}
                id="user-topic-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.userTopic.subscriptionDate')}
                id="user-topic-subscriptionDate"
                name="subscriptionDate"
                data-cy="subscriptionDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('alippoApp.userTopic.revocationDate')}
                id="user-topic-revocationDate"
                name="revocationDate"
                data-cy="revocationDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('alippoApp.userTopic.metadata')}
                id="user-topic-metadata"
                name="metadata"
                data-cy="metadata"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.userTopic.createdAt')}
                id="user-topic-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.userTopic.updatedAt')}
                id="user-topic-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField id="user-topic-device" name="device" data-cy="device" label={translate('alippoApp.userTopic.device')} />
              <ValidatedField id="user-topic-user" name="user" data-cy="user" label={translate('alippoApp.userTopic.user')} />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/user-topic" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UserTopicUpdate;
