import React, { FormEvent, useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Label, Input, Spinner } from 'reactstrap';
import axios from 'axios';
import { omit } from 'lodash';

import { ICourse } from 'app/shared/model/course.model';
import { IResCourse } from 'app/shared/model/res-course.model';
import { CourseLevel } from 'app/shared/model/enumerations/course-level.model';
import { CourseStatus } from 'app/shared/model/enumerations/course-status.model';
import { CurrencyCode } from 'app/shared/model/enumerations/currency-code.model';
import { convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { ICourseTranslation } from 'app/shared/model/course-translation.model';
import { ICourseSection } from 'app/shared/model/course-section.model';

export const CourseClone = (props: RouteComponentProps<{ id: string }>) => {
  const [cloningCourse, setCloningCourse] = useState<ICourse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    const courseId = new URLSearchParams(history.location.search).get('courseId');

    if (courseId) {
      getCourseDetails(courseId);
    }
  }, []);

  // Fetch course details from courseId
  const getCourseDetails = async (courseId: string) => {
    try {
      setIsLoading(true);

      // course details
      const { data: courseData } = await axios.get<ICourse>(`api/courses/${courseId}`);

      // course transactions
      const { data: courseTranslationsData } = await axios.get<ICourseTranslation[]>(
        `api/course-translations?courseId.equals=${courseData.id}`
      );

      // course sections
      const { data: courseSectionsData } = await axios.get<ICourseSection[]>(
        `api/course-sections?courseId.equals=${courseData.id}&size=200`
      );

      setCloningCourse({
        ...courseData,
        translations: courseTranslationsData,
        courseSections: courseSectionsData,
      });
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.detail);
    } finally {
      setIsLoading(false);
    }
  };

  // Stop cloning process
  const handleStopCloningCourse = () => {
    setCloningCourse(null);
  };

  // Handler to submit course id
  const handleCourseToBeClonedSubmit = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const formData = new FormData(ev.target as HTMLFormElement);

    const courseId = formData.get('courseId') as string;

    getCourseDetails(courseId);
  };

  // Handler to submit course name
  const handleCourseCloneSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const formData = new FormData(ev.target as HTMLFormElement);

    const courseName = formData.get('courseName') as string;

    if (courseName) {
      let newConfigPayload = null;

      if (cloningCourse.configPayload) {
        newConfigPayload = JSON.stringify(omit(JSON.parse(cloningCourse.configPayload), ['userOrderPageLink']));
      }

      const course: any = {
        ...omit(cloningCourse, [
          'id',
          'courseSections',
          'courseDiscussions',
          'resources',
          'sku',
          'skuId',
          'translations',
          'discussions',
          'configPayload',
          'seoConfigPayload',
        ]),
        name: courseName,
        sellingPriceCurrencyCode: cloningCourse.sellingPriceCurrencyCode || CurrencyCode.INR,
        mrpPriceCurrencyCode: cloningCourse.mrpPriceCurrencyCode || CurrencyCode.INR,
        courseStatus: CourseStatus.DRAFT,
        courseLevel: cloningCourse.courseLevel || CourseLevel.BEGINNER,
        fromDateTime: convertDateTimeToServer(displayDefaultDateTime()),
        createdAt: convertDateTimeToServer(displayDefaultDateTime()),
        updatedAt: convertDateTimeToServer(displayDefaultDateTime()),
        sku: { id: undefined },
        categories: mapIdList(cloningCourse?.categories?.map(e => e.id.toString())),
        configPayload: newConfigPayload,
      };

      try {
        setIsLoading(true);
        const { data } = await axios.post<ICourse>('https://api.alippo.com/backend-prod/api/courses', course);

        const newCourseId = data.id;

        // Clone course translations
        if (cloningCourse.translations?.length > 0) {
          const promises = cloningCourse.translations.map(async translation => {
            const newCourseTranslation: any = {
              ...omit(translation, ['id']),
              course: {
                id: newCourseId,
              },
              createdAt: convertDateTimeToServer(displayDefaultDateTime()),
              updatedAt: convertDateTimeToServer(displayDefaultDateTime()),
            };

            return await axios.post<ICourseTranslation>(
              'https://api.alippo.com/backend-prod/api/course-translations',
              newCourseTranslation
            );
          });

          await Promise.allSettled(promises);
        }

        // Clone course section
        if (cloningCourse.courseSections?.length > 0) {
          const promises = cloningCourse.courseSections.map(async courseSection => {
            const newCourseSection: any = {
              course: {
                id: newCourseId,
              },
              fromDateTime: convertDateTimeToServer(displayDefaultDateTime()),
              createdAt: convertDateTimeToServer(displayDefaultDateTime()),
              updatedAt: convertDateTimeToServer(displayDefaultDateTime()),
              section: {
                id: courseSection.section.id, // use same section
              },
              duration: courseSection.duration,
              sortOrder: courseSection.sortOrder,
            };

            return await axios.post<ICourseSection>(`https://api.alippo.com/backend-prod/api/course-sections`, newCourseSection);
          });

          await Promise.allSettled(promises);
        }

        // Clone course resources
        if (cloningCourse.resources?.length > 0) {
          const promises = cloningCourse.resources.map(async resource => {
            const newResCourse: any = {
              ...omit(resource, ['id']),
              course: {
                id: newCourseId,
              },
              createdAt: convertDateTimeToServer(displayDefaultDateTime()),
              updatedAt: convertDateTimeToServer(displayDefaultDateTime()),
            };

            return await axios.post<IResCourse>('https://api.alippo.com/backend-prod/api/res-courses', newResCourse);
          });

          await Promise.allSettled(promises);
        }

        history.replace(`/course/${newCourseId}`);
        toast.success('Course cloned successfully');
      } catch (err) {
        console.error(err);
        toast.error(err.response?.data?.detail);
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error('Invalid Course Name');
    }
  };

  return isLoading ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '8rem' }}>
      <Spinner className="align-baseline" />
    </div>
  ) : cloningCourse ? (
    <div className="layout-wrapper">
      <p style={{ fontSize: 'large' }}>
        You are about to clone <span className="font-weight-bold">{cloningCourse.name}</span>
      </p>
      <Form className="mx-auto" onSubmit={handleCourseCloneSubmit}>
        <FormGroup>
          <Label>Course Name</Label>
          <Input type="text" name="courseName" id="courseName" placeholder="Enter the course name for the new course..." />
        </FormGroup>
        <div className="d-flex justify-content-end">
          <Button className="mr-2" type="button" onClick={handleStopCloningCourse}>
            Back
          </Button>
          <Button type="submit" color="success">
            Submit
          </Button>
        </div>
      </Form>
    </div>
  ) : (
    <Form className="layout-wrapper" onSubmit={handleCourseToBeClonedSubmit}>
      <FormGroup>
        <Label>Course ID</Label>
        <Input type="number" name="courseId" id="courseId" placeholder="Enter the id of the course that you want to clone..." />
      </FormGroup>
      <Button className="d-block ml-auto" type="submit" color="success">
        Submit
      </Button>
    </Form>
  );
};

export default CourseClone;
