import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './rule-ledger.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const RuleLedgerDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const ruleLedgerEntity = useAppSelector(state => state.ruleLedger.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="ruleLedgerDetailsHeading">
          <Translate contentKey="alippoApp.ruleLedger.detail.title">RuleLedger</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.id}</dd>
          <dt>
            <span id="ledgerId">
              <Translate contentKey="alippoApp.ruleLedger.ledgerId">Ledger Id</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.ledgerId}</dd>
          <dt>
            <span id="referredAmount">
              <Translate contentKey="alippoApp.ruleLedger.referredAmount">Referred Amount</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referredAmount}</dd>
          <dt>
            <span id="referredBasis">
              <Translate contentKey="alippoApp.ruleLedger.referredBasis">Referred Basis</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referredBasis}</dd>
          <dt>
            <span id="referredRuleCurrency">
              <Translate contentKey="alippoApp.ruleLedger.referredRuleCurrency">Referred Rule Currency</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referredRuleCurrency}</dd>
          <dt>
            <span id="referredCurrencyCode">
              <Translate contentKey="alippoApp.ruleLedger.referredCurrencyCode">Referred Currency Code</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referredCurrencyCode}</dd>
          <dt>
            <span id="referredAmountUsed">
              <Translate contentKey="alippoApp.ruleLedger.referredAmountUsed">Referred Amount Used</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referredAmountUsed}</dd>
          <dt>
            <span id="referredUsageLimit">
              <Translate contentKey="alippoApp.ruleLedger.referredUsageLimit">Referred Usage Limit</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referredUsageLimit}</dd>
          <dt>
            <span id="referredTimesUsed">
              <Translate contentKey="alippoApp.ruleLedger.referredTimesUsed">Referred Times Used</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referredTimesUsed}</dd>
          <dt>
            <span id="referredAmountStatus">
              <Translate contentKey="alippoApp.ruleLedger.referredAmountStatus">Referred Amount Status</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referredAmountStatus}</dd>
          <dt>
            <span id="referredFromDateTime">
              <Translate contentKey="alippoApp.ruleLedger.referredFromDateTime">Referred From Date Time</Translate>
            </span>
          </dt>
          <dd>
            {ruleLedgerEntity.referredFromDateTime ? (
              <TextFormat value={ruleLedgerEntity.referredFromDateTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="referredDuration">
              <Translate contentKey="alippoApp.ruleLedger.referredDuration">Referred Duration</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referredDuration}</dd>
          <dt>
            <span id="referrerAmount">
              <Translate contentKey="alippoApp.ruleLedger.referrerAmount">Referrer Amount</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referrerAmount}</dd>
          <dt>
            <span id="referrerBasis">
              <Translate contentKey="alippoApp.ruleLedger.referrerBasis">Referrer Basis</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referrerBasis}</dd>
          <dt>
            <span id="referrerRuleCurrency">
              <Translate contentKey="alippoApp.ruleLedger.referrerRuleCurrency">Referrer Rule Currency</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referrerRuleCurrency}</dd>
          <dt>
            <span id="referrerCurrencyCode">
              <Translate contentKey="alippoApp.ruleLedger.referrerCurrencyCode">Referrer Currency Code</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referrerCurrencyCode}</dd>
          <dt>
            <span id="referrerAmountUsed">
              <Translate contentKey="alippoApp.ruleLedger.referrerAmountUsed">Referrer Amount Used</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referrerAmountUsed}</dd>
          <dt>
            <span id="referrerUsageLimit">
              <Translate contentKey="alippoApp.ruleLedger.referrerUsageLimit">Referrer Usage Limit</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referrerUsageLimit}</dd>
          <dt>
            <span id="referrerTimesUsed">
              <Translate contentKey="alippoApp.ruleLedger.referrerTimesUsed">Referrer Times Used</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referrerTimesUsed}</dd>
          <dt>
            <span id="referrerAmountStatus">
              <Translate contentKey="alippoApp.ruleLedger.referrerAmountStatus">Referrer Amount Status</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referrerAmountStatus}</dd>
          <dt>
            <span id="referrerFromDateTime">
              <Translate contentKey="alippoApp.ruleLedger.referrerFromDateTime">Referrer From Date Time</Translate>
            </span>
          </dt>
          <dd>
            {ruleLedgerEntity.referrerFromDateTime ? (
              <TextFormat value={ruleLedgerEntity.referrerFromDateTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="referrerDuration">
              <Translate contentKey="alippoApp.ruleLedger.referrerDuration">Referrer Duration</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.referrerDuration}</dd>
          <dt>
            <span id="expiryAt">
              <Translate contentKey="alippoApp.ruleLedger.expiryAt">Expiry At</Translate>
            </span>
          </dt>
          <dd>
            {ruleLedgerEntity.expiryAt ? <TextFormat value={ruleLedgerEntity.expiryAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="metadata">
              <Translate contentKey="alippoApp.ruleLedger.metadata">Metadata</Translate>
            </span>
          </dt>
          <dd>{ruleLedgerEntity.metadata}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.ruleLedger.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {ruleLedgerEntity.createdAt ? <TextFormat value={ruleLedgerEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.ruleLedger.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {ruleLedgerEntity.updatedAt ? <TextFormat value={ruleLedgerEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.ruleLedger.referredUser">Referred User</Translate>
          </dt>
          <dd>{ruleLedgerEntity.referredUser ? ruleLedgerEntity.referredUser.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.ruleLedger.referrerUser">Referrer User</Translate>
          </dt>
          <dd>{ruleLedgerEntity.referrerUser ? ruleLedgerEntity.referrerUser.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.ruleLedger.referredRule">Referred Rule</Translate>
          </dt>
          <dd>{ruleLedgerEntity.referredRule ? ruleLedgerEntity.referredRule.name : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.ruleLedger.referrerRule">Referrer Rule</Translate>
          </dt>
          <dd>{ruleLedgerEntity.referrerRule ? ruleLedgerEntity.referrerRule.name : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.ruleLedger.orderInfo">Order Info</Translate>
          </dt>
          <dd>{ruleLedgerEntity.orderInfo ? ruleLedgerEntity.orderInfo.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.ruleLedger.task">Task</Translate>
          </dt>
          <dd>{ruleLedgerEntity.task ? ruleLedgerEntity.task.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/rule-ledger" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/rule-ledger/${ruleLedgerEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default RuleLedgerDetail;
