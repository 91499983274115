import dayjs from 'dayjs';
import { ISku } from 'app/shared/model/sku.model';
import { ICategory } from 'app/shared/model/category.model';

export interface ISkuSegment {
  id?: number;
  active?: boolean;
  name?: string;
  source?: string | null;
  metadata?: string | null;
  createdAt?: string;
  updatedAt?: string;
  sku?: ISku;
  topic?: ICategory | null;
}

export const defaultValue: Readonly<ISkuSegment> = {
  active: false,
};
