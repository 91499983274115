import React, { useState, useEffect, ChangeEvent } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRes } from 'app/shared/model/res.model';
import { getEntities as getRes } from 'app/entities/res/res.reducer';
import { getEntities as getCourseSectionLessons } from 'app/entities/course-section-lesson/course-section-lesson.reducer';
import { getEntity, updateEntity, createEntity, reset } from './res-course-section-lesson.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ResState } from 'app/shared/model/enumerations/res-state.model';
import { ResType } from 'app/shared/model/enumerations/res-type.model';
import { ResSource } from 'app/shared/model/enumerations/res-source.model';
import { ResMediaType } from 'app/shared/model/enumerations/res-media-type.model';
import { Language } from 'app/shared/model/enumerations/language.model';
import axios from 'axios';
import { isValidHttpUrl } from 'app/shared/util/utils';

export const ResCourseSectionLessonUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [lessonResType, setLessonResType] = useState<ResType | null>(null);

  const res = useAppSelector(state => state.res.entities);
  const resCourseSectionLessonEntity = useAppSelector(state => state.resCourseSectionLesson.entity);
  const loading = useAppSelector(state => state.resCourseSectionLesson.loading);
  const updating = useAppSelector(state => state.resCourseSectionLesson.updating);
  const updateSuccess = useAppSelector(state => state.resCourseSectionLesson.updateSuccess);
  const resStateValues = Object.keys(ResState);
  const resTypeValues = Object.keys(ResType);
  const resSourceValues = Object.keys(ResSource);
  const resMediaTypeValues = Object.keys(ResMediaType);
  const languageValues = Object.keys(Language);

  useEffect(() => {
    if (resCourseSectionLessonEntity) {
      setLessonResType(resCourseSectionLessonEntity.resType);
    }
  }, [resCourseSectionLessonEntity]);

  const handleClose = () => {
    if (resCourseSectionLessonEntity && resCourseSectionLessonEntity.id) {
      props.history.push('/res-course-section-lesson/' + `${resCourseSectionLessonEntity.id}`);
    } else {
      props.history.push('/res-course-section-lesson' + props.location.search);
    }
  };

  const zoomEmailMap = {
    ZOOM1: 'ayushi@alippo.com',
    ZOOM2: 'nikhil@alippo.com',
    ZOOM3: 'care@alippo.com',
    ZOOM4: 'sukriti@alippo.com',
    ZOOM5: 'ayushi.g@alippo.com',
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getRes({}));
    dispatch(getCourseSectionLessons({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const handleResTypeChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setLessonResType(ev.target.value as ResType);
  };

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...resCourseSectionLessonEntity,
      ...values,
      res: { id: values.res },
      courseSectionLesson: { id: values.courseSectionLesson },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
          resType: lessonResType ?? 'EBOOK',
        }
      : {
          resState: 'AVAILABLE',
          resSource: 'ZOOM1',
          mediaType: 'IMAGE',
          language: 'HINGLISH',
          ...resCourseSectionLessonEntity,
          resType: lessonResType ?? 'EBOOK',
          createdAt: convertDateTimeFromServer(resCourseSectionLessonEntity.createdAt),
          updatedAt: convertDateTimeFromServer(resCourseSectionLessonEntity.updatedAt),
          res: resCourseSectionLessonEntity?.res?.id,
          courseSectionLesson: resCourseSectionLessonEntity?.courseSectionLesson?.id,
        };

  const validateUrlField = async (url: string) => {
    if (lessonResType === ResType.RECORDING && !isValidHttpUrl(url) && url !== 'sample.mp4') {
      const apiUrl = 'api/aws/s3/exists';
      const requestParams: { bucketName: string; objectName: string } = {
        bucketName: 'alippo-recording-files',
        objectName: url,
      };

      const { data } = await axios.get<boolean>(apiUrl, {
        params: requestParams,
      });

      return data;
    }

    return true;
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.resCourseSectionLesson.home.createOrEditLabel" data-cy="ResCourseSectionLessonCreateUpdateHeading">
            <Translate contentKey="alippoApp.resCourseSectionLesson.home.createOrEditLabel">
              Create or edit a ResCourseSectionLesson
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="res-course-section-lesson-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.resCourseSectionLesson.name')}
                id="res-course-section-lesson-name"
                name="name"
                data-cy="name"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.resCourseSectionLesson.url')}
                id="res-course-section-lesson-url"
                name="url"
                data-cy="url"
                type="text"
                validate={{
                  validate: async url => (await validateUrlField(url)) || 'Given resource is not found in S3',
                }}
              />
              <ValidatedField
                label={translate('alippoApp.resCourseSectionLesson.resState')}
                id="res-course-section-lesson-resState"
                name="resState"
                data-cy="resState"
                type="select"
              >
                {resStateValues.map(resState => (
                  <option value={resState} key={resState}>
                    {translate('alippoApp.ResState' + resState)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resCourseSectionLesson.resType')}
                id="res-course-section-lesson-resType"
                name="resType"
                data-cy="resType"
                type="select"
                onChange={handleResTypeChange}
              >
                {resTypeValues.map(resType => (
                  <option value={resType} key={resType}>
                    {translate('alippoApp.ResType' + resType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resCourseSectionLesson.resSource')}
                id="res-course-section-lesson-resSource"
                name="resSource"
                data-cy="resSource"
                type="select"
              >
                {resSourceValues.map(resSource => (
                  <option value={resSource} key={resSource}>
                    {translate('alippoApp.ResSource' + resSource)} - {zoomEmailMap[resSource]}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resCourseSectionLesson.mediaType')}
                id="res-course-section-lesson-mediaType"
                name="mediaType"
                data-cy="mediaType"
                type="select"
              >
                {resMediaTypeValues.map(resMediaType => (
                  <option value={resMediaType} key={resMediaType}>
                    {translate('alippoApp.ResMediaType' + resMediaType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resCourseSectionLesson.description')}
                id="res-course-section-lesson-description"
                name="description"
                data-cy="description"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.resCourseSectionLesson.language')}
                id="res-course-section-lesson-language"
                name="language"
                data-cy="language"
                type="select"
              >
                {languageValues.map(language => (
                  <option value={language} key={language}>
                    {translate('alippoApp.Language' + language)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resCourseSectionLesson.videoControlsConfig')}
                id="res-course-section-lesson-videoControlsConfig"
                name="videoControlsConfig"
                data-cy="videoControlsConfig"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.resCourseSectionLesson.aspectRatio')}
                id="res-course-section-lesson-aspectRatio"
                name="aspectRatio"
                data-cy="aspectRatio"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.resCourseSectionLesson.createdAt')}
                id="res-course-section-lesson-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.resCourseSectionLesson.updatedAt')}
                id="res-course-section-lesson-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="res-course-section-lesson-res"
                name="res"
                data-cy="res"
                label={translate('alippoApp.resCourseSectionLesson.res')}
              />
              <ValidatedField
                id="res-course-section-lesson-courseSectionLesson"
                name="courseSectionLesson"
                data-cy="courseSectionLesson"
                label={translate('alippoApp.resCourseSectionLesson.courseSectionLesson')}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/res-course-section-lesson" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ResCourseSectionLessonUpdate;
