import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Table, UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './course-section-lesson.reducer';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { toast } from 'react-toastify';
import { isValidHttpUrl, nullifyEmptyString } from 'app/shared/util/utils';
import { IUserCourseSectionLesson } from 'app/shared/model/user-course-section-lesson.model';
import { ClassChecklistResponseType } from 'app/modules/instructor-checklist';
import { convertToLocalDateTime } from 'app/shared/util/date-utils';
import ReactJson from 'react-json-view';

export const CourseSectionLessonDetail = (props: RouteComponentProps<{ id: string }>) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const courseSectionLessonEntity = useAppSelector(state => state.courseSectionLesson.entity);
  const [resourcesData, setResourcesData] = useState(null);
  const [instructorsData, setInstructorsData] = useState<IUserCourseSectionLesson[]>(null);
  const [instructorChecklistData, setInstructorChecklistData] = useState<ClassChecklistResponseType[]>(null);
  const [reminders, setReminders] = useState<any[]>(null);

  const zoomEmailMap = {
    ZOOM1: 'ayushi@alippo.com',
    ZOOM2: 'nikhil@alippo.com',
    ZOOM3: 'care@alippo.com',
    ZOOM4: 'sukriti@alippo.com',
    ZOOM5: 'ayushi.g@alippo.com',
  };

  const handleModalOpen = () => {
    setShowDeleteDialog(true);
  };

  const handleModalClose = () => {
    setShowDeleteDialog(false);
  };

  const getSectionLessons = async () => {
    if (courseSectionLessonEntity.id) {
      try {
        const apiUrl = `api/res-course-section-lessons?courseSectionLessonId.equals=${courseSectionLessonEntity.id}`;
        const res: any = await axios.get(apiUrl);
        const resources = res.data;
        if (resources.length === 0) {
          toast.error('No Resources available');
        } else {
          setResourcesData(
            resources.map(e => {
              const resrouceId = e.id;
              return {
                id: resrouceId,
                name: e.name,
                url: e.url,
                state: e.resState,
                type: e.resType,
                source: e.resSource,
                link: `/res-course-section-lesson/${resrouceId}`,
              };
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSyncRecordings = async () => {
    if (courseSectionLessonEntity) {
      try {
        const apiUrl = `api/customs/sync-recordings`;
        const postData = [courseSectionLessonEntity.id];
        const res = await axios.post(apiUrl, postData);
        getSectionLessons();
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleSyncLiveSessions = async () => {
    if (courseSectionLessonEntity) {
      try {
        const apiUrl = `api/customs/sync-zoom-meetings`;
        const postData = [courseSectionLessonEntity.id];
        const res = await axios.post(apiUrl, postData);
        getSectionLessons();
      } catch (err) {
        console.error(err);
      }
    }
  };

  const getInstructorChecklistResponses = async () => {
    if (courseSectionLessonEntity.id) {
      try {
        const apiUrl = `api/custom/class-checklist-responses/${courseSectionLessonEntity.id}`;
        const { data } = await axios.get(apiUrl);

        if (data && data?.length > 0) {
          setInstructorChecklistData(data);
        } else {
          setInstructorChecklistData(null);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const getReminders = async () => {
    if (courseSectionLessonEntity.id) {
      try {
        const apiUrl = `api/course-section-lesson/${courseSectionLessonEntity.id}/reminders`;
        const { data } = await axios.get(apiUrl);

        if (data && data?.length > 0) {
          setReminders(data);
        } else {
          setReminders(null);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const deleteInstructorChecklistResponses = async () => {
    if (courseSectionLessonEntity.id) {
      try {
        const apiUrl = `api/custom/class-checklist-responses/${courseSectionLessonEntity.id}/delete`;
        await axios.delete(apiUrl);
        toast.success('Deleted responses successfully');
      } catch (err) {
        console.error(err);
        toast.error(err?.response?.data?.detail);
      } finally {
        handleModalClose();
        getInstructorChecklistResponses();
      }
    }
  };

  const getInstructors = async () => {
    if (courseSectionLessonEntity.id) {
      try {
        const apiUrl = `api/user-course-section-lessons?courseSectionLessonId.equals=${courseSectionLessonEntity.id}`;
        const { data } = await axios.get<IUserCourseSectionLesson[]>(apiUrl);
        if (data.length === 0) {
          toast.error('No Instructors available');
        } else {
          setInstructorsData(data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <Row>
        <Col md="8">
          <h2 data-cy="courseSectionLessonDetailsHeading">
            <Translate contentKey="alippoApp.courseSectionLesson.detail.title">CourseSectionLesson</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="id">
                <Translate contentKey="global.field.id">ID</Translate>
              </span>
            </dt>
            <dd>{courseSectionLessonEntity.id}</dd>
            <dt>
              <span id="fromDateTime">
                <Translate contentKey="alippoApp.courseSectionLesson.fromDateTime">From Date Time</Translate>
              </span>
            </dt>
            <dd>
              {courseSectionLessonEntity.fromDateTime ? (
                <TextFormat value={courseSectionLessonEntity.fromDateTime} type="date" format={APP_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <span id="duration">
                <Translate contentKey="alippoApp.courseSectionLesson.duration">Duration</Translate>
              </span>
            </dt>
            <dd>{courseSectionLessonEntity.duration}</dd>
            <dt>
              <span id="sortOrder">
                <Translate contentKey="alippoApp.courseSectionLesson.sortOrder">Sort Order</Translate>
              </span>
            </dt>
            <dd>{courseSectionLessonEntity.sortOrder}</dd>
            <dt>
              <span id="isPreRecorded">
                <Translate contentKey="alippoApp.courseSectionLesson.isPreRecorded">Is Pre Recorded</Translate>
              </span>
            </dt>
            <dd>{courseSectionLessonEntity.isPreRecorded ? 'true' : 'false'}</dd>
            <dt>
              <span id="createdAt">
                <Translate contentKey="alippoApp.courseSectionLesson.createdAt">Created At</Translate>
              </span>
            </dt>
            <dd>
              {courseSectionLessonEntity.createdAt ? (
                <TextFormat value={courseSectionLessonEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <span id="updatedAt">
                <Translate contentKey="alippoApp.courseSectionLesson.updatedAt">Updated At</Translate>
              </span>
            </dt>
            <dd>
              {courseSectionLessonEntity.updatedAt ? (
                <TextFormat value={courseSectionLessonEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <span id="configPayload">
                <Translate contentKey="alippoApp.courseSectionLesson.configPayload">Config Payload</Translate>
              </span>
            </dt>
            <dd>
              <ReactJson
                src={JSON.parse(nullifyEmptyString(courseSectionLessonEntity?.configPayload) ?? '{}')}
                displayObjectSize={false}
                displayDataTypes={false}
                collapsed
              />
            </dd>
            <dt>
              <Translate contentKey="alippoApp.courseSectionLesson.lesson">Lesson</Translate>
            </dt>
            <dd>{courseSectionLessonEntity.lesson ? courseSectionLessonEntity.lesson.name : ''}</dd>
            <dt>
              <Translate contentKey="alippoApp.courseSectionLesson.section">Section</Translate>
            </dt>
            <dd>{courseSectionLessonEntity.section ? courseSectionLessonEntity.section.name : ''}</dd>
            <dt>
              <Translate contentKey="alippoApp.courseSectionLesson.associatedCourseSectionLesson">
                Associated Course Section Lesson
              </Translate>
            </dt>
            <dd>
              {courseSectionLessonEntity.associatedCourseSectionLesson ? courseSectionLessonEntity.associatedCourseSectionLesson.id : ''}
            </dd>
            <dt>
              <Translate contentKey="alippoApp.courseSectionLesson.discussion">Discussion</Translate>
            </dt>
            <dd>
              {courseSectionLessonEntity.discussion ? (
                <Link target="_blank" rel="noopener noreferrer" to={`/course-discussion/${courseSectionLessonEntity.discussion.id}`}>
                  {courseSectionLessonEntity.discussion.id}
                </Link>
              ) : (
                ''
              )}
            </dd>
            <dt>
              <Translate contentKey="alippoApp.courseSectionLesson.courseSection">Course Section</Translate>
            </dt>
            <dd>{courseSectionLessonEntity.courseSection ? courseSectionLessonEntity.courseSection.id : ''}</dd>
          </dl>
          <dl>
            <dt>Resources</dt>
            <Button onClick={getSectionLessons} color="primary">
              <span>Get Resources</span>
            </Button>
            &nbsp;
            <Button onClick={handleSyncRecordings} color="primary">
              <span>Sync Recordings</span>
            </Button>
            &nbsp;
            <Button onClick={handleSyncLiveSessions} color="primary">
              <span>Sync Live sessions</span>
            </Button>
            <dd>
              {resourcesData && resourcesData.length > 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="hand">Resource ID</th>
                      <th className="hand">Resource Name</th>
                      <th className="hand">Resource URL</th>
                      <th className="hand">Resource Type</th>
                      <th className="hand">Resource State</th>
                      <th className="hand">Resource Source</th>
                      <th className="hand">Resource Zoom Email</th>
                      <th className="hand">Course Section Lesson Resource Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resourcesData.map((resource, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable">
                        <td>{resource.id}</td>
                        <td>{resource.name}</td>
                        <td>
                          <div>
                            <a target="_blank" rel="noopener noreferrer" href={resource.url}>
                              {resource.url}
                            </a>
                          </div>
                        </td>
                        <td>{resource.type}</td>
                        <td>{resource.state}</td>
                        <td>{resource.source}</td>
                        <td>{zoomEmailMap[resource.source]}</td>
                        <td>
                          <div>
                            <Link to={resource.link}>
                              <span id="resource-link">Link</span>
                            </Link>
                            <UncontrolledTooltip target="resource-link" placement="top">
                              {resource.link}
                            </UncontrolledTooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : null}
            </dd>
          </dl>
          <dl>
            <dt>Instructors</dt>
            <Button onClick={getInstructors} className="mr-2" color="primary">
              <span>Get Instructors</span>
            </Button>
            <Link to={{ pathname: '/user-course-section-lesson/new', state: { courseSectionLesson: courseSectionLessonEntity } }}>
              <Button className="rounded-circle" color="success">
                <FontAwesomeIcon icon="plus" />
              </Button>
            </Link>
            <dd>
              {instructorsData && instructorsData.length > 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="hand">User Course Section Lesson ID</th>
                      <th className="hand">Instructor ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {instructorsData.map((item, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable">
                        <td>
                          <div>
                            <Link to={`/user-course-section-lesson/${item.id}`}>{item.id}</Link>
                          </div>
                        </td>
                        <td>{item.instructor.id}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : null}
            </dd>
          </dl>
          <dl>
            <dt>Checklist responses</dt>
            <Button onClick={getInstructorChecklistResponses} color="primary">
              <span>Get Responses</span>
            </Button>
            &nbsp;
            <Button onClick={handleModalOpen} color="primary">
              <span>Delete Responses</span>
            </Button>
            <dd>
              {instructorChecklistData && instructorChecklistData.length > 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th>Answer</th>
                      <th>Comment</th>
                      <th>Updated At</th>
                    </tr>
                  </thead>
                  <tbody className="text-nowrap">
                    {instructorChecklistData.map((response, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable">
                        <td>{response.question.question}</td>
                        <td>
                          {isValidHttpUrl(response.answer) ? (
                            <a href={response.answer} target="_blank" rel="noreferrer">
                              Screenshot
                            </a>
                          ) : (
                            response.answer
                          )}
                        </td>
                        <td>{response.comment}</td>
                        <td>{convertToLocalDateTime(response.updatedAt)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : null}
            </dd>
          </dl>
          <dl>
            <dt>Reminders</dt>
            <Button onClick={getReminders} color="primary">
              <span>Get Reminders</span>
            </Button>
            <dd>
              {reminders && reminders.length > 0 ? (
                <ReactJson src={reminders} displayObjectSize={false} displayDataTypes={false} collapsed />
              ) : null}
            </dd>
          </dl>
          <Button tag={Link} to="/course-section-lesson" replace color="info" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/course-section-lesson/${courseSectionLessonEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/course-section-lesson/${courseSectionLessonEntity.id}/delete`} replace color="danger">
            <FontAwesomeIcon icon="trash" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.delete">Delete</Translate>
            </span>
          </Button>
        </Col>
      </Row>

      <Modal isOpen={showDeleteDialog} toggle={handleModalClose}>
        <ModalHeader toggle={handleModalClose}>Confirm delete operation</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the instructor checklist responses of CourseSectionLesson {courseSectionLessonEntity.id}?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleModalClose}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button id="jhi-confirm-delete-courseSectionLesson" color="danger" onClick={deleteInstructorChecklistResponses}>
            <FontAwesomeIcon icon="trash" />
            &nbsp;
            <Translate contentKey="entity.action.delete">Delete</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CourseSectionLessonDetail;
