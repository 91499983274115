import dayjs from 'dayjs';
import { ILessonTranslation } from 'app/shared/model/lesson-translation.model';

export interface ILesson {
  id?: number;
  name?: string;
  description?: string | null;
  createdAt?: string;
  updatedAt?: string;
  translations?: ILessonTranslation[] | null;
}

export const defaultValue: Readonly<ILesson> = {};
