import dayjs from 'dayjs';
import { ISectionTranslation } from 'app/shared/model/section-translation.model';

export interface ISection {
  id?: number;
  name?: string;
  description?: string | null;
  createdAt?: string;
  updatedAt?: string;
  translations?: ISectionTranslation[] | null;
}

export const defaultValue: Readonly<ISection> = {};
