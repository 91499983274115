export enum SeoMetadataType {
  PRIVACY = 'PRIVACY',

  ABOUT_US = 'ABOUT_US',

  CONTACT = 'CONTACT',

  TERMS_AND_CONDITION = 'TERMS_AND_CONDITION',

  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',

  CANCELLATION_POLICY = 'CANCELLATION_POLICY',

  REFUND_POLICY = 'REFUND_POLICY',

  HOME_PAGE = 'HOME_PAGE',

  COURSE = 'COURSE',

  CATEGORY = 'CATEGORY',

  REFER_EARN = 'REFER_EARN',

  BLOG_ROOT = 'BLOG_ROOT',

  BLOG_CATEGORY_ROOT = 'BLOG_CATEGORY_ROOT',

  ACCOUNT_DELETION_PAGE = 'ACCOUNT_DELETION_PAGE',
}
