import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ICourse } from 'app/shared/model/course.model';
import { getEntities as getCourses } from 'app/entities/course/course.reducer';
import { ISku } from 'app/shared/model/sku.model';
import { getEntities as getSkus } from 'app/entities/sku/sku.reducer';
import { ICart } from 'app/shared/model/cart.model';
import { getEntities as getCarts } from 'app/entities/cart/cart.reducer';
import { getEntity, updateEntity, createEntity, reset } from './cart-item.reducer';
import { ICartItem } from 'app/shared/model/cart-item.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CurrencyCode } from 'app/shared/model/enumerations/currency-code.model';
import { CartItemUnavailabilityReason } from 'app/shared/model/enumerations/cart-item-unavailability-reason.model';

export const CartItemUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const courses = useAppSelector(state => state.course.entities);
  const skus = useAppSelector(state => state.sku.entities);
  const carts = useAppSelector(state => state.cart.entities);
  const cartItemEntity = useAppSelector(state => state.cartItem.entity);
  const loading = useAppSelector(state => state.cartItem.loading);
  const updating = useAppSelector(state => state.cartItem.updating);
  const updateSuccess = useAppSelector(state => state.cartItem.updateSuccess);
  const currencyCodeValues = Object.keys(CurrencyCode);
  const cartItemUnavailabilityReasonValues = Object.keys(CartItemUnavailabilityReason);
  const handleClose = () => {
    props.history.push('/cart-item' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getCourses({}));
    dispatch(getSkus({}));
    dispatch(getCarts({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);
    values.availabilityUpdatedAt = convertDateTimeToServer(values.availabilityUpdatedAt);

    const entity = {
      ...cartItemEntity,
      ...values,
      course: courses.find(it => it.id.toString() === values.course.toString()),
      sku: skus.find(it => it.id.toString() === values.sku.toString()),
      cart: carts.find(it => it.id.toString() === values.cart.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
          availabilityUpdatedAt: displayDefaultDateTime(),
        }
      : {
          sellingPriceCurrencyCode: 'INR',
          unavailabilityReason: 'REMOVED_BY_USER',
          ...cartItemEntity,
          createdAt: convertDateTimeFromServer(cartItemEntity.createdAt),
          updatedAt: convertDateTimeFromServer(cartItemEntity.updatedAt),
          availabilityUpdatedAt: convertDateTimeFromServer(cartItemEntity.availabilityUpdatedAt),
          course: cartItemEntity?.course?.id,
          sku: cartItemEntity?.sku?.id,
          cart: cartItemEntity?.cart?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.cartItem.home.createOrEditLabel" data-cy="CartItemCreateUpdateHeading">
            <Translate contentKey="alippoApp.cartItem.home.createOrEditLabel">Create or edit a CartItem</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="cart-item-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.cartItem.sellingPrice')}
                id="cart-item-sellingPrice"
                name="sellingPrice"
                data-cy="sellingPrice"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alippoApp.cartItem.sellingPriceCurrencyCode')}
                id="cart-item-sellingPriceCurrencyCode"
                name="sellingPriceCurrencyCode"
                data-cy="sellingPriceCurrencyCode"
                type="select"
              >
                {currencyCodeValues.map(currencyCode => (
                  <option value={currencyCode} key={currencyCode}>
                    {translate('alippoApp.CurrencyCode' + currencyCode)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.cartItem.description')}
                id="cart-item-description"
                name="description"
                data-cy="description"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.cartItem.metaData')}
                id="cart-item-metaData"
                name="metaData"
                data-cy="metaData"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.cartItem.available')}
                id="cart-item-available"
                name="available"
                data-cy="available"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.cartItem.platform')}
                id="cart-item-platform"
                name="platform"
                data-cy="platform"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.cartItem.platformVersion')}
                id="cart-item-platformVersion"
                name="platformVersion"
                data-cy="platformVersion"
                type="text"
              />
              <ValidatedField label={translate('alippoApp.cartItem.utm')} id="cart-item-utm" name="utm" data-cy="utm" type="textarea" />
              <ValidatedField
                label={translate('alippoApp.cartItem.unavailabilityReason')}
                id="cart-item-unavailabilityReason"
                name="unavailabilityReason"
                data-cy="unavailabilityReason"
                type="select"
              >
                {cartItemUnavailabilityReasonValues.map(cartItemUnavailabilityReason => (
                  <option value={cartItemUnavailabilityReason} key={cartItemUnavailabilityReason}>
                    {translate('alippoApp.CartItemUnavailabilityReason' + cartItemUnavailabilityReason)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.cartItem.createdAt')}
                id="cart-item-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.cartItem.updatedAt')}
                id="cart-item-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.cartItem.availabilityUpdatedAt')}
                id="cart-item-availabilityUpdatedAt"
                name="availabilityUpdatedAt"
                data-cy="availabilityUpdatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="cart-item-course"
                name="course"
                data-cy="course"
                label={translate('alippoApp.cartItem.course')}
                type="select"
              >
                <option value="" key="0" />
                {courses
                  ? courses.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="cart-item-sku" name="sku" data-cy="sku" label={translate('alippoApp.cartItem.sku')} type="select">
                <option value="" key="0" />
                {skus
                  ? skus.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="cart-item-cart" name="cart" data-cy="cart" label={translate('alippoApp.cartItem.cart')} type="select">
                <option value="" key="0" />
                {carts
                  ? carts.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/cart-item" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CartItemUpdate;
