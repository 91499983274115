import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { Button, Row, Col, FormText, Input, Label } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ILesson } from 'app/shared/model/lesson.model';
import { getEntity, updateEntity, createEntity, reset } from './lesson-translation.reducer';
import { ILessonTranslation } from 'app/shared/model/lesson-translation.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Language } from 'app/shared/model/enumerations/language.model';

export const LessonTranslationUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locationState: any = location.state;

  let inheritedLesson = null;
  if (locationState) {
    inheritedLesson = locationState.lesson;
  }
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const lessonTranslationEntity = useAppSelector(state => state.lessonTranslation.entity);
  const loading = useAppSelector(state => state.lessonTranslation.loading);
  const updating = useAppSelector(state => state.lessonTranslation.updating);
  const updateSuccess = useAppSelector(state => state.lessonTranslation.updateSuccess);
  const languageValues = Object.keys(Language);

  const handleClose = () => {
    if (lessonTranslationEntity && lessonTranslationEntity.id) {
      dispatch(reset());
      props.history.push('/lesson/' + `${lessonTranslationEntity.lesson.id}`);
    } else {
      props.history.push('/lesson-translation' + props.location.search);
    }
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...lessonTranslationEntity,
      ...values,
      lesson: { id: values.lesson },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
          lesson: inheritedLesson ? inheritedLesson.id : null,
          name: inheritedLesson ? inheritedLesson.name : null,
          description: inheritedLesson ? inheritedLesson.description : null,
        }
      : {
          language: 'HINGLISH',
          ...lessonTranslationEntity,
          createdAt: convertDateTimeFromServer(lessonTranslationEntity.createdAt),
          updatedAt: convertDateTimeFromServer(lessonTranslationEntity.updatedAt),
          lesson: lessonTranslationEntity?.lesson?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.lessonTranslation.home.createOrEditLabel" data-cy="LessonTranslationCreateUpdateHeading">
            <Translate contentKey="alippoApp.lessonTranslation.home.createOrEditLabel">Create or edit a LessonTranslation</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="lesson-translation-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.lessonTranslation.name')}
                id="lesson-translation-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.lessonTranslation.description')}
                id="lesson-translation-description"
                name="description"
                data-cy="description"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.lessonTranslation.language')}
                id="lesson-translation-language"
                name="language"
                data-cy="language"
                type="select"
              >
                {languageValues.map(language => (
                  <option value={language} key={language}>
                    {translate('alippoApp.Language' + language)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.lessonTranslation.isDefault')}
                id="lesson-translation-isDefault"
                name="isDefault"
                data-cy="isDefault"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.lessonTranslation.createdAt')}
                id="lesson-translation-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.lessonTranslation.updatedAt')}
                id="lesson-translation-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="lesson-translation-lesson"
                name="lesson"
                data-cy="lesson"
                label={translate('alippoApp.lessonTranslation.lesson')}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/lesson-translation" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LessonTranslationUpdate;
