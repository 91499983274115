import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SectionTranslation from './section-translation';
import SectionTranslationDetail from './section-translation-detail';
import SectionTranslationUpdate from './section-translation-update';
import SectionTranslationDeleteDialog from './section-translation-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SectionTranslationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SectionTranslationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SectionTranslationDetail} />
      <ErrorBoundaryRoute path={match.url} component={SectionTranslation} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={SectionTranslationDeleteDialog} />
  </>
);

export default Routes;
