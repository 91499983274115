import dayjs from 'dayjs';
import { IPayment } from 'app/shared/model/payment.model';
import { PaymentStatus } from 'app/shared/model/enumerations/payment-status.model';

export interface IPaymentStateTransition {
  id?: number;
  oldStatus?: PaymentStatus;
  newStatus?: PaymentStatus;
  comment?: string | null;
  transitionAt?: string;
  createdAt?: string;
  payment?: IPayment | null;
}

export const defaultValue: Readonly<IPaymentStateTransition> = {};
