import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IVoucher } from 'app/shared/model/voucher.model';
import { getEntities as getVouchers } from 'app/entities/voucher/voucher.reducer';
import { getEntity, updateEntity, createEntity, reset } from './rule.reducer';
import { IRule } from 'app/shared/model/rule.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { RuleType } from 'app/shared/model/enumerations/rule-type.model';
import { RuleBasis } from 'app/shared/model/enumerations/rule-basis.model';
import { CurrencyCode } from 'app/shared/model/enumerations/currency-code.model';
import { RuleCurrency } from 'app/shared/model/enumerations/rule-currency.model';

export const RuleUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const vouchers = useAppSelector(state => state.voucher.entities);
  const ruleEntity = useAppSelector(state => state.rule.entity);
  const loading = useAppSelector(state => state.rule.loading);
  const updating = useAppSelector(state => state.rule.updating);
  const updateSuccess = useAppSelector(state => state.rule.updateSuccess);
  const ruleTypeValues = Object.keys(RuleType);
  const ruleBasisValues = Object.keys(RuleBasis);
  const currencyCodeValues = Object.keys(CurrencyCode);
  const ruleCurrencyValues = Object.keys(RuleCurrency);
  const handleClose = () => {
    props.history.push('/rule' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getVouchers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.fromDateTime = convertDateTimeToServer(values.fromDateTime);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...ruleEntity,
      ...values,
      voucher: { id: values.voucher },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          fromDateTime: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          ruleType: 'REFER_INTERNAL_ORDER',
          basis: 'FIXED',
          sellingPriceCurrencyCode: 'INR',
          mrpPriceCurrencyCode: 'INR',
          ruleCurrency: 'DEFAULT',
          currencyCode: 'INR',
          ...ruleEntity,
          fromDateTime: convertDateTimeFromServer(ruleEntity.fromDateTime),
          createdAt: convertDateTimeFromServer(ruleEntity.createdAt),
          updatedAt: convertDateTimeFromServer(ruleEntity.updatedAt),
          voucher: ruleEntity?.voucher?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.rule.home.createOrEditLabel" data-cy="RuleCreateUpdateHeading">
            <Translate contentKey="alippoApp.rule.home.createOrEditLabel">Create or edit a Rule</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="rule-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.rule.name')}
                id="rule-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.rule.description')}
                id="rule-description"
                name="description"
                data-cy="description"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.rule.ruleType')}
                id="rule-ruleType"
                name="ruleType"
                data-cy="ruleType"
                type="select"
              >
                {ruleTypeValues.map(ruleType => (
                  <option value={ruleType} key={ruleType}>
                    {translate('alippoApp.RuleType' + ruleType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.rule.fromDateTime')}
                id="rule-fromDateTime"
                name="fromDateTime"
                data-cy="fromDateTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('alippoApp.rule.duration')}
                id="rule-duration"
                name="duration"
                data-cy="duration"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.rule.disabled')}
                id="rule-disabled"
                name="disabled"
                data-cy="disabled"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.rule.minCriteria')}
                id="rule-minCriteria"
                name="minCriteria"
                data-cy="minCriteria"
                type="text"
              />
              <ValidatedField label={translate('alippoApp.rule.amount')} id="rule-amount" name="amount" data-cy="amount" type="text" />
              <ValidatedField label={translate('alippoApp.rule.basis')} id="rule-basis" name="basis" data-cy="basis" type="select">
                {ruleBasisValues.map(ruleBasis => (
                  <option value={ruleBasis} key={ruleBasis}>
                    {translate('alippoApp.RuleBasis' + ruleBasis)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.rule.maxAmount')}
                id="rule-maxAmount"
                name="maxAmount"
                data-cy="maxAmount"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.rule.sellingPrice')}
                id="rule-sellingPrice"
                name="sellingPrice"
                data-cy="sellingPrice"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.rule.sellingPriceCurrencyCode')}
                id="rule-sellingPriceCurrencyCode"
                name="sellingPriceCurrencyCode"
                data-cy="sellingPriceCurrencyCode"
                type="select"
              >
                {currencyCodeValues.map(currencyCode => (
                  <option value={currencyCode} key={currencyCode}>
                    {translate('alippoApp.CurrencyCode' + currencyCode)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.rule.mrpPrice')}
                id="rule-mrpPrice"
                name="mrpPrice"
                data-cy="mrpPrice"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.rule.mrpPriceCurrencyCode')}
                id="rule-mrpPriceCurrencyCode"
                name="mrpPriceCurrencyCode"
                data-cy="mrpPriceCurrencyCode"
                type="select"
              >
                {currencyCodeValues.map(currencyCode => (
                  <option value={currencyCode} key={currencyCode}>
                    {translate('alippoApp.CurrencyCode' + currencyCode)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.rule.ruleCurrency')}
                id="rule-ruleCurrency"
                name="ruleCurrency"
                data-cy="ruleCurrency"
                type="select"
              >
                {ruleCurrencyValues.map(ruleCurrency => (
                  <option value={ruleCurrency} key={ruleCurrency}>
                    {translate('alippoApp.RuleCurrency' + ruleCurrency)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.rule.currencyCode')}
                id="rule-currencyCode"
                name="currencyCode"
                data-cy="currencyCode"
                type="select"
              >
                {currencyCodeValues.map(currencyCode => (
                  <option value={currencyCode} key={currencyCode}>
                    {translate('alippoApp.CurrencyCode' + currencyCode)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField label={translate('alippoApp.rule.code')} id="rule-code" name="code" data-cy="code" type="text" />
              <ValidatedField
                label={translate('alippoApp.rule.usageLimit')}
                id="rule-usageLimit"
                name="usageLimit"
                data-cy="usageLimit"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.rule.usagePerCustomer')}
                id="rule-usagePerCustomer"
                name="usagePerCustomer"
                data-cy="usagePerCustomer"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.rule.timesUsed')}
                id="rule-timesUsed"
                name="timesUsed"
                data-cy="timesUsed"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.rule.sortOrder')}
                id="rule-sortOrder"
                name="sortOrder"
                data-cy="sortOrder"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alippoApp.rule.createdAt')}
                id="rule-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.rule.updatedAt')}
                id="rule-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.rule.expiryPeriodInSeconds')}
                id="rule-expiryPeriodInSeconds"
                name="expiryPeriodInSeconds"
                data-cy="expiryPeriodInSeconds"
                type="text"
              />
              <ValidatedField label={translate('alippoApp.rule.config')} id="rule-config" name="config" data-cy="config" type="textarea" />
              <ValidatedField id="rule-voucher" name="voucher" data-cy="voucher" label={translate('alippoApp.rule.voucher')} />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/rule" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RuleUpdate;
