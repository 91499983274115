import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './user-topic.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const UserTopicDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const userTopicEntity = useAppSelector(state => state.userTopic.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="userTopicDetailsHeading">
          <Translate contentKey="alippoApp.userTopic.detail.title">UserTopic</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{userTopicEntity.id}</dd>
          <dt>
            <span id="topicName">
              <Translate contentKey="alippoApp.userTopic.topicName">Topic Name</Translate>
            </span>
          </dt>
          <dd>{userTopicEntity.topicName}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="alippoApp.userTopic.type">Type</Translate>
            </span>
          </dt>
          <dd>{userTopicEntity.type}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="alippoApp.userTopic.active">Active</Translate>
            </span>
          </dt>
          <dd>{userTopicEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="subscriptionDate">
              <Translate contentKey="alippoApp.userTopic.subscriptionDate">Subscription Date</Translate>
            </span>
          </dt>
          <dd>
            {userTopicEntity.subscriptionDate ? (
              <TextFormat value={userTopicEntity.subscriptionDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="revocationDate">
              <Translate contentKey="alippoApp.userTopic.revocationDate">Revocation Date</Translate>
            </span>
          </dt>
          <dd>
            {userTopicEntity.revocationDate ? (
              <TextFormat value={userTopicEntity.revocationDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="metadata">
              <Translate contentKey="alippoApp.userTopic.metadata">Metadata</Translate>
            </span>
          </dt>
          <dd>{userTopicEntity.metadata}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.userTopic.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {userTopicEntity.createdAt ? <TextFormat value={userTopicEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.userTopic.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {userTopicEntity.updatedAt ? <TextFormat value={userTopicEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.userTopic.device">Device</Translate>
          </dt>
          <dd>{userTopicEntity.device ? userTopicEntity.device.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.userTopic.user">User</Translate>
          </dt>
          <dd>{userTopicEntity.user ? userTopicEntity.user.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/user-topic" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-topic/${userTopicEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UserTopicDetail;
