import dayjs from 'dayjs';
import { SeoMetadataType } from 'app/shared/model/enumerations/seo-metadata-type.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface ISeoMetadata {
  id?: number;
  type?: SeoMetadataType;
  payload?: string;
  language?: Language;
  createdAt?: string;
  updatedAt?: string;
}

export const defaultValue: Readonly<ISeoMetadata> = {};
