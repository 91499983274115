import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Row, Table } from 'reactstrap';

import { faClone } from '@fortawesome/free-solid-svg-icons';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IReview } from 'app/shared/model/review.model';
import ReactJson from 'react-json-view';
import { getEntity } from './course.reducer';

export const CourseDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const courseEntity = useAppSelector(state => state.course.entity);
  const [courseSectionData, setCourseSectionData] = useState(null);
  const [courseResourceData, setCourseResourceData] = useState(null);
  const [courseTranslationData, setCourseTranslationData] = useState(null);
  const [courseDiscussionData, setCourseDiscussionData] = useState(null);
  const [courseReviewsData, setCourseReviewsData] = useState<(IReview & { url: string })[]>(null);

  const getCourseResources = async () => {
    if (courseEntity.id) {
      try {
        const apiUrl = `api/res-courses?courseId.equals=${courseEntity.id}`;
        const res: any = await axios.get(apiUrl);
        const resources = res.data;
        if (resources.length === 0) {
          toast.error('No resources available');
        } else {
          setCourseResourceData(
            resources.map(e => {
              return {
                id: e.id,
                resourceName: e.name,
                resourceLink: e.url,
                resourceType: e.resType,
                resourceMediaType: e.mediaType,
                resourceUrl: `/res-course/${e.id}`,
              };
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getCourseTranslations = async () => {
    if (courseEntity.id) {
      try {
        const apiUrl = `api/course-translations?courseId.equals=${courseEntity.id}`;
        const res: any = await axios.get(apiUrl);
        const translations = res.data;
        if (translations.length === 0) {
          toast.error('No translations available');
        } else {
          setCourseTranslationData(
            translations.map(e => {
              return {
                id: e.id,
                name: e.name,
                language: e.language,
                isDefault: e.isDefault,
                url: `/course-translation/${e.id}`,
              };
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getCourseSections = async () => {
    if (courseEntity.id) {
      const apiUrl = `api/course-sections?courseId.equals=${courseEntity.id}&sort=sortOrder,asc&size=400&page=0`;
      const data: any = await axios.get(apiUrl);
      const sections = data.data;
      if (sections.length === 0) {
        toast.error('No sections available');
      }
      setCourseSectionData(
        sections.map(section => {
          return {
            ...section,
            url: `/course-section/${section.id}`,
          };
        })
      );
    }
  };

  const getCourseDiscussion = async () => {
    if (courseEntity.id) {
      const apiUrl = `api/course-discussions?courseV2Id.equals=${courseEntity.id}`;
      const data: any = await axios.get(apiUrl);
      const discussion = data.data;
      if (discussion.length === 0) {
        toast.error('No discussions available');
      }
      setCourseDiscussionData(
        discussion.map(e => {
          return {
            id: e.id,
            link: e.discussionLink,
            url: `/course-discussion/${e.id}`,
          };
        })
      );
    }
  };

  const getCourseReviews = async () => {
    if (courseEntity.id) {
      const apiUrl = `api/reviews?entityId.equals=${courseEntity.id}&page=0&size=40&sort=id,asc&entityType.equals=COURSE`;
      const data: any = await axios.get(apiUrl);
      const reviews = data.data;
      if (reviews.length === 0) {
        toast.error('No reviews available');
      }
      setCourseReviewsData(
        reviews.map(e => {
          return {
            ...e,
            url: `/review/${e.id}`,
          };
        })
      );
    }
  };

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="courseDetailsHeading">
          <Translate contentKey="alippoApp.course.detail.title">Course</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{courseEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.course.name">Name</Translate>
            </span>
          </dt>
          <dd>{courseEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.course.description">Description</Translate>
            </span>
          </dt>
          <dd>{courseEntity.description}</dd>
          <dt>
            <span id="sellingPrice">
              <Translate contentKey="alippoApp.course.sellingPrice">Selling Price</Translate>
            </span>
          </dt>
          <dd>{courseEntity.sellingPrice}</dd>
          <dt>
            <span id="sellingPriceCurrencyCode">
              <Translate contentKey="alippoApp.course.sellingPriceCurrencyCode">Selling Price Currency Code</Translate>
            </span>
          </dt>
          <dd>{courseEntity.sellingPriceCurrencyCode}</dd>
          <dt>
            <span id="mrpPrice">
              <Translate contentKey="alippoApp.course.mrpPrice">Mrp Price</Translate>
            </span>
          </dt>
          <dd>{courseEntity.mrpPrice}</dd>
          <dt>
            <span id="mrpPriceCurrencyCode">
              <Translate contentKey="alippoApp.course.mrpPriceCurrencyCode">Mrp Price Currency Code</Translate>
            </span>
          </dt>
          <dd>{courseEntity.mrpPriceCurrencyCode}</dd>
          <dt>
            <span id="fromDateTime">
              <Translate contentKey="alippoApp.course.fromDateTime">From Date Time</Translate>
            </span>
          </dt>
          <dd>
            {courseEntity.fromDateTime ? <TextFormat value={courseEntity.fromDateTime} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="duration">
              <Translate contentKey="alippoApp.course.duration">Duration</Translate>
            </span>
          </dt>
          <dd>{courseEntity.duration}</dd>
          <dt>
            <span id="courseStatus">
              <Translate contentKey="alippoApp.course.courseStatus">Course Status</Translate>
            </span>
          </dt>
          <dd>{courseEntity.courseStatus}</dd>
          <dt>
            <span id="courseLevel">
              <Translate contentKey="alippoApp.course.courseLevel">Course Level</Translate>
            </span>
          </dt>
          <dd>{courseEntity.courseLevel}</dd>
          <dt>
            <span id="sortOrder">
              <Translate contentKey="alippoApp.course.sortOrder">Sort Order</Translate>
            </span>
          </dt>
          <dd>{courseEntity.sortOrder}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.course.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{courseEntity.createdAt ? <TextFormat value={courseEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.course.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{courseEntity.updatedAt ? <TextFormat value={courseEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="configPayload">
              <Translate contentKey="alippoApp.course.configPayload">Config Payload</Translate>
            </span>
          </dt>
          <dd>
            <ReactJson src={JSON.parse(courseEntity.configPayload ?? '{}')} displayObjectSize={false} displayDataTypes={false} collapsed />
          </dd>
          <dt>
            <span id="seoConfigPayload">
              <Translate contentKey="alippoApp.course.seoConfigPayload">Seo Config Payload</Translate>
            </span>
          </dt>
          <dd>
            <ReactJson
              src={JSON.parse(courseEntity.seoConfigPayload ?? '{}')}
              displayObjectSize={false}
              displayDataTypes={false}
              collapsed
            />
          </dd>
          <dt>
            <span id="batchIndex">
              <Translate contentKey="alippoApp.course.batchIndex">Batch Index</Translate>
            </span>
          </dt>
          <dd>{courseEntity.batchIndex}</dd>
          <dt>
            <Translate contentKey="alippoApp.course.sku">Sku</Translate>
          </dt>
          <dd>{courseEntity.sku ? courseEntity.sku.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.course.applicationForm">Application Form</Translate>
          </dt>
          <dd>{courseEntity.applicationForm ? courseEntity.applicationForm.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.course.parentCourse">Parent Course</Translate>
          </dt>
          <dd>{courseEntity.parentCourse ? courseEntity.parentCourse.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.course.category">Category</Translate>
          </dt>
          <dd>
            {courseEntity.categories
              ? courseEntity.categories.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.name}</a>
                    {courseEntity.categories && i === courseEntity.categories.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.course.discussion">Discussion</Translate>
          </dt>
          <dd>
            {courseEntity.discussions
              ? courseEntity.discussions.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {courseEntity.discussions && i === courseEntity.discussions.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <dl>
          <dt>Course Translations</dt>
          <Button onClick={getCourseTranslations} className="mr-2" color="primary">
            <span>Get Course Translations</span>
          </Button>
          <Link to={{ pathname: '/course-translation/new', state: { course: courseEntity } }}>
            <Button className="rounded-circle" color="success">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Link>
          <dd>
            {courseTranslationData && courseTranslationData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">Course Translation ID</th>
                    <th className="hand">Course Translation Name</th>
                    <th className="hand">Default?</th>
                    <th className="hand">Course Translation Language</th>
                    <th className="hand">Course Translation URL</th>
                  </tr>
                </thead>
                <tbody>
                  {courseTranslationData.map((translation, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{translation.id}</td>
                      <td>{translation.name}</td>
                      <td>{translation.isDefault ? 'Yes' : 'No'}</td>
                      <td>{translation.language}</td>
                      <td>
                        <Link target="_blank" rel="noopener noreferrer" to={translation.url}>
                          Link
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <dl>
          <dt>Course Sections</dt>
          <Button onClick={getCourseSections} className="mr-2" color="primary">
            <span>Get Course Sections</span>
          </Button>
          <Link to={{ pathname: '/course-section/new', state: { course: courseEntity } }}>
            <Button className="rounded-circle" color="success">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Link>
          <dd>
            {courseSectionData && courseSectionData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">S.No</th>
                    <th className="hand">Course Section ID</th>
                    <th className="hand">Section Name</th>
                    <th className="hand">Sort Order</th>
                    <th className="hand">Duration</th>
                    <th className="hand">Course Section URL</th>
                  </tr>
                </thead>
                <tbody>
                  {courseSectionData.map((section, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{i + 1}</td>
                      <td>{section.id}</td>
                      <td>{section.section.name}</td>
                      <td>{section.sortOrder}</td>
                      <td>{section.duration}</td>
                      <td>
                        <Link target="_blank" rel="noopener noreferrer" to={section.url}>
                          Link
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <dl>
          <dt>Course Resources</dt>
          <Button onClick={getCourseResources} className="mr-2" color="primary">
            <span>Get Course Resources</span>
          </Button>
          <Link to={{ pathname: '/res-course/new', state: { course: courseEntity } }}>
            <Button className="rounded-circle" color="success">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Link>
          <dd>
            {courseResourceData && courseResourceData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">Course Resource ID</th>
                    <th className="hand">Course Resource Name</th>
                    <th className="hand">Course Resource Type</th>
                    <th className="hand">Course Resource Link</th>
                    <th className="hand">Course Resource URL</th>
                  </tr>
                </thead>
                <tbody>
                  {courseResourceData.map((resource, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{resource.id}</td>
                      <td>{resource.resourceName}</td>
                      <td>{resource.resourceType}</td>
                      <td>
                        <a target="_blank" rel="noopener noreferrer" href={resource.resourceLink}>
                          {resource.resourceLink}
                        </a>
                      </td>
                      <td>
                        <Link target="_blank" rel="noopener noreferrer" to={resource.resourceUrl}>
                          Link
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <dl>
          <dt>Course Discussion</dt>
          <Button onClick={getCourseDiscussion} className="mr-2" color="primary">
            <span>Get Course Discussion</span>
          </Button>
          <Link to={{ pathname: '/course-discussion/new', state: { course: courseEntity } }}>
            <Button className="rounded-circle" color="success">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Link>
          <dd>
            {courseDiscussionData && courseDiscussionData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">Course Discussion ID</th>
                    <th className="hand">Course Discussion Link</th>
                    <th className="hand">Course Resource URL</th>
                  </tr>
                </thead>
                <tbody>
                  {courseDiscussionData.map((resource, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{resource.id}</td>
                      <td>
                        <a target="_blank" rel="noopener noreferrer" href={resource.link}>
                          {resource.link}
                        </a>
                      </td>
                      <td>
                        <Link target="_blank" rel="noopener noreferrer" to={resource.url}>
                          Link
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <dl>
          <dt>Course Reviews</dt>
          <Button onClick={getCourseReviews} className="mr-2" color="primary">
            <span>Get Course Reviews</span>
          </Button>
          <Link to={{ pathname: '/review/new', state: { course: courseEntity } }}>
            <Button className="rounded-circle" color="success">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Link>
          <dd>
            {courseReviewsData && courseReviewsData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">
                      <Translate contentKey="alippoApp.review.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.review.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.review.subType">Sub Type</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.review.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.review.sortOrder">Sort Order</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th>
                      <Translate contentKey="alippoApp.review.rateOption">Rate Option</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th>
                      <Translate contentKey="alippoApp.review.user">User</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th>
                      <Translate contentKey="alippoApp.review.displayName">Display Name</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {courseReviewsData.map((resource: IReview & { url: string }, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{resource.id}</td>
                      <td>{resource.type}</td>
                      <td>{resource.subType}</td>
                      <td>{resource.status}</td>
                      <td>{resource.sortOrder}</td>
                      <td>{resource.rateOption?.value}</td>
                      <td>{resource.user.id}</td>
                      <td>{resource.displayName}</td>
                      <td>
                        <Link target="_blank" rel="noopener noreferrer" to={resource.url}>
                          Link
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <dl>
          <dt>Course Order Items</dt>
          <a href={`/order-item?page=1&sort=id,asc&courseId.equals=${courseEntity.id}`}>
            <Button type="button" color="primary">
              See Order Items
            </Button>
          </a>
        </dl>
        <dl>
          <dt>Course Slugs</dt>
          <a href={`/slug?page=1&sort=id,asc&courseId.equals=${courseEntity.id}`}>
            <Button type="button" color="primary">
              See Slugs
            </Button>
          </a>
        </dl>
        {courseEntity.applicationForm && courseEntity.applicationForm.id && (
          <dl>
            <dt>Course Applications</dt>
            {courseEntity.sku && (
              <a href={`/sku-application?page=1&sort=id,asc&skuId.equals=${courseEntity.sku.id}`}>
                <Button type="button" color="primary">
                  See Applications
                </Button>
              </a>
            )}
          </dl>
        )}
        <Button tag={Link} to="/course" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/course/clone?courseId=${courseEntity.id}`} color="dark">
          <FontAwesomeIcon icon={faClone} /> <span>Clone</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/course/${courseEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CourseDetail;
