import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IBizCard } from 'app/shared/model/biz-card.model';
import { getEntities as getBizCards } from 'app/entities/biz-card/biz-card.reducer';
import { getEntity, updateEntity, createEntity, reset } from './res-biz-card.reducer';
import { IResBizCard } from 'app/shared/model/res-biz-card.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ResState } from 'app/shared/model/enumerations/res-state.model';
import { ResType } from 'app/shared/model/enumerations/res-type.model';
import { ResMediaType } from 'app/shared/model/enumerations/res-media-type.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export const ResBizCardUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const bizCards = useAppSelector(state => state.bizCard.entities);
  const resBizCardEntity = useAppSelector(state => state.resBizCard.entity);
  const loading = useAppSelector(state => state.resBizCard.loading);
  const updating = useAppSelector(state => state.resBizCard.updating);
  const updateSuccess = useAppSelector(state => state.resBizCard.updateSuccess);
  const resStateValues = Object.keys(ResState);
  const resTypeValues = Object.keys(ResType);
  const resMediaTypeValues = Object.keys(ResMediaType);
  const languageValues = Object.keys(Language);
  const handleClose = () => {
    props.history.push('/res-biz-card' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getBizCards({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...resBizCardEntity,
      ...values,
      bizCard: { id: values.bizCard },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          resState: 'AVAILABLE',
          resType: 'EBOOK',
          mediaType: 'IMAGE',
          language: 'HINGLISH',
          ...resBizCardEntity,
          createdAt: convertDateTimeFromServer(resBizCardEntity.createdAt),
          updatedAt: convertDateTimeFromServer(resBizCardEntity.updatedAt),
          bizCard: resBizCardEntity?.bizCard?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.resBizCard.home.createOrEditLabel" data-cy="ResBizCardCreateUpdateHeading">
            <Translate contentKey="alippoApp.resBizCard.home.createOrEditLabel">Create or edit a ResBizCard</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="res-biz-card-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.resBizCard.name')}
                id="res-biz-card-name"
                name="name"
                data-cy="name"
                type="text"
              />
              <ValidatedField label={translate('alippoApp.resBizCard.url')} id="res-biz-card-url" name="url" data-cy="url" type="text" />
              <ValidatedField
                label={translate('alippoApp.resBizCard.resState')}
                id="res-biz-card-resState"
                name="resState"
                data-cy="resState"
                type="select"
              >
                {resStateValues.map(resState => (
                  <option value={resState} key={resState}>
                    {translate('alippoApp.ResState' + resState)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resBizCard.resType')}
                id="res-biz-card-resType"
                name="resType"
                data-cy="resType"
                type="select"
              >
                {resTypeValues.map(resType => (
                  <option value={resType} key={resType}>
                    {translate('alippoApp.ResType' + resType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resBizCard.mediaType')}
                id="res-biz-card-mediaType"
                name="mediaType"
                data-cy="mediaType"
                type="select"
              >
                {resMediaTypeValues.map(resMediaType => (
                  <option value={resMediaType} key={resMediaType}>
                    {translate('alippoApp.ResMediaType' + resMediaType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resBizCard.description')}
                id="res-biz-card-description"
                name="description"
                data-cy="description"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.resBizCard.language')}
                id="res-biz-card-language"
                name="language"
                data-cy="language"
                type="select"
              >
                {languageValues.map(language => (
                  <option value={language} key={language}>
                    {translate('alippoApp.Language' + language)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resBizCard.createdAt')}
                id="res-biz-card-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.resBizCard.updatedAt')}
                id="res-biz-card-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="res-biz-card-bizCard"
                name="bizCard"
                data-cy="bizCard"
                label={translate('alippoApp.resBizCard.bizCard')}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/res-biz-card" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ResBizCardUpdate;
