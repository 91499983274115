import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface ISuggestion {
  id?: number;
  suggestion?: string | null;
  createdAt?: string;
  updatedAt?: string;
  user?: IUser | null;
}

export const defaultValue: Readonly<ISuggestion> = {};
