import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Category from './category';
import CategoryTranslation from './category-translation';
import Course from './course';
import CourseTranslation from './course-translation';
import CourseDiscussion from './course-discussion';
import Section from './section';
import SectionTranslation from './section-translation';
import Lesson from './lesson';
import LessonTranslation from './lesson-translation';
import Tip from './tip';
import TipTranslation from './tip-translation';
import CourseSection from './course-section';
import CourseSectionLesson from './course-section-lesson';
import CourseSectionTip from './course-section-tip';
import UserCourseSectionLesson from './user-course-section-lesson';
import Cart from './cart';
import CartItem from './cart-item';
import OrderInfo from './order-info';
import OrderItem from './order-item';
import Address from './address';
import UserExtra from './user-extra';
import Device from './device';
import Feedback from './feedback';
import Suggestion from './suggestion';
import Banner from './banner';
import ResCourse from './res-course';
import ResCourseSection from './res-course-section';
import ResCourseSectionLesson from './res-course-section-lesson';
import ResCourseSectionTip from './res-course-section-tip';
import ResOrderItem from './res-order-item';
import ResUserCourseSectionLesson from './res-user-course-section-lesson';
import BizCard from './biz-card';
import ResBizCard from './res-biz-card';
import ResRule from './res-rule';
import Rule from './rule';
import RuleTranslation from './rule-translation';
import RuleLedger from './rule-ledger';
import Slug from './slug';
import SeoMetadata from './seo-metadata';
import BeneficiaryInfo from './beneficiary-info';
import Payment from './payment';
import PaymentStateTransition from './payment-state-transition';
import PaymentSource from './payment-source';
import Review from './review';
import ResReview from './res-review';
import RatingOption from './rating-option';
import ResCategory from './res-category';
import Sku from './sku';
import Plan from './plan';
import PlanTranslation from './plan-translation';
import PlanItem from './plan-item';
import Product from './product';
import ProductTranslation from './product-translation';
import Inventory from './inventory';
import OrderItemPlan from './order-item-plan';
import Location from './location';
import Tenant from './tenant';
import TenantExtra from './tenant-extra';
import ResPlan from './res-plan';
import ResProduct from './res-product';
import SkuAttribute from './sku-attribute';
import SkuAttributeVariant from './sku-attribute-variant';
import Voucher from './voucher';
import VoucherTranslation from './voucher-translation';
import Config from './config';
import UserTopic from './user-topic';
import SkuReservation from './sku-reservation';
import ResDiscussion from './res-discussion';
import DiscussionMember from './discussion-member';
import UserSegment from './user-segment';
import SkuSegment from './sku-segment';
import WhatsappLog from './whatsapp-log';
import Form from './form';
import SkuApplication from './sku-application';
import SkuApplicationStateTransition from './sku-application-state-transition';
import MessageTemplate from './message-template';
import ResUser from './res-user';
import Res from './res';
import Task from './task';
import Step from './step';
import ResTask from './res-task';
import ResStep from './res-step';
import Cluster from './cluster';
import CourseCluster from './course-cluster';
import OrderItemSkuAttribute from './order-item-sku-attribute';
import CartItemSkuAttribute from './cart-item-sku-attribute';
import PageNotFound from 'app/shared/error/page-not-found';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}category`} component={Category} />
      <ErrorBoundaryRoute path={`${match.url}category-translation`} component={CategoryTranslation} />
      <ErrorBoundaryRoute path={`${match.url}course`} component={Course} />
      <ErrorBoundaryRoute path={`${match.url}course-translation`} component={CourseTranslation} />
      <ErrorBoundaryRoute path={`${match.url}course-discussion`} component={CourseDiscussion} />
      <ErrorBoundaryRoute path={`${match.url}section`} component={Section} />
      <ErrorBoundaryRoute path={`${match.url}section-translation`} component={SectionTranslation} />
      <ErrorBoundaryRoute path={`${match.url}lesson`} component={Lesson} />
      <ErrorBoundaryRoute path={`${match.url}lesson-translation`} component={LessonTranslation} />
      <ErrorBoundaryRoute path={`${match.url}tip`} component={Tip} />
      <ErrorBoundaryRoute path={`${match.url}tip-translation`} component={TipTranslation} />
      <ErrorBoundaryRoute path={`${match.url}course-section`} component={CourseSection} />
      <ErrorBoundaryRoute path={`${match.url}course-section-lesson`} component={CourseSectionLesson} />
      <ErrorBoundaryRoute path={`${match.url}course-section-tip`} component={CourseSectionTip} />
      <ErrorBoundaryRoute path={`${match.url}user-course-section-lesson`} component={UserCourseSectionLesson} />
      <ErrorBoundaryRoute path={`${match.url}cart`} component={Cart} />
      <ErrorBoundaryRoute path={`${match.url}cart-item`} component={CartItem} />
      <ErrorBoundaryRoute path={`${match.url}order-info`} component={OrderInfo} />
      <ErrorBoundaryRoute path={`${match.url}order-item`} component={OrderItem} />
      <ErrorBoundaryRoute path={`${match.url}address`} component={Address} />
      <ErrorBoundaryRoute path={`${match.url}user-extra`} component={UserExtra} />
      <ErrorBoundaryRoute path={`${match.url}device`} component={Device} />
      <ErrorBoundaryRoute path={`${match.url}feedback`} component={Feedback} />
      <ErrorBoundaryRoute path={`${match.url}suggestion`} component={Suggestion} />
      <ErrorBoundaryRoute path={`${match.url}banner`} component={Banner} />
      <ErrorBoundaryRoute path={`${match.url}res-course`} component={ResCourse} />
      <ErrorBoundaryRoute path={`${match.url}res-course-section`} component={ResCourseSection} />
      <ErrorBoundaryRoute path={`${match.url}res-course-section-lesson`} component={ResCourseSectionLesson} />
      <ErrorBoundaryRoute path={`${match.url}res-course-section-tip`} component={ResCourseSectionTip} />
      <ErrorBoundaryRoute path={`${match.url}res-order-item`} component={ResOrderItem} />
      <ErrorBoundaryRoute path={`${match.url}res-user-course-section-lesson`} component={ResUserCourseSectionLesson} />
      <ErrorBoundaryRoute path={`${match.url}biz-card`} component={BizCard} />
      <ErrorBoundaryRoute path={`${match.url}res-biz-card`} component={ResBizCard} />
      <ErrorBoundaryRoute path={`${match.url}res-rule`} component={ResRule} />
      <ErrorBoundaryRoute path={`${match.url}rule`} component={Rule} />
      <ErrorBoundaryRoute path={`${match.url}rule-translation`} component={RuleTranslation} />
      <ErrorBoundaryRoute path={`${match.url}rule-ledger`} component={RuleLedger} />
      <ErrorBoundaryRoute path={`${match.url}slug`} component={Slug} />
      <ErrorBoundaryRoute path={`${match.url}seo-metadata`} component={SeoMetadata} />
      <ErrorBoundaryRoute path={`${match.url}beneficiary-info`} component={BeneficiaryInfo} />
      <ErrorBoundaryRoute path={`${match.url}payment`} component={Payment} />
      <ErrorBoundaryRoute path={`${match.url}payment-state-transition`} component={PaymentStateTransition} />
      <ErrorBoundaryRoute path={`${match.url}payment-source`} component={PaymentSource} />
      <ErrorBoundaryRoute path={`${match.url}review`} component={Review} />
      <ErrorBoundaryRoute path={`${match.url}res-review`} component={ResReview} />
      <ErrorBoundaryRoute path={`${match.url}rating-option`} component={RatingOption} />
      <ErrorBoundaryRoute path={`${match.url}res-category`} component={ResCategory} />
      <ErrorBoundaryRoute path={`${match.url}sku`} component={Sku} />
      <ErrorBoundaryRoute path={`${match.url}plan`} component={Plan} />
      <ErrorBoundaryRoute path={`${match.url}plan-translation`} component={PlanTranslation} />
      <ErrorBoundaryRoute path={`${match.url}plan-item`} component={PlanItem} />
      <ErrorBoundaryRoute path={`${match.url}product`} component={Product} />
      <ErrorBoundaryRoute path={`${match.url}product-translation`} component={ProductTranslation} />
      <ErrorBoundaryRoute path={`${match.url}inventory`} component={Inventory} />
      <ErrorBoundaryRoute path={`${match.url}order-item-plan`} component={OrderItemPlan} />
      <ErrorBoundaryRoute path={`${match.url}location`} component={Location} />
      <ErrorBoundaryRoute path={`${match.url}tenant`} component={Tenant} />
      <ErrorBoundaryRoute path={`${match.url}tenant-extra`} component={TenantExtra} />
      <ErrorBoundaryRoute path={`${match.url}res-plan`} component={ResPlan} />
      <ErrorBoundaryRoute path={`${match.url}res-product`} component={ResProduct} />
      <ErrorBoundaryRoute path={`${match.url}sku-attribute`} component={SkuAttribute} />
      <ErrorBoundaryRoute path={`${match.url}sku-attribute-variant`} component={SkuAttributeVariant} />
      <ErrorBoundaryRoute path={`${match.url}voucher`} component={Voucher} />
      <ErrorBoundaryRoute path={`${match.url}voucher-translation`} component={VoucherTranslation} />
      <ErrorBoundaryRoute path={`${match.url}config`} component={Config} />
      <ErrorBoundaryRoute path={`${match.url}user-topic`} component={UserTopic} />
      <ErrorBoundaryRoute path={`${match.url}sku-reservation`} component={SkuReservation} />
      <ErrorBoundaryRoute path={`${match.url}res-discussion`} component={ResDiscussion} />
      <ErrorBoundaryRoute path={`${match.url}discussion-member`} component={DiscussionMember} />
      <ErrorBoundaryRoute path={`${match.url}user-segment`} component={UserSegment} />
      <ErrorBoundaryRoute path={`${match.url}sku-segment`} component={SkuSegment} />
      <ErrorBoundaryRoute path={`${match.url}whatsapp-log`} component={WhatsappLog} />
      <ErrorBoundaryRoute path={`${match.url}form`} component={Form} />
      <ErrorBoundaryRoute path={`${match.url}sku-application`} component={SkuApplication} />
      <ErrorBoundaryRoute path={`${match.url}sku-application-state-transition`} component={SkuApplicationStateTransition} />
      <ErrorBoundaryRoute path={`${match.url}message-template`} component={MessageTemplate} />
      <ErrorBoundaryRoute path={`${match.url}res-user`} component={ResUser} />
      <ErrorBoundaryRoute path={`${match.url}res`} component={Res} />
      <ErrorBoundaryRoute path={`${match.url}task`} component={Task} />
      <ErrorBoundaryRoute path={`${match.url}step`} component={Step} />
      <ErrorBoundaryRoute path={`${match.url}res-task`} component={ResTask} />
      <ErrorBoundaryRoute path={`${match.url}res-step`} component={ResStep} />
      <ErrorBoundaryRoute path={`${match.url}cluster`} component={Cluster} />
      <ErrorBoundaryRoute path={`${match.url}course-cluster`} component={CourseCluster} />
      <ErrorBoundaryRoute path={`${match.url}order-item-sku-attribute`} component={OrderItemSkuAttribute} />
      <ErrorBoundaryRoute path={`${match.url}cart-item-sku-attribute`} component={CartItemSkuAttribute} />
      <ErrorBoundaryRoute component={PageNotFound} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
