import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './section-translation.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const SectionTranslationDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const { match } = props;
  const handleDeleteResource = () => {
    props.history.replace(`${match.url}/delete`);
  };

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const sectionTranslationEntity = useAppSelector(state => state.sectionTranslation.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="sectionTranslationDetailsHeading">
          <Translate contentKey="alippoApp.sectionTranslation.detail.title">SectionTranslation</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{sectionTranslationEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.sectionTranslation.name">Name</Translate>
            </span>
          </dt>
          <dd>{sectionTranslationEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.sectionTranslation.description">Description</Translate>
            </span>
          </dt>
          <dd>{sectionTranslationEntity.description}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="alippoApp.sectionTranslation.language">Language</Translate>
            </span>
          </dt>
          <dd>{sectionTranslationEntity.language}</dd>
          <dt>
            <span id="isDefault">
              <Translate contentKey="alippoApp.sectionTranslation.isDefault">Is Default</Translate>
            </span>
          </dt>
          <dd>{sectionTranslationEntity.isDefault ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.sectionTranslation.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {sectionTranslationEntity.createdAt ? (
              <TextFormat value={sectionTranslationEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.sectionTranslation.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {sectionTranslationEntity.updatedAt ? (
              <TextFormat value={sectionTranslationEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.sectionTranslation.section">Section</Translate>
          </dt>
          <dd>{sectionTranslationEntity.section ? sectionTranslationEntity.section.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/section-translation" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/section-translation/${sectionTranslationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
        &nbsp;
        <Button onClick={handleDeleteResource} color="danger" data-cy="entityDeleteButton">
          <FontAwesomeIcon icon="trash" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.delete">Delete</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SectionTranslationDetail;
