import dayjs from 'dayjs';
import { ISkuAttribute } from 'app/shared/model/sku-attribute.model';
import { ICourse } from 'app/shared/model/course.model';
import { IPlan } from 'app/shared/model/plan.model';
import { IProduct } from 'app/shared/model/product.model';
import { IVoucher } from 'app/shared/model/voucher.model';
import { SkuType } from 'app/shared/model/enumerations/sku-type.model';
import { SkuStatus } from 'app/shared/model/enumerations/sku-status.model';

export interface ISku {
  id?: number;
  type?: SkuType;
  status?: SkuStatus | null;
  earlyAccessEnabled?: boolean | null;
  earlyAccessEndDate?: string | null;
  atp?: boolean | null;
  createdAt?: string;
  updatedAt?: string;
  attributes?: ISkuAttribute[] | null;
  course?: ICourse | null;
  plan?: IPlan | null;
  product?: IProduct | null;
  voucher?: IVoucher | null;
}

export const defaultValue: Readonly<ISku> = {
  earlyAccessEnabled: false,
  atp: false,
};
