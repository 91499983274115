import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './cart.reducer';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ICartItem } from 'app/shared/model/cart-item.model';

export const CartDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [cartItemsData, setCartItemsData] = useState<ICartItem[]>(null);

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const cartEntity = useAppSelector(state => state.cart.entity);

  const getCartItems = async () => {
    if (cartEntity.id) {
      try {
        const apiUrl = `api/cart-items?cartId.equals=${cartEntity.id}`;
        const { data } = await axios.get(apiUrl);
        if (data.length === 0) {
          toast.error('No items found');
        } else {
          setCartItemsData(data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="cartDetailsHeading">
          <Translate contentKey="alippoApp.cart.detail.title">Cart</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{cartEntity.id}</dd>
          <dt>
            <span id="cartStatus">
              <Translate contentKey="alippoApp.cart.cartStatus">Cart Status</Translate>
            </span>
          </dt>
          <dd>{cartEntity.cartStatus}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="alippoApp.cart.type">Type</Translate>
            </span>
          </dt>
          <dd>{cartEntity.type}</dd>
          <dt>
            <span id="promotions">
              <Translate contentKey="alippoApp.cart.promotions">Promotions</Translate>
            </span>
          </dt>
          <dd>{cartEntity.promotions}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.cart.description">Description</Translate>
            </span>
          </dt>
          <dd>{cartEntity.description}</dd>
          <dt>
            <span id="independent">
              <Translate contentKey="alippoApp.cart.independent">Independent</Translate>
            </span>
          </dt>
          <dd>{cartEntity.independent ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.cart.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{cartEntity.createdAt ? <TextFormat value={cartEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.cart.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{cartEntity.updatedAt ? <TextFormat value={cartEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="alippoApp.cart.user">User</Translate>
          </dt>
          <dd>{cartEntity.user ? cartEntity.user.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.cart.referrerUser">Referrer User</Translate>
          </dt>
          <dd>{cartEntity.referrerUser ? cartEntity.referrerUser.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.cart.deliveryAddress">Delivery Address</Translate>
          </dt>
          <dd>{cartEntity.deliveryAddress ? cartEntity.deliveryAddress.id : ''}</dd>
        </dl>
        <dl>
          <dt>Cart Items</dt>
          <Button onClick={getCartItems} color="primary">
            <span>Get Cart Items</span>
          </Button>
          <dd>
            {cartItemsData && cartItemsData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">ID</th>
                    <th className="hand">Selling Price</th>
                    <th className="hand">Course</th>
                    <th className="hand">Sku</th>
                    <th className="hand">Available</th>
                    <th className="hand">Availability Updated At</th>
                    <th className="hand">Unavailability Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItemsData.map((cartItem, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>
                        <a target="_blank" rel="noopener noreferrer" href={`/cart-item/${cartItem.id}`}>
                          {cartItem.id}
                        </a>
                      </td>
                      <td>{cartItem.sellingPrice}</td>
                      <td>{cartItem.course?.id}</td>
                      <td>{cartItem.sku.id}</td>
                      <td>{`${cartItem.available}`}</td>
                      <td>{cartItem.availabilityUpdatedAt}</td>
                      <td>{cartItem.unavailabilityReason}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/cart" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/cart/${cartEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CartDetail;
