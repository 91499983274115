import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './payment.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const PaymentDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const paymentEntity = useAppSelector(state => state.payment.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="paymentDetailsHeading">
          <Translate contentKey="alippoApp.payment.detail.title">Payment</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{paymentEntity.id}</dd>
          <dt>
            <span id="paymentDate">
              <Translate contentKey="alippoApp.payment.paymentDate">Payment Date</Translate>
            </span>
          </dt>
          <dd>
            {paymentEntity.paymentDate ? <TextFormat value={paymentEntity.paymentDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="vendorType">
              <Translate contentKey="alippoApp.payment.vendorType">Vendor Type</Translate>
            </span>
          </dt>
          <dd>{paymentEntity.vendorType}</dd>
          <dt>
            <span id="transactionId">
              <Translate contentKey="alippoApp.payment.transactionId">Transaction Id</Translate>
            </span>
          </dt>
          <dd>{paymentEntity.transactionId}</dd>
          <dt>
            <span id="transactionType">
              <Translate contentKey="alippoApp.payment.transactionType">Transaction Type</Translate>
            </span>
          </dt>
          <dd>{paymentEntity.transactionType}</dd>
          <dt>
            <span id="paymentType">
              <Translate contentKey="alippoApp.payment.paymentType">Payment Type</Translate>
            </span>
          </dt>
          <dd>{paymentEntity.paymentType}</dd>
          <dt>
            <span id="amount">
              <Translate contentKey="alippoApp.payment.amount">Amount</Translate>
            </span>
          </dt>
          <dd>{paymentEntity.amount}</dd>
          <dt>
            <span id="comment">
              <Translate contentKey="alippoApp.payment.comment">Comment</Translate>
            </span>
          </dt>
          <dd>{paymentEntity.comment}</dd>
          <dt>
            <span id="exitCode">
              <Translate contentKey="alippoApp.payment.exitCode">Exit Code</Translate>
            </span>
          </dt>
          <dd>{paymentEntity.exitCode}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.payment.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{paymentEntity.createdAt ? <TextFormat value={paymentEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.payment.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{paymentEntity.updatedAt ? <TextFormat value={paymentEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="alippoApp.payment.transferInfo">Transfer Info</Translate>
          </dt>
          <dd>{paymentEntity.transferInfo ? paymentEntity.transferInfo.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.payment.source">Source</Translate>
          </dt>
          <dd>{paymentEntity.source ? paymentEntity.source.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/payment" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/payment/${paymentEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PaymentDetail;
