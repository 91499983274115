export enum ReviewSubType {
  STAR_RATING = 'STAR_RATING',

  CUSTOMER_WORK = 'CUSTOMER_WORK',

  WHATSAPP_FEEDBACK = 'WHATSAPP_FEEDBACK',

  ZOOM_SESSION = 'ZOOM_SESSION',

  INSTRUCTOR = 'INSTRUCTOR',
}
