import dayjs from 'dayjs';
import { IResStep } from 'app/shared/model/res-step.model';
import { ITask } from 'app/shared/model/task.model';
import { StepStatus } from 'app/shared/model/enumerations/step-status.model';

export interface IStep {
  id?: number;
  stepId?: string;
  status?: StepStatus;
  input?: string | null;
  additionalInput?: string | null;
  description?: string | null;
  createdAt?: string;
  updatedAt?: string;
  resources?: IResStep[] | null;
  task?: ITask | null;
}

export const defaultValue: Readonly<IStep> = {};
