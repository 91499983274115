import dayjs from 'dayjs';
import { ISku } from 'app/shared/model/sku.model';
import { ILocation } from 'app/shared/model/location.model';
import { IUser } from 'app/shared/model/user.model';
import { CurrencyCode } from 'app/shared/model/enumerations/currency-code.model';

export interface IInventory {
  id?: number;
  quantity?: number | null;
  fromDateTime?: string | null;
  duration?: number | null;
  sellingPrice?: number | null;
  sellingPriceCurrencyCode?: CurrencyCode | null;
  groupSellingPrice?: number | null;
  groupSellingPriceCurrencyCode?: CurrencyCode | null;
  mrpPrice?: number | null;
  mrpPriceCurrencyCode?: CurrencyCode | null;
  createdAt?: string;
  updatedAt?: string;
  sku?: ISku | null;
  serviceability?: ILocation | null;
  supplier?: IUser | null;
}

export const defaultValue: Readonly<IInventory> = {};
