import dayjs from 'dayjs';
import { ReviewType } from 'app/shared/model/enumerations/review-type.model';
import { ReviewEntityType } from 'app/shared/model/enumerations/review-entity-type.model';

export interface IRatingOption {
  id?: number;
  reviewType?: ReviewType;
  reviewEntityType?: ReviewEntityType;
  sortOrder?: number;
  value?: number;
  label?: string;
  createdAt?: string;
  updatedAt?: string;
}

export const defaultValue: Readonly<IRatingOption> = {};
