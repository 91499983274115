import dayjs from 'dayjs';
import { LocationLevel } from 'app/shared/model/enumerations/location-level.model';

export interface ILocation {
  id?: number;
  code?: string;
  name?: string;
  level?: LocationLevel;
  area?: string | null;
  addressLine1?: string | null;
  postalCode?: string | null;
  createdAt?: string;
  updatedAt?: string;
  children?: ILocation[] | null;
  parent?: ILocation | null;
}

export const defaultValue: Readonly<ILocation> = {};
