import dayjs from 'dayjs';
import { IPaymentStateTransition } from 'app/shared/model/payment-state-transition.model';
import { IBeneficiaryInfo } from 'app/shared/model/beneficiary-info.model';
import { IPaymentSource } from 'app/shared/model/payment-source.model';
import { PaymentVendorType } from 'app/shared/model/enumerations/payment-vendor-type.model';
import { TransactionType } from 'app/shared/model/enumerations/transaction-type.model';
import { PaymentType } from 'app/shared/model/enumerations/payment-type.model';

export interface IPayment {
  id?: number;
  paymentDate?: string;
  vendorType?: PaymentVendorType;
  transactionId?: string;
  transactionType?: TransactionType;
  paymentType?: PaymentType;
  amount?: number;
  comment?: string | null;
  exitCode?: string | null;
  createdAt?: string;
  updatedAt?: string;
  transitionStates?: IPaymentStateTransition[] | null;
  transferInfo?: IBeneficiaryInfo | null;
  source?: IPaymentSource | null;
}

export const defaultValue: Readonly<IPayment> = {};
