import { createAsyncThunk, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import axios from 'axios';

import { IUser } from 'app/shared/model/user.model';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';

const initialState: {
  loading: boolean;
  errorMessage?: string;
  updating: boolean;
  updateSuccess?: boolean;
  successMessage?: string;
  oldUser?: IUser;
  newUser?: IUser;
  oldUserFetchLoading?: boolean;
  oldUserFetchErrorMessage?: string;
  newUserFetchLoading?: boolean;
  newUserFetchErrorMessage?: string;
  oldUserOrderCount?: number;
  oldUserCartCount?: number;
  newUserOrderCount?: number;
  newUserCartCount?: number;
} = {
  loading: false,
  errorMessage: null,
  updating: false,
  updateSuccess: false,
  successMessage: null,
  oldUser: null,
  newUser: null,
  oldUserFetchLoading: false,
  oldUserFetchErrorMessage: null,
  newUserFetchLoading: false,
  newUserFetchErrorMessage: null,
  oldUserOrderCount: null,
  oldUserCartCount: null,
  newUserOrderCount: null,
  newUserCartCount: null,
};

const apiUrl = 'api/users';
const adminUrl = 'api/admin/users';
const ordersCountUrl = 'api/order-infos/count';
const cartCountUrl = 'api/carts/count';
const migrateUrl = 'api/user/migrate-phone-number';

// Async Actions

export const getOldUser = createAsyncThunk(
  'migratePhoneNumber/fetch_old_user',
  async (id: string) => {
    const requestUrl = `${adminUrl}/${id}`;
    return axios.get<IUser>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);
export const getOldUserCartCount = createAsyncThunk(
  'migratePhoneNumber/fetch_old_user/cart_count',
  async (id: string) => {
    const requestUrl = `${cartCountUrl}?userId.equals=${id}`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getOldUserOrderCount = createAsyncThunk(
  'migratePhoneNumber/fetch_old_user/order_count',
  async (id: string) => {
    const requestUrl = `${ordersCountUrl}?userId.equals=${id}`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getNewUser = createAsyncThunk(
  'migratePhoneNumber/fetch_new_user',
  async (id: string) => {
    const requestUrl = `${adminUrl}/${id}`;
    return axios.get<IUser>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getNewUserCartCount = createAsyncThunk(
  'migratePhoneNumber/fetch_new_user/cart_count',
  async (id: string) => {
    const requestUrl = `${cartCountUrl}?userId.equals=${id}`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getNewUserOrderCount = createAsyncThunk(
  'migratePhoneNumber/fetch_new_user/order_count',
  async (id: string) => {
    const requestUrl = `${ordersCountUrl}?userId.equals=${id}`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const migrate = createAsyncThunk(
  'migratePhoneNumber/migrate',
  async (payload: { fromUserId: number; toUserId: number }) => {
    const { fromUserId, toUserId } = payload;
    const requestUrl = `${migrateUrl}/${fromUserId}/${toUserId}`;
    return axios.post<string>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export type MigratePhoneNumberState = Readonly<typeof initialState>;

export const MigratePhoneNumberSlice = createSlice({
  name: 'migratePhoneNumber',
  initialState: initialState as MigratePhoneNumberState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getOldUser.fulfilled, (state, action) => {
        state.oldUserFetchLoading = false;
        state.oldUser = action.payload.data;
        state.oldUserFetchErrorMessage = null;
      })
      .addCase(getNewUser.fulfilled, (state, action) => {
        state.newUserFetchLoading = false;
        state.newUser = action.payload.data;
        state.newUserFetchErrorMessage = null;
      })
      .addCase(migrate.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMessage = null;
        state.updateSuccess = true;
        state.successMessage = action.payload.data;
      })
      .addCase(getOldUserOrderCount.fulfilled, (state, action) => {
        state.oldUserOrderCount = action.payload.data;
      })
      .addCase(getOldUserCartCount.fulfilled, (state, action) => {
        state.oldUserCartCount = action.payload.data;
      })
      .addCase(getNewUserOrderCount.fulfilled, (state, action) => {
        state.newUserOrderCount = action.payload.data;
      })
      .addCase(getNewUserCartCount.fulfilled, (state, action) => {
        state.newUserCartCount = action.payload.data;
      })
      .addMatcher(isPending(getOldUser), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = false;
        state.oldUserFetchLoading = true;
        state.oldUserCartCount = null;
        state.oldUserOrderCount = null;
      })
      .addMatcher(isPending(getNewUser), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = false;
        state.newUserFetchLoading = true;
        state.newUserCartCount = null;
        state.newUserOrderCount = null;
      })
      .addMatcher(isRejected(getOldUser), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.oldUserFetchLoading = false;
        state.oldUserFetchErrorMessage = action.error.message;
        state.oldUserCartCount = null;
        state.oldUserOrderCount = null;
      })
      .addMatcher(isRejected(getNewUser), (state, action) => {
        state.loading = false;
        state.updating = false;
        state.updateSuccess = false;
        state.newUserFetchLoading = false;
        state.newUserFetchErrorMessage = action.error.message;
        state.newUserCartCount = null;
        state.newUserOrderCount = null;
      })
      .addMatcher(isPending(migrate), (state, action) => {
        state.loading = true;
        state.errorMessage = null;
        state.updateSuccess = false;
        state.successMessage = null;
      })
      .addMatcher(isRejected(migrate), (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
        state.updateSuccess = false;
        state.successMessage = null;
      });
  },
});

export const { reset } = MigratePhoneNumberSlice.actions;

// Reducer
export default MigratePhoneNumberSlice.reducer;
