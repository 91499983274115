import dayjs from 'dayjs';
import { IPayment } from 'app/shared/model/payment.model';
import { PaymentSourceType } from 'app/shared/model/enumerations/payment-source-type.model';

export interface IPaymentSource {
  id?: number;
  type?: PaymentSourceType;
  reference?: string;
  createdAt?: string;
  updatedAt?: string;
  payments?: IPayment[] | null;
}

export const defaultValue: Readonly<IPaymentSource> = {};
