import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './sku.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ISkuAttribute } from 'app/shared/model/sku-attribute.model';
import axios from 'axios';
import { toast } from 'react-toastify';

export const SkuDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const skuEntity = useAppSelector(state => state.sku.entity);
  const [skuAttributesData, setSkuAttributesData] = useState<(ISkuAttribute & { url: string })[]>(null);

  const getSkuAttributes = async () => {
    if (skuEntity.id) {
      const apiUrl = `api/sku-attributes?skuId.equals=${skuEntity.id}&page=0&size=40&sort=id,asc`;
      const data: any = await axios.get(apiUrl);
      const attributes = data.data;
      if (attributes.length === 0) {
        toast.error('No attribuites available');
      }
      setSkuAttributesData(
        attributes.map(e => {
          return {
            ...e,
            url: `/sku-attribute/${e.id}`,
          };
        })
      );
    }
  };
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="skuDetailsHeading">
          <Translate contentKey="alippoApp.sku.detail.title">Sku</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{skuEntity.id}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="alippoApp.sku.type">Type</Translate>
            </span>
          </dt>
          <dd>{skuEntity.type}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="alippoApp.sku.status">Status</Translate>
            </span>
          </dt>
          <dd>{skuEntity.status}</dd>
          <dt>
            <span id="earlyAccessEnabled">
              <Translate contentKey="alippoApp.sku.earlyAccessEnabled">Early Access Enabled</Translate>
            </span>
          </dt>
          <dd>{skuEntity.earlyAccessEnabled ? 'true' : 'false'}</dd>
          <dt>
            <span id="earlyAccessEndDate">
              <Translate contentKey="alippoApp.sku.earlyAccessEndDate">Early Access End Date</Translate>
            </span>
          </dt>
          <dd>
            {skuEntity.earlyAccessEndDate ? <TextFormat value={skuEntity.earlyAccessEndDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="atp">
              <Translate contentKey="alippoApp.sku.atp">Atp</Translate>
            </span>
          </dt>
          <dd>{skuEntity.atp !== null ? skuEntity.atp.toString() : 'null'}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.sku.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{skuEntity.createdAt ? <TextFormat value={skuEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.sku.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{skuEntity.updatedAt ? <TextFormat value={skuEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <dl>
          <dt>Sku Attributes</dt>
          <Button onClick={getSkuAttributes} className="mr-2" color="primary">
            <span>Get Sku Attributes</span>
          </Button>
          <Link to={{ pathname: '/sku-attribute/new', state: { sku: skuEntity } }}>
            <Button className="rounded-circle" color="success">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Link>
          <dd>
            {skuAttributesData && skuAttributesData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">
                      <Translate contentKey="alippoApp.skuAttribute.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.skuAttribute.active">Active</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.skuAttribute.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.skuAttribute.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.skuAttribute.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.skuAttribute.inputSelectionType">Input Selection Type</Translate>{' '}
                      <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.skuAttribute.unitType">Unit Type</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.skuAttribute.unitValue">Unit Value</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.skuAttribute.sortOrder">Sort Order</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {skuAttributesData.map((resource: ISkuAttribute & { url: string }, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{resource.id}</td>
                      <td>{resource.active}</td>
                      <td>{resource.name}</td>
                      <td>{resource.description}</td>
                      <td>{resource.type}</td>
                      <td>{resource.inputSelectionType}</td>
                      <td>{resource.unitType}</td>
                      <td>{resource.unitValue}</td>
                      <td>{resource.sortOrder}</td>
                      <td>
                        <Link target="_blank" rel="noopener noreferrer" to={resource.url}>
                          Link
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/sku" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/sku/${skuEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SkuDetail;
