import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import OrderItemSkuAttribute from './order-item-sku-attribute';
import OrderItemSkuAttributeDetail from './order-item-sku-attribute-detail';
import OrderItemSkuAttributeUpdate from './order-item-sku-attribute-update';
import OrderItemSkuAttributeDeleteDialog from './order-item-sku-attribute-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={OrderItemSkuAttributeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={OrderItemSkuAttributeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={OrderItemSkuAttributeDetail} />
      <ErrorBoundaryRoute path={match.url} component={OrderItemSkuAttribute} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={OrderItemSkuAttributeDeleteDialog} />
  </>
);

export default Routes;
