import dayjs from 'dayjs';
import { ICourseSectionLesson } from 'app/shared/model/course-section-lesson.model';
import { IResCourseSection } from 'app/shared/model/res-course-section.model';
import { ISection } from 'app/shared/model/section.model';
import { ICourse } from 'app/shared/model/course.model';

export interface ICourseSection {
  id?: number;
  fromDateTime?: string | null;
  duration?: number | null;
  sortOrder?: number;
  createdAt?: string;
  updatedAt?: string;
  courseSectionLessons?: ICourseSectionLesson[] | null;
  resources?: IResCourseSection[] | null;
  section?: ISection | null;
  course?: ICourse | null;
}

export const defaultValue: Readonly<ICourseSection> = {};
