import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ISkuApplication } from 'app/shared/model/sku-application.model';
import { getEntities as getSkuApplications } from 'app/entities/sku-application/sku-application.reducer';
import { getEntity, updateEntity, createEntity, reset } from './sku-application-state-transition.reducer';
import { ISkuApplicationStateTransition } from 'app/shared/model/sku-application-state-transition.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { SkuApplicationStatus } from 'app/shared/model/enumerations/sku-application-status.model';

export const SkuApplicationStateTransitionUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const skuApplications = useAppSelector(state => state.skuApplication.entities);
  const skuApplicationStateTransitionEntity = useAppSelector(state => state.skuApplicationStateTransition.entity);
  const loading = useAppSelector(state => state.skuApplicationStateTransition.loading);
  const updating = useAppSelector(state => state.skuApplicationStateTransition.updating);
  const updateSuccess = useAppSelector(state => state.skuApplicationStateTransition.updateSuccess);
  const skuApplicationStatusValues = Object.keys(SkuApplicationStatus);
  const handleClose = () => {
    props.history.push('/sku-application-state-transition' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getSkuApplications({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);

    const entity = {
      ...skuApplicationStateTransitionEntity,
      ...values,
      skuApplication: { id: values.skuApplication },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
        }
      : {
          oldStatus: 'APPLICATION_RECEIVED',
          newStatus: 'APPLICATION_RECEIVED',
          ...skuApplicationStateTransitionEntity,
          createdAt: convertDateTimeFromServer(skuApplicationStateTransitionEntity.createdAt),
          skuApplication: skuApplicationStateTransitionEntity?.skuApplication?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="alippoApp.skuApplicationStateTransition.home.createOrEditLabel"
            data-cy="SkuApplicationStateTransitionCreateUpdateHeading"
          >
            <Translate contentKey="alippoApp.skuApplicationStateTransition.home.createOrEditLabel">
              Create or edit a SkuApplicationStateTransition
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="sku-application-state-transition-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.skuApplicationStateTransition.oldStatus')}
                id="sku-application-state-transition-oldStatus"
                name="oldStatus"
                data-cy="oldStatus"
                type="select"
              >
                {skuApplicationStatusValues.map(skuApplicationStatus => (
                  <option value={skuApplicationStatus} key={skuApplicationStatus}>
                    {translate('alippoApp.SkuApplicationStatus' + skuApplicationStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.skuApplicationStateTransition.newStatus')}
                id="sku-application-state-transition-newStatus"
                name="newStatus"
                data-cy="newStatus"
                type="select"
              >
                {skuApplicationStatusValues.map(skuApplicationStatus => (
                  <option value={skuApplicationStatus} key={skuApplicationStatus}>
                    {translate('alippoApp.SkuApplicationStatus' + skuApplicationStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.skuApplicationStateTransition.comment')}
                id="sku-application-state-transition-comment"
                name="comment"
                data-cy="comment"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.skuApplicationStateTransition.createdAt')}
                id="sku-application-state-transition-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="sku-application-state-transition-skuApplication"
                name="skuApplication"
                data-cy="skuApplication"
                label={translate('alippoApp.skuApplicationStateTransition.skuApplication')}
              />
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/sku-application-state-transition"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SkuApplicationStateTransitionUpdate;
