import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './res-course-section-tip.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ResCourseSectionTipDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const resCourseSectionTipEntity = useAppSelector(state => state.resCourseSectionTip.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="resCourseSectionTipDetailsHeading">
          <Translate contentKey="alippoApp.resCourseSectionTip.detail.title">ResCourseSectionTip</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionTipEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.resCourseSectionTip.name">Name</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionTipEntity.name}</dd>
          <dt>
            <span id="url">
              <Translate contentKey="alippoApp.resCourseSectionTip.url">Url</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionTipEntity.url}</dd>
          <dt>
            <span id="resState">
              <Translate contentKey="alippoApp.resCourseSectionTip.resState">Res State</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionTipEntity.resState}</dd>
          <dt>
            <span id="resType">
              <Translate contentKey="alippoApp.resCourseSectionTip.resType">Res Type</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionTipEntity.resType}</dd>
          <dt>
            <span id="mediaType">
              <Translate contentKey="alippoApp.resCourseSectionTip.mediaType">Media Type</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionTipEntity.mediaType}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.resCourseSectionTip.description">Description</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionTipEntity.description}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="alippoApp.resCourseSectionTip.language">Language</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionTipEntity.language}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.resCourseSectionTip.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {resCourseSectionTipEntity.createdAt ? (
              <TextFormat value={resCourseSectionTipEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.resCourseSectionTip.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {resCourseSectionTipEntity.updatedAt ? (
              <TextFormat value={resCourseSectionTipEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.resCourseSectionTip.courseSectionTip">Course Section Tip</Translate>
          </dt>
          <dd>{resCourseSectionTipEntity.courseSectionTip ? resCourseSectionTipEntity.courseSectionTip.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/res-course-section-tip" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/res-course-section-tip/${resCourseSectionTipEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ResCourseSectionTipDetail;
