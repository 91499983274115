import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRes } from 'app/shared/model/res.model';
import { getEntities as getRes } from 'app/entities/res/res.reducer';
import { ICourseDiscussion } from 'app/shared/model/course-discussion.model';
import { getEntities as getCourseDiscussions } from 'app/entities/course-discussion/course-discussion.reducer';
import { getEntity, updateEntity, createEntity, reset } from './res-discussion.reducer';
import { IResDiscussion } from 'app/shared/model/res-discussion.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ResState } from 'app/shared/model/enumerations/res-state.model';
import { Language } from 'app/shared/model/enumerations/language.model';
import { ResType } from 'app/shared/model/enumerations/res-type.model';

export const ResDiscussionUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const res = useAppSelector(state => state.res.entities);
  const { state: locationState }: { state: any } = useLocation();
  const resDiscussionEntity = useAppSelector(state => state.resDiscussion.entity);
  const loading = useAppSelector(state => state.resDiscussion.loading);
  const updating = useAppSelector(state => state.resDiscussion.updating);
  const updateSuccess = useAppSelector(state => state.resDiscussion.updateSuccess);
  const resStateValues = Object.keys(ResState);
  const languageValues = Object.keys(Language);
  const resTypeValues = Object.keys(ResType);
  const handleClose = () => {
    props.history.push('/res-discussion' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getRes({}));
    dispatch(getCourseDiscussions({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...resDiscussionEntity,
      ...values,
      res: res.find(it => it.id.toString() === values.res.toString()),
      courseDiscussion: { id: values.courseDiscussion },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
          courseDiscussion: locationState?.courseDiscussionId,
        }
      : {
          resState: 'AVAILABLE',
          language: 'HINGLISH',
          resType: 'EBOOK',
          ...resDiscussionEntity,
          createdAt: convertDateTimeFromServer(resDiscussionEntity.createdAt),
          updatedAt: convertDateTimeFromServer(resDiscussionEntity.updatedAt),
          res: resDiscussionEntity?.res?.id,
          courseDiscussion: resDiscussionEntity?.courseDiscussion?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.resDiscussion.home.createOrEditLabel" data-cy="ResDiscussionCreateUpdateHeading">
            <Translate contentKey="alippoApp.resDiscussion.home.createOrEditLabel">Create or edit a ResDiscussion</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="res-discussion-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.resDiscussion.name')}
                id="res-discussion-name"
                name="name"
                data-cy="name"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.resDiscussion.resState')}
                id="res-discussion-resState"
                name="resState"
                data-cy="resState"
                type="select"
              >
                {resStateValues.map(resState => (
                  <option value={resState} key={resState}>
                    {translate('alippoApp.ResState' + resState)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resDiscussion.description')}
                id="res-discussion-description"
                name="description"
                data-cy="description"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.resDiscussion.language')}
                id="res-discussion-language"
                name="language"
                data-cy="language"
                type="select"
              >
                {languageValues.map(language => (
                  <option value={language} key={language}>
                    {translate('alippoApp.Language' + language)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resDiscussion.imageUrl')}
                id="res-discussion-imageUrl"
                name="imageUrl"
                data-cy="imageUrl"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.resDiscussion.isDefault')}
                id="res-discussion-isDefault"
                name="isDefault"
                data-cy="isDefault"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.resDiscussion.resType')}
                id="res-discussion-resType"
                name="resType"
                data-cy="resType"
                type="select"
              >
                {resTypeValues.map(resType => (
                  <option value={resType} key={resType}>
                    {translate('alippoApp.ResType' + resType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resDiscussion.createdAt')}
                id="res-discussion-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.resDiscussion.updatedAt')}
                id="res-discussion-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="res-discussion-res"
                name="res"
                data-cy="res"
                label={translate('alippoApp.resDiscussion.res')}
                type="select"
              >
                <option value="" key="0" />
                {res
                  ? res.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="res-discussion-courseDiscussion"
                name="courseDiscussion"
                data-cy="courseDiscussion"
                label={translate('alippoApp.resDiscussion.courseDiscussion')}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/res-discussion" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ResDiscussionUpdate;
