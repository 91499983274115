export enum ResSource {
  ZOOM1 = 'ZOOM1',

  ZOOM2 = 'ZOOM2',

  ZOOM3 = 'ZOOM3',

  ZOOM4 = 'ZOOM4',

  ZOOM5 = 'ZOOM5',

  AWS1 = 'AWS1',

  AZ1 = 'AZ1',

  GCP1 = 'GCP1',
}
