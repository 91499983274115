import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './tip-translation.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const TipTranslationDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const tipTranslationEntity = useAppSelector(state => state.tipTranslation.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="tipTranslationDetailsHeading">
          <Translate contentKey="alippoApp.tipTranslation.detail.title">TipTranslation</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{tipTranslationEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.tipTranslation.name">Name</Translate>
            </span>
          </dt>
          <dd>{tipTranslationEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.tipTranslation.description">Description</Translate>
            </span>
          </dt>
          <dd>{tipTranslationEntity.description}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="alippoApp.tipTranslation.language">Language</Translate>
            </span>
          </dt>
          <dd>{tipTranslationEntity.language}</dd>
          <dt>
            <span id="isDefault">
              <Translate contentKey="alippoApp.tipTranslation.isDefault">Is Default</Translate>
            </span>
          </dt>
          <dd>{tipTranslationEntity.isDefault ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.tipTranslation.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {tipTranslationEntity.createdAt ? (
              <TextFormat value={tipTranslationEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.tipTranslation.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {tipTranslationEntity.updatedAt ? (
              <TextFormat value={tipTranslationEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.tipTranslation.tip">Tip</Translate>
          </dt>
          <dd>{tipTranslationEntity.tip ? tipTranslationEntity.tip.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/tip-translation" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/tip-translation/${tipTranslationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TipTranslationDetail;
