import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Modal, ModalBody, Row } from 'reactstrap';

import { IRes } from 'app/shared/model/res.model';
import { getEntities as getRes } from 'app/entities/res/res.reducer';
import JsonEntryEditor from 'app/components/json-entry-editor';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getCourses } from 'app/entities/course/course.reducer';
import { Language } from 'app/shared/model/enumerations/language.model';
import { ResMediaType } from 'app/shared/model/enumerations/res-media-type.model';
import { ResState } from 'app/shared/model/enumerations/res-state.model';
import { ResType } from 'app/shared/model/enumerations/res-type.model';
import { IResCourse } from 'app/shared/model/res-course.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import axios from 'axios';
import { createEntity, getEntity, reset, updateEntity } from './res-course.reducer';

export const ResCourseUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locationState: any = location.state;

  let inheritedCourse = null;
  if (locationState) {
    inheritedCourse = locationState.course;
  }
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const res = useAppSelector(state => state.res.entities);
  const resCourseEntity = useAppSelector(state => state.resCourse.entity);
  const loading = useAppSelector(state => state.resCourse.loading);
  const updating = useAppSelector(state => state.resCourse.updating);
  const updateSuccess = useAppSelector(state => state.resCourse.updateSuccess);
  const resStateValues = Object.keys(ResState);
  const resTypeValues = Object.keys(ResType);
  const resMediaTypeValues = Object.keys(ResMediaType);
  const languageValues = Object.keys(Language);

  const handleClose = () => {
    if (resCourseEntity && resCourseEntity.id) {
      dispatch(reset());
      props.history.push('/course/' + `${resCourseEntity.course.id}`);
    } else {
      props.history.push('/res-course' + props.location.search);
    }
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getRes({}));
    dispatch(getCourses({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const defaultValues = () =>
    isNew
      ? {
          resType: resCourseEntity?.resType ?? ResType.EBOOK,
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
          course: inheritedCourse ? inheritedCourse.id : null,
        }
      : {
          ...resCourseEntity,
          resType: resCourseEntity?.resType ?? ResType.EBOOK,
          createdAt: convertDateTimeFromServer(resCourseEntity.createdAt),
          updatedAt: convertDateTimeFromServer(resCourseEntity.updatedAt),
          course: resCourseEntity?.course?.id,
          description: resCourseEntity.description || null,
          res: resCourseEntity?.res?.id,
        };

  const [resCourseData, setResCourseData] = useState<Omit<IResCourse, 'course'> & { course: number }>(defaultValues());
  const [showDescriptionUploadModal, setShowDescriptionUploadModal] = useState<boolean>(false);

  useEffect(() => {
    if (resCourseEntity) {
      setResCourseData(defaultValues());
    }
  }, [resCourseEntity]);

  const handleCloseModal = () => {
    if (showDescriptionUploadModal) {
      setShowDescriptionUploadModal(false);
    }
  };

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity: IResCourse = {
      ...resCourseEntity,
      ...resCourseData,
      ...values,
      res: {
        id: values.res,
      },
      course: {
        id: values.course,
      },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const injectFieldsInDescription = (description: any) => {
    if (resCourseEntity?.resType === ResType.CONFIG) {
      return {
        ...description,
        howToSchemaPayload: description?.howToSchemaPayload ?? null,
      };
    } else if (resCourseEntity?.resType === ResType.AMA) {
      return {
        status: description?.status ?? null,
        startTime: description?.startTime ?? null,
        endTime: description?.endTime ?? null,
      };
    }

    return description;
  };

  const handleSubmitDescription = (values: { description: string }) => {
    try {
      if (values && values.description !== '') {
        const validatedJson = JSON.parse(values.description);
        setResCourseData(prev => ({
          ...prev,
          description: JSON.stringify(validatedJson),
        }));
        toast.success('Description uploaded successfully!');
      }
    } catch (err) {
      console.error(err);
      toast.error('Invalid JSON!');
    } finally {
      setShowDescriptionUploadModal(false);
    }
  };

  const validateUrlField = async (url: string) => {
    if (resCourseEntity?.resType === ResType.EBOOK) {
      const apiUrl = 'api/aws/s3/exists';
      const requestParams: { bucketName: string; objectName: string } = {
        bucketName: 'alippo-ebook-files',
        objectName: url,
      };

      const { data } = await axios.get<boolean>(apiUrl, {
        params: requestParams,
      });

      return data;
    }

    return true;
  };

  const handleGetUpdatedEntity = (newValues: Omit<IResCourse, 'course'> & { course: number }) => {
    setResCourseData(prev => ({
      ...prev,
      ...newValues,
    }));
  };

  return (
    <>
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="alippoApp.resCourse.home.createOrEditLabel" data-cy="ResCourseCreateUpdateHeading">
              <Translate contentKey="alippoApp.resCourse.home.createOrEditLabel">Create or edit a ResCourse</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <ValidatedForm defaultValues={resCourseData} onSubmit={saveEntity}>
                {!isNew ? (
                  <ValidatedField
                    name="id"
                    required
                    readOnly
                    id="res-course-id"
                    label={translate('global.field.id')}
                    validate={{ required: true }}
                  />
                ) : null}
                <ValidatedField label={translate('alippoApp.resCourse.name')} id="res-course-name" name="name" data-cy="name" type="text" />
                <ValidatedField
                  label={translate('alippoApp.resCourse.url')}
                  id="res-course-url"
                  name="url"
                  data-cy="url"
                  type="text"
                  validate={{
                    validate: async url => (await validateUrlField(url)) || 'Given resource is not found in S3',
                  }}
                />
                <ValidatedField
                  label={translate('alippoApp.resCourse.resState')}
                  id="res-course-resState"
                  name="resState"
                  data-cy="resState"
                  type="select"
                >
                  {resStateValues.map(resState => (
                    <option value={resState} key={resState}>
                      {translate('alippoApp.ResState' + resState)}
                    </option>
                  ))}
                </ValidatedField>
                <ValidatedField
                  label={translate('alippoApp.resCourse.resType')}
                  id="res-course-resType"
                  name="resType"
                  data-cy="resType"
                  type="select"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setResCourseData({ ...resCourseData, resType: ResType[event.target.value] })
                  }
                >
                  {resTypeValues.map(resType => (
                    <option value={resType} key={resType}>
                      {translate('alippoApp.ResType' + resType)}
                    </option>
                  ))}
                </ValidatedField>
                <ValidatedField
                  label={translate('alippoApp.resCourse.mediaType')}
                  id="res-course-mediaType"
                  name="mediaType"
                  data-cy="mediaType"
                  type="select"
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setResCourseData({ ...resCourseData, mediaType: ResMediaType[event.target.value] })
                  }
                >
                  {resMediaTypeValues.map(resMediaType => (
                    <option value={resMediaType} key={resMediaType}>
                      {translate('alippoApp.ResMediaType' + resMediaType)}
                    </option>
                  ))}
                </ValidatedField>
                {resCourseData?.mediaType === ResMediaType.JSON ? (
                  <>
                    <JsonEntryEditor
                      entityShape={resCourseData}
                      entityKey="description"
                      value={injectFieldsInDescription(JSON.parse(resCourseData?.description ?? '{}'))}
                      label={translate('alippoApp.resCourse.description')}
                      id="res-course-description"
                      cyDataKey="description"
                      handleGetUpdatedEntity={handleGetUpdatedEntity}
                    />
                    <a
                      href="https://technicalseo.com/tools/schema-markup-generator"
                      className="text-info font-weight-normal d-inline-block mb-2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Create how to schema
                    </a>
                  </>
                ) : (
                  <ValidatedField
                    label={translate('alippoApp.resCourse.description')}
                    id="res-course-description"
                    name="description"
                    data-cy="description"
                    type="textarea"
                  />
                )}
                <ValidatedField
                  label={translate('alippoApp.resCourse.language')}
                  id="res-course-language"
                  name="language"
                  data-cy="language"
                  type="select"
                >
                  {languageValues.map(language => (
                    <option value={language} key={language}>
                      {translate('alippoApp.Language' + language)}
                    </option>
                  ))}
                </ValidatedField>
                <ValidatedField
                  label={translate('alippoApp.resCourse.videoControlsConfig')}
                  id="res-course-videoControlsConfig"
                  name="videoControlsConfig"
                  data-cy="videoControlsConfig"
                  type="textarea"
                />
                <ValidatedField
                  label={translate('alippoApp.resCourse.aspectRatio')}
                  id="res-course-aspectRatio"
                  name="aspectRatio"
                  data-cy="aspectRatio"
                  type="text"
                />
                <ValidatedField
                  label={translate('alippoApp.resCourse.createdAt')}
                  id="res-course-createdAt"
                  name="createdAt"
                  data-cy="createdAt"
                  type="datetime-local"
                  placeholder="YYYY-MM-DD HH:mm"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
                <ValidatedField
                  label={translate('alippoApp.resCourse.updatedAt')}
                  id="res-course-updatedAt"
                  name="updatedAt"
                  data-cy="updatedAt"
                  type="datetime-local"
                  placeholder="YYYY-MM-DD HH:mm"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
                <ValidatedField id="res-course-res" name="res" data-cy="res" label={translate('alippoApp.resCourse.res')} />
                <ValidatedField
                  id="res-course-course"
                  name="course"
                  data-cy="course"
                  label={translate('alippoApp.resCourse.course')}
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/res-course" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </ValidatedForm>
            )}
          </Col>
        </Row>
      </div>

      {/* Upload user extra properties modal */}
      <Modal isOpen={showDescriptionUploadModal} toggle={handleCloseModal}>
        <ModalBody>
          <ValidatedForm onSubmit={handleSubmitDescription}>
            <ValidatedField
              label={translate('alippoApp.resCourse.description')}
              id="res-course-description"
              name="description"
              data-cy="description"
              type="textarea"
              placeholder="Please add description JSON..."
              style={{ minHeight: '15rem' }}
            />
            <div className="d-flex w-100 justify-content-end">
              <Button className="mr-2" color="secondary" onClick={handleCloseModal}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <Button color="success" type="submit">
                Submit
              </Button>
            </div>
          </ValidatedForm>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ResCourseUpdate;
