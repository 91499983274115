import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { ICourseDiscussion } from 'app/shared/model/course-discussion.model';
import { getEntities as getCourseDiscussions } from 'app/entities/course-discussion/course-discussion.reducer';
import { getEntity, updateEntity, createEntity, reset } from './discussion-member.reducer';
import { IDiscussionMember } from 'app/shared/model/discussion-member.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { GroupLeaveReason } from 'app/shared/model/enumerations/group-leave-reason.model';

export const DiscussionMemberUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const users = useAppSelector(state => state.userManagement.users);
  const courseDiscussions = useAppSelector(state => state.courseDiscussion.entities);
  const discussionMemberEntity = useAppSelector(state => state.discussionMember.entity);
  const loading = useAppSelector(state => state.discussionMember.loading);
  const updating = useAppSelector(state => state.discussionMember.updating);
  const updateSuccess = useAppSelector(state => state.discussionMember.updateSuccess);
  const groupLeaveReasonValues = Object.keys(GroupLeaveReason);
  const handleClose = () => {
    props.history.push('/discussion-member' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getUsers({}));
    dispatch(getCourseDiscussions({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.joinedOn = convertDateTimeToServer(values.joinedOn);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...discussionMemberEntity,
      ...values,
      user: { id: values.user },
      removedByUser: { id: values.removedByUser },
      discussion: { id: values.discussion },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          joinedOn: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          leaveReason: 'NO_REASON',
          ...discussionMemberEntity,
          joinedOn: convertDateTimeFromServer(discussionMemberEntity.joinedOn),
          createdAt: convertDateTimeFromServer(discussionMemberEntity.createdAt),
          updatedAt: convertDateTimeFromServer(discussionMemberEntity.updatedAt),
          user: discussionMemberEntity?.user?.id,
          removedByUser: discussionMemberEntity?.removedByUser?.id,
          discussion: discussionMemberEntity?.discussion?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.discussionMember.home.createOrEditLabel" data-cy="DiscussionMemberCreateUpdateHeading">
            <Translate contentKey="alippoApp.discussionMember.home.createOrEditLabel">Create or edit a DiscussionMember</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="discussion-member-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.discussionMember.joinedOn')}
                id="discussion-member-joinedOn"
                name="joinedOn"
                data-cy="joinedOn"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('alippoApp.discussionMember.hasMutedGroup')}
                id="discussion-member-hasMutedGroup"
                name="hasMutedGroup"
                data-cy="hasMutedGroup"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.discussionMember.leaveReason')}
                id="discussion-member-leaveReason"
                name="leaveReason"
                data-cy="leaveReason"
                type="select"
              >
                {groupLeaveReasonValues.map(groupLeaveReason => (
                  <option value={groupLeaveReason} key={groupLeaveReason}>
                    {translate('alippoApp.GroupLeaveReason' + groupLeaveReason)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.discussionMember.removed')}
                id="discussion-member-removed"
                name="removed"
                data-cy="removed"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.discussionMember.nickName')}
                id="discussion-member-nickName"
                name="nickName"
                data-cy="nickName"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.discussionMember.color')}
                id="discussion-member-color"
                name="color"
                data-cy="color"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.discussionMember.blocked')}
                id="discussion-member-blocked"
                name="blocked"
                data-cy="blocked"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.discussionMember.admin')}
                id="discussion-member-admin"
                name="admin"
                data-cy="admin"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.discussionMember.createdAt')}
                id="discussion-member-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.discussionMember.updatedAt')}
                id="discussion-member-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="discussion-member-user"
                name="user"
                data-cy="user"
                label={translate('alippoApp.discussionMember.user')}
                required
              />
              <ValidatedField
                id="discussion-member-removedByUser"
                name="removedByUser"
                data-cy="removedByUser"
                label={translate('alippoApp.discussionMember.removedByUser')}
              />
              <ValidatedField
                id="discussion-member-discussion"
                name="discussion"
                data-cy="discussion"
                label={translate('alippoApp.discussionMember.discussion')}
                required
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/discussion-member" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DiscussionMemberUpdate;
