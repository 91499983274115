import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import RuleLedger from './rule-ledger';
import RuleLedgerDetail from './rule-ledger-detail';
import RuleLedgerUpdate from './rule-ledger-update';
import RuleLedgerDeleteDialog from './rule-ledger-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={RuleLedgerUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={RuleLedgerUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={RuleLedgerDetail} />
      <ErrorBoundaryRoute path={match.url} component={RuleLedger} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={RuleLedgerDeleteDialog} />
  </>
);

export default Routes;
