import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './voucher.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { toast } from 'react-toastify';

export const VoucherDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [voucherTranslationData, setVoucherTranslationData] = useState(null);

  const getVoucherTranslations = async () => {
    if (voucherEntity.id) {
      try {
        const apiUrl = `api/voucher-translations?voucherId.equals=${voucherEntity.id}`;
        const res: any = await axios.get(apiUrl);
        const voucherTransations = res.data;
        if (voucherTransations.length === 0) {
          toast.error('No voucher translations available');
        } else {
          setVoucherTranslationData(
            voucherTransations.map(e => {
              const voucherTranslationId = e.id;
              return {
                id: voucherTranslationId,
                name: e.name,
                voucherUrl: `/voucher-translation/${voucherTranslationId}`,
              };
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const voucherEntity = useAppSelector(state => state.voucher.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="voucherDetailsHeading">
          <Translate contentKey="alippoApp.voucher.detail.title">Voucher</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{voucherEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.voucher.name">Name</Translate>
            </span>
          </dt>
          <dd>{voucherEntity.name}</dd>
          <dt>
            <span id="sortOrder">
              <Translate contentKey="alippoApp.voucher.sortOrder">Sort Order</Translate>
            </span>
          </dt>
          <dd>{voucherEntity.sortOrder}</dd>
          <dt>
            <span id="isAutoRedeemable">
              <Translate contentKey="alippoApp.voucher.isAutoRedeemable">Is Auto Redeemable</Translate>
            </span>
          </dt>
          <dd>{voucherEntity.isAutoRedeemable ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.voucher.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{voucherEntity.createdAt ? <TextFormat value={voucherEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.voucher.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{voucherEntity.updatedAt ? <TextFormat value={voucherEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="alippoApp.voucher.sku">Sku</Translate>
          </dt>
          <dd>{voucherEntity.sku ? voucherEntity.sku.id : ''}</dd>
        </dl>
        <dl>
          <dt>Voucher Translations</dt>
          <Button onClick={getVoucherTranslations} className="mr-2" color="primary">
            <span>Get Voucher Translations</span>
          </Button>
          <Link to={{ pathname: '/voucher-translation/new', state: { voucher: voucherEntity } }}>
            <Button className="rounded-circle" color="success">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Link>
          <dd>
            {voucherTranslationData && voucherTranslationData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">ID</th>
                    <th className="hand">Voucher Translation Name</th>
                    <th className="hand">Voucher Translation URL</th>
                  </tr>
                </thead>
                <tbody>
                  {voucherTranslationData.map((voucherTranslation, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{voucherTranslation.id}</td>
                      <td>{voucherTranslation.name}</td>
                      <td>
                        <Link to={voucherTranslation.voucherUrl}>Link</Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/voucher" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/voucher/${voucherEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default VoucherDetail;
