export enum RuleCurrency {
  DEFAULT = 'DEFAULT',

  COIN = 'COIN',

  COUPON = 'COUPON',

  DISCOUNT = 'DISCOUNT',

  VIRTUAL_MONEY_COIN = 'VIRTUAL_MONEY_COIN',

  VIRTUAL_MONEY_FIAT = 'VIRTUAL_MONEY_FIAT',

  DIG_COF_CREDIT = 'DIG_COF_CREDIT',
}
