import React from 'react';
import Section from './section';
import {
  faBook,
  faBookOpen,
  faClone,
  faCog,
  faStar,
  faUserTie,
  faExchangeAlt,
  faPumpSoap,
  faMoneyBill,
} from '@fortawesome/free-solid-svg-icons';

function HomeSections() {
  const courseElements = [
    {
      title: 'Onboard Course',
      icon: faBook,
      route: '/course/new',
    },
    {
      title: 'Modify Course',
      icon: faBookOpen,
      route: '/course?page=1&sort=id,asc',
    },
    {
      title: 'Clone Course',
      icon: faClone,
      route: '/course/clone',
    },
    {
      title: 'Onboard Instructor',
      icon: faUserTie,
      route: '/admin/user-management/new',
    },
    {
      title: 'Manage Ratings and Reviews',
      icon: faStar,
      route: '/review?page=1&sort=id,asc',
    },
    {
      title: 'Website Invalidator',
      icon: faPumpSoap,
      route: '/website-invalidator',
    },
    {
      title: 'Place Order',
      icon: faMoneyBill,
      route: '/place-order',
    },
  ];

  const toolsElements = [
    {
      title: 'UTM Generator',
      icon: faCog,
      route: '/admin/utm-parameters',
    },
  ];

  const customerSupportElements = [
    {
      title: 'Migrate phone number',
      icon: faExchangeAlt,
      route: '/customer-support/migrate-phone-number',
    },
  ];

  return (
    <div className="d-flex flex-column">
      <Section title="Operations" elements={courseElements} />
      <Section title="Marketing Tools" elements={toolsElements} />
      <Section title="Customer Support" elements={customerSupportElements} />
    </div>
  );
}

export default HomeSections;
