import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getCourseDiscussions } from 'app/entities/course-discussion/course-discussion.reducer';
import { getEntities as getCourses } from 'app/entities/course/course.reducer';
import { getEntities as getOrderInfos } from 'app/entities/order-info/order-info.reducer';
import { getEntities as getOrderItems } from 'app/entities/order-item/order-item.reducer';
import { getEntities as getSkus } from 'app/entities/sku/sku.reducer';
import { CurrencyCode } from 'app/shared/model/enumerations/currency-code.model';
import { OrderItemStatus } from 'app/shared/model/enumerations/order-item-status.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { createEntity, getEntity, reset, updateEntity } from './order-item.reducer';

export const OrderItemUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const orderItemEntity = useAppSelector(state => state.orderItem.entity);
  const loading = useAppSelector(state => state.orderItem.loading);
  const updating = useAppSelector(state => state.orderItem.updating);
  const updateSuccess = useAppSelector(state => state.orderItem.updateSuccess);
  const orderItemStatusValues = Object.keys(OrderItemStatus);
  const currencyCodeValues = Object.keys(CurrencyCode);
  const handleClose = () => {
    if (orderItemEntity && orderItemEntity.id) {
      props.history.push('/order-item/' + `${orderItemEntity.id}`);
    } else {
      props.history.push('/order-item' + props.location.search);
    }
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getCourses({}));
    dispatch(getSkus({}));
    dispatch(getCourseDiscussions({}));
    dispatch(getOrderItems({}));
    dispatch(getOrderInfos({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...orderItemEntity,
      ...values,
      course: { id: values.course },
      sku: { id: values.sku },
      discussion: { id: values.discussion },
      parent: { id: values.parent },
      orderInfo: { id: values.orderInfo },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          orderItemStatus: 'PENDING',
          sellingPriceCurrencyCode: 'INR',
          ...orderItemEntity,
          createdAt: convertDateTimeFromServer(orderItemEntity.createdAt),
          updatedAt: convertDateTimeFromServer(orderItemEntity.updatedAt),
          course: orderItemEntity?.course?.id,
          sku: orderItemEntity?.sku?.id,
          discussion: orderItemEntity?.discussion?.id,
          parent: orderItemEntity?.parent?.id,
          orderInfo: orderItemEntity?.orderInfo?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.orderItem.home.createOrEditLabel" data-cy="OrderItemCreateUpdateHeading">
            <Translate contentKey="alippoApp.orderItem.home.createOrEditLabel">Create or edit a OrderItem</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="order-item-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.orderItem.orderItemStatus')}
                id="order-item-orderItemStatus"
                name="orderItemStatus"
                data-cy="orderItemStatus"
                type="select"
              >
                {orderItemStatusValues.map(orderItemStatus => (
                  <option value={orderItemStatus} key={orderItemStatus}>
                    {translate('alippoApp.OrderItemStatus' + orderItemStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.orderItem.sellingPriceAmount')}
                id="order-item-sellingPriceAmount"
                name="sellingPriceAmount"
                data-cy="sellingPriceAmount"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alippoApp.orderItem.originalPriceAmount')}
                id="order-item-originalPriceAmount"
                name="originalPriceAmount"
                data-cy="originalPriceAmount"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.orderItem.sellingPriceCurrencyCode')}
                id="order-item-sellingPriceCurrencyCode"
                name="sellingPriceCurrencyCode"
                data-cy="sellingPriceCurrencyCode"
                type="select"
              >
                {currencyCodeValues.map(currencyCode => (
                  <option value={currencyCode} key={currencyCode}>
                    {translate('alippoApp.CurrencyCode' + currencyCode)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.orderItem.virtualMoneyRewardUsed')}
                id="order-item-virtualMoneyRewardUsed"
                name="virtualMoneyRewardUsed"
                data-cy="virtualMoneyRewardUsed"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.orderItem.description')}
                id="order-item-description"
                name="description"
                data-cy="description"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.orderItem.createdAt')}
                id="order-item-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.orderItem.updatedAt')}
                id="order-item-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField id="order-item-course" name="course" data-cy="course" label={translate('alippoApp.orderItem.course')} />
              <ValidatedField label={translate('alippoApp.orderItem.sku')} id="order-item-sku" data-cy="sku" name="sku" />
              <ValidatedField
                id="order-item-discussion"
                name="discussion"
                data-cy="discussion"
                label={translate('alippoApp.orderItem.discussion')}
              />
              <ValidatedField label={translate('alippoApp.orderItem.parent')} id="order-item-parent" data-cy="parent" name="parent" />
              <ValidatedField
                id="order-item-orderInfo"
                name="orderInfo"
                data-cy="orderInfo"
                label={translate('alippoApp.orderItem.orderInfo')}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/order-item" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OrderItemUpdate;
