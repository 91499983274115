import dayjs from 'dayjs';
import { ITenantExtra } from 'app/shared/model/tenant-extra.model';
import { TenantType } from 'app/shared/model/enumerations/tenant-type.model';

export interface ITenant {
  id?: number;
  name?: string;
  tenantType?: TenantType;
  createdAt?: string;
  updatedAt?: string;
  tenantExtras?: ITenantExtra[] | null;
}

export const defaultValue: Readonly<ITenant> = {};
