import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import RuleTranslation from './rule-translation';
import RuleTranslationDetail from './rule-translation-detail';
import RuleTranslationUpdate from './rule-translation-update';
import RuleTranslationDeleteDialog from './rule-translation-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={RuleTranslationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={RuleTranslationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={RuleTranslationDetail} />
      <ErrorBoundaryRoute path={match.url} component={RuleTranslation} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={RuleTranslationDeleteDialog} />
  </>
);

export default Routes;
