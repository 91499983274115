import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './order-item-plan.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const OrderItemPlanDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const orderItemPlanEntity = useAppSelector(state => state.orderItemPlan.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="orderItemPlanDetailsHeading">
          <Translate contentKey="alippoApp.orderItemPlan.detail.title">OrderItemPlan</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{orderItemPlanEntity.id}</dd>
          <dt>
            <span id="action">
              <Translate contentKey="alippoApp.orderItemPlan.action">Action</Translate>
            </span>
          </dt>
          <dd>{orderItemPlanEntity.action}</dd>
          <dt>
            <span id="fromDateTime">
              <Translate contentKey="alippoApp.orderItemPlan.fromDateTime">From Date Time</Translate>
            </span>
          </dt>
          <dd>
            {orderItemPlanEntity.fromDateTime ? (
              <TextFormat value={orderItemPlanEntity.fromDateTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="duration">
              <Translate contentKey="alippoApp.orderItemPlan.duration">Duration</Translate>
            </span>
          </dt>
          <dd>{orderItemPlanEntity.duration}</dd>
          <dt>
            <span id="quantity">
              <Translate contentKey="alippoApp.orderItemPlan.quantity">Quantity</Translate>
            </span>
          </dt>
          <dd>{orderItemPlanEntity.quantity}</dd>
          <dt>
            <span id="frequency">
              <Translate contentKey="alippoApp.orderItemPlan.frequency">Frequency</Translate>
            </span>
          </dt>
          <dd>{orderItemPlanEntity.frequency}</dd>
          <dt>
            <span id="prefDoorBell">
              <Translate contentKey="alippoApp.orderItemPlan.prefDoorBell">Pref Door Bell</Translate>
            </span>
          </dt>
          <dd>{orderItemPlanEntity.prefDoorBell ? 'true' : 'false'}</dd>
          <dt>
            <span id="deliverySlotFromTime">
              <Translate contentKey="alippoApp.orderItemPlan.deliverySlotFromTime">Delivery Slot From Time</Translate>
            </span>
          </dt>
          <dd>{orderItemPlanEntity.deliverySlotFromTime}</dd>
          <dt>
            <span id="deliverySlotDuration">
              <Translate contentKey="alippoApp.orderItemPlan.deliverySlotDuration">Delivery Slot Duration</Translate>
            </span>
          </dt>
          <dd>{orderItemPlanEntity.deliverySlotDuration}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.orderItemPlan.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {orderItemPlanEntity.createdAt ? (
              <TextFormat value={orderItemPlanEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.orderItemPlan.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {orderItemPlanEntity.updatedAt ? (
              <TextFormat value={orderItemPlanEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.orderItemPlan.orderItem">Order Item</Translate>
          </dt>
          <dd>{orderItemPlanEntity.orderItem ? orderItemPlanEntity.orderItem.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.orderItemPlan.planItem">Plan Item</Translate>
          </dt>
          <dd>{orderItemPlanEntity.planItem ? orderItemPlanEntity.planItem.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/order-item-plan" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/order-item-plan/${orderItemPlanEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default OrderItemPlanDetail;
