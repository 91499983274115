import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './user-extra.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const UserExtraDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const userExtraEntity = useAppSelector(state => state.userExtra.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="userExtraDetailsHeading">
          <Translate contentKey="alippoApp.userExtra.detail.title">UserExtra</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{userExtraEntity.id}</dd>
          <dt>
            <span id="otp">
              <Translate contentKey="alippoApp.userExtra.otp">Otp</Translate>
            </span>
          </dt>
          <dd>{userExtraEntity.otp}</dd>
          <dt>
            <span id="otpTs">
              <Translate contentKey="alippoApp.userExtra.otpTs">Otp Ts</Translate>
            </span>
          </dt>
          <dd>{userExtraEntity.otpTs ? <TextFormat value={userExtraEntity.otpTs} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="verifiedTs">
              <Translate contentKey="alippoApp.userExtra.verifiedTs">Verified Ts</Translate>
            </span>
          </dt>
          <dd>
            {userExtraEntity.verifiedTs ? <TextFormat value={userExtraEntity.verifiedTs} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="certName">
              <Translate contentKey="alippoApp.userExtra.certName">Cert Name</Translate>
            </span>
          </dt>
          <dd>{userExtraEntity.certName}</dd>
          <dt>
            <span id="certNameTs">
              <Translate contentKey="alippoApp.userExtra.certNameTs">Cert Name Ts</Translate>
            </span>
          </dt>
          <dd>
            {userExtraEntity.certNameTs ? <TextFormat value={userExtraEntity.certNameTs} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="imageUrl">
              <Translate contentKey="alippoApp.userExtra.imageUrl">Image Url</Translate>
            </span>
          </dt>
          <dd>{userExtraEntity.imageUrl}</dd>
          <dt>
            <span id="ipInfo">
              <Translate contentKey="alippoApp.userExtra.ipInfo">Ip Info</Translate>
            </span>
          </dt>
          <dd>{userExtraEntity.ipInfo}</dd>
          <dt>
            <span id="referrerCode">
              <Translate contentKey="alippoApp.userExtra.referrerCode">Referrer Code</Translate>
            </span>
          </dt>
          <dd>{userExtraEntity.referrerCode}</dd>
          <dt>
            <span id="properties">
              <Translate contentKey="alippoApp.userExtra.properties">Properties</Translate>
            </span>
          </dt>
          <dd>{userExtraEntity.properties}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.userExtra.description">Description</Translate>
            </span>
          </dt>
          <dd>{userExtraEntity.description}</dd>
          <dt>
            <span id="interest">
              <Translate contentKey="alippoApp.userExtra.interest">Interest</Translate>
            </span>
          </dt>
          <dd>{userExtraEntity.interest}</dd>
          <dt>
            <span id="phone">
              <Translate contentKey="alippoApp.userExtra.phone">Phone</Translate>
            </span>
          </dt>
          <dd>{userExtraEntity.phone}</dd>
          <dt>
            <span id="firstAcquisitionInfo">
              <Translate contentKey="alippoApp.userExtra.firstAcquisitionInfo">First Acquisition Info</Translate>
            </span>
          </dt>
          <dd>{userExtraEntity.firstAcquisitionInfo}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.userExtra.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {userExtraEntity.createdAt ? <TextFormat value={userExtraEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.userExtra.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {userExtraEntity.updatedAt ? <TextFormat value={userExtraEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.userExtra.user">User</Translate>
          </dt>
          <dd>{userExtraEntity.user ? userExtraEntity.user.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/user-extra" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/user-extra/${userExtraEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default UserExtraDetail;
