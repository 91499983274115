import dayjs from 'dayjs';
import { IResTask } from 'app/shared/model/res-task.model';
import { IResStep } from 'app/shared/model/res-step.model';
import { ResState } from 'app/shared/model/enumerations/res-state.model';
import { ResSource } from 'app/shared/model/enumerations/res-source.model';
import { ResMediaType } from 'app/shared/model/enumerations/res-media-type.model';
import { ResMimeType } from 'app/shared/model/enumerations/res-mime-type.model';

export interface IRes {
  id?: number;
  name?: string | null;
  url?: string | null;
  resState?: ResState | null;
  resSource?: ResSource | null;
  mediaType?: ResMediaType | null;
  mimeType?: ResMimeType | null;
  description?: string | null;
  createdAt?: string;
  updatedAt?: string;
  parent?: IRes | null;
  children?: IRes[] | null;
  resTasks?: IResTask[] | null;
  resSteps?: IResStep[] | null;
}

export const defaultValue: Readonly<IRes> = {};
