import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ResProduct from './res-product';
import ResProductDetail from './res-product-detail';
import ResProductUpdate from './res-product-update';
import ResProductDeleteDialog from './res-product-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ResProductUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ResProductUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ResProductDetail} />
      <ErrorBoundaryRoute path={match.url} component={ResProduct} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ResProductDeleteDialog} />
  </>
);

export default Routes;
