import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { BankInfoType } from 'app/shared/model/enumerations/bank-info-type.model';

export interface IBeneficiaryInfo {
  id?: number;
  type?: BankInfoType;
  isActive?: boolean;
  priority?: number;
  payload?: string;
  version?: string;
  createdAt?: string;
  updatedAt?: string;
  user?: IUser | null;
}

export const defaultValue: Readonly<IBeneficiaryInfo> = {
  isActive: false,
};
