import React, { useState, useEffect, useMemo } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { byteSize, Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';

import { getEntities } from './course-section-lesson.reducer';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overrideFiltersStateWithQueryParams, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

type CourseSectionLessonFilterType = {
  courseSectionId: string | null;
  sectionId: string | null;
  lessonId: string | null;
};

export const CourseSectionLesson = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );
  const [courseSectionLessonFilter, setCourseSectionLessonFilter] = useState<CourseSectionLessonFilterType>(
    overrideFiltersStateWithQueryParams(
      {
        courseSectionId: null,
        sectionId: null,
        lessonId: null,
      } as CourseSectionLessonFilterType,
      props.location.search
    )
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const courseSectionLessonList = useAppSelector(state => state.courseSectionLesson.entities);
  const loading = useAppSelector(state => state.courseSectionLesson.loading);
  const totalItems = useAppSelector(state => state.courseSectionLesson.totalItems);

  const getAllEntities = () => {
    setIsLoading(true);

    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        props: buildSearchParamsString(),
      })
    ).then(() => setIsLoading(false));

    const params = buildSearchParamsString();

    const endUrl = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}${params}`;

    if (window.location.search !== endUrl) {
      window.history.replaceState(null, '', `${props.location.pathname}${endUrl}`);
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState, courseSectionLessonFilter]);

  const buildSearchParamsString = () => {
    let paramsString = '';

    if (courseSectionLessonFilter.courseSectionId) {
      paramsString += `&courseSectionId.equals=${courseSectionLessonFilter.courseSectionId}`;
    }

    if (courseSectionLessonFilter.sectionId) {
      paramsString += `&sectionId.equals=${courseSectionLessonFilter.sectionId}`;
    }

    if (courseSectionLessonFilter.lessonId) {
      paramsString += `&lessonId.equals=${courseSectionLessonFilter.lessonId}`;
    }

    return paramsString;
  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    getAllEntities();
  };

  const handleFilterChange = e => {
    setCourseSectionLessonFilter(prev => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const debouncedHandleFilterChange = useMemo(() => debounce(handleFilterChange, 500), []);

  const { match } = props;

  return (
    <div>
      <h2 id="course-section-lesson-heading" data-cy="CourseSectionLessonHeading">
        <Translate contentKey="alippoApp.courseSectionLesson.home.title">Course Section Lessons</Translate>
      </h2>
      <div className="d-flex justify-content-end">
        <Button className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="alippoApp.courseSectionLesson.home.refreshListLabel">Refresh List</Translate>
        </Button>
        <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="alippoApp.courseSectionLesson.home.createLabel">Create new Course Section Lesson</Translate>
        </Link>
      </div>
      <div className="mt-5 mb-4">
        <Form>
          <Row xs={1} sm={2} md={3}>
            <Col>
              <FormGroup>
                <Label for="name">Course Section ID</Label>
                <Input
                  id="courseSectionId"
                  name="courseSectionId"
                  placeholder="Enter Course Section ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={courseSectionLessonFilter.courseSectionId}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="name">Section ID</Label>
                <Input
                  id="sectionId"
                  name="sectionId"
                  placeholder="Enter Section ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={courseSectionLessonFilter.sectionId}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="name">Lesson ID</Label>
                <Input
                  id="lessonId"
                  name="lessonId"
                  placeholder="Enter Lesson ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={courseSectionLessonFilter.lessonId}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <h6 className="text-center mt-2" style={isLoading ? { opacity: 1 } : { opacity: 0 }}>
          Loading ...
        </h6>
      </div>
      <div className="table-responsive">
        {courseSectionLessonList && courseSectionLessonList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="alippoApp.courseSectionLesson.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('fromDateTime')}>
                  <Translate contentKey="alippoApp.courseSectionLesson.fromDateTime">From Date Time</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('duration')}>
                  <Translate contentKey="alippoApp.courseSectionLesson.duration">Duration</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('sortOrder')}>
                  <Translate contentKey="alippoApp.courseSectionLesson.sortOrder">Sort Order</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('isPreRecorded')}>
                  <Translate contentKey="alippoApp.courseSectionLesson.isPreRecorded">Is Pre Recorded</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="alippoApp.courseSectionLesson.createdAt">Created At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updatedAt')}>
                  <Translate contentKey="alippoApp.courseSectionLesson.updatedAt">Updated At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('configPayload')}>
                  <Translate contentKey="alippoApp.courseSectionLesson.configPayload">Config Payload</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.courseSectionLesson.lesson">Lesson</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.courseSectionLesson.section">Section</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.courseSectionLesson.associatedCourseSectionLesson">
                    Associated Course Section Lesson
                  </Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.courseSectionLesson.discussion">Discussion</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.courseSectionLesson.courseSection">Course Section</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {courseSectionLessonList.map((courseSectionLesson, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`${match.url}/${courseSectionLesson.id}`} color="link" size="sm">
                      {courseSectionLesson.id}
                    </Button>
                  </td>
                  <td>
                    {courseSectionLesson.fromDateTime ? (
                      <TextFormat type="date" value={courseSectionLesson.fromDateTime} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{courseSectionLesson.duration}</td>
                  <td>{courseSectionLesson.sortOrder}</td>
                  <td>{courseSectionLesson.isPreRecorded ? 'true' : 'false'}</td>
                  <td>
                    {courseSectionLesson.createdAt ? (
                      <TextFormat type="date" value={courseSectionLesson.createdAt} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {courseSectionLesson.updatedAt ? (
                      <TextFormat type="date" value={courseSectionLesson.updatedAt} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{courseSectionLesson.configPayload}</td>
                  <td>
                    {courseSectionLesson.lesson ? (
                      <Link to={`lesson/${courseSectionLesson.lesson.id}`}>{courseSectionLesson.lesson.name}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {courseSectionLesson.section ? (
                      <Link to={`section/${courseSectionLesson.section.id}`}>{courseSectionLesson.section.name}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {courseSectionLesson.associatedCourseSectionLesson ? (
                      <Link to={`course-section-lesson/${courseSectionLesson.associatedCourseSectionLesson.id}`}>
                        {courseSectionLesson.associatedCourseSectionLesson.id}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {courseSectionLesson.discussion ? (
                      <Link to={`course-discussion/${courseSectionLesson.discussion.id}`}>{courseSectionLesson.discussion.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {courseSectionLesson.courseSection ? (
                      <Link to={`course-section/${courseSectionLesson.courseSection.id}`}>{courseSectionLesson.courseSection.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${courseSectionLesson.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${courseSectionLesson.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${courseSectionLesson.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="alippoApp.courseSectionLesson.home.notFound">No Course Section Lessons found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={courseSectionLessonList && courseSectionLessonList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default CourseSectionLesson;
