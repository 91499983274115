import React, { useState, useEffect, useMemo } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities } from './sku-application-state-transition.reducer';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overrideFiltersStateWithQueryParams, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { debounce } from 'lodash';

type SkuApplicationStateTransitionFilterType = {
  skuApplicationId: number | null;
};

export const SkuApplicationStateTransition = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );
  const [skuApplicationStateTransitionFilter, setSkuApplicationStateTransitionFilter] = useState<SkuApplicationStateTransitionFilterType>(
    overrideFiltersStateWithQueryParams({ skuApplicationId: null } as SkuApplicationStateTransitionFilterType, props.location.search)
  );
  const [skuApplicationStateTransitionsLoading, setSkuApplicationStateTransitionsLoading] = useState(false);

  const skuApplicationStateTransitionList = useAppSelector(state => state.skuApplicationStateTransition.entities);
  const loading = useAppSelector(state => state.skuApplicationStateTransition.loading);
  const totalItems = useAppSelector(state => state.skuApplicationStateTransition.totalItems);

  const getAllEntities = () => {
    setSkuApplicationStateTransitionsLoading(true);

    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        props: buildSearchParamsString(),
      })
    ).then(() => setSkuApplicationStateTransitionsLoading(false));

    const params = buildSearchParamsString();

    const endUrl = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}${params}`;

    if (window.location.search !== endUrl) {
      window.history.replaceState(null, '', `${props.location.pathname}${endUrl}`);
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState, skuApplicationStateTransitionFilter]);

  const buildSearchParamsString = () => {
    let paramsString = '';

    if (skuApplicationStateTransitionFilter.skuApplicationId) {
      paramsString += `&skuApplicationId.equals=${skuApplicationStateTransitionFilter.skuApplicationId}`;
    }

    return paramsString;
  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    getAllEntities();
  };

  const handleFilterChange = e => {
    setSkuApplicationStateTransitionFilter(prev => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const debouncedHandleFilterChange = useMemo(() => debounce(handleFilterChange, 500), []);

  const { match } = props;

  return (
    <div>
      <h2 id="sku-application-state-transition-heading" data-cy="SkuApplicationStateTransitionHeading">
        <Translate contentKey="alippoApp.skuApplicationStateTransition.home.title">Sku Application State Transitions</Translate>
      </h2>
      <div className="d-flex justify-content-end">
        <Button className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="alippoApp.skuApplicationStateTransition.home.refreshListLabel">Refresh List</Translate>
        </Button>
        <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="alippoApp.skuApplicationStateTransition.home.createLabel">
            Create new Sku Application State Transition
          </Translate>
        </Link>
      </div>
      <div className="my-4">
        <Form>
          <Row md={3}>
            <Col>
              <FormGroup>
                <Label for="name">Sku Application ID</Label>
                <Input
                  id="skuApplicationId"
                  name="skuApplicationId"
                  placeholder="Enter Sku Application ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={skuApplicationStateTransitionFilter.skuApplicationId}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <h6 className="text-center mt-2" style={skuApplicationStateTransitionsLoading ? { opacity: 1 } : { opacity: 0 }}>
          Loading ...
        </h6>
      </div>
      <div className="table-responsive">
        {skuApplicationStateTransitionList && skuApplicationStateTransitionList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="alippoApp.skuApplicationStateTransition.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('oldStatus')}>
                  <Translate contentKey="alippoApp.skuApplicationStateTransition.oldStatus">Old Status</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('newStatus')}>
                  <Translate contentKey="alippoApp.skuApplicationStateTransition.newStatus">New Status</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('comment')}>
                  <Translate contentKey="alippoApp.skuApplicationStateTransition.comment">Comment</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="alippoApp.skuApplicationStateTransition.createdAt">Created At</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.skuApplicationStateTransition.skuApplication">Sku Application</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {skuApplicationStateTransitionList.map((skuApplicationStateTransition, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`${match.url}/${skuApplicationStateTransition.id}`} color="link" size="sm">
                      {skuApplicationStateTransition.id}
                    </Button>
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.SkuApplicationStatus.${skuApplicationStateTransition.oldStatus}`} />
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.SkuApplicationStatus.${skuApplicationStateTransition.newStatus}`} />
                  </td>
                  <td>{skuApplicationStateTransition.comment}</td>
                  <td>
                    {skuApplicationStateTransition.createdAt ? (
                      <TextFormat type="date" value={skuApplicationStateTransition.createdAt} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {skuApplicationStateTransition.skuApplication ? (
                      <Link to={`sku-application/${skuApplicationStateTransition.skuApplication.id}`}>
                        {skuApplicationStateTransition.skuApplication.id}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`${match.url}/${skuApplicationStateTransition.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${skuApplicationStateTransition.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${skuApplicationStateTransition.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="alippoApp.skuApplicationStateTransition.home.notFound">
                No Sku Application State Transitions found
              </Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={skuApplicationStateTransitionList && skuApplicationStateTransitionList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SkuApplicationStateTransition;
