import dayjs from 'dayjs';
import { ICartItemSkuAttribute } from 'app/shared/model/cart-item-sku-attribute.model';
import { ICourse } from 'app/shared/model/course.model';
import { ISku } from 'app/shared/model/sku.model';
import { ICart } from 'app/shared/model/cart.model';
import { CurrencyCode } from 'app/shared/model/enumerations/currency-code.model';
import { CartItemUnavailabilityReason } from 'app/shared/model/enumerations/cart-item-unavailability-reason.model';

export interface ICartItem {
  id?: number;
  sellingPrice?: number;
  sellingPriceCurrencyCode?: CurrencyCode;
  description?: string | null;
  metaData?: string | null;
  available?: boolean | null;
  platform?: string | null;
  platformVersion?: string | null;
  utm?: string | null;
  unavailabilityReason?: CartItemUnavailabilityReason | null;
  createdAt?: string;
  updatedAt?: string;
  availabilityUpdatedAt?: string | null;
  skuAttributes?: ICartItemSkuAttribute[] | null;
  course?: ICourse | null;
  sku?: ISku | null;
  cart?: ICart | null;
}

export const defaultValue: Readonly<ICartItem> = {
  available: false,
};
