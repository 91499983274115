import dayjs from 'dayjs';
import { ISkuAttribute } from 'app/shared/model/sku-attribute.model';
import { ISkuAttributeVariant } from 'app/shared/model/sku-attribute-variant.model';
import { IOrderItem } from 'app/shared/model/order-item.model';

export interface IOrderItemSkuAttribute {
  id?: number;
  value?: string;
  metadata?: string | null;
  createdAt?: string;
  updatedAt?: string;
  skuAttribute?: ISkuAttribute | null;
  skuAttributeVariant?: ISkuAttributeVariant | null;
  orderItem?: IOrderItem | null;
}

export const defaultValue: Readonly<IOrderItemSkuAttribute> = {};
