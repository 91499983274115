export enum RuleLedgerAmountStatus {
  PENDING = 'PENDING',

  INITIATED = 'INITIATED',

  SETTLED = 'SETTLED',

  FAILED = 'FAILED',

  CANCELLED = 'CANCELLED',
}
