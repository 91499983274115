import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import SectionElement from './section-element';

interface SectionElementInterface {
  title: string;
  route: string;
  icon: IconDefinition;
}

interface SectionProps {
  title: string;
  elements: Array<SectionElementInterface>;
}

function Section(props: SectionProps) {
  return (
    <div>
      <div>
        <h3>{props.title}</h3>
        <div className="container">
          <div className="row">
            {props.elements &&
              props.elements.map((element, index) => {
                return <SectionElement key={index} title={element.title} icon={element.icon} route={element.route} />;
              })}
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default Section;
