export enum PaymentGateway {
  MANUAL = 'MANUAL',

  RAZORPAY = 'RAZORPAY',

  RAZORPAY_WEB = 'RAZORPAY_WEB',

  GOOGLE_BILLING = 'GOOGLE_BILLING',

  CASHFREE = 'CASHFREE',

  CASHFREE_WEB = 'CASHFREE_WEB',

  PREPAID = 'PREPAID',

  VIRTUAL_MONEY = 'VIRTUAL_MONEY',

  IN_APP_PURCHASE = 'IN_APP_PURCHASE',
}
