import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ResUserCourseSectionLesson from './res-user-course-section-lesson';
import ResUserCourseSectionLessonDetail from './res-user-course-section-lesson-detail';
import ResUserCourseSectionLessonUpdate from './res-user-course-section-lesson-update';
import ResUserCourseSectionLessonDeleteDialog from './res-user-course-section-lesson-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ResUserCourseSectionLessonUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ResUserCourseSectionLessonUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ResUserCourseSectionLessonDetail} />
      <ErrorBoundaryRoute path={match.url} component={ResUserCourseSectionLesson} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ResUserCourseSectionLessonDeleteDialog} />
  </>
);

export default Routes;
