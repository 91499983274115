import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { IAddress } from 'app/shared/model/address.model';
import { getEntities as getAddresses } from 'app/entities/address/address.reducer';
import { getEntity, updateEntity, createEntity, reset } from './cart.reducer';
import { ICart } from 'app/shared/model/cart.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CartStatus } from 'app/shared/model/enumerations/cart-status.model';
import { CartType } from 'app/shared/model/enumerations/cart-type.model';

export const CartUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const users = useAppSelector(state => state.userManagement.users);
  const addresses = useAppSelector(state => state.address.entities);
  const cartEntity = useAppSelector(state => state.cart.entity);
  const loading = useAppSelector(state => state.cart.loading);
  const updating = useAppSelector(state => state.cart.updating);
  const updateSuccess = useAppSelector(state => state.cart.updateSuccess);
  const cartStatusValues = Object.keys(CartStatus);
  const cartTypeValues = Object.keys(CartType);

  const handleClose = () => {
    if (cartEntity && cartEntity.id) {
      props.history.push('/cart/' + `${cartEntity.id}`);
    } else {
      props.history.push('/cart' + props.location.search);
    }
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getUsers({}));
    dispatch(getAddresses({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...cartEntity,
      ...values,
      user: { id: values.user },
      referrerUser: users.find(it => it.id.toString() === values.referrerUser.toString()),
      deliveryAddress: addresses.find(it => it.id.toString() === values.deliveryAddress.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          cartStatus: 'ACTIVE',
          type: 'EXPRESS',
          ...cartEntity,
          createdAt: convertDateTimeFromServer(cartEntity.createdAt),
          updatedAt: convertDateTimeFromServer(cartEntity.updatedAt),
          user: cartEntity?.user?.id,
          referrerUser: cartEntity?.referrerUser?.id,
          deliveryAddress: cartEntity?.deliveryAddress?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.cart.home.createOrEditLabel" data-cy="CartCreateUpdateHeading">
            <Translate contentKey="alippoApp.cart.home.createOrEditLabel">Create or edit a Cart</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="cart-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.cart.cartStatus')}
                id="cart-cartStatus"
                name="cartStatus"
                data-cy="cartStatus"
                type="select"
              >
                {cartStatusValues.map(cartStatus => (
                  <option value={cartStatus} key={cartStatus}>
                    {translate('alippoApp.CartStatus' + cartStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField label={translate('alippoApp.cart.type')} id="cart-type" name="type" data-cy="type" type="select">
                {cartTypeValues.map(cartType => (
                  <option value={cartType} key={cartType}>
                    {translate('alippoApp.CartType' + cartType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.cart.promotions')}
                id="cart-promotions"
                name="promotions"
                data-cy="promotions"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.cart.description')}
                id="cart-description"
                name="description"
                data-cy="description"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.cart.independent')}
                id="cart-independent"
                name="independent"
                data-cy="independent"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.cart.createdAt')}
                id="cart-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.cart.updatedAt')}
                id="cart-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="cart-user"
                name="user"
                data-cy="user"
                label={translate('alippoApp.cart.user')}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="cart-referrerUser"
                name="referrerUser"
                data-cy="referrerUser"
                label={translate('alippoApp.cart.referrerUser')}
                type="select"
              >
                <option value="" key="0" />
                {users
                  ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="cart-deliveryAddress"
                name="deliveryAddress"
                data-cy="deliveryAddress"
                label={translate('alippoApp.cart.deliveryAddress')}
                type="select"
              >
                <option value="" key="0" />
                {addresses
                  ? addresses.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/cart" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CartUpdate;
