import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './beneficiary-info.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const BeneficiaryInfoDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const beneficiaryInfoEntity = useAppSelector(state => state.beneficiaryInfo.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="beneficiaryInfoDetailsHeading">
          <Translate contentKey="alippoApp.beneficiaryInfo.detail.title">BeneficiaryInfo</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{beneficiaryInfoEntity.id}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="alippoApp.beneficiaryInfo.type">Type</Translate>
            </span>
          </dt>
          <dd>{beneficiaryInfoEntity.type}</dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="alippoApp.beneficiaryInfo.isActive">Is Active</Translate>
            </span>
          </dt>
          <dd>{beneficiaryInfoEntity.isActive ? 'true' : 'false'}</dd>
          <dt>
            <span id="priority">
              <Translate contentKey="alippoApp.beneficiaryInfo.priority">Priority</Translate>
            </span>
          </dt>
          <dd>{beneficiaryInfoEntity.priority}</dd>
          <dt>
            <span id="payload">
              <Translate contentKey="alippoApp.beneficiaryInfo.payload">Payload</Translate>
            </span>
          </dt>
          <dd>{beneficiaryInfoEntity.payload}</dd>
          <dt>
            <span id="version">
              <Translate contentKey="alippoApp.beneficiaryInfo.version">Version</Translate>
            </span>
          </dt>
          <dd>{beneficiaryInfoEntity.version}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.beneficiaryInfo.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {beneficiaryInfoEntity.createdAt ? (
              <TextFormat value={beneficiaryInfoEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.beneficiaryInfo.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {beneficiaryInfoEntity.updatedAt ? (
              <TextFormat value={beneficiaryInfoEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.beneficiaryInfo.user">User</Translate>
          </dt>
          <dd>{beneficiaryInfoEntity.user ? beneficiaryInfoEntity.user.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/beneficiary-info" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/beneficiary-info/${beneficiaryInfoEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default BeneficiaryInfoDetail;
