export enum CourseLevel {
  BEGINNER = 'BEGINNER',

  EXPERT = 'EXPERT',

  INTERMEDIATE = 'INTERMEDIATE',

  BEGINNER_EXPERT = 'BEGINNER_EXPERT',

  INTERMEDIATE_EXPERT = 'INTERMEDIATE_EXPERT',

  BEGINNER_INTERMEDIATE = 'BEGINNER_INTERMEDIATE',
}
