import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, Modal, ModalBody } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import ReactJson from 'react-json-view';

import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntity, updateEntity, createEntity, reset } from './user-extra.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IUserExtra } from 'app/shared/model/user-extra.model';
import { toast } from 'react-toastify';

export const UserExtraUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const [showPropertiesUploadModal, setShowPropertiesUploadModal] = useState(false);

  const userExtraEntity = useAppSelector(state => state.userExtra.entity);
  const loading = useAppSelector(state => state.userExtra.loading);
  const updating = useAppSelector(state => state.userExtra.updating);
  const updateSuccess = useAppSelector(state => state.userExtra.updateSuccess);

  const handleClose = () => {
    if (userExtraEntity && userExtraEntity.id) {
      props.history.push('/user-extra/' + `${userExtraEntity.id}`);
    } else {
      props.history.push('/user-extra' + props.location.search);
    }
  };

  const handleCloseModal = () => {
    if (showPropertiesUploadModal) {
      setShowPropertiesUploadModal(false);
    }
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const defaultValues = () =>
    isNew
      ? {
          otpTs: displayDefaultDateTime(),
          verifiedTs: displayDefaultDateTime(),
          certNameTs: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          ...userExtraEntity,
          otpTs: convertDateTimeFromServer(userExtraEntity.otpTs),
          verifiedTs: convertDateTimeFromServer(userExtraEntity.verifiedTs),
          certNameTs: convertDateTimeFromServer(userExtraEntity.certNameTs),
          createdAt: convertDateTimeFromServer(userExtraEntity.createdAt),
          updatedAt: convertDateTimeFromServer(userExtraEntity.updatedAt),
          user: userExtraEntity?.user?.id,
        };

  const [userExtrasData, setUserExtrasData] = useState<IUserExtra>(defaultValues());

  useEffect(() => {
    if (userExtraEntity) {
      setUserExtrasData(defaultValues());
    }
  }, [userExtraEntity]);

  const handleUserExtraPropertiesChange = ({ updated_src }) => {
    setUserExtrasData(prev => ({
      ...prev,
      properties: JSON.stringify(updated_src),
    }));
  };

  const saveEntity = values => {
    values.otpTs = convertDateTimeToServer(values.otpTs);
    values.verifiedTs = convertDateTimeToServer(values.verifiedTs);
    values.certNameTs = convertDateTimeToServer(values.certNameTs);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity: IUserExtra = {
      ...userExtraEntity,
      ...values,
      user: { id: values.user },
      properties: Object.keys(JSON.parse(userExtrasData.properties ?? '{}')).length === 0 ? null : userExtrasData.properties,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const handleSubmitProperties = values => {
    try {
      if (values.properties && values.properties !== '') {
        const validatedJson = JSON.parse(values.properties);
        setUserExtrasData(prev => ({
          ...prev,
          properties: JSON.stringify(validatedJson),
        }));
        toast.success('Properties uploaded successfully!');
      }
    } catch (err) {
      console.error(err);
      toast.error('Invalid JSON!');
    } finally {
      setShowPropertiesUploadModal(false);
    }
  };

  return (
    <>
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="alippoApp.userExtra.home.createOrEditLabel" data-cy="UserExtraCreateUpdateHeading">
              <Translate contentKey="alippoApp.userExtra.home.createOrEditLabel">Create or edit a UserExtra</Translate>
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <ValidatedForm defaultValues={userExtrasData} onSubmit={saveEntity}>
                {!isNew ? (
                  <ValidatedField
                    name="id"
                    required
                    readOnly
                    id="user-extra-id"
                    label={translate('global.field.id')}
                    validate={{ required: true }}
                  />
                ) : null}
                <ValidatedField label={translate('alippoApp.userExtra.otp')} id="user-extra-otp" name="otp" data-cy="otp" type="text" />
                <ValidatedField
                  label={translate('alippoApp.userExtra.otpTs')}
                  id="user-extra-otpTs"
                  name="otpTs"
                  data-cy="otpTs"
                  type="datetime-local"
                  placeholder="YYYY-MM-DD HH:mm"
                />
                <ValidatedField
                  label={translate('alippoApp.userExtra.verifiedTs')}
                  id="user-extra-verifiedTs"
                  name="verifiedTs"
                  data-cy="verifiedTs"
                  type="datetime-local"
                  placeholder="YYYY-MM-DD HH:mm"
                />
                <ValidatedField
                  label={translate('alippoApp.userExtra.certName')}
                  id="user-extra-certName"
                  name="certName"
                  data-cy="certName"
                  type="text"
                />
                <ValidatedField
                  label={translate('alippoApp.userExtra.certNameTs')}
                  id="user-extra-certNameTs"
                  name="certNameTs"
                  data-cy="certNameTs"
                  type="datetime-local"
                  placeholder="YYYY-MM-DD HH:mm"
                />
                <ValidatedField
                  label={translate('alippoApp.userExtra.imageUrl')}
                  id="user-extra-imageUrl"
                  name="imageUrl"
                  data-cy="imageUrl"
                  type="text"
                />
                <ValidatedField
                  label={translate('alippoApp.userExtra.ipInfo')}
                  id="user-extra-ipInfo"
                  name="ipInfo"
                  data-cy="ipInfo"
                  type="textarea"
                />
                <ValidatedField
                  label={translate('alippoApp.userExtra.referrerCode')}
                  id="user-extra-referrerCode"
                  name="referrerCode"
                  data-cy="referrerCode"
                  type="text"
                />
                <Label>{translate('alippoApp.userExtra.properties')}</Label>
                <Button className="d-block mb-2" color="info" size="sm" onClick={() => setShowPropertiesUploadModal(true)}>
                  <FontAwesomeIcon icon={faUpload} /> Upload
                </Button>
                <div style={{ marginBottom: '1rem' }}>
                  <ReactJson
                    src={JSON.parse(userExtrasData.properties ?? '{}')}
                    onEdit={handleUserExtraPropertiesChange}
                    onAdd={handleUserExtraPropertiesChange}
                    onDelete={handleUserExtraPropertiesChange}
                    displayObjectSize={false}
                    displayDataTypes={false}
                    collapsed
                  />
                </div>
                <ValidatedField
                  label={translate('alippoApp.userExtra.description')}
                  id="user-extra-description"
                  name="description"
                  data-cy="description"
                  type="textarea"
                />
              <ValidatedField
                label={translate('alippoApp.userExtra.interest')}
                id="user-extra-interest"
                name="interest"
                data-cy="interest"
                type="textarea"
              />
                <ValidatedField
                label={translate('alippoApp.userExtra.phone')}
                id="user-extra-phone"
                name="phone"
                data-cy="phone"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.userExtra.firstAcquisitionInfo')}
                id="user-extra-firstAcquisitionInfo"
                name="firstAcquisitionInfo"
                data-cy="firstAcquisitionInfo"
                type="textarea"
              />
              <ValidatedField
                  label={translate('alippoApp.userExtra.createdAt')}
                  id="user-extra-createdAt"
                  name="createdAt"
                  data-cy="createdAt"
                  type="datetime-local"
                  placeholder="YYYY-MM-DD HH:mm"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
                <ValidatedField
                  label={translate('alippoApp.userExtra.updatedAt')}
                  id="user-extra-updatedAt"
                  name="updatedAt"
                  data-cy="updatedAt"
                  type="datetime-local"
                  placeholder="YYYY-MM-DD HH:mm"
                  validate={{
                    required: { value: true, message: translate('entity.validation.required') },
                  }}
                />
                <ValidatedField id="user-extra-user" name="user" data-cy="user" label={translate('alippoApp.userExtra.user')} />
                <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/user-extra" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </ValidatedForm>
            )}
          </Col>
        </Row>
      </div>

      {/* Upload user extra properties modal */}
      <Modal isOpen={showPropertiesUploadModal} toggle={handleCloseModal}>
        <ModalBody>
          <ValidatedForm onSubmit={handleSubmitProperties}>
            <ValidatedField
              label={translate('alippoApp.userExtra.properties')}
              id="user-extra-properties"
              name="properties"
              data-cy="properties"
              type="textarea"
              placeholder="Please add properties JSON..."
              style={{ minHeight: '15rem' }}
            />
            <div className="d-flex w-100 justify-content-end">
              <Button className="mr-2" color="secondary" onClick={handleCloseModal}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <Button color="success" type="submit">
                Submit
              </Button>
            </div>
          </ValidatedForm>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UserExtraUpdate;
