import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, isNumber, translate } from 'react-jhipster';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getCourseDiscussions } from 'app/entities/course-discussion/course-discussion.reducer';
import { getEntities as getSections } from 'app/entities/section/section.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { createEntity, getEntity, reset, updateEntity } from './course-section-lesson.reducer';
import JsonEntryEditor from 'app/components/json-entry-editor';
import { nullifyEmptyString } from 'app/shared/util/utils';

export const CourseSectionLessonUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locationState: any = location.state;

  let inheritedCourseSection = null;
  if (locationState) {
    inheritedCourseSection = locationState.courseSection;
  }

  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const courseSectionLessonEntity = useAppSelector(state => state.courseSectionLesson.entity);
  const loading = useAppSelector(state => state.courseSectionLesson.loading);
  const updating = useAppSelector(state => state.courseSectionLesson.updating);
  const updateSuccess = useAppSelector(state => state.courseSectionLesson.updateSuccess);

  const handleClose = () => {
    if (courseSectionLessonEntity && courseSectionLessonEntity.id) {
      props.history.push('/course-section/' + `${courseSectionLessonEntity.courseSection.id}`);
    } else {
      props.history.push('/course-section-lesson' + props.location.search);
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          fromDateTime: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
          courseSection: inheritedCourseSection ? inheritedCourseSection.id : null,
          section: inheritedCourseSection ? inheritedCourseSection.section.id : null,
          sortOrder: inheritedCourseSection ? inheritedCourseSection.sortOrder : null,
        }
      : {
          ...courseSectionLessonEntity,
          fromDateTime: convertDateTimeFromServer(courseSectionLessonEntity.fromDateTime),
          createdAt: convertDateTimeFromServer(courseSectionLessonEntity.createdAt),
          updatedAt: convertDateTimeFromServer(courseSectionLessonEntity.updatedAt),
          lesson: courseSectionLessonEntity?.lesson?.id,
          section: courseSectionLessonEntity?.section?.id,
          associatedCourseSectionLesson: courseSectionLessonEntity?.associatedCourseSectionLesson?.id,
          discussion: courseSectionLessonEntity?.discussion?.id,
          courseSection: courseSectionLessonEntity?.courseSection?.id,
        };

  const [courseSectionLessonData, setCourseSectionLessonData] = useState<any>(defaultValues());

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
    dispatch(getSections({}));
    dispatch(getCourseDiscussions({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.fromDateTime = convertDateTimeToServer(values.fromDateTime);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);
    const entity = {
      ...courseSectionLessonEntity,
      ...values,
      lesson: { id: values.lesson },
      section: { id: values.section },
      courseSection: { id: values.courseSection },
      associatedCourseSectionLesson: { id: values.associatedCourseSectionLesson },
      discussion: { id: values.discussion.toString() },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  useEffect(() => {
    if (courseSectionLessonEntity) {
      setCourseSectionLessonData(defaultValues());
    }
  }, [courseSectionLessonEntity]);

  const handleGetUpdatedEntity = newValues => {
    setCourseSectionLessonData(prev => ({
      ...prev,
      ...newValues,
    }));
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.courseSectionLesson.home.createOrEditLabel" data-cy="CourseSectionLessonCreateUpdateHeading">
            <Translate contentKey="alippoApp.courseSectionLesson.home.createOrEditLabel">Create or edit a CourseSectionLesson</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={courseSectionLessonData} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="course-section-lesson-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.courseSectionLesson.fromDateTime')}
                id="course-section-lesson-fromDateTime"
                name="fromDateTime"
                data-cy="fromDateTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('alippoApp.courseSectionLesson.duration')}
                id="course-section-lesson-duration"
                name="duration"
                data-cy="duration"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.courseSectionLesson.sortOrder')}
                id="course-section-lesson-sortOrder"
                name="sortOrder"
                data-cy="sortOrder"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alippoApp.courseSectionLesson.isPreRecorded')}
                id="course-section-lesson-isPreRecorded"
                name="isPreRecorded"
                data-cy="isPreRecorded"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.courseSectionLesson.createdAt')}
                id="course-section-lesson-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.courseSectionLesson.updatedAt')}
                id="course-section-lesson-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <JsonEntryEditor
                entityShape={courseSectionLessonData}
                entityKey="configPayload"
                value={JSON.parse(nullifyEmptyString(courseSectionLessonData?.configPayload) ?? '{}')}
                label={translate('alippoApp.course.configPayload')}
                id="course-configPayload"
                cyDataKey="configPayload"
                handleGetUpdatedEntity={handleGetUpdatedEntity}
              />
              <ValidatedField
                id="course-section-lesson-lesson"
                name="lesson"
                data-cy="lesson"
                label={translate('alippoApp.courseSectionLesson.lesson')}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="course-section-lesson-section"
                name="section"
                data-cy="section"
                label={translate('alippoApp.courseSectionLesson.section')}
              />
              <ValidatedField
                id="course-section-lesson-associatedCourseSectionLesson"
                name="associatedCourseSectionLesson"
                data-cy="associatedCourseSectionLesson"
                label={translate('alippoApp.courseSectionLesson.associatedCourseSectionLesson')}
              />
              <ValidatedField
                id="course-section-lesson-discussion"
                name="discussion"
                data-cy="discussion"
                label={translate('alippoApp.courseSectionLesson.discussion')}
              />
              <ValidatedField
                id="course-section-lesson-courseSection"
                name="courseSection"
                data-cy="courseSection"
                label={translate('alippoApp.courseSectionLesson.courseSection')}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/course-section-lesson" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CourseSectionLessonUpdate;
