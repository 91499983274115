import dayjs from 'dayjs';
import { ICartItem } from 'app/shared/model/cart-item.model';
import { IUser } from 'app/shared/model/user.model';
import { IAddress } from 'app/shared/model/address.model';
import { CartStatus } from 'app/shared/model/enumerations/cart-status.model';
import { CartType } from 'app/shared/model/enumerations/cart-type.model';

export interface ICart {
  id?: number;
  cartStatus?: CartStatus | null;
  type?: CartType;
  promotions?: string | null;
  description?: string | null;
  independent?: boolean | null;
  createdAt?: string;
  updatedAt?: string;
  cartItems?: ICartItem[] | null;
  user?: IUser | null;
  referrerUser?: IUser | null;
  deliveryAddress?: IAddress | null;
}

export const defaultValue: Readonly<ICart> = {
  independent: false,
};
