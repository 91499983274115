import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './sku-attribute.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ISkuAttributeVariant } from 'app/shared/model/sku-attribute-variant.model';
import axios from 'axios';
import { toast } from 'react-toastify';

export const SkuAttributeDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const [skuAttributesVariantsData, setSkuAttributesVariantsData] = useState<(ISkuAttributeVariant & { url: string })[]>(null);

  const getSkuAttributeVariants = async () => {
    if (skuAttributeEntity.id) {
      const apiUrl = `api/sku-attribute-variants?skuAttributeId.equals=${skuAttributeEntity.id}&page=0&size=40&sort=id,asc`;
      const data: any = await axios.get(apiUrl);
      const variants = data.data;
      if (variants.length === 0) {
        toast.error('No variants available');
      }
      setSkuAttributesVariantsData(
        variants.map(e => {
          return {
            ...e,
            url: `/sku-attribute-variant/${e.id}`,
          };
        })
      );
    }
  };

  const skuAttributeEntity = useAppSelector(state => state.skuAttribute.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="skuAttributeDetailsHeading">
          <Translate contentKey="alippoApp.skuAttribute.detail.title">SkuAttribute</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{skuAttributeEntity.id}</dd>
          <dt>
            <span id="active">
              <Translate contentKey="alippoApp.skuAttribute.active">Active</Translate>
            </span>
          </dt>
          <dd>{skuAttributeEntity.active ? 'true' : 'false'}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.skuAttribute.name">Name</Translate>
            </span>
          </dt>
          <dd>{skuAttributeEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.skuAttribute.description">Description</Translate>
            </span>
          </dt>
          <dd>{skuAttributeEntity.description}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="alippoApp.skuAttribute.type">Type</Translate>
            </span>
          </dt>
          <dd>{skuAttributeEntity.type}</dd>
          <dt>
            <span id="inputSelectionType">
              <Translate contentKey="alippoApp.skuAttribute.inputSelectionType">Input Selection Type</Translate>
            </span>
          </dt>
          <dd>{skuAttributeEntity.inputSelectionType}</dd>
          <dt>
            <span id="unitType">
              <Translate contentKey="alippoApp.skuAttribute.unitType">Unit Type</Translate>
            </span>
          </dt>
          <dd>{skuAttributeEntity.unitType}</dd>
          <dt>
            <span id="unitValue">
              <Translate contentKey="alippoApp.skuAttribute.unitValue">Unit Value</Translate>
            </span>
          </dt>
          <dd>{skuAttributeEntity.unitValue}</dd>
          <dt>
            <span id="config">
              <Translate contentKey="alippoApp.skuAttribute.config">Config</Translate>
            </span>
          </dt>
          <dd>{skuAttributeEntity.config}</dd>
          <dt>
            <span id="sortOrder">
              <Translate contentKey="alippoApp.skuAttribute.sortOrder">Sort Order</Translate>
            </span>
          </dt>
          <dd>{skuAttributeEntity.sortOrder}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.skuAttribute.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {skuAttributeEntity.createdAt ? <TextFormat value={skuAttributeEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.skuAttribute.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {skuAttributeEntity.updatedAt ? <TextFormat value={skuAttributeEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.skuAttribute.sku">Sku</Translate>
          </dt>
          <dd>{skuAttributeEntity.sku ? skuAttributeEntity.sku.id : ''}</dd>
        </dl>
        <dl>
          <dt>Sku Attribute Variants</dt>
          <Button onClick={getSkuAttributeVariants} className="mr-2" color="primary">
            <span>Get Sku Attribute Variants</span>
          </Button>
          <Link to={{ pathname: '/sku-attribute-variant/new', state: { skuAttribute: skuAttributeEntity } }}>
            <Button className="rounded-circle" color="success">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Link>
          <dd>
            {skuAttributesVariantsData && skuAttributesVariantsData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">
                      <Translate contentKey="alippoApp.skuAttributeVariant.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.skuAttributeVariant.value">Value</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.skuAttributeVariant.valueLabel">Value Label</Translate>{' '}
                      <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.skuAttributeVariant.isDefault">Is Default</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {skuAttributesVariantsData.map((resource: ISkuAttributeVariant & { url: string }, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{resource.id}</td>
                      <td>{resource.value}</td>
                      <td>{resource.valueLabel}</td>
                      <td>{resource.isDefault}</td>
                      <td>
                        <Link target="_blank" rel="noopener noreferrer" to={resource.url}>
                          Link
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/sku-attribute" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/sku-attribute/${skuAttributeEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SkuAttributeDetail;
