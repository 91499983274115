import dayjs from 'dayjs';
import { ILesson } from 'app/shared/model/lesson.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface ILessonTranslation {
  id?: number;
  name?: string;
  description?: string | null;
  language?: Language;
  isDefault?: boolean;
  createdAt?: string;
  updatedAt?: string;
  lesson?: ILesson | null;
}

export const defaultValue: Readonly<ILessonTranslation> = {
  isDefault: false,
};
