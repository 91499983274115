import dayjs from 'dayjs';

export interface IForm {
  id?: number;
  schema?: string;
  createdAt?: string;
  updatedAt?: string;
}

export const defaultValue: Readonly<IForm> = {};
