import dayjs from 'dayjs';
import { IOrderItem } from 'app/shared/model/order-item.model';
import { IPlanItem } from 'app/shared/model/plan-item.model';
import { OrderItemPlanAction } from 'app/shared/model/enumerations/order-item-plan-action.model';
import { Frequency } from 'app/shared/model/enumerations/frequency.model';

export interface IOrderItemPlan {
  id?: number;
  action?: OrderItemPlanAction;
  fromDateTime?: string | null;
  duration?: number | null;
  quantity?: number | null;
  frequency?: Frequency | null;
  prefDoorBell?: boolean | null;
  deliverySlotFromTime?: number | null;
  deliverySlotDuration?: number | null;
  createdAt?: string;
  updatedAt?: string;
  orderItem?: IOrderItem;
  planItem?: IPlanItem | null;
}

export const defaultValue: Readonly<IOrderItemPlan> = {
  prefDoorBell: false,
};
