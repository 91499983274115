import dayjs from 'dayjs';
import { ISku } from 'app/shared/model/sku.model';
import { ICourseSection } from 'app/shared/model/course-section.model';
import { ICourseDiscussion } from 'app/shared/model/course-discussion.model';
import { IResCourse } from 'app/shared/model/res-course.model';
import { IForm } from 'app/shared/model/form.model';
import { ICategory } from 'app/shared/model/category.model';
import { ICourseTranslation } from 'app/shared/model/course-translation.model';
import { CurrencyCode } from 'app/shared/model/enumerations/currency-code.model';
import { CourseStatus } from 'app/shared/model/enumerations/course-status.model';
import { CourseLevel } from 'app/shared/model/enumerations/course-level.model';

export interface ICourse {
  id?: number;
  name?: string;
  description?: string | null;
  sellingPrice?: number;
  sellingPriceCurrencyCode?: CurrencyCode;
  mrpPrice?: number;
  mrpPriceCurrencyCode?: CurrencyCode;
  fromDateTime?: string | null;
  duration?: number | null;
  courseStatus?: CourseStatus;
  courseLevel?: CourseLevel | null;
  sortOrder?: number;
  createdAt?: string;
  updatedAt?: string;
  configPayload?: string | null;
  seoConfigPayload?: string | null;
  batchIndex?: number | null;
  sku?: ISku | null;
  courseSections?: ICourseSection[] | null;
  courseDiscussions?: ICourseDiscussion[] | null;
  resources?: IResCourse[] | null;
  applicationForm?: IForm | null;
  parentCourse?: ICourse | null;
  categories?: ICategory[] | null;
  discussions?: ICourseDiscussion[] | null;
  translations?: ICourseTranslation[] | null;
}

export const defaultValue: Readonly<ICourse> = {};
