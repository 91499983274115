import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './res-product.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ResProductDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const resProductEntity = useAppSelector(state => state.resProduct.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="resProductDetailsHeading">
          <Translate contentKey="alippoApp.resProduct.detail.title">ResProduct</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{resProductEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.resProduct.name">Name</Translate>
            </span>
          </dt>
          <dd>{resProductEntity.name}</dd>
          <dt>
            <span id="url">
              <Translate contentKey="alippoApp.resProduct.url">Url</Translate>
            </span>
          </dt>
          <dd>{resProductEntity.url}</dd>
          <dt>
            <span id="resState">
              <Translate contentKey="alippoApp.resProduct.resState">Res State</Translate>
            </span>
          </dt>
          <dd>{resProductEntity.resState}</dd>
          <dt>
            <span id="resType">
              <Translate contentKey="alippoApp.resProduct.resType">Res Type</Translate>
            </span>
          </dt>
          <dd>{resProductEntity.resType}</dd>
          <dt>
            <span id="mediaType">
              <Translate contentKey="alippoApp.resProduct.mediaType">Media Type</Translate>
            </span>
          </dt>
          <dd>{resProductEntity.mediaType}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.resProduct.description">Description</Translate>
            </span>
          </dt>
          <dd>{resProductEntity.description}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="alippoApp.resProduct.language">Language</Translate>
            </span>
          </dt>
          <dd>{resProductEntity.language}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.resProduct.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {resProductEntity.createdAt ? <TextFormat value={resProductEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.resProduct.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {resProductEntity.updatedAt ? <TextFormat value={resProductEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.resProduct.product">Product</Translate>
          </dt>
          <dd>{resProductEntity.product ? resProductEntity.product.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/res-product" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/res-product/${resProductEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ResProductDetail;
