import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getCategories } from 'app/entities/category/category.reducer';
import { getEntities as getCourses } from 'app/entities/course/course.reducer';
import { DiscussionType } from 'app/shared/model/enumerations/discussion-type.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { createEntity, getEntity, reset, updateEntity } from './course-discussion.reducer';
import { DiscussionSubType } from 'app/shared/model/enumerations/discussion-sub-type.model';
import { handleCopyToClipboard } from 'app/shared/util/entity-utils';
import JsonEntryEditor from 'app/components/json-entry-editor';
import { nullifyEmptyString } from 'app/shared/util/utils';

export const CourseDiscussionUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locationState: any = location.state;

  let inheritedCourse = null;
  if (locationState) {
    inheritedCourse = locationState.course;
  }
  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const courseDiscussionEntity = useAppSelector(state => state.courseDiscussion.entity);
  const loading = useAppSelector(state => state.courseDiscussion.loading);
  const updating = useAppSelector(state => state.courseDiscussion.updating);
  const updateSuccess = useAppSelector(state => state.courseDiscussion.updateSuccess);
  const discussionTypeValues = Object.keys(DiscussionType);
  const discussionSubTypeValues = Object.keys(DiscussionSubType);
  const handleClose = () => {
    if (courseDiscussionEntity && courseDiscussionEntity.id && courseDiscussionEntity.course) {
      handleCopyToClipboard(courseDiscussionEntity.id.toString());
      dispatch(reset());
      props.history.push('/course/' + `${courseDiscussionEntity.course.id}`);
    } else {
      props.history.push('/course-discussion' + props.location.search);
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
          joined: 0,
          course: inheritedCourse ? inheritedCourse.id : null,
        }
      : {
          discussionType: 'WHATSAPP',
          discussionSubType: 'DIG_COF_SMM',
          ...courseDiscussionEntity,
          createdAt: convertDateTimeFromServer(courseDiscussionEntity.createdAt),
          updatedAt: convertDateTimeFromServer(courseDiscussionEntity.updatedAt),
          course: courseDiscussionEntity?.course?.id,
          category: courseDiscussionEntity?.category?.id,
        };

  const [courseDiscussionData, setCourseDiscussionData] = useState<any>(defaultValues());

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getCourses({}));
    dispatch(getCategories({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (courseDiscussionEntity) {
      setCourseDiscussionData(defaultValues());
    }
  }, [courseDiscussionEntity]);
  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...courseDiscussionEntity,
      ...values,
      course: values.course ? { id: values.course } : null,
      category: values.category ? { id: values.category } : null,
      adminList: values.adminList ? values.adminList.split(',').map(userId => Number(userId.trim())) : null,
      discussionSubType: values.discussionSubType ? values.discussionSubType : null,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };
  const handleGetUpdatedEntity = newValues => {
    setCourseDiscussionData(prev => ({
      ...prev,
      ...newValues,
    }));
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.courseDiscussion.home.createOrEditLabel" data-cy="CourseDiscussionCreateUpdateHeading">
            <Translate contentKey="alippoApp.courseDiscussion.home.createOrEditLabel">Create or edit a CourseDiscussion</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={courseDiscussionData} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="course-discussion-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.courseDiscussion.discussionLink')}
                id="course-discussion-discussionLink"
                name="discussionLink"
                data-cy="discussionLink"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.courseDiscussion.discussionWebLink')}
                id="course-discussion-discussionWebLink"
                name="discussionWebLink"
                data-cy="discussionWebLink"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.courseDiscussion.joined')}
                id="course-discussion-joined"
                name="joined"
                data-cy="joined"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.courseDiscussion.disabled')}
                id="course-discussion-disabled"
                name="disabled"
                data-cy="disabled"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.courseDiscussion.discussionType')}
                id="course-discussion-discussionType"
                name="discussionType"
                data-cy="discussionType"
                type="select"
              >
                {discussionTypeValues.map(discussionType => (
                  <option value={discussionType} key={discussionType}>
                    {translate('alippoApp.DiscussionType' + discussionType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.courseDiscussion.createdAt')}
                id="course-discussion-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.courseDiscussion.updatedAt')}
                id="course-discussion-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.courseDiscussion.configPayload')}
                id="course-discussion-configPayload"
                name="configPayload"
                data-cy="configPayload"
                type="textarea"
              />
              <JsonEntryEditor
                entityShape={courseDiscussionData}
                entityKey="configPayload"
                value={JSON.parse(nullifyEmptyString(courseDiscussionData?.configPayload) ?? '{}')}
                label="Config Payload"
                id="course-discussion-configPayload"
                cyDataKey="configPayload"
                handleGetUpdatedEntity={handleGetUpdatedEntity}
              />
              <ValidatedField
                label={translate('alippoApp.courseDiscussion.discussionSubType')}
                id="course-discussion-discussionSubType"
                name="discussionSubType"
                data-cy="discussionSubType"
                type="select"
                defaultValue={null}
              >
                {discussionSubTypeValues.map(discussionSubType => (
                  <option value={discussionSubType} key={discussionSubType}>
                    {translate('alippoApp.DiscussionSubType' + discussionSubType)}
                  </option>
                ))}
                <option value={null}></option>
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.courseDiscussion.vacant')}
                id="course-discussion-vacant"
                name="vacant"
                data-cy="vacant"
                check
                type="checkbox"
              />
              <ValidatedField
                id="course-discussion-course"
                name="course"
                data-cy="course"
                label={translate('alippoApp.courseDiscussion.course')}
              />
              <ValidatedField
                id="course-discussion-category"
                name="category"
                data-cy="category"
                label={translate('alippoApp.courseDiscussion.category')}
              />
              <ValidatedField id="course-discussion-admin-list" name="adminList" data-cy="adminList" label="Admin User Ids" />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/course-discussion" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CourseDiscussionUpdate;
