import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ITenant } from 'app/shared/model/tenant.model';
import { getEntities as getTenants } from 'app/entities/tenant/tenant.reducer';
import { getEntity, updateEntity, createEntity, reset } from './tenant-extra.reducer';
import { ITenantExtra } from 'app/shared/model/tenant-extra.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Language } from 'app/shared/model/enumerations/language.model';

export const TenantExtraUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const tenants = useAppSelector(state => state.tenant.entities);
  const tenantExtraEntity = useAppSelector(state => state.tenantExtra.entity);
  const loading = useAppSelector(state => state.tenantExtra.loading);
  const updating = useAppSelector(state => state.tenantExtra.updating);
  const updateSuccess = useAppSelector(state => state.tenantExtra.updateSuccess);
  const languageValues = Object.keys(Language);
  const handleClose = () => {
    props.history.push('/tenant-extra' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getTenants({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...tenantExtraEntity,
      ...values,
      tenant: { id: values.tenant },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          language: 'HINGLISH',
          ...tenantExtraEntity,
          createdAt: convertDateTimeFromServer(tenantExtraEntity.createdAt),
          updatedAt: convertDateTimeFromServer(tenantExtraEntity.updatedAt),
          tenant: tenantExtraEntity?.tenant?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.tenantExtra.home.createOrEditLabel" data-cy="TenantExtraCreateUpdateHeading">
            <Translate contentKey="alippoApp.tenantExtra.home.createOrEditLabel">Create or edit a TenantExtra</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="tenant-extra-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.tenantExtra.phoneContact')}
                id="tenant-extra-phoneContact"
                name="phoneContact"
                data-cy="phoneContact"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.tenantExtra.displayPhoneContact')}
                id="tenant-extra-displayPhoneContact"
                name="displayPhoneContact"
                data-cy="displayPhoneContact"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.tenantExtra.chatContact')}
                id="tenant-extra-chatContact"
                name="chatContact"
                data-cy="chatContact"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.tenantExtra.displayChatContact')}
                id="tenant-extra-displayChatContact"
                name="displayChatContact"
                data-cy="displayChatContact"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.tenantExtra.androidAppId')}
                id="tenant-extra-androidAppId"
                name="androidAppId"
                data-cy="androidAppId"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.tenantExtra.baseWebUrl')}
                id="tenant-extra-baseWebUrl"
                name="baseWebUrl"
                data-cy="baseWebUrl"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.tenantExtra.refundUrl')}
                id="tenant-extra-refundUrl"
                name="refundUrl"
                data-cy="refundUrl"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.tenantExtra.privacyUrl')}
                id="tenant-extra-privacyUrl"
                name="privacyUrl"
                data-cy="privacyUrl"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.tenantExtra.language')}
                id="tenant-extra-language"
                name="language"
                data-cy="language"
                type="select"
              >
                {languageValues.map(language => (
                  <option value={language} key={language}>
                    {translate('alippoApp.Language' + language)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.tenantExtra.isDefault')}
                id="tenant-extra-isDefault"
                name="isDefault"
                data-cy="isDefault"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.tenantExtra.createdAt')}
                id="tenant-extra-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.tenantExtra.updatedAt')}
                id="tenant-extra-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.tenantExtra.faqs')}
                id="tenant-extra-faqs"
                name="faqs"
                data-cy="faqs"
                type="textarea"
              />
              <ValidatedField id="tenant-extra-tenant" name="tenant" data-cy="tenant" label={translate('alippoApp.tenantExtra.tenant')} />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/tenant-extra" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TenantExtraUpdate;
