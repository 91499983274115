import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { ResState } from 'app/shared/model/enumerations/res-state.model';
import { ResType } from 'app/shared/model/enumerations/res-type.model';
import { ResSubType } from 'app/shared/model/enumerations/res-sub-type.model';
import { ResMediaType } from 'app/shared/model/enumerations/res-media-type.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface IResUser {
  id?: number;
  name?: string;
  url?: string | null;
  resState?: ResState | null;
  resType?: ResType | null;
  resSubType?: ResSubType | null;
  mediaType?: ResMediaType | null;
  description?: string | null;
  language?: Language | null;
  videoControlsConfig?: string | null;
  aspectRatio?: number | null;
  createdAt?: string;
  updatedAt?: string;
  user?: IUser | null;
}

export const defaultValue: Readonly<IResUser> = {};
