export enum SkuAttributeType {
  DENOMINATION = 'DENOMINATION',

  COLOR = 'COLOR',

  SIZE = 'SIZE',

  LANGUAGE = 'LANGUAGE',

  HEIGHT = 'HEIGHT',

  WIDTH = 'WIDTH',

  VOLUME = 'VOLUME',

  DISCRETE_QUANTITY = 'DISCRETE_QUANTITY',
}
