import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IDevice {
  id?: number;
  deviceId?: string;
  fcmToken?: string | null;
  fcmTokenRefTs?: string | null;
  deviceInfo?: string | null;
  description?: string | null;
  isFcmTokenActive?: boolean | null;
  createdAt?: string;
  updatedAt?: string;
  user?: IUser | null;
}

export const defaultValue: Readonly<IDevice> = {
  isFcmTokenActive: false,
};
