import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './tenant-extra.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const TenantExtraDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const tenantExtraEntity = useAppSelector(state => state.tenantExtra.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="tenantExtraDetailsHeading">
          <Translate contentKey="alippoApp.tenantExtra.detail.title">TenantExtra</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{tenantExtraEntity.id}</dd>
          <dt>
            <span id="phoneContact">
              <Translate contentKey="alippoApp.tenantExtra.phoneContact">Phone Contact</Translate>
            </span>
          </dt>
          <dd>{tenantExtraEntity.phoneContact}</dd>
          <dt>
            <span id="displayPhoneContact">
              <Translate contentKey="alippoApp.tenantExtra.displayPhoneContact">Display Phone Contact</Translate>
            </span>
          </dt>
          <dd>{tenantExtraEntity.displayPhoneContact}</dd>
          <dt>
            <span id="chatContact">
              <Translate contentKey="alippoApp.tenantExtra.chatContact">Chat Contact</Translate>
            </span>
          </dt>
          <dd>{tenantExtraEntity.chatContact}</dd>
          <dt>
            <span id="displayChatContact">
              <Translate contentKey="alippoApp.tenantExtra.displayChatContact">Display Chat Contact</Translate>
            </span>
          </dt>
          <dd>{tenantExtraEntity.displayChatContact}</dd>
          <dt>
            <span id="androidAppId">
              <Translate contentKey="alippoApp.tenantExtra.androidAppId">Android App Id</Translate>
            </span>
          </dt>
          <dd>{tenantExtraEntity.androidAppId}</dd>
          <dt>
            <span id="baseWebUrl">
              <Translate contentKey="alippoApp.tenantExtra.baseWebUrl">Base Web Url</Translate>
            </span>
          </dt>
          <dd>{tenantExtraEntity.baseWebUrl}</dd>
          <dt>
            <span id="refundUrl">
              <Translate contentKey="alippoApp.tenantExtra.refundUrl">Refund Url</Translate>
            </span>
          </dt>
          <dd>{tenantExtraEntity.refundUrl}</dd>
          <dt>
            <span id="privacyUrl">
              <Translate contentKey="alippoApp.tenantExtra.privacyUrl">Privacy Url</Translate>
            </span>
          </dt>
          <dd>{tenantExtraEntity.privacyUrl}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="alippoApp.tenantExtra.language">Language</Translate>
            </span>
          </dt>
          <dd>{tenantExtraEntity.language}</dd>
          <dt>
            <span id="isDefault">
              <Translate contentKey="alippoApp.tenantExtra.isDefault">Is Default</Translate>
            </span>
          </dt>
          <dd>{tenantExtraEntity.isDefault ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.tenantExtra.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {tenantExtraEntity.createdAt ? <TextFormat value={tenantExtraEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.tenantExtra.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {tenantExtraEntity.updatedAt ? <TextFormat value={tenantExtraEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="faqs">
              <Translate contentKey="alippoApp.tenantExtra.faqs">Faqs</Translate>
            </span>
          </dt>
          <dd>{tenantExtraEntity.faqs}</dd>
          <dt>
            <Translate contentKey="alippoApp.tenantExtra.tenant">Tenant</Translate>
          </dt>
          <dd>{tenantExtraEntity.tenant ? tenantExtraEntity.tenant.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/tenant-extra" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/tenant-extra/${tenantExtraEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TenantExtraDetail;
