import dayjs from 'dayjs';
import { IResUserCourseSectionLesson } from 'app/shared/model/res-user-course-section-lesson.model';
import { IUser } from 'app/shared/model/user.model';
import { ICourseSectionLesson } from 'app/shared/model/course-section-lesson.model';

export interface IUserCourseSectionLesson {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  resources?: IResUserCourseSectionLesson[] | null;
  instructor?: IUser | null;
  courseSectionLesson?: ICourseSectionLesson | null;
}

export const defaultValue: Readonly<IUserCourseSectionLesson> = {};
