import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Cluster from './cluster';
import ClusterDetail from './cluster-detail';
import ClusterUpdate from './cluster-update';
import ClusterDeleteDialog from './cluster-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ClusterUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ClusterUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ClusterDetail} />
      <ErrorBoundaryRoute path={match.url} component={Cluster} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ClusterDeleteDialog} />
  </>
);

export default Routes;
