import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { IRule } from 'app/shared/model/rule.model';
import { getEntities as getRules } from 'app/entities/rule/rule.reducer';
import { IOrderInfo } from 'app/shared/model/order-info.model';
import { getEntities as getOrderInfos } from 'app/entities/order-info/order-info.reducer';
import { ITask } from 'app/shared/model/task.model';
import { getEntities as getTasks } from 'app/entities/task/task.reducer';
import { getEntity, updateEntity, createEntity, reset } from './rule-ledger.reducer';
import { IRuleLedger } from 'app/shared/model/rule-ledger.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { RuleBasis } from 'app/shared/model/enumerations/rule-basis.model';
import { RuleCurrency } from 'app/shared/model/enumerations/rule-currency.model';
import { CurrencyCode } from 'app/shared/model/enumerations/currency-code.model';
import { RuleLedgerAmountStatus } from 'app/shared/model/enumerations/rule-ledger-amount-status.model';

export const RuleLedgerUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const users = useAppSelector(state => state.userManagement.users);
  const rules = useAppSelector(state => state.rule.entities);
  const orderInfos = useAppSelector(state => state.orderInfo.entities);
  const tasks = useAppSelector(state => state.task.entities);
  const ruleLedgerEntity = useAppSelector(state => state.ruleLedger.entity);
  const loading = useAppSelector(state => state.ruleLedger.loading);
  const updating = useAppSelector(state => state.ruleLedger.updating);
  const updateSuccess = useAppSelector(state => state.ruleLedger.updateSuccess);
  const ruleBasisValues = Object.keys(RuleBasis);
  const ruleCurrencyValues = Object.keys(RuleCurrency);
  const currencyCodeValues = Object.keys(CurrencyCode);
  const ruleLedgerAmountStatusValues = Object.keys(RuleLedgerAmountStatus);
  const handleClose = () => {
    props.history.push('/rule-ledger' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getUsers({}));
    dispatch(getRules({}));
    dispatch(getOrderInfos({}));
    dispatch(getTasks({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.referredFromDateTime = convertDateTimeToServer(values.referredFromDateTime);
    values.referrerFromDateTime = convertDateTimeToServer(values.referrerFromDateTime);
    values.expiryAt = convertDateTimeToServer(values.expiryAt);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...ruleLedgerEntity,
      ...values,
      referredUser: { id: values.referredUser },
      referrerUser: { id: values.referrerUser },
      referredRule: { id: values.referredRule },
      referrerRule: { id: values.referrerRule },
      orderInfo: { id: values.orderInfo },
      task: tasks.find(it => it.id.toString() === values.task.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          referredFromDateTime: displayDefaultDateTime(),
          referrerFromDateTime: displayDefaultDateTime(),
          expiryAt: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          referredBasis: 'FIXED',
          referredRuleCurrency: 'DEFAULT',
          referredCurrencyCode: 'INR',
          referredAmountStatus: 'PENDING',
          referrerBasis: 'FIXED',
          referrerRuleCurrency: 'DEFAULT',
          referrerCurrencyCode: 'INR',
          referrerAmountStatus: 'PENDING',
          ...ruleLedgerEntity,
          referredFromDateTime: convertDateTimeFromServer(ruleLedgerEntity.referredFromDateTime),
          referrerFromDateTime: convertDateTimeFromServer(ruleLedgerEntity.referrerFromDateTime),
          expiryAt: convertDateTimeFromServer(ruleLedgerEntity.expiryAt),
          createdAt: convertDateTimeFromServer(ruleLedgerEntity.createdAt),
          updatedAt: convertDateTimeFromServer(ruleLedgerEntity.updatedAt),
          referredUser: ruleLedgerEntity?.referredUser?.id,
          referrerUser: ruleLedgerEntity?.referrerUser?.id,
          referredRule: ruleLedgerEntity?.referredRule?.id,
          referrerRule: ruleLedgerEntity?.referrerRule?.id,
          orderInfo: ruleLedgerEntity?.orderInfo?.id,
          task: ruleLedgerEntity?.task?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.ruleLedger.home.createOrEditLabel" data-cy="RuleLedgerCreateUpdateHeading">
            <Translate contentKey="alippoApp.ruleLedger.home.createOrEditLabel">Create or edit a RuleLedger</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="rule-ledger-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.ruleLedger.ledgerId')}
                id="rule-ledger-ledgerId"
                name="ledgerId"
                data-cy="ledgerId"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referredAmount')}
                id="rule-ledger-referredAmount"
                name="referredAmount"
                data-cy="referredAmount"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referredBasis')}
                id="rule-ledger-referredBasis"
                name="referredBasis"
                data-cy="referredBasis"
                type="select"
              >
                {ruleBasisValues.map(ruleBasis => (
                  <option value={ruleBasis} key={ruleBasis}>
                    {translate('alippoApp.RuleBasis' + ruleBasis)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referredRuleCurrency')}
                id="rule-ledger-referredRuleCurrency"
                name="referredRuleCurrency"
                data-cy="referredRuleCurrency"
                type="select"
              >
                {ruleCurrencyValues.map(ruleCurrency => (
                  <option value={ruleCurrency} key={ruleCurrency}>
                    {translate('alippoApp.RuleCurrency' + ruleCurrency)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referredCurrencyCode')}
                id="rule-ledger-referredCurrencyCode"
                name="referredCurrencyCode"
                data-cy="referredCurrencyCode"
                type="select"
              >
                {currencyCodeValues.map(currencyCode => (
                  <option value={currencyCode} key={currencyCode}>
                    {translate('alippoApp.CurrencyCode' + currencyCode)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referredAmountUsed')}
                id="rule-ledger-referredAmountUsed"
                name="referredAmountUsed"
                data-cy="referredAmountUsed"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referredUsageLimit')}
                id="rule-ledger-referredUsageLimit"
                name="referredUsageLimit"
                data-cy="referredUsageLimit"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referredTimesUsed')}
                id="rule-ledger-referredTimesUsed"
                name="referredTimesUsed"
                data-cy="referredTimesUsed"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referredAmountStatus')}
                id="rule-ledger-referredAmountStatus"
                name="referredAmountStatus"
                data-cy="referredAmountStatus"
                type="select"
              >
                {ruleLedgerAmountStatusValues.map(ruleLedgerAmountStatus => (
                  <option value={ruleLedgerAmountStatus} key={ruleLedgerAmountStatus}>
                    {translate('alippoApp.RuleLedgerAmountStatus' + ruleLedgerAmountStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referredFromDateTime')}
                id="rule-ledger-referredFromDateTime"
                name="referredFromDateTime"
                data-cy="referredFromDateTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referredDuration')}
                id="rule-ledger-referredDuration"
                name="referredDuration"
                data-cy="referredDuration"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referrerAmount')}
                id="rule-ledger-referrerAmount"
                name="referrerAmount"
                data-cy="referrerAmount"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referrerBasis')}
                id="rule-ledger-referrerBasis"
                name="referrerBasis"
                data-cy="referrerBasis"
                type="select"
              >
                {ruleBasisValues.map(ruleBasis => (
                  <option value={ruleBasis} key={ruleBasis}>
                    {translate('alippoApp.RuleBasis' + ruleBasis)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referrerRuleCurrency')}
                id="rule-ledger-referrerRuleCurrency"
                name="referrerRuleCurrency"
                data-cy="referrerRuleCurrency"
                type="select"
              >
                {ruleCurrencyValues.map(ruleCurrency => (
                  <option value={ruleCurrency} key={ruleCurrency}>
                    {translate('alippoApp.RuleCurrency' + ruleCurrency)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referrerCurrencyCode')}
                id="rule-ledger-referrerCurrencyCode"
                name="referrerCurrencyCode"
                data-cy="referrerCurrencyCode"
                type="select"
              >
                {currencyCodeValues.map(currencyCode => (
                  <option value={currencyCode} key={currencyCode}>
                    {translate('alippoApp.CurrencyCode' + currencyCode)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referrerAmountUsed')}
                id="rule-ledger-referrerAmountUsed"
                name="referrerAmountUsed"
                data-cy="referrerAmountUsed"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referrerUsageLimit')}
                id="rule-ledger-referrerUsageLimit"
                name="referrerUsageLimit"
                data-cy="referrerUsageLimit"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referrerTimesUsed')}
                id="rule-ledger-referrerTimesUsed"
                name="referrerTimesUsed"
                data-cy="referrerTimesUsed"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referrerAmountStatus')}
                id="rule-ledger-referrerAmountStatus"
                name="referrerAmountStatus"
                data-cy="referrerAmountStatus"
                type="select"
              >
                {ruleLedgerAmountStatusValues.map(ruleLedgerAmountStatus => (
                  <option value={ruleLedgerAmountStatus} key={ruleLedgerAmountStatus}>
                    {translate('alippoApp.RuleLedgerAmountStatus' + ruleLedgerAmountStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referrerFromDateTime')}
                id="rule-ledger-referrerFromDateTime"
                name="referrerFromDateTime"
                data-cy="referrerFromDateTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.referrerDuration')}
                id="rule-ledger-referrerDuration"
                name="referrerDuration"
                data-cy="referrerDuration"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.expiryAt')}
                id="rule-ledger-expiryAt"
                name="expiryAt"
                data-cy="expiryAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.metadata')}
                id="rule-ledger-metadata"
                name="metadata"
                data-cy="metadata"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.createdAt')}
                id="rule-ledger-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.ruleLedger.updatedAt')}
                id="rule-ledger-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="rule-ledger-referredUser"
                name="referredUser"
                data-cy="referredUser"
                label={translate('alippoApp.ruleLedger.referredUser')}
              />
              <ValidatedField
                id="rule-ledger-referrerUser"
                name="referrerUser"
                data-cy="referrerUser"
                label={translate('alippoApp.ruleLedger.referrerUser')}
              />
              <ValidatedField
                id="rule-ledger-referredRule"
                name="referredRule"
                data-cy="referredRule"
                label={translate('alippoApp.ruleLedger.referredRule')}
              />
              <ValidatedField
                id="rule-ledger-referrerRule"
                name="referrerRule"
                data-cy="referrerRule"
                label={translate('alippoApp.ruleLedger.referrerRule')}
              />
              <ValidatedField
                id="rule-ledger-orderInfo"
                name="orderInfo"
                data-cy="orderInfo"
                label={translate('alippoApp.ruleLedger.orderInfo')}
              />
              <ValidatedField id="rule-ledger-task" name="task" data-cy="task" label={translate('alippoApp.ruleLedger.task')} type="select">
                <option value="" key="0" />
                {tasks
                  ? tasks.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/rule-ledger" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RuleLedgerUpdate;
