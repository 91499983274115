import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ICourse } from 'app/shared/model/course.model';
import { getEntities as getCourses } from 'app/entities/course/course.reducer';
import { ICategory } from 'app/shared/model/category.model';
import { getEntities as getCategories } from 'app/entities/category/category.reducer';
import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntities as getSlugs } from 'app/entities/slug/slug.reducer';
import { getEntity, updateEntity, createEntity, reset } from './slug.reducer';
import { ISlug } from 'app/shared/model/slug.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { SlugEntityType } from 'app/shared/model/enumerations/slug-entity-type.model';
import { SlugRecordType } from 'app/shared/model/enumerations/slug-record-type.model';

export const SlugUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const courses = useAppSelector(state => state.course.entities);
  const categories = useAppSelector(state => state.category.entities);
  const users = useAppSelector(state => state.userManagement.users);
  const slugs = useAppSelector(state => state.slug.entities);
  const slugEntity = useAppSelector(state => state.slug.entity);
  const loading = useAppSelector(state => state.slug.loading);
  const updating = useAppSelector(state => state.slug.updating);
  const updateSuccess = useAppSelector(state => state.slug.updateSuccess);
  const slugEntityTypeValues = Object.keys(SlugEntityType);
  const slugRecordTypeValues = Object.keys(SlugRecordType);
  const handleClose = () => {
    props.history.push('/slug' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getCourses({}));
    dispatch(getCategories({}));
    dispatch(getUsers({}));
    dispatch(getSlugs({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...slugEntity,
      ...values,
      course: { id: values.course },
      category: { id: values.category },
      user: { id: values.user },
      redirectSlug: { id: values.redirectSlug },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          entityType: 'COURSE',
          recordType: 'DIRECT',
          ...slugEntity,
          createdAt: convertDateTimeFromServer(slugEntity.createdAt),
          updatedAt: convertDateTimeFromServer(slugEntity.updatedAt),
          course: slugEntity?.course?.id,
          category: slugEntity?.category?.id,
          user: slugEntity?.user?.id,
          redirectSlug: slugEntity?.redirectSlug?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.slug.home.createOrEditLabel" data-cy="SlugCreateUpdateHeading">
            <Translate contentKey="alippoApp.slug.home.createOrEditLabel">Create or edit a Slug</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="slug-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.slug.slug')}
                id="slug-slug"
                name="slug"
                data-cy="slug"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.slug.entityType')}
                id="slug-entityType"
                name="entityType"
                data-cy="entityType"
                type="select"
              >
                {slugEntityTypeValues.map(slugEntityType => (
                  <option value={slugEntityType} key={slugEntityType}>
                    {translate('alippoApp.SlugEntityType' + slugEntityType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.slug.recordType')}
                id="slug-recordType"
                name="recordType"
                data-cy="recordType"
                type="select"
              >
                {slugRecordTypeValues.map(slugRecordType => (
                  <option value={slugRecordType} key={slugRecordType}>
                    {translate('alippoApp.SlugRecordType' + slugRecordType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.slug.createdAt')}
                id="slug-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.slug.updatedAt')}
                id="slug-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField id="slug-course" name="course" data-cy="course" label={translate('alippoApp.slug.course')} />
              <ValidatedField id="slug-category" name="category" data-cy="category" label={translate('alippoApp.slug.category')} />
              <ValidatedField id="slug-user" name="user" data-cy="user" label={translate('alippoApp.slug.user')} />
              <ValidatedField
                id="slug-redirectSlug"
                name="redirectSlug"
                data-cy="redirectSlug"
                label={translate('alippoApp.slug.redirectSlug')}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/slug" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SlugUpdate;
