import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import TenantExtra from './tenant-extra';
import TenantExtraDetail from './tenant-extra-detail';
import TenantExtraUpdate from './tenant-extra-update';
import TenantExtraDeleteDialog from './tenant-extra-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={TenantExtraUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={TenantExtraUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={TenantExtraDetail} />
      <ErrorBoundaryRoute path={match.url} component={TenantExtra} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={TenantExtraDeleteDialog} />
  </>
);

export default Routes;
