import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SkuApplication from './sku-application';
import SkuApplicationDetail from './sku-application-detail';
import SkuApplicationUpdate from './sku-application-update';
import SkuApplicationDeleteDialog from './sku-application-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SkuApplicationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SkuApplicationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SkuApplicationDetail} />
      <ErrorBoundaryRoute path={match.url} component={SkuApplication} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={SkuApplicationDeleteDialog} />
  </>
);

export default Routes;
