import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { WhatsappMessageStatus } from 'app/shared/model/enumerations/whatsapp-message-status.model';

export interface IWhatsappLog {
  id?: number;
  messageId?: string;
  utmSource?: string | null;
  utmMedium?: string | null;
  utmCampaign?: string | null;
  status?: WhatsappMessageStatus;
  eventLog?: string | null;
  createdAt?: string;
  updatedAt?: string;
  user?: IUser | null;
}

export const defaultValue: Readonly<IWhatsappLog> = {};
