import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './lesson.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { toast } from 'react-toastify';

export const LessonDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const lessonEntity = useAppSelector(state => state.lesson.entity);
  const [lessonTranslationData, setLessonTranslationData] = useState(null);
  const { match } = props;
  const handleDeleteResource = () => {
    props.history.replace(`${match.url}/delete`);
  };

  const getLessonTranslations = async () => {
    if (lessonEntity.id) {
      try {
        const apiUrl = `api/lesson-translations?lessonId.equals=${lessonEntity.id}`;
        const res: any = await axios.get(apiUrl);
        const lessonTranslations = res.data;
        if (lessonTranslations.length === 0) {
          toast.error('No lessons available');
        } else {
          setLessonTranslationData(
            lessonTranslations.map(e => {
              const lessonTranslationId = e.id;
              return {
                id: lessonTranslationId,
                name: e.name,
                lessonUrl: `/lesson-translation/${lessonTranslationId}`,
              };
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="lessonDetailsHeading">
          <Translate contentKey="alippoApp.lesson.detail.title">Lesson</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{lessonEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.lesson.name">Name</Translate>
            </span>
          </dt>
          <dd>{lessonEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.lesson.description">Description</Translate>
            </span>
          </dt>
          <dd>{lessonEntity.description}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.lesson.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{lessonEntity.createdAt ? <TextFormat value={lessonEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.lesson.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{lessonEntity.updatedAt ? <TextFormat value={lessonEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <dl>
          <dt>Lesson Translations</dt>
          <Button onClick={getLessonTranslations} className="mr-2" color="primary">
            <span>Get Lesson Translations</span>
          </Button>
          <Link to={{ pathname: '/lesson-translation/new', state: { lesson: lessonEntity } }}>
            <Button className="rounded-circle" color="success">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Link>
          <dd>
            {lessonTranslationData && lessonTranslationData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">ID</th>
                    <th className="hand">Lesson Translation Name</th>
                    <th className="hand">Lesson Translation URL</th>
                  </tr>
                </thead>
                <tbody>
                  {lessonTranslationData.map((lessonTranslation, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{lessonTranslation.id}</td>
                      <td>{lessonTranslation.name}</td>
                      <td>
                        <Link to={lessonTranslation.lessonUrl}>Link</Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/lesson" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/lesson/${lessonEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
        &nbsp;
        <Button onClick={handleDeleteResource} color="danger" data-cy="entityDeleteButton">
          <FontAwesomeIcon icon="trash" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.delete">Delete</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default LessonDetail;
