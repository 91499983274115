import dayjs from 'dayjs';
import { ITenant } from 'app/shared/model/tenant.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface ITenantExtra {
  id?: number;
  phoneContact?: string | null;
  displayPhoneContact?: string | null;
  chatContact?: string | null;
  displayChatContact?: string | null;
  androidAppId?: string | null;
  baseWebUrl?: string | null;
  refundUrl?: string | null;
  privacyUrl?: string | null;
  language?: Language;
  isDefault?: boolean;
  createdAt?: string;
  updatedAt?: string;
  faqs?: string | null;
  tenant?: ITenant | null;
}

export const defaultValue: Readonly<ITenantExtra> = {
  isDefault: false,
};
