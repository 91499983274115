import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import RatingOption from './rating-option';
import RatingOptionDetail from './rating-option-detail';
import RatingOptionUpdate from './rating-option-update';
import RatingOptionDeleteDialog from './rating-option-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={RatingOptionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={RatingOptionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={RatingOptionDetail} />
      <ErrorBoundaryRoute path={match.url} component={RatingOption} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={RatingOptionDeleteDialog} />
  </>
);

export default Routes;
