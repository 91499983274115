import dayjs from 'dayjs';
import { ISku } from 'app/shared/model/sku.model';
import { IVoucherTranslation } from 'app/shared/model/voucher-translation.model';

export interface IVoucher {
  id?: number;
  name?: string;
  sortOrder?: number;
  isAutoRedeemable?: boolean;
  createdAt?: string;
  updatedAt?: string;
  sku?: ISku | null;
  translations?: IVoucherTranslation[] | null;
}

export const defaultValue: Readonly<IVoucher> = {
  isAutoRedeemable: false,
};
