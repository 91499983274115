import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IUserExtra {
  id?: number;
  otp?: string | null;
  otpTs?: string | null;
  verifiedTs?: string | null;
  certName?: string | null;
  certNameTs?: string | null;
  imageUrl?: string | null;
  ipInfo?: string | null;
  referrerCode?: string | null;
  properties?: string | null;
  description?: string | null;
  interest?: string | null;
  phone?: string | null;
  firstAcquisitionInfo?: string | null;
  createdAt?: string;
  updatedAt?: string;
  user?: IUser | null;
}

export const defaultValue: Readonly<IUserExtra> = {};
