import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './res-plan.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ResPlanDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const resPlanEntity = useAppSelector(state => state.resPlan.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="resPlanDetailsHeading">
          <Translate contentKey="alippoApp.resPlan.detail.title">ResPlan</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{resPlanEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.resPlan.name">Name</Translate>
            </span>
          </dt>
          <dd>{resPlanEntity.name}</dd>
          <dt>
            <span id="url">
              <Translate contentKey="alippoApp.resPlan.url">Url</Translate>
            </span>
          </dt>
          <dd>{resPlanEntity.url}</dd>
          <dt>
            <span id="resState">
              <Translate contentKey="alippoApp.resPlan.resState">Res State</Translate>
            </span>
          </dt>
          <dd>{resPlanEntity.resState}</dd>
          <dt>
            <span id="resType">
              <Translate contentKey="alippoApp.resPlan.resType">Res Type</Translate>
            </span>
          </dt>
          <dd>{resPlanEntity.resType}</dd>
          <dt>
            <span id="mediaType">
              <Translate contentKey="alippoApp.resPlan.mediaType">Media Type</Translate>
            </span>
          </dt>
          <dd>{resPlanEntity.mediaType}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.resPlan.description">Description</Translate>
            </span>
          </dt>
          <dd>{resPlanEntity.description}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="alippoApp.resPlan.language">Language</Translate>
            </span>
          </dt>
          <dd>{resPlanEntity.language}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.resPlan.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{resPlanEntity.createdAt ? <TextFormat value={resPlanEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.resPlan.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{resPlanEntity.updatedAt ? <TextFormat value={resPlanEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="alippoApp.resPlan.plan">Plan</Translate>
          </dt>
          <dd>{resPlanEntity.plan ? resPlanEntity.plan.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/res-plan" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/res-plan/${resPlanEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ResPlanDetail;
