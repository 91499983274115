export enum RuleType {
  REFER_INTERNAL_ORDER = 'REFER_INTERNAL_ORDER',

  REFER_SIGNUP = 'REFER_SIGNUP',

  REFER_ORDER = 'REFER_ORDER',

  REFER_ORDER_BONUS = 'REFER_ORDER_BONUS',

  SUBSCRIPTION_PLATINUM = 'SUBSCRIPTION_PLATINUM',

  SUBSCRIPTION_DIAMOND = 'SUBSCRIPTION_DIAMOND',

  SUBSCRIPTION_GOLD = 'SUBSCRIPTION_GOLD',

  SUBSCRIPTION_SILVER = 'SUBSCRIPTION_SILVER',

  COUPON_CART_VALUE = 'COUPON_CART_VALUE',

  COUPON_NEW_USER = 'COUPON_NEW_USER',

  COUPON_OLD_USER = 'COUPON_OLD_USER',

  PROMOTION = 'PROMOTION',

  VIRTUAL_MONEY_ACTIVATE = 'VIRTUAL_MONEY_ACTIVATE',

  VOUCHER_RECHARGE = 'VOUCHER_RECHARGE',

  VIRTUAL_MONEY_ORDER_CONSUMPTION = 'VIRTUAL_MONEY_ORDER_CONSUMPTION',

  VIRTUAL_MONEY_MANUAL_CREDIT = 'VIRTUAL_MONEY_MANUAL_CREDIT',

  COUPON_SUDO = 'COUPON_SUDO',

  DIG_COF_SMM_SUBSCRIPTION = 'DIG_COF_SMM_SUBSCRIPTION',

  DIG_COF_SMM_CREDIT_CONSUMPTION = 'DIG_COF_SMM_CREDIT_CONSUMPTION',

  DIG_COF_SMM_CREDIT_MANUAL_RECHARGE = 'DIG_COF_SMM_CREDIT_MANUAL_RECHARGE',
}
