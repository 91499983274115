import dayjs from 'dayjs';
import { ILocation } from 'app/shared/model/location.model';
import { IUser } from 'app/shared/model/user.model';
import { AddressType } from 'app/shared/model/enumerations/address-type.model';

export interface IAddress {
  id?: number;
  name?: string | null;
  phone?: string | null;
  email?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  addressLine3?: string | null;
  landmark?: string | null;
  postalCode?: string | null;
  city?: string | null;
  district?: string | null;
  stateOrRegion?: string | null;
  country?: string | null;
  countryCode?: string | null;
  addressType?: AddressType | null;
  createdAt?: string;
  updatedAt?: string;
  location?: ILocation | null;
  user?: IUser;
}

export const defaultValue: Readonly<IAddress> = {};
