export enum TaskStatus {
  PENDING = 'PENDING',

  PROCESSING = 'PROCESSING',

  PROCESSED = 'PROCESSED',

  FAILED = 'FAILED',

  CANCELLED = 'CANCELLED',
}
