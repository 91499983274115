export enum UnitType {
  WEIGHT = 'WEIGHT',

  LENGTH = 'LENGTH',

  VOLUME = 'VOLUME',

  DISCRETE = 'DISCRETE',

  CURRENCY = 'CURRENCY',
}
