import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs';

import React, { useContext, useEffect } from 'react';
import { Card } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import Header from 'app/shared/layout/header/header';
import Footer from 'app/shared/layout/footer/footer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import { RedirectUrlContext } from './contexts/RedirectUrlContext';

export const App = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { setRedirectPath } = useContext(RedirectUrlContext);

  const isInstructorChecklistPage = pathname.includes('/instructor-checklist');

  useEffect(() => {
    if (!isInstructorChecklistPage) {
      dispatch(getSession());
      dispatch(getProfile());
    }

    window.addEventListener('message', function (event) {
      try {
        const eventData = JSON.parse(event.data);
        if ((eventData['event'] ?? '') === 'appContext') {
          const number = eventData['data']['contact']['phone_number'];
          setRedirectPath('/admin/user-management/' + number);
        }
      } catch (e) {
        // console.error('Unparsable message', event);
      }
    });
  }, []);

  const currentLocale = useAppSelector(state => state.locale.currentLocale);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);

  const paddingTop = isInstructorChecklistPage ? '0' : '60px';
  return (
    <div className="app-container" style={{ paddingTop }}>
      <ToastContainer position={toast.POSITION.TOP_LEFT} className="toastify-container" toastClassName="toastify-toast" />
      {!isInstructorChecklistPage && (
        <ErrorBoundary>
          <Header
            isAuthenticated={isAuthenticated}
            isAdmin={isAdmin}
            currentLocale={currentLocale}
            ribbonEnv={ribbonEnv}
            isInProduction={isInProduction}
            isOpenAPIEnabled={isOpenAPIEnabled}
          />
        </ErrorBoundary>
      )}
      <div className="container-fluid view-container" id="app-view-container">
        <Card className="jh-card">
          <ErrorBoundary>
            <AppRoutes />
          </ErrorBoundary>
        </Card>
        <Footer />
      </div>
    </div>
  );
};

export default App;
