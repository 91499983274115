import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './course-section.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { toast } from 'react-toastify';

export const CourseSectionDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const courseSectionEntity = useAppSelector(state => state.courseSection.entity);
  const [sectionLessonData, setSectionLessonData] = useState(null);

  const getSectionLessons = async () => {
    if (courseSectionEntity.id) {
      try {
        const apiUrl = `api/course-section-lessons?courseSectionId.equals=${courseSectionEntity.id}&sort=sortOrder,asc&size=40&page=0`;
        const res: any = await axios.get(apiUrl);
        const lessons = res.data;
        if (lessons.length === 0) {
          toast.error('No lessons available');
        } else {
          setSectionLessonData(
            lessons.map(e => {
              const lessonId = e.lesson.id;
              return {
                id: e.id,
                lessonId,
                lessonName: e.lesson.name,
                courseSectionLessonUrl: `/course-section-lesson/${e.id}`,
                lessonUrl: `/lesson/${lessonId}`,
                acslUrl: e.associatedCourseSectionLesson ? `/course-section-lesson/${e.associatedCourseSectionLesson.id}` : null,
                associatedCourseSectionLesson: e.associatedCourseSectionLesson,
                lessonSortOrder: e.sortOrder,
                lessonDuration: e.duration,
                lessonSectionName: e.section.name,
                fromDateTime: e.fromDateTime,
              };
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="courseSectionDetailsHeading">
          <Translate contentKey="alippoApp.courseSection.detail.title">CourseSection</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{courseSectionEntity.id}</dd>
          <dt>
            <span id="fromDateTime">
              <Translate contentKey="alippoApp.courseSection.fromDateTime">From Date Time</Translate>
            </span>
          </dt>
          <dd>
            {courseSectionEntity.fromDateTime ? (
              <TextFormat value={courseSectionEntity.fromDateTime} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="duration">
              <Translate contentKey="alippoApp.courseSection.duration">Duration</Translate>
            </span>
          </dt>
          <dd>{courseSectionEntity.duration}</dd>
          <dt>
            <span id="sortOrder">
              <Translate contentKey="alippoApp.courseSection.sortOrder">Sort Order</Translate>
            </span>
          </dt>
          <dd>{courseSectionEntity.sortOrder}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.courseSection.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {courseSectionEntity.createdAt ? (
              <TextFormat value={courseSectionEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.courseSection.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {courseSectionEntity.updatedAt ? (
              <TextFormat value={courseSectionEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.courseSection.section">Section</Translate>
          </dt>
          <dd>{courseSectionEntity.section ? courseSectionEntity.section.name : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.courseSection.course">Course</Translate>
          </dt>
          <dd>{courseSectionEntity.course ? courseSectionEntity.course.name : ''}</dd>
        </dl>
        <dl>
          <dt>Course Sections Lessons</dt>
          <Button onClick={getSectionLessons} className="mr-3" color="primary">
            <span>Get Course Section Lessons</span>
          </Button>
          <Link to={{ pathname: '/course-section-lesson/new', state: { courseSection: courseSectionEntity } }}>
            <Button className="rounded-circle" color="success">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Link>
          <dd>
            {sectionLessonData && sectionLessonData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">S.No</th>
                    <th className="hand">Sort Order</th>
                    <th className="hand">From Date Time</th>
                    <th className="hand">Duration</th>
                    <th className="hand">Course Section Lesson ID</th>
                    <th className="hand">Course Section Lesson URL</th>
                    <th className="hand">ACSL</th>
                    <th className="hand">Section Name</th>
                    <th className="hand">Lesson ID</th>
                    <th className="hand">Lesson Name</th>
                    <th className="hand">Lesson URL</th>
                  </tr>
                </thead>
                <tbody>
                  {sectionLessonData.map((lesson, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{i + 1}</td>
                      <td>{lesson.lessonSortOrder}</td>
                      <td>{lesson.fromDateTime}</td>
                      <td>{lesson.lessonDuration}</td>
                      <td>{lesson.id}</td>
                      <td>
                        <Link to={lesson.courseSectionLessonUrl}>Link</Link>
                      </td>
                      <td>
                        {lesson.associatedCourseSectionLesson && (
                          <Link target="_blank" rel="noopener noreferrer" to={lesson.acslUrl}>
                            {lesson.associatedCourseSectionLesson.id}
                          </Link>
                        )}
                      </td>
                      <td>{lesson.lessonSectionName}</td>
                      <td>{lesson.lessonId}</td>
                      <td>{lesson.lessonName}</td>
                      <td>
                        <Link target="_blank" rel="noopener noreferrer" to={lesson.lessonUrl}>
                          Link
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/course-section" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/course-section/${courseSectionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/course-section/${courseSectionEntity.id}/delete`} replace color="danger">
          <FontAwesomeIcon icon="trash" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.delete">Delete</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CourseSectionDetail;
