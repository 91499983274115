import dayjs from 'dayjs';
import { IRes } from 'app/shared/model/res.model';
import { ICourseDiscussion } from 'app/shared/model/course-discussion.model';
import { ResState } from 'app/shared/model/enumerations/res-state.model';
import { Language } from 'app/shared/model/enumerations/language.model';
import { ResType } from 'app/shared/model/enumerations/res-type.model';

export interface IResDiscussion {
  id?: number;
  name?: string | null;
  resState?: ResState | null;
  description?: string | null;
  language?: Language | null;
  imageUrl?: string | null;
  isDefault?: boolean | null;
  resType?: ResType | null;
  createdAt?: string;
  updatedAt?: string;
  res?: IRes | null;
  courseDiscussion?: ICourseDiscussion | null;
}

export const defaultValue: Readonly<IResDiscussion> = {
  isDefault: false,
};
