import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ICourse } from 'app/shared/model/course.model';
import { getEntity, updateEntity, createEntity, reset } from './course-translation.reducer';
import { ICourseTranslation } from 'app/shared/model/course-translation.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Language } from 'app/shared/model/enumerations/language.model';

export const CourseTranslationUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locationState: any = location.state;

  let inheritedCourse = null;
  if (locationState) {
    inheritedCourse = locationState.course;
  }

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const courseTranslationEntity = useAppSelector(state => state.courseTranslation.entity);
  const loading = useAppSelector(state => state.courseTranslation.loading);
  const updating = useAppSelector(state => state.courseTranslation.updating);
  const updateSuccess = useAppSelector(state => state.courseTranslation.updateSuccess);
  const languageValues = Object.keys(Language);

  const handleClose = () => {
    if (courseTranslationEntity && courseTranslationEntity.id) {
      dispatch(reset());
      props.history.push('/course/' + `${courseTranslationEntity.course.id}`);
    } else {
      props.history.push('/course-translation' + props.location.search);
    }
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...courseTranslationEntity,
      ...values,
      course: { id: values.course },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
          course: inheritedCourse ? inheritedCourse.id : null,
          name: inheritedCourse ? inheritedCourse.name : null,
          description: inheritedCourse ? inheritedCourse.description : null,
        }
      : {
          language: 'HINGLISH',
          ...courseTranslationEntity,
          createdAt: convertDateTimeFromServer(courseTranslationEntity.createdAt),
          updatedAt: convertDateTimeFromServer(courseTranslationEntity.updatedAt),
          course: courseTranslationEntity?.course?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.courseTranslation.home.createOrEditLabel" data-cy="CourseTranslationCreateUpdateHeading">
            <Translate contentKey="alippoApp.courseTranslation.home.createOrEditLabel">Create or edit a CourseTranslation</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="course-translation-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.courseTranslation.name')}
                id="course-translation-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.courseTranslation.description')}
                id="course-translation-description"
                name="description"
                data-cy="description"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.courseTranslation.language')}
                id="course-translation-language"
                name="language"
                data-cy="language"
                type="select"
              >
                {languageValues.map(language => (
                  <option value={language} key={language}>
                    {translate('alippoApp.Language' + language)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.courseTranslation.isDefault')}
                id="course-translation-isDefault"
                name="isDefault"
                data-cy="isDefault"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.courseTranslation.createdAt')}
                id="course-translation-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.courseTranslation.updatedAt')}
                id="course-translation-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="course-translation-course"
                name="course"
                data-cy="course"
                label={translate('alippoApp.courseTranslation.course')}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/course-translation" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CourseTranslationUpdate;
