import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import DiscussionMember from './discussion-member';
import DiscussionMemberDetail from './discussion-member-detail';
import DiscussionMemberUpdate from './discussion-member-update';
import DiscussionMemberDeleteDialog from './discussion-member-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={DiscussionMemberUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={DiscussionMemberUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={DiscussionMemberDetail} />
      <ErrorBoundaryRoute path={match.url} component={DiscussionMember} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={DiscussionMemberDeleteDialog} />
  </>
);

export default Routes;
