import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import UserCourseSectionLesson from './user-course-section-lesson';
import UserCourseSectionLessonDetail from './user-course-section-lesson-detail';
import UserCourseSectionLessonUpdate from './user-course-section-lesson-update';
import UserCourseSectionLessonDeleteDialog from './user-course-section-lesson-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UserCourseSectionLessonUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UserCourseSectionLessonUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UserCourseSectionLessonDetail} />
      <ErrorBoundaryRoute path={match.url} component={UserCourseSectionLesson} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={UserCourseSectionLessonDeleteDialog} />
  </>
);

export default Routes;
