import dayjs from 'dayjs';
import { IResOrderItem } from 'app/shared/model/res-order-item.model';
import { IOrderItemSkuAttribute } from 'app/shared/model/order-item-sku-attribute.model';
import { ICourse } from 'app/shared/model/course.model';
import { ISku } from 'app/shared/model/sku.model';
import { ICourseDiscussion } from 'app/shared/model/course-discussion.model';
import { IOrderInfo } from 'app/shared/model/order-info.model';
import { OrderItemStatus } from 'app/shared/model/enumerations/order-item-status.model';
import { CurrencyCode } from 'app/shared/model/enumerations/currency-code.model';

export interface IOrderItem {
  id?: number;
  orderItemStatus?: OrderItemStatus;
  sellingPriceAmount?: number;
  originalPriceAmount?: number | null;
  sellingPriceCurrencyCode?: CurrencyCode;
  virtualMoneyRewardUsed?: number | null;
  description?: string | null;
  createdAt?: string;
  updatedAt?: string;
  resources?: IResOrderItem[] | null;
  skuAttributes?: IOrderItemSkuAttribute[] | null;
  course?: ICourse | null;
  sku?: ISku | null;
  discussion?: ICourseDiscussion | null;
  parent?: IOrderItem | null;
  orderInfo?: IOrderInfo | null;
}

export const defaultValue: Readonly<IOrderItem> = {};
