import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getRatingOptions } from 'app/entities/rating-option/rating-option.reducer';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { ReviewEntityType } from 'app/shared/model/enumerations/review-entity-type.model';
import { ReviewStatus } from 'app/shared/model/enumerations/review-status.model';
import { ReviewSubType } from 'app/shared/model/enumerations/review-sub-type.model';
import { ReviewType } from 'app/shared/model/enumerations/review-type.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { buildSearchParamsPathFromEntity } from 'app/shared/util/entity-utils';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { createEntity, getEntity, reset, updateEntity } from './review.reducer';

export const ReviewUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const ratingOptions = useAppSelector(state => state.ratingOption.entities);
  const users = useAppSelector(state => state.userManagement.users);
  const reviewEntity = useAppSelector(state => state.review.entity);
  const loading = useAppSelector(state => state.review.loading);
  const updating = useAppSelector(state => state.review.updating);
  const updateSuccess = useAppSelector(state => state.review.updateSuccess);
  const reviewEntityTypeValues = Object.keys(ReviewEntityType);
  const reviewTypeValues = Object.keys(ReviewType);
  const reviewSubTypeValues = Object.keys(ReviewSubType);
  const reviewStatusValues = Object.keys(ReviewStatus);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getUsers({}));
  }, []);

  const location = useLocation();
  const locationState: any = location.state;

  const inheritedState = {
    type: null,
    id: null,
  };

  if (locationState?.category) {
    (inheritedState.type = 'CATEGORY'), (inheritedState.id = locationState.category.id);
  } else if (locationState?.course) {
    (inheritedState.type = 'COURSE'), (inheritedState.id = locationState.course.id);
  }

  const defaultValues = () =>
    isNew
      ? {
          entityType: inheritedState.type,
          type: 'GENERIC',
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
          sortOrder: 1,
          entityId: inheritedState.id,
        }
      : {
          entityType: 'COURSE',
          type: 'GENERIC',
          subType: 'STAR_RATING',
          status: 'PENDING',
          ...reviewEntity,
          createdAt: convertDateTimeFromServer(reviewEntity.createdAt),
          updatedAt: convertDateTimeFromServer(reviewEntity.updatedAt),
          rateOption: reviewEntity?.rateOption?.id,
          user: reviewEntity?.user?.id,
        };
  const [reviewEntitySelectedFields, setReviewEntitySelectedFields] = useState({ ...defaultValues() });
  const handleClose = () => {
    if (reviewEntity && reviewEntity.id) {
      props.history.push('/review/' + reviewEntity.id);
    }
    dispatch(reset());
  };

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const reloadRatingOptions = () => {
    const { entityType, type } = reviewEntitySelectedFields;

    const searchParamPath = buildSearchParamsPathFromEntity({ reviewEntityType: entityType, reviewType: type });
    dispatch(getRatingOptions({ props: searchParamPath, page: 0, size: 20, sort: 'value,asc' }));
  };

  useEffect(() => {
    reloadRatingOptions();
  }, [reviewEntitySelectedFields.entityType, reviewEntitySelectedFields.type]);

  useEffect(() => {
    reloadRatingOptions();
  }, [reviewEntity]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...reviewEntity,
      ...values,
      rateOption: ratingOptions.find(it => it.value.toString() === values.rateOption.toString()),
      user: {
        id: values.user,
      },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const handleEntityTypeChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setReviewEntitySelectedFields(prev => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.review.home.createOrEditLabel" data-cy="ReviewCreateUpdateHeading">
            <Translate contentKey="alippoApp.review.home.createOrEditLabel">Create or edit a Review</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={reviewEntitySelectedFields} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="review-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                  // defaultValue={reviewEntitySelectedFields['entityId'] || ''}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.review.entityType')}
                id="review-entityType"
                name="entityType"
                data-cy="entityType"
                type="select"
                value={reviewEntitySelectedFields['entityType']}
                onChange={handleEntityTypeChanged}
              >
                {reviewEntityTypeValues.map(reviewEntityType => (
                  <option value={reviewEntityType} key={reviewEntityType}>
                    {reviewEntityType}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.review.entityId')}
                id="review-entityId"
                name="entityId"
                data-cy="entityId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
                // defaultValue={reviewEntitySelectedFields['entityId'] || ''}
              />
              <ValidatedField
                label={translate('alippoApp.review.type')}
                id="review-type"
                name="type"
                data-cy="type"
                type="select"
                onChange={handleEntityTypeChanged}
                value={reviewEntitySelectedFields['type']}
              >
                {reviewTypeValues.map(reviewType => (
                  <option value={reviewType} key={reviewType}>
                    {reviewType}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.review.subType')}
                id="review-subType"
                name="subType"
                data-cy="subType"
                type="select"
                // defaultValue={reviewEntitySelectedFields['subType'] || ''}
              >
                {reviewSubTypeValues.map(reviewSubType => (
                  <option value={reviewSubType} key={reviewSubType}>
                    {reviewSubType}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.review.status')}
                id="review-status"
                name="status"
                data-cy="status"
                type="select"
                // defaultValue={reviewEntitySelectedFields['status']}
              >
                {reviewStatusValues.map(reviewStatus => (
                  <option value={reviewStatus} key={reviewStatus}>
                    {reviewStatus}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.review.displayName')}
                id="review-displayName"
                name="displayName"
                data-cy="displayName"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.review.sortOrder')}
                id="review-sortOrder"
                name="sortOrder"
                data-cy="sortOrder"
                type="text"
                // defaultValue={1}
              />
              <ValidatedField
                label={translate('alippoApp.review.createdAt')}
                id="review-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                // defaultValue={reviewEntitySelectedFields['createdAt']}
              />
              <ValidatedField
                label={translate('alippoApp.review.updatedAt')}
                id="review-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                // defaultValue={reviewEntitySelectedFields['updatedAt']}
              />
              <ValidatedField
                id="review-rateOption"
                name="rateOption"
                data-cy="rateOption"
                label={translate('alippoApp.review.rateOption')}
              />
              <ValidatedField
                id="review-user"
                name="user"
                data-cy="user"
                label={translate('alippoApp.review.user')}
                type="text"
                // defaultValue={reviewEntitySelectedFields['user'] ?? ''}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/review" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ReviewUpdate;
