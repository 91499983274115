import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Sku from './sku';
import SkuDetail from './sku-detail';
import SkuUpdate from './sku-update';
import SkuDeleteDialog from './sku-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SkuUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SkuUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SkuDetail} />
      <ErrorBoundaryRoute path={match.url} component={Sku} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={SkuDeleteDialog} />
  </>
);

export default Routes;
