import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './address.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const AddressDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const addressEntity = useAppSelector(state => state.address.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="addressDetailsHeading">
          <Translate contentKey="alippoApp.address.detail.title">Address</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{addressEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.address.name">Name</Translate>
            </span>
          </dt>
          <dd>{addressEntity.name}</dd>
          <dt>
            <span id="phone">
              <Translate contentKey="alippoApp.address.phone">Phone</Translate>
            </span>
          </dt>
          <dd>{addressEntity.phone}</dd>
          <dt>
            <span id="email">
              <Translate contentKey="alippoApp.address.email">Email</Translate>
            </span>
          </dt>
          <dd>{addressEntity.email}</dd>
          <dt>
            <span id="addressLine1">
              <Translate contentKey="alippoApp.address.addressLine1">Address Line 1</Translate>
            </span>
          </dt>
          <dd>{addressEntity.addressLine1}</dd>
          <dt>
            <span id="addressLine2">
              <Translate contentKey="alippoApp.address.addressLine2">Address Line 2</Translate>
            </span>
          </dt>
          <dd>{addressEntity.addressLine2}</dd>
          <dt>
            <span id="addressLine3">
              <Translate contentKey="alippoApp.address.addressLine3">Address Line 3</Translate>
            </span>
          </dt>
          <dd>{addressEntity.addressLine3}</dd>
          <dt>
            <span id="landmark">
              <Translate contentKey="alippoApp.address.landmark">Landmark</Translate>
            </span>
          </dt>
          <dd>{addressEntity.landmark}</dd>
          <dt>
            <span id="postalCode">
              <Translate contentKey="alippoApp.address.postalCode">Postal Code</Translate>
            </span>
          </dt>
          <dd>{addressEntity.postalCode}</dd>
          <dt>
            <span id="city">
              <Translate contentKey="alippoApp.address.city">City</Translate>
            </span>
          </dt>
          <dd>{addressEntity.city}</dd>
          <dt>
            <span id="district">
              <Translate contentKey="alippoApp.address.district">District</Translate>
            </span>
          </dt>
          <dd>{addressEntity.district}</dd>
          <dt>
            <span id="stateOrRegion">
              <Translate contentKey="alippoApp.address.stateOrRegion">State Or Region</Translate>
            </span>
          </dt>
          <dd>{addressEntity.stateOrRegion}</dd>
          <dt>
            <span id="country">
              <Translate contentKey="alippoApp.address.country">Country</Translate>
            </span>
          </dt>
          <dd>{addressEntity.country}</dd>
          <dt>
            <span id="countryCode">
              <Translate contentKey="alippoApp.address.countryCode">Country Code</Translate>
            </span>
          </dt>
          <dd>{addressEntity.countryCode}</dd>
          <dt>
            <span id="addressType">
              <Translate contentKey="alippoApp.address.addressType">Address Type</Translate>
            </span>
          </dt>
          <dd>{addressEntity.addressType}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.address.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{addressEntity.createdAt ? <TextFormat value={addressEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.address.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{addressEntity.updatedAt ? <TextFormat value={addressEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="alippoApp.address.location">Location</Translate>
          </dt>
          <dd>{addressEntity.location ? addressEntity.location.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.address.user">User</Translate>
          </dt>
          <dd>{addressEntity.user ? addressEntity.user.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/address" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/address/${addressEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default AddressDetail;
