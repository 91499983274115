import React, { useState, useEffect, useMemo } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';

import { getEntities } from './order-item.reducer';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overrideFiltersStateWithQueryParams, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { OrderItemStatus } from 'app/shared/model/enumerations/order-item-status.model';

type OrderItemsFilterType = {
  orderItemStatus: keyof typeof OrderItemStatus | 'NONE';
  courseId: number | null;
  discussionId: number | null;
  orderInfoId: number | null;
};

export const OrderItem = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );
  const [orderItemsFilter, setOrderItemsFilter] = useState<OrderItemsFilterType>(
    overrideFiltersStateWithQueryParams(
      {
        orderItemStatus: 'NONE',
        courseId: null,
        discussionId: null,
        orderInfoId: null,
      } as OrderItemsFilterType,
      props.location.search
    )
  );
  const [orderItemsLoading, setOrderItemsLoading] = useState<boolean>(false);

  const orderItemList = useAppSelector(state => state.orderItem.entities);
  const loading = useAppSelector(state => state.orderItem.loading);
  const totalItems = useAppSelector(state => state.orderItem.totalItems);

  const { match } = props;

  const getAllEntities = () => {
    setOrderItemsLoading(true);

    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        props: buildSearchParamsString(),
      })
    ).then(() => setOrderItemsLoading(false));

    const params = buildSearchParamsString();

    const endUrl = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}${params}`;

    if (window.location.search !== endUrl) {
      window.history.replaceState(null, '', `${props.location.pathname}${endUrl}`);
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState, orderItemsFilter]);

  const buildSearchParamsString = () => {
    let paramsString = '';

    if (orderItemsFilter.orderItemStatus && orderItemsFilter.orderItemStatus !== 'NONE') {
      paramsString += `&orderItemStatus.equals=${orderItemsFilter.orderItemStatus}`;
    }

    if (orderItemsFilter.courseId) {
      paramsString += `&courseId.equals=${orderItemsFilter.courseId}`;
    }

    if (orderItemsFilter.discussionId) {
      paramsString += `&discussionId.equals=${orderItemsFilter.discussionId}`;
    }

    if (orderItemsFilter.orderInfoId) {
      paramsString += `&orderInfoId.equals=${orderItemsFilter.orderInfoId}`;
    }

    return paramsString;
  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    getAllEntities();
  };

  const handleFilterChange = e => {
    setOrderItemsFilter(prev => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const debouncedHandleFilterChange = useMemo(() => debounce(handleFilterChange, 500), []);

  return (
    <div>
      <h2 id="order-item-heading" data-cy="OrderItemHeading">
        <Translate contentKey="alippoApp.orderItem.home.title">Order Items</Translate>
      </h2>
      <div className="d-flex justify-content-end">
        <Button className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="alippoApp.orderItem.home.refreshListLabel">Refresh List</Translate>
        </Button>
        <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="alippoApp.orderItem.home.createLabel">Create new Order Item</Translate>
        </Link>
      </div>
      <div className="my-4">
        <Form>
          <Row md={3}>
            <Col>
              <FormGroup>
                <Label for="orderItemStatus">Order Item Status</Label>
                <Input
                  id="orderItemStatus"
                  name="orderItemStatus"
                  type="select"
                  onChange={handleFilterChange}
                  defaultValue={orderItemsFilter.orderItemStatus}
                >
                  {(Object.keys(OrderItemStatus) as Array<keyof typeof OrderItemStatus>).map(status => (
                    <option value={status} key={status}>
                      {status}
                    </option>
                  ))}
                  <option value="NONE">-</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="name">Course ID</Label>
                <Input
                  id="courseId"
                  name="courseId"
                  placeholder="Enter Course ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={orderItemsFilter.courseId}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="name">Discussion ID</Label>
                <Input
                  id="discussionId"
                  name="discussionId"
                  placeholder="Enter Discussion ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={orderItemsFilter.discussionId}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="name">Order Info ID</Label>
                <Input
                  id="orderInfoId"
                  name="orderInfoId"
                  placeholder="Enter Order Info ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={orderItemsFilter.orderInfoId}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <h6 className="text-center mt-2" style={orderItemsLoading ? { opacity: 1 } : { opacity: 0 }}>
          Loading ...
        </h6>
      </div>
      <div className="table-responsive">
        {orderItemList && orderItemList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="alippoApp.orderItem.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('orderItemStatus')}>
                  <Translate contentKey="alippoApp.orderItem.orderItemStatus">Order Item Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('sellingPriceAmount')}>
                  <Translate contentKey="alippoApp.orderItem.sellingPriceAmount">Selling Price Amount</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('originalPriceAmount')}>
                  <Translate contentKey="alippoApp.orderItem.originalPriceAmount">Original Price Amount</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('sellingPriceCurrencyCode')}>
                  <Translate contentKey="alippoApp.orderItem.sellingPriceCurrencyCode">Selling Price Currency Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('virtualMoneyRewardUsed')}>
                  <Translate contentKey="alippoApp.orderItem.virtualMoneyRewardUsed">Virtual Money Reward Used</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  <Translate contentKey="alippoApp.orderItem.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="alippoApp.orderItem.createdAt">Created At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updatedAt')}>
                  <Translate contentKey="alippoApp.orderItem.updatedAt">Updated At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.orderItem.course">Course</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.orderItem.sku">Sku</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.orderItem.discussion">Discussion</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.orderItem.parent">Parent</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.orderItem.orderInfo">Order Info</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {orderItemList.map((orderItem, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`${match.url}/${orderItem.id}`} color="link" size="sm">
                      {orderItem.id}
                    </Button>
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.OrderItemStatus.${orderItem.orderItemStatus}`} />
                  </td>
                  <td>{orderItem.sellingPriceAmount}</td>
                  <td>{orderItem.originalPriceAmount}</td>
                  <td>
                    <Translate contentKey={`alippoApp.CurrencyCode.${orderItem.sellingPriceCurrencyCode}`} />
                  </td>
                  <td>{orderItem.virtualMoneyRewardUsed}</td>
                  <td>{orderItem.description}</td>
                  <td>{orderItem.createdAt ? <TextFormat type="date" value={orderItem.createdAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{orderItem.updatedAt ? <TextFormat type="date" value={orderItem.updatedAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{orderItem.course ? <Link to={`course/${orderItem.course.id}`}>{orderItem.course.id}</Link> : ''}</td>
                  <td>{orderItem.sku ? <Link to={`sku/${orderItem.sku.id}`}>{orderItem.sku.id}</Link> : ''}</td>
                  <td>
                    {orderItem.discussion ? <Link to={`course-discussion/${orderItem.discussion.id}`}>{orderItem.discussion.id}</Link> : ''}
                  </td>
                  <td>{orderItem.parent ? <Link to={`order-item/${orderItem.parent.id}`}>{orderItem.parent.id}</Link> : ''}</td>
                  <td>{orderItem.orderInfo ? <Link to={`order-info/${orderItem.orderInfo.id}`}>{orderItem.orderInfo.id}</Link> : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${orderItem.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${orderItem.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${orderItem.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="alippoApp.orderItem.home.notFound">No Order Items found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={orderItemList && orderItemList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default OrderItem;
