import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import axios from 'axios';
import { omit } from 'lodash';

import { getEntity } from './rule.reducer';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IRule } from 'app/shared/model/rule.model';
import { convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { IResRule } from 'app/shared/model/res-rule.model';

export const RuleDetail = (props: RouteComponentProps<{ id: string }>) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const ruleEntity = useAppSelector(state => state.rule.entity);

  const [resourcesData, setResourcesData] = useState(null);

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleConfirmClone = async () => {
    const newRule = {
      ...omit(ruleEntity, ['id']),
      name: `${ruleEntity.name} [cloned from ${ruleEntity.id}]`,
      createdAt: convertDateTimeToServer(displayDefaultDateTime()),
      updatedAt: convertDateTimeToServer(displayDefaultDateTime()),
    };

    try {
      // Clone rule
      const { data } = await axios.post<IRule>('api/rules', newRule);

      const newRuleId = data.id;

      const { data: resRules } = await axios.get<IResRule[]>(`api/res-rules?ruleId.equals=${ruleEntity.id}`);

      // Clone corresponding res rules
      if (resRules.length > 0) {
        const promises = resRules.map(async resRule => {
          const newResRule = {
            ...omit(resRule, ['id']),
            createdAt: convertDateTimeToServer(displayDefaultDateTime()),
            updatedAt: convertDateTimeToServer(displayDefaultDateTime()),
            rule: {
              id: newRuleId,
            },
          };

          return await axios.post<IResRule>('api/res-rules', newResRule);
        });

        await Promise.allSettled(promises);
      }

      handleModalClose();
      toast.success('Rule cloned successfully');
    } catch (err) {
      handleModalClose();
      console.error(err);
      toast.error(err);
    }
  };

  const getResRules = async () => {
    if (ruleEntity.id) {
      try {
        const apiUrl = `api/res-rules?ruleId.equals=${ruleEntity.id}`;
        const { data } = await axios.get(apiUrl);
        if (data.length === 0) {
          toast.error('No Resources available');
        } else {
          setResourcesData(data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <Row>
        <Col md="8">
          <h2 data-cy="ruleDetailsHeading">
            <Translate contentKey="alippoApp.rule.detail.title">Rule</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="id">
                <Translate contentKey="global.field.id">ID</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.id}</dd>
            <dt>
              <span id="name">
                <Translate contentKey="alippoApp.rule.name">Name</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.name}</dd>
            <dt>
              <span id="description">
                <Translate contentKey="alippoApp.rule.description">Description</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.description}</dd>
            <dt>
              <span id="ruleType">
                <Translate contentKey="alippoApp.rule.ruleType">Rule Type</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.ruleType}</dd>
            <dt>
              <span id="fromDateTime">
                <Translate contentKey="alippoApp.rule.fromDateTime">From Date Time</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.fromDateTime ? <TextFormat value={ruleEntity.fromDateTime} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
            <dt>
              <span id="duration">
                <Translate contentKey="alippoApp.rule.duration">Duration</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.duration}</dd>
            <dt>
              <span id="disabled">
                <Translate contentKey="alippoApp.rule.disabled">Disabled</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.disabled ? 'true' : 'false'}</dd>
            <dt>
              <span id="minCriteria">
                <Translate contentKey="alippoApp.rule.minCriteria">Min Criteria</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.minCriteria}</dd>
            <dt>
              <span id="amount">
                <Translate contentKey="alippoApp.rule.amount">Amount</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.amount}</dd>
            <dt>
              <span id="basis">
                <Translate contentKey="alippoApp.rule.basis">Basis</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.basis}</dd>
            <dt>
              <span id="maxAmount">
                <Translate contentKey="alippoApp.rule.maxAmount">Max Amount</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.maxAmount}</dd>
            <dt>
              <span id="sellingPrice">
                <Translate contentKey="alippoApp.rule.sellingPrice">Selling Price</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.sellingPrice}</dd>
            <dt>
              <span id="sellingPriceCurrencyCode">
                <Translate contentKey="alippoApp.rule.sellingPriceCurrencyCode">Selling Price Currency Code</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.sellingPriceCurrencyCode}</dd>
            <dt>
              <span id="mrpPrice">
                <Translate contentKey="alippoApp.rule.mrpPrice">Mrp Price</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.mrpPrice}</dd>
            <dt>
              <span id="mrpPriceCurrencyCode">
                <Translate contentKey="alippoApp.rule.mrpPriceCurrencyCode">Mrp Price Currency Code</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.mrpPriceCurrencyCode}</dd>
            <dt>
              <span id="ruleCurrency">
                <Translate contentKey="alippoApp.rule.ruleCurrency">Rule Currency</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.ruleCurrency}</dd>
            <dt>
              <span id="currencyCode">
                <Translate contentKey="alippoApp.rule.currencyCode">Currency Code</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.currencyCode}</dd>
            <dt>
              <span id="code">
                <Translate contentKey="alippoApp.rule.code">Code</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.code}</dd>
            <dt>
              <span id="usageLimit">
                <Translate contentKey="alippoApp.rule.usageLimit">Usage Limit</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.usageLimit}</dd>
            <dt>
              <span id="usagePerCustomer">
                <Translate contentKey="alippoApp.rule.usagePerCustomer">Usage Per Customer</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.usagePerCustomer}</dd>
            <dt>
              <span id="timesUsed">
                <Translate contentKey="alippoApp.rule.timesUsed">Times Used</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.timesUsed}</dd>
            <dt>
              <span id="sortOrder">
                <Translate contentKey="alippoApp.rule.sortOrder">Sort Order</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.sortOrder}</dd>
            <dt>
              <span id="createdAt">
                <Translate contentKey="alippoApp.rule.createdAt">Created At</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.createdAt ? <TextFormat value={ruleEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
            <dt>
              <span id="updatedAt">
                <Translate contentKey="alippoApp.rule.updatedAt">Updated At</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.updatedAt ? <TextFormat value={ruleEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
            <dt>
            <span id="expiryPeriodInSeconds">
              <Translate contentKey="alippoApp.rule.expiryPeriodInSeconds">Expiry Period In Seconds</Translate>
            </span>
          </dt>
          <dd>{ruleEntity.expiryPeriodInSeconds}</dd>
          <dt>
              <span id="config">
                <Translate contentKey="alippoApp.rule.config">Config</Translate>
              </span>
            </dt>
            <dd>{ruleEntity.config}</dd>
            <dt>
              <Translate contentKey="alippoApp.rule.voucher">Voucher</Translate>
            </dt>
            <dd>{ruleEntity.voucher ? ruleEntity.voucher.id : ''}</dd>
          </dl>
          <dl>
            <dt>Resources</dt>
            <Button onClick={getResRules} color="primary">
              <span>Get Resources</span>
            </Button>
            <dd>
              {resourcesData && resourcesData.length > 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="hand">ID</th>
                      <th className="hand">Name</th>
                      <th className="hand">Url</th>
                      <th className="hand">Res State</th>
                      <th className="hand">Res Type</th>
                      <th className="hand">Media Type</th>
                      <th className="hand">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resourcesData.map((resource, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable">
                        <td>
                          <a target="_blank" rel="noopener noreferrer" href={`/res-rule/${resource.id}`}>
                            {resource.id}
                          </a>
                        </td>
                        <td>{resource.name}</td>
                        <td>{resource.url}</td>
                        <td>{resource.resState}</td>
                        <td>{resource.resType}</td>
                        <td>{resource.mediaType}</td>
                        <td>{resource.description}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : null}
            </dd>
          </dl>
          <Button tag={Link} to="/rule" replace color="info" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button color="dark" onClick={handleModalOpen}>
            <FontAwesomeIcon icon={faClone} /> <span>Clone</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/rule/${ruleEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>

      {/* Rule Clone Modal */}
      <Modal isOpen={isModalOpen} toggle={handleModalClose}>
        <ModalHeader toggle={handleModalClose}>Confirm clone operation</ModalHeader>
        <ModalBody>Are you sure you want to clone this Rule {ruleEntity.id ?? ''}?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleModalClose}>
            <FontAwesomeIcon icon="ban" /> Cancel
          </Button>
          <Button color="success" onClick={handleConfirmClone}>
            <FontAwesomeIcon icon={faClone} /> <span>Clone</span>
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RuleDetail;
