import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './payment-state-transition.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const PaymentStateTransitionDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const paymentStateTransitionEntity = useAppSelector(state => state.paymentStateTransition.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="paymentStateTransitionDetailsHeading">
          <Translate contentKey="alippoApp.paymentStateTransition.detail.title">PaymentStateTransition</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{paymentStateTransitionEntity.id}</dd>
          <dt>
            <span id="oldStatus">
              <Translate contentKey="alippoApp.paymentStateTransition.oldStatus">Old Status</Translate>
            </span>
          </dt>
          <dd>{paymentStateTransitionEntity.oldStatus}</dd>
          <dt>
            <span id="newStatus">
              <Translate contentKey="alippoApp.paymentStateTransition.newStatus">New Status</Translate>
            </span>
          </dt>
          <dd>{paymentStateTransitionEntity.newStatus}</dd>
          <dt>
            <span id="comment">
              <Translate contentKey="alippoApp.paymentStateTransition.comment">Comment</Translate>
            </span>
          </dt>
          <dd>{paymentStateTransitionEntity.comment}</dd>
          <dt>
            <span id="transitionAt">
              <Translate contentKey="alippoApp.paymentStateTransition.transitionAt">Transition At</Translate>
            </span>
          </dt>
          <dd>
            {paymentStateTransitionEntity.transitionAt ? (
              <TextFormat value={paymentStateTransitionEntity.transitionAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.paymentStateTransition.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {paymentStateTransitionEntity.createdAt ? (
              <TextFormat value={paymentStateTransitionEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.paymentStateTransition.payment">Payment</Translate>
          </dt>
          <dd>{paymentStateTransitionEntity.payment ? paymentStateTransitionEntity.payment.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/payment-state-transition" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/payment-state-transition/${paymentStateTransitionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default PaymentStateTransitionDetail;
