import { toast } from 'react-toastify';

// Find whether the given object is empty or not
export const isEmptyObject = obj => Object.keys(obj).length === 0;

// Replace url text in a string with anchor element
export const urlify = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
};

// Copy to clipboard
export async function copyToClipboard(text: string) {
  // check if navigator api is supported
  if ('clipboard' in navigator) {
    try {
      await navigator.clipboard.writeText(text);
      toast.success('Copied to clipboard');
    } catch (err) {
      toast.error('Something went wrong');
    }
  }

  // if not supported, use text area trick fallback
  else {
    const textArea = document.createElement('textarea');
    textArea.setAttribute('value', text);

    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      const wasValid = document.execCommand('copy');

      if (wasValid) {
        toast.success('Copied to clipboard');
      }
      document.body.removeChild(textArea);
    } catch (err) {
      toast.error('Something went wrong');
    }
  }
}

// Find whether the given string is a valid url
export const isValidHttpUrl = (string: string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

// Nullify empty string
export const nullifyEmptyString = (value: string) => (value === '' ? null : value);
