import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ResCourse from './res-course';
import ResCourseDetail from './res-course-detail';
import ResCourseUpdate from './res-course-update';
import ResCourseDeleteDialog from './res-course-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ResCourseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ResCourseUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ResCourseDetail} />
      <ErrorBoundaryRoute path={match.url} component={ResCourse} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ResCourseDeleteDialog} />
  </>
);

export default Routes;
