import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { toast } from 'react-toastify';

import { getEntity } from './order-item.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IResOrderItem } from 'app/shared/model/res-order-item.model';

export const OrderItemDetail = (props: RouteComponentProps<{ id: string }>) => {
  const [orderItemResourceData, setOrderItemResourceData] = useState<IResOrderItem[]>(null);

  const dispatch = useAppDispatch();

  const getOrderItemResources = async () => {
    if (orderItemEntity.id) {
      try {
        const apiUrl = `api/res-order-items?orderItemId.equals=${orderItemEntity.id}`;
        const { data } = await axios.get<IResOrderItem[]>(apiUrl);
        if (data.length === 0) {
          toast.error('No resources available');
        } else {
          setOrderItemResourceData(data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const orderItemEntity = useAppSelector(state => state.orderItem.entity);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="orderItemDetailsHeading">
          <Translate contentKey="alippoApp.orderItem.detail.title">OrderItem</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{orderItemEntity.id}</dd>
          <dt>
            <span id="orderItemStatus">
              <Translate contentKey="alippoApp.orderItem.orderItemStatus">Order Item Status</Translate>
            </span>
          </dt>
          <dd>{orderItemEntity.orderItemStatus}</dd>
          <dt>
            <span id="sellingPriceAmount">
              <Translate contentKey="alippoApp.orderItem.sellingPriceAmount">Selling Price Amount</Translate>
            </span>
          </dt>
          <dd>{orderItemEntity.sellingPriceAmount}</dd>
          <dt>
            <span id="originalPriceAmount">
              <Translate contentKey="alippoApp.orderItem.originalPriceAmount">Original Price Amount</Translate>
            </span>
          </dt>
          <dd>{orderItemEntity.originalPriceAmount}</dd>
          <dt>
            <span id="sellingPriceCurrencyCode">
              <Translate contentKey="alippoApp.orderItem.sellingPriceCurrencyCode">Selling Price Currency Code</Translate>
            </span>
          </dt>
          <dd>{orderItemEntity.sellingPriceCurrencyCode}</dd>
          <dt>
            <span id="virtualMoneyRewardUsed">
              <Translate contentKey="alippoApp.orderItem.virtualMoneyRewardUsed">Virtual Money Reward Used</Translate>
            </span>
          </dt>
          <dd>{orderItemEntity.virtualMoneyRewardUsed}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.orderItem.description">Description</Translate>
            </span>
          </dt>
          <dd>{orderItemEntity.description}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.orderItem.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {orderItemEntity.createdAt ? <TextFormat value={orderItemEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.orderItem.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {orderItemEntity.updatedAt ? <TextFormat value={orderItemEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.orderItem.course">Course</Translate>
          </dt>
          <dd>{orderItemEntity.course ? orderItemEntity.course.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.orderItem.sku">Sku</Translate>
          </dt>
          <dd>{orderItemEntity.sku ? orderItemEntity.sku.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.orderItem.discussion">Discussion</Translate>
          </dt>
          <dd>{orderItemEntity.discussion ? orderItemEntity.discussion.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.orderItem.parent">Parent</Translate>
          </dt>
          <dd>{orderItemEntity.parent ? orderItemEntity.parent.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.orderItem.orderInfo">Order Info</Translate>
          </dt>
          <dd>{orderItemEntity.orderInfo ? orderItemEntity.orderInfo.id : ''}</dd>
        </dl>
        <dl>
          <dt>Order Item Resources</dt>
          <Button onClick={getOrderItemResources} className="mr-2" color="primary">
            <span>Get Order Item Resources</span>
          </Button>
          <Link to={{ pathname: '/res-order-item/new', state: { orderItemId: orderItemEntity.id } }}>
            <Button className="rounded-circle" color="success">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Link>
          <dd>
            {orderItemResourceData && orderItemResourceData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">Order Item Resource ID</th>
                    <th className="hand">Order Item Media Type</th>
                    <th className="hand">Order Item Resource Type</th>
                    <th className="hand">Order Item Resource URL</th>
                  </tr>
                </thead>
                <tbody>
                  {orderItemResourceData.map((resource, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>
                        <Link target="_blank" rel="noopener noreferrer" to={`/res-order-item/${resource.id}`}>
                          {resource.id}
                        </Link>
                      </td>
                      <td>{resource.mediaType}</td>
                      <td>{resource.resType}</td>
                      <td>{resource.url}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/order-item" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/order-item/${orderItemEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default OrderItemDetail;
