import dayjs from 'dayjs';
import { ISection } from 'app/shared/model/section.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface ISectionTranslation {
  id?: number;
  name?: string;
  description?: string | null;
  language?: Language;
  isDefault?: boolean;
  createdAt?: string;
  updatedAt?: string;
  section?: ISection | null;
}

export const defaultValue: Readonly<ISectionTranslation> = {
  isDefault: false,
};
