export enum SkuApplicationStatus {
  APPLICATION_RECEIVED = 'APPLICATION_RECEIVED',

  APPLICATION_PROCESSING = 'APPLICATION_PROCESSING',

  APPLICATION_ACCEPTED = 'APPLICATION_ACCEPTED',

  APPLICATION_REJECTED = 'APPLICATION_REJECTED',

  APPLICATION_CANCELLED = 'APPLICATION_CANCELLED',
}
