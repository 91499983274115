import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SkuApplicationStateTransition from './sku-application-state-transition';
import SkuApplicationStateTransitionDetail from './sku-application-state-transition-detail';
import SkuApplicationStateTransitionUpdate from './sku-application-state-transition-update';
import SkuApplicationStateTransitionDeleteDialog from './sku-application-state-transition-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SkuApplicationStateTransitionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SkuApplicationStateTransitionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SkuApplicationStateTransitionDetail} />
      <ErrorBoundaryRoute path={match.url} component={SkuApplicationStateTransition} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={SkuApplicationStateTransitionDeleteDialog} />
  </>
);

export default Routes;
