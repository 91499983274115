import dayjs from 'dayjs';
import { IResTask } from 'app/shared/model/res-task.model';
import { IStep } from 'app/shared/model/step.model';
import { IUser } from 'app/shared/model/user.model';
import { TaskStatus } from 'app/shared/model/enumerations/task-status.model';

export interface ITask {
  id?: number;
  taskId?: string;
  status?: TaskStatus;
  input?: string | null;
  additionalInput?: string | null;
  description?: string | null;
  createdAt?: string;
  updatedAt?: string;
  resources?: IResTask[] | null;
  steps?: IStep[] | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<ITask> = {};
