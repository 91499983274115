import dayjs from 'dayjs';
import { IRule } from 'app/shared/model/rule.model';
import { ResState } from 'app/shared/model/enumerations/res-state.model';
import { ResType } from 'app/shared/model/enumerations/res-type.model';
import { ResMediaType } from 'app/shared/model/enumerations/res-media-type.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface IResRule {
  id?: number;
  name?: string | null;
  url?: string | null;
  resState?: ResState | null;
  resType?: ResType | null;
  mediaType?: ResMediaType | null;
  description?: string | null;
  language?: Language | null;
  createdAt?: string;
  updatedAt?: string;
  rule?: IRule | null;
}

export const defaultValue: Readonly<IResRule> = {};
