import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ResUser from './res-user';
import ResUserDetail from './res-user-detail';
import ResUserUpdate from './res-user-update';
import ResUserDeleteDialog from './res-user-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ResUserUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ResUserUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ResUserDetail} />
      <ErrorBoundaryRoute path={match.url} component={ResUser} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ResUserDeleteDialog} />
  </>
);

export default Routes;
