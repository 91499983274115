export enum DiscussionSubType {
  DIG_COF_SMM = 'DIG_COF_SMM',

  DIG_COF_SMM_COMMUNITY = 'DIG_COF_SMM_COMMUNITY',

  DIG_COF_CONSULTATION = 'DIG_COF_CONSULTATION',

  COURSE_COMMUNITY = 'COURSE_COMMUNITY',

  MISCELLANEOUS = 'MISCELLANEOUS',

  CLASS_CURATED_CHAT = 'CLASS_CURATED_CHAT',
}
