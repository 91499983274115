import dayjs from 'dayjs';
import { IRes } from 'app/shared/model/res.model';
import { ICategory } from 'app/shared/model/category.model';
import { ResState } from 'app/shared/model/enumerations/res-state.model';
import { ResType } from 'app/shared/model/enumerations/res-type.model';
import { ResMediaType } from 'app/shared/model/enumerations/res-media-type.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface IResCategory {
  id?: number;
  name?: string | null;
  url?: string | null;
  actionUrl?: string | null;
  resState?: ResState | null;
  resType?: ResType | null;
  mediaType?: ResMediaType | null;
  description?: string | null;
  language?: Language | null;
  videoControlsConfig?: string | null;
  aspectRatio?: number | null;
  createdAt?: string;
  updatedAt?: string;
  res?: IRes | null;
  category?: ICategory | null;
}

export const defaultValue: Readonly<IResCategory> = {};
