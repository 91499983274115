import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getAddresses } from 'app/entities/address/address.reducer';
import { getEntity as getCart, getEntities as getCarts } from 'app/entities/cart/cart.reducer';
import { getEntities as getOrderInfos } from 'app/entities/order-info/order-info.reducer';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { PaymentGateway } from 'app/shared/model/enumerations/payment-gateway.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import axios from 'axios';
import { createEntity, getEntity, reset, updateEntity } from './order-info.reducer';

export const OrderInfoUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const orderInfoEntity = useAppSelector(state => state.orderInfo.entity);
  const loading = useAppSelector(state => state.orderInfo.loading);
  const updating = useAppSelector(state => state.orderInfo.updating);
  const updateSuccess = useAppSelector(state => state.orderInfo.updateSuccess);
  const paymentGatewayValues = Object.keys(PaymentGateway);

  const [addresses] = useState(null);

  const handleClose = () => {
    if (orderInfoEntity && orderInfoEntity.id) {
      props.history.push('/order-info/' + `${orderInfoEntity.id}`);
    } else {
      props.history.push('/order-info' + props.location.search);
    }
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      dispatch(getUsers({}));
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getAddresses({}));
    dispatch(getCarts({}));
    dispatch(getOrderInfos({}));
  }, []);

  const getUser = async login => {
    try {
      const apiUrl = `api/admin/users/${login}`;
      const res = await axios.get(apiUrl);
      const userData = res.data;
      return userData;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!isNew) {
      if (orderInfoEntity.user) {
        getUser(orderInfoEntity.user.login);
      }
      if (orderInfoEntity.cart) {
        dispatch(getCart(orderInfoEntity.cart.id));
      }
    }
  }, [orderInfoEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.orderDate = convertDateTimeToServer(values.orderDate);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);
    const entity = {
      ...orderInfoEntity,
      ...values,
      billingAddress: { id: values.billingAddress },
      deliveryAddress: addresses.find(it => it.id.toString() === values.deliveryAddress.toString()),
      cart: { id: values.cart },
      user: { id: values.user },
      parentOrderInfo: { id: values.parentOrderInfo },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          orderDate: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          pg: 'MANUAL',
          secondaryPg: 'MANUAL',
          ...orderInfoEntity,
          orderDate: convertDateTimeFromServer(orderInfoEntity.orderDate),
          createdAt: convertDateTimeFromServer(orderInfoEntity.createdAt),
          updatedAt: convertDateTimeFromServer(orderInfoEntity.updatedAt),
          billingAddress: orderInfoEntity?.billingAddress?.id,
          user: orderInfoEntity?.user?.id,
          cart: orderInfoEntity?.cart?.id,
          parentOrderInfo: orderInfoEntity?.parentOrderInfo?.id,
          deliveryAddress: orderInfoEntity?.deliveryAddress?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.orderInfo.home.createOrEditLabel" data-cy="OrderInfoCreateUpdateHeading">
            <Translate contentKey="alippoApp.orderInfo.home.createOrEditLabel">Create or edit a OrderInfo</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="order-info-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.orderInfo.orderId')}
                id="order-info-orderId"
                name="orderId"
                data-cy="orderId"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.orderInfo.orderDate')}
                id="order-info-orderDate"
                name="orderDate"
                data-cy="orderDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField label={translate('alippoApp.orderInfo.pg')} id="order-info-pg" name="pg" data-cy="pg" type="select">
                {paymentGatewayValues.map(paymentGateway => (
                  <option value={paymentGateway} key={paymentGateway}>
                    {translate('alippoApp.PaymentGateway' + paymentGateway)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.orderInfo.secondaryPg')}
                id="order-info-secondaryPg"
                name="secondaryPg"
                data-cy="secondaryPg"
                type="select"
              >
                {paymentGatewayValues.map(paymentGateway => (
                  <option value={paymentGateway} key={paymentGateway}>
                    {translate('alippoApp.PaymentGateway' + paymentGateway)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.orderInfo.pgOrderId')}
                id="order-info-pgOrderId"
                name="pgOrderId"
                data-cy="pgOrderId"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.orderInfo.pgResponse')}
                id="order-info-pgResponse"
                name="pgResponse"
                data-cy="pgResponse"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.orderInfo.ipInfo')}
                id="order-info-ipInfo"
                name="ipInfo"
                data-cy="ipInfo"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.orderInfo.platform')}
                id="order-info-platform"
                name="platform"
                data-cy="platform"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.orderInfo.platformVersion')}
                id="order-info-platformVersion"
                name="platformVersion"
                data-cy="platformVersion"
                type="text"
              />
              <ValidatedField label={translate('alippoApp.orderInfo.utm')} id="order-info-utm" name="utm" data-cy="utm" type="textarea" />
              <ValidatedField
                label={translate('alippoApp.orderInfo.description')}
                id="order-info-description"
                name="description"
                data-cy="description"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.orderInfo.deliveryFee')}
                id="order-info-deliveryFee"
                name="deliveryFee"
                data-cy="deliveryFee"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.orderInfo.createdAt')}
                id="order-info-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.orderInfo.updatedAt')}
                id="order-info-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="order-info-billingAddress"
                name="billingAddress"
                data-cy="billingAddress"
                label={translate('alippoApp.orderInfo.billingAddress')}
              />
              <ValidatedField
                id="order-info-user"
                name="user"
                data-cy="user"
                label={translate('alippoApp.orderInfo.user')}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField id="order-info-cart" name="cart" data-cy="cart" label={translate('alippoApp.orderInfo.cart')} />
              <ValidatedField
                id="order-info-parentOrderInfo"
                name="parentOrderInfo"
                data-cy="parentOrderInfo"
                label={translate('alippoApp.orderInfo.parentOrderInfo')}
              />
              <ValidatedField
                id="order-info-deliveryAddress"
                name="deliveryAddress"
                data-cy="deliveryAddress"
                label={translate('alippoApp.orderInfo.deliveryAddress')}
                type="select"
              >
                <option value="" key="0" />
                {addresses
                  ? addresses.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/order-info" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OrderInfoUpdate;
