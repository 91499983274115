import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './res-course-section-lesson.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ResCourseSectionLessonDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const { match } = props;

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const handleDeleteResource = () => {
    props.history.replace(`${match.url}/delete`);
  };

  const resCourseSectionLessonEntity = useAppSelector(state => state.resCourseSectionLesson.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="resCourseSectionLessonDetailsHeading">
          <Translate contentKey="alippoApp.resCourseSectionLesson.detail.title">ResCourseSectionLesson</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionLessonEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.resCourseSectionLesson.name">Name</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionLessonEntity.name}</dd>
          <dt>
            <span id="url">
              <Translate contentKey="alippoApp.resCourseSectionLesson.url">Url</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionLessonEntity.url}</dd>
          <dt>
            <span id="resState">
              <Translate contentKey="alippoApp.resCourseSectionLesson.resState">Res State</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionLessonEntity.resState}</dd>
          <dt>
            <span id="resType">
              <Translate contentKey="alippoApp.resCourseSectionLesson.resType">Res Type</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionLessonEntity.resType}</dd>
          <dt>
            <span id="resSource">
              <Translate contentKey="alippoApp.resCourseSectionLesson.resSource">Res Source</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionLessonEntity.resSource}</dd>
          <dt>
            <span id="mediaType">
              <Translate contentKey="alippoApp.resCourseSectionLesson.mediaType">Media Type</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionLessonEntity.mediaType}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.resCourseSectionLesson.description">Description</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionLessonEntity.description}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="alippoApp.resCourseSectionLesson.language">Language</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionLessonEntity.language}</dd>
          <dt>
            <span id="videoControlsConfig">
              <Translate contentKey="alippoApp.resCourseSectionLesson.videoControlsConfig">Video Controls Config</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionLessonEntity.videoControlsConfig}</dd>
          <dt>
            <span id="aspectRatio">
              <Translate contentKey="alippoApp.resCourseSectionLesson.aspectRatio">Aspect Ratio</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionLessonEntity.aspectRatio}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.resCourseSectionLesson.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {resCourseSectionLessonEntity.createdAt ? (
              <TextFormat value={resCourseSectionLessonEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.resCourseSectionLesson.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {resCourseSectionLessonEntity.updatedAt ? (
              <TextFormat value={resCourseSectionLessonEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.resCourseSectionLesson.res">Res</Translate>
          </dt>
          <dd>{resCourseSectionLessonEntity.res ? resCourseSectionLessonEntity.res.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.resCourseSectionLesson.courseSectionLesson">Course Section Lesson</Translate>
          </dt>
          <dd>{resCourseSectionLessonEntity.courseSectionLesson ? resCourseSectionLessonEntity.courseSectionLesson.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/res-course-section-lesson" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/res-course-section-lesson/${resCourseSectionLessonEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
        &nbsp;
        <Button onClick={handleDeleteResource} color="danger" data-cy="entityDeleteButton">
          <FontAwesomeIcon icon="trash" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.delete">Delete</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ResCourseSectionLessonDetail;
