import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IResReview } from 'app/shared/model/res-review.model';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Row, Table } from 'reactstrap';
import { getEntity } from './review.reducer';

export const ReviewDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [reviewResourceData, setReviewResourceData] = useState<IResReview[]>(null);

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const getReviewResources = async () => {
    if (reviewEntity.id) {
      try {
        const apiUrl = `api/res-reviews?reviewId.equals=${reviewEntity.id}`;
        const res: any = await axios.get(apiUrl);
        const resources: IResReview[] = res.data;
        if (resources.length === 0) {
          toast.error('No resources available');
        } else {
          setReviewResourceData(resources);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const reviewEntity = useAppSelector(state => state.review.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="reviewDetailsHeading">
          <Translate contentKey="alippoApp.review.detail.title">Review</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{reviewEntity.id}</dd>
          <dt>
            <span id="entityType">
              <Translate contentKey="alippoApp.review.entityType">Entity Type</Translate>
            </span>
          </dt>
          <dd>{reviewEntity.entityType}</dd>
          <dt>
            <span id="entityId">
              <Translate contentKey="alippoApp.review.entityId">Entity Id</Translate>
            </span>
          </dt>
          <dd>{reviewEntity.entityId}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="alippoApp.review.type">Type</Translate>
            </span>
          </dt>
          <dd>{reviewEntity.type}</dd>
          <dt>
            <span id="subType">
              <Translate contentKey="alippoApp.review.subType">Sub Type</Translate>
            </span>
          </dt>
          <dd>{reviewEntity.subType}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="alippoApp.review.status">Status</Translate>
            </span>
          </dt>
          <dd>{reviewEntity.status}</dd>
          <dt>
            <span id="displayName">
              <Translate contentKey="alippoApp.review.displayName">Display Name</Translate>
            </span>
          </dt>
          <dd>{reviewEntity.displayName}</dd>
          <dt>
            <span id="sortOrder">
              <Translate contentKey="alippoApp.review.sortOrder">Sort Order</Translate>
            </span>
          </dt>
          <dd>{reviewEntity.sortOrder}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.review.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{reviewEntity.createdAt ? <TextFormat value={reviewEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.review.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{reviewEntity.updatedAt ? <TextFormat value={reviewEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="alippoApp.review.rateOption">Rate Option</Translate>
          </dt>
          <dd>{reviewEntity.rateOption ? reviewEntity.rateOption.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.review.user">User</Translate>
          </dt>
          <dd>{reviewEntity.user ? reviewEntity.user.id : ''}</dd>
        </dl>
        <dl>
          <dt>Review Resources</dt>
          <Button onClick={getReviewResources} className="mr-2" color="primary">
            <span>Get Review Resources</span>
          </Button>
          <Link to={{ pathname: '/res-review/new', state: { review: reviewEntity } }}>
            <Button className="rounded-circle" color="success">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Link>
          <dd>
            {reviewResourceData && reviewResourceData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">Review Resource ID</th>
                    <th className="hand">Review Media Type</th>
                    <th className="hand">Review Resource Type</th>
                    <th className="hand">Review Resource Link</th>
                    <th className="hand">Review Resource URL</th>
                  </tr>
                </thead>
                <tbody>
                  {reviewResourceData.map((resource, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{resource.id}</td>
                      <td>{resource.mediaType}</td>
                      <td>{resource.resType}</td>
                      <td>
                        <a target="_blank" rel="noopener noreferrer" href={resource.url}>
                          {resource.url}
                        </a>
                      </td>
                      <td>
                        <Link target="_blank" rel="noopener noreferrer" to={`/res-review/${resource.id}`}>
                          Link
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/review" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/review/${reviewEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ReviewDetail;
