import dayjs from 'dayjs';
import { IRes } from 'app/shared/model/res.model';
import { IStep } from 'app/shared/model/step.model';
import { ResState } from 'app/shared/model/enumerations/res-state.model';
import { ResType } from 'app/shared/model/enumerations/res-type.model';

export interface IResStep {
  id?: number;
  resState?: ResState | null;
  resType?: ResType | null;
  additionalOutput?: string | null;
  visible?: boolean | null;
  description?: string | null;
  createdAt?: string;
  updatedAt?: string;
  res?: IRes[] | null;
  step?: IStep | null;
}

export const defaultValue: Readonly<IResStep> = {
  visible: false,
};
