import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale from './locale';
import authentication from './authentication';
import applicationProfile from './application-profile';

import administration from 'app/modules/administration/administration.reducer';
import userManagement from 'app/modules/administration/user-management/user-management.reducer';
import register from 'app/modules/account/register/register.reducer';
import activate from 'app/modules/account/activate/activate.reducer';
import password from 'app/modules/account/password/password.reducer';
import settings from 'app/modules/account/settings/settings.reducer';
import passwordReset from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import category from 'app/entities/category/category.reducer';
// prettier-ignore
import categoryTranslation from 'app/entities/category-translation/category-translation.reducer';
// prettier-ignore
import course from 'app/entities/course/course.reducer';
// prettier-ignore
import courseTranslation from 'app/entities/course-translation/course-translation.reducer';
// prettier-ignore
import courseDiscussion from 'app/entities/course-discussion/course-discussion.reducer';
// prettier-ignore
import section from 'app/entities/section/section.reducer';
// prettier-ignore
import sectionTranslation from 'app/entities/section-translation/section-translation.reducer';
// prettier-ignore
import lesson from 'app/entities/lesson/lesson.reducer';
// prettier-ignore
import lessonTranslation from 'app/entities/lesson-translation/lesson-translation.reducer';
// prettier-ignore
import tip from 'app/entities/tip/tip.reducer';
// prettier-ignore
import tipTranslation from 'app/entities/tip-translation/tip-translation.reducer';
// prettier-ignore
import courseSection from 'app/entities/course-section/course-section.reducer';
// prettier-ignore
import courseSectionLesson from 'app/entities/course-section-lesson/course-section-lesson.reducer';
// prettier-ignore
import courseSectionTip from 'app/entities/course-section-tip/course-section-tip.reducer';
// prettier-ignore
import userCourseSectionLesson from 'app/entities/user-course-section-lesson/user-course-section-lesson.reducer';
// prettier-ignore
import cart from 'app/entities/cart/cart.reducer';
// prettier-ignore
import cartItem from 'app/entities/cart-item/cart-item.reducer';
// prettier-ignore
import orderInfo from 'app/entities/order-info/order-info.reducer';
// prettier-ignore
import orderItem from 'app/entities/order-item/order-item.reducer';
// prettier-ignore
import address from 'app/entities/address/address.reducer';
// prettier-ignore
import userExtra from 'app/entities/user-extra/user-extra.reducer';
// prettier-ignore
import device from 'app/entities/device/device.reducer';
// prettier-ignore
import feedback from 'app/entities/feedback/feedback.reducer';
// prettier-ignore
import suggestion from 'app/entities/suggestion/suggestion.reducer';
// prettier-ignore
import banner from 'app/entities/banner/banner.reducer';
// prettier-ignore
import resCourse from 'app/entities/res-course/res-course.reducer';
// prettier-ignore
import resCourseSection from 'app/entities/res-course-section/res-course-section.reducer';
// prettier-ignore
import resCourseSectionLesson from 'app/entities/res-course-section-lesson/res-course-section-lesson.reducer';
// prettier-ignore
import resCourseSectionTip from 'app/entities/res-course-section-tip/res-course-section-tip.reducer';
// prettier-ignore
import resOrderItem from 'app/entities/res-order-item/res-order-item.reducer';
// prettier-ignore
import resUserCourseSectionLesson from 'app/entities/res-user-course-section-lesson/res-user-course-section-lesson.reducer';
// prettier-ignore
import bizCard from 'app/entities/biz-card/biz-card.reducer';
// prettier-ignore
import resBizCard from 'app/entities/res-biz-card/res-biz-card.reducer';
// prettier-ignore
import resRule from 'app/entities/res-rule/res-rule.reducer';
// prettier-ignore
import rule from 'app/entities/rule/rule.reducer';
// prettier-ignore
import ruleTranslation from 'app/entities/rule-translation/rule-translation.reducer';
// prettier-ignore
import ruleLedger from 'app/entities/rule-ledger/rule-ledger.reducer';
// prettier-ignore
import slug from 'app/entities/slug/slug.reducer';
// prettier-ignore
import seoMetadata from 'app/entities/seo-metadata/seo-metadata.reducer';
// prettier-ignore
import beneficiaryInfo from 'app/entities/beneficiary-info/beneficiary-info.reducer';
// prettier-ignore
import payment from 'app/entities/payment/payment.reducer';
// prettier-ignore
import paymentStateTransition from 'app/entities/payment-state-transition/payment-state-transition.reducer';
// prettier-ignore
import paymentSource from 'app/entities/payment-source/payment-source.reducer';
// prettier-ignore
import review from 'app/entities/review/review.reducer';
// prettier-ignore
import resReview from 'app/entities/res-review/res-review.reducer';
// prettier-ignore
import ratingOption from 'app/entities/rating-option/rating-option.reducer';
// prettier-ignore
import resCategory from 'app/entities/res-category/res-category.reducer';
// prettier-ignore
import sku from 'app/entities/sku/sku.reducer';
// prettier-ignore
import plan from 'app/entities/plan/plan.reducer';
// prettier-ignore
import planTranslation from 'app/entities/plan-translation/plan-translation.reducer';
// prettier-ignore
import planItem from 'app/entities/plan-item/plan-item.reducer';
// prettier-ignore
import product from 'app/entities/product/product.reducer';
// prettier-ignore
import productTranslation from 'app/entities/product-translation/product-translation.reducer';
// prettier-ignore
import inventory from 'app/entities/inventory/inventory.reducer';
// prettier-ignore
import orderItemPlan from 'app/entities/order-item-plan/order-item-plan.reducer';
// prettier-ignore
import location from 'app/entities/location/location.reducer';
// prettier-ignore
import tenant from 'app/entities/tenant/tenant.reducer';
// prettier-ignore
import tenantExtra from 'app/entities/tenant-extra/tenant-extra.reducer';
// prettier-ignore
import resPlan from 'app/entities/res-plan/res-plan.reducer';
// prettier-ignore
import resProduct from 'app/entities/res-product/res-product.reducer';
// prettier-ignore
import skuAttribute from 'app/entities/sku-attribute/sku-attribute.reducer';
// prettier-ignore
import skuAttributeVariant from 'app/entities/sku-attribute-variant/sku-attribute-variant.reducer';
// prettier-ignore
import voucher from 'app/entities/voucher/voucher.reducer';
// prettier-ignore
import voucherTranslation from 'app/entities/voucher-translation/voucher-translation.reducer';
// prettier-ignore
import config from 'app/entities/config/config.reducer';
// prettier-ignore
import userTopic from 'app/entities/user-topic/user-topic.reducer';
// prettier-ignore
import skuReservation from 'app/entities/sku-reservation/sku-reservation.reducer';
// prettier-ignore
import resDiscussion from 'app/entities/res-discussion/res-discussion.reducer';
// prettier-ignore
import discussionMember from 'app/entities/discussion-member/discussion-member.reducer';
// prettier-ignore
import userSegment from 'app/entities/user-segment/user-segment.reducer';
// prettier-ignore
import skuSegment from 'app/entities/sku-segment/sku-segment.reducer';
import migratePhoneNumber from 'app/modules/customer-support/migrate-phone-number.reducer';
// prettier-ignore
import whatsappLog from 'app/entities/whatsapp-log/whatsapp-log.reducer';
// prettier-ignore
import form from 'app/entities/form/form.reducer';
// prettier-ignore
import skuApplication from 'app/entities/sku-application/sku-application.reducer';
// prettier-ignore
import skuApplicationStateTransition from 'app/entities/sku-application-state-transition/sku-application-state-transition.reducer';
// prettier-ignore
import messageTemplate from 'app/entities/message-template/message-template.reducer';
// prettier-ignore
import resUser from 'app/entities/res-user/res-user.reducer';
// prettier-ignore
import res from 'app/entities/res/res.reducer';
// prettier-ignore
import task from 'app/entities/task/task.reducer';
// prettier-ignore
import step from 'app/entities/step/step.reducer';
// prettier-ignore
import resTask from 'app/entities/res-task/res-task.reducer';
// prettier-ignore
import resStep from 'app/entities/res-step/res-step.reducer';
// prettier-ignore
import cluster from 'app/entities/cluster/cluster.reducer';
// prettier-ignore
import courseCluster from 'app/entities/course-cluster/course-cluster.reducer';
// prettier-ignore
import orderItemSkuAttribute from 'app/entities/order-item-sku-attribute/order-item-sku-attribute.reducer';
// prettier-ignore
import cartItemSkuAttribute from 'app/entities/cart-item-sku-attribute/cart-item-sku-attribute.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const rootReducer = {
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  category,
  categoryTranslation,
  course,
  courseTranslation,
  courseDiscussion,
  section,
  sectionTranslation,
  lesson,
  lessonTranslation,
  tip,
  tipTranslation,
  courseSection,
  courseSectionLesson,
  courseSectionTip,
  userCourseSectionLesson,
  cart,
  cartItem,
  orderInfo,
  orderItem,
  address,
  userExtra,
  device,
  feedback,
  suggestion,
  banner,
  resCourse,
  resCourseSection,
  resCourseSectionLesson,
  resCourseSectionTip,
  resOrderItem,
  resUserCourseSectionLesson,
  bizCard,
  resBizCard,
  resRule,
  rule,
  ruleTranslation,
  ruleLedger,
  slug,
  seoMetadata,
  beneficiaryInfo,
  payment,
  paymentStateTransition,
  paymentSource,
  review,
  resReview,
  ratingOption,
  resCategory,
  sku,
  plan,
  planTranslation,
  planItem,
  product,
  productTranslation,
  inventory,
  orderItemPlan,
  location,
  tenant,
  tenantExtra,
  resPlan,
  resProduct,
  skuAttribute,
  skuAttributeVariant,
  voucher,
  voucherTranslation,
  config,
  userTopic,
  skuReservation,
  resDiscussion,
  discussionMember,
  userSegment,
  skuSegment,
  whatsappLog,
  form,
  skuApplication,
  skuApplicationStateTransition,
  messageTemplate,
  resUser,
  res,
  task,
  step,
  resTask,
  resStep,
  cluster,
  courseCluster,
  orderItemSkuAttribute,
  cartItemSkuAttribute,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
  migratePhoneNumber,
};

export default rootReducer;
