import dayjs from 'dayjs';
import { IResBizCard } from 'app/shared/model/res-biz-card.model';
import { IUser } from 'app/shared/model/user.model';

export interface IBizCard {
  id?: number;
  name?: string | null;
  designation?: string | null;
  location?: string | null;
  contact?: string | null;
  createdAt?: string;
  updatedAt?: string;
  resources?: IResBizCard[] | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IBizCard> = {};
