import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './section.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { toast } from 'react-toastify';

export const SectionDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [sectionTranslationData, setSectionTranslationData] = useState(null);
  const { match } = props;
  const handleDeleteResource = () => {
    props.history.replace(`${match.url}/delete`);
  };

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const sectionEntity = useAppSelector(state => state.section.entity);

  const getSectionTranslations = async () => {
    if (sectionEntity.id) {
      try {
        const apiUrl = `api/section-translations?sectionId.equals=${sectionEntity.id}`;
        const res: any = await axios.get(apiUrl);
        const translations = res.data;
        if (translations.length === 0) {
          toast.error('No translations available');
        } else {
          setSectionTranslationData(
            translations.map(e => {
              return {
                id: e.id,
                name: e.name,
                language: e.language,
                isDefault: e.isDefault,
                url: `/section-translation/${e.id}`,
              };
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const createSectionTranslation = () => {
    history.push({
      pathname: '/section-translation/new',
      state: {
        section: sectionEntity,
      },
    });
  };

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="sectionDetailsHeading">
          <Translate contentKey="alippoApp.section.detail.title">Section</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.section.name">Name</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.section.description">Description</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.description}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.section.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.createdAt ? <TextFormat value={sectionEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.section.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{sectionEntity.updatedAt ? <TextFormat value={sectionEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <dl>
          <dt>Section Translations</dt>
          <Button onClick={getSectionTranslations} className="mr-2" color="primary">
            <span>Get Section Translations</span>
          </Button>
          <Button onClick={createSectionTranslation} className="rounded-circle" color="success">
            <FontAwesomeIcon icon="plus" />
          </Button>
          <dd>
            {sectionTranslationData && sectionTranslationData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">Section Translation ID</th>
                    <th className="hand">Section Translation Name</th>
                    <th className="hand">Default?</th>
                    <th className="hand">Section Translation Language</th>
                    <th className="hand">Section Translation URL</th>
                  </tr>
                </thead>
                <tbody>
                  {sectionTranslationData.map((translation, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{translation.id}</td>
                      <td>{translation.name}</td>
                      <td>{translation.isDefault ? 'Yes' : 'No'}</td>
                      <td>{translation.language}</td>
                      <td>
                        <Link target="_blank" rel="noopener noreferrer" to={translation.url}>
                          Link
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/section" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/section/${sectionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
        &nbsp;
        <Button onClick={handleDeleteResource} color="danger" data-cy="entityDeleteButton">
          <FontAwesomeIcon icon="trash" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.delete">Delete</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SectionDetail;
