import React, {useEffect, useState} from 'react';
import {Link, RouteComponentProps, useHistory} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {isNumber, Translate, translate, ValidatedField, ValidatedForm} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {getEntities as getCategories} from 'app/entities/category/category.reducer';
import {getEntity, reset} from './category.reducer';
import {convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime} from 'app/shared/util/date-utils';
import {useAppDispatch, useAppSelector} from 'app/config/store';
import axios from 'axios';
import {toast} from 'react-toastify';

export const CategoryUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const categories = useAppSelector(state => state.category.entities);
  const categoryEntity = useAppSelector(state => state.category.entity);
  const loading = useAppSelector(state => state.category.loading);
  const updating = useAppSelector(state => state.category.updating);
  const updateSuccess = useAppSelector(state => state.category.updateSuccess);
  const handleClose = () => {
    props.history.push('/category' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getCategories({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = async values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...categoryEntity,
      ...values,
      parentCategory: categories.find(it => it.id.toString() === values.parentCategory.toString()),
      updateSlugs: values.updateSlugs || false,
    };

    if (isNew) {
      try {
        await axios.post('api/categories', entity);
        toast.success('Category created successfully!');
      } catch (err) {
        console.error(err);
        toast.error('Failed to create category');
      }
    } else {
      try {
        await axios.put(`api/categories/${entity.id}`, entity);
        toast.success('Category updated successfully!');
      } catch (err) {
        console.error(err);
        toast.error('Failed to update category');
      }
    }

    history.replace('/category');
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          ...categoryEntity,
          createdAt: convertDateTimeFromServer(categoryEntity.createdAt),
          updatedAt: convertDateTimeFromServer(categoryEntity.updatedAt),
          parentCategory: categoryEntity?.parentCategory?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.category.home.createOrEditLabel" data-cy="CategoryCreateUpdateHeading">
            <Translate contentKey="alippoApp.category.home.createOrEditLabel">Create or edit a Category</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="category-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.category.name')}
                id="category-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.category.description')}
                id="category-description"
                name="description"
                data-cy="description"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.category.sortOrder')}
                id="category-sortOrder"
                name="sortOrder"
                data-cy="sortOrder"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alippoApp.category.createdAt')}
                id="category-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.category.updatedAt')}
                id="category-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.category.properties')}
                id="category-properties"
                name="properties"
                data-cy="properties"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.category.isDiscoverable')}
                id="category-isDiscoverable"
                name="isDiscoverable"
                data-cy="isDiscoverable"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.category.isTopic')}
                id="category-isTopic"
                name="isTopic"
                data-cy="isTopic"
                check
                type="checkbox"
              />
              <ValidatedField label="updateSlug" id="category-updateSlug" name="updateSlug" data-cy="updateSlug" check type="checkbox" />
              <ValidatedField
                id="category-parentCategory"
                name="parentCategory"
                data-cy="parentCategory"
                label={translate('alippoApp.category.parentCategory')}
                type="select"
              >
                <option value="" key="0" />
                {categories
                  ? categories.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="update-slugs" name="updateSlugs" data-cy="updateSlugs" label="Update slugs" type="checkbox" check />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/category" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CategoryUpdate;
