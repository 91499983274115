import dayjs from 'dayjs';
import { ISku } from 'app/shared/model/sku.model';
import { IPlan } from 'app/shared/model/plan.model';

export interface IPlanItem {
  id?: number;
  quantity?: number;
  createdAt?: string;
  updatedAt?: string;
  sku?: ISku | null;
  plan?: IPlan | null;
}

export const defaultValue: Readonly<IPlanItem> = {};
