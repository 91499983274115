import dayjs from 'dayjs';
import { IResCourseSectionTip } from 'app/shared/model/res-course-section-tip.model';
import { ICourseSection } from 'app/shared/model/course-section.model';
import { ITip } from 'app/shared/model/tip.model';

export interface ICourseSectionTip {
  id?: number;
  sortOrder?: number;
  createdAt?: string;
  updatedAt?: string;
  resources?: IResCourseSectionTip[] | null;
  courseSection?: ICourseSection | null;
  tip?: ITip | null;
}

export const defaultValue: Readonly<ICourseSectionTip> = {};
