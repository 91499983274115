import dayjs from 'dayjs';
import { IRes } from 'app/shared/model/res.model';
import { ITask } from 'app/shared/model/task.model';
import { ResState } from 'app/shared/model/enumerations/res-state.model';
import { ResType } from 'app/shared/model/enumerations/res-type.model';

export interface IResTask {
  id?: number;
  resState?: ResState | null;
  resType?: ResType | null;
  description?: string | null;
  createdAt?: string;
  updatedAt?: string;
  res?: IRes[] | null;
  task?: ITask | null;
}

export const defaultValue: Readonly<IResTask> = {};
