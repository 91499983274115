import React, { createContext, useState } from 'react';

export const RedirectUrlContext = createContext({
  redirectPath: null,
  setRedirectPath(newPath: string) {},
});

export const RedirectUrlProvider = ({ children }) => {
  const [path, setPath] = useState(null);

  const setRedirectPathHandler = newPath => {
    setPath(newPath);
  };

  const redirectUrlContext = {
    redirectPath: path,
    setRedirectPath: setRedirectPathHandler,
  };

  return <RedirectUrlContext.Provider value={redirectUrlContext}>{children}</RedirectUrlContext.Provider>;
};
