import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IPlan } from 'app/shared/model/plan.model';
import { getEntities as getPlans } from 'app/entities/plan/plan.reducer';
import { getEntity, updateEntity, createEntity, reset } from './res-plan.reducer';
import { IResPlan } from 'app/shared/model/res-plan.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ResState } from 'app/shared/model/enumerations/res-state.model';
import { ResType } from 'app/shared/model/enumerations/res-type.model';
import { ResMediaType } from 'app/shared/model/enumerations/res-media-type.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export const ResPlanUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const plans = useAppSelector(state => state.plan.entities);
  const resPlanEntity = useAppSelector(state => state.resPlan.entity);
  const loading = useAppSelector(state => state.resPlan.loading);
  const updating = useAppSelector(state => state.resPlan.updating);
  const updateSuccess = useAppSelector(state => state.resPlan.updateSuccess);
  const resStateValues = Object.keys(ResState);
  const resTypeValues = Object.keys(ResType);
  const resMediaTypeValues = Object.keys(ResMediaType);
  const languageValues = Object.keys(Language);
  const handleClose = () => {
    props.history.push('/res-plan' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getPlans({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...resPlanEntity,
      ...values,
      plan: { id: values.plan },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          resState: 'AVAILABLE',
          resType: 'EBOOK',
          mediaType: 'IMAGE',
          language: 'HINGLISH',
          ...resPlanEntity,
          createdAt: convertDateTimeFromServer(resPlanEntity.createdAt),
          updatedAt: convertDateTimeFromServer(resPlanEntity.updatedAt),
          plan: resPlanEntity?.plan?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.resPlan.home.createOrEditLabel" data-cy="ResPlanCreateUpdateHeading">
            <Translate contentKey="alippoApp.resPlan.home.createOrEditLabel">Create or edit a ResPlan</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="res-plan-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('alippoApp.resPlan.name')} id="res-plan-name" name="name" data-cy="name" type="text" />
              <ValidatedField label={translate('alippoApp.resPlan.url')} id="res-plan-url" name="url" data-cy="url" type="text" />
              <ValidatedField
                label={translate('alippoApp.resPlan.resState')}
                id="res-plan-resState"
                name="resState"
                data-cy="resState"
                type="select"
              >
                {resStateValues.map(resState => (
                  <option value={resState} key={resState}>
                    {translate('alippoApp.ResState' + resState)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resPlan.resType')}
                id="res-plan-resType"
                name="resType"
                data-cy="resType"
                type="select"
              >
                {resTypeValues.map(resType => (
                  <option value={resType} key={resType}>
                    {translate('alippoApp.ResType' + resType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resPlan.mediaType')}
                id="res-plan-mediaType"
                name="mediaType"
                data-cy="mediaType"
                type="select"
              >
                {resMediaTypeValues.map(resMediaType => (
                  <option value={resMediaType} key={resMediaType}>
                    {translate('alippoApp.ResMediaType' + resMediaType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resPlan.description')}
                id="res-plan-description"
                name="description"
                data-cy="description"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.resPlan.language')}
                id="res-plan-language"
                name="language"
                data-cy="language"
                type="select"
              >
                {languageValues.map(language => (
                  <option value={language} key={language}>
                    {translate('alippoApp.Language' + language)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resPlan.createdAt')}
                id="res-plan-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.resPlan.updatedAt')}
                id="res-plan-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField id="res-plan-plan" name="plan" data-cy="plan" label={translate('alippoApp.resPlan.plan')} />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/res-plan" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ResPlanUpdate;
