export enum BannerType {
  SIMPLE = 'SIMPLE',

  COURSE = 'COURSE',

  CATEGORY = 'CATEGORY',

  COURSE_COLLECTION = 'COURSE_COLLECTION',

  HTML = 'HTML',

  DEEPLINK = 'DEEPLINK',
}
