import dayjs from 'dayjs';
import { ICourse } from 'app/shared/model/course.model';
import { ICluster } from 'app/shared/model/cluster.model';

export interface ICourseCluster {
  id?: number;
  sortOrder?: number;
  createdAt?: string;
  updatedAt?: string;
  course?: ICourse | null;
  cluster?: ICluster | null;
}

export const defaultValue: Readonly<ICourseCluster> = {};
