import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CourseCluster from './course-cluster';
import CourseClusterDetail from './course-cluster-detail';
import CourseClusterUpdate from './course-cluster-update';
import CourseClusterDeleteDialog from './course-cluster-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CourseClusterUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CourseClusterUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CourseClusterDetail} />
      <ErrorBoundaryRoute path={match.url} component={CourseCluster} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={CourseClusterDeleteDialog} />
  </>
);

export default Routes;
