import dayjs from 'dayjs';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface IRuleTranslation {
  id?: number;
  name?: string;
  description?: string | null;
  language?: Language;
  isDefault?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export const defaultValue: Readonly<IRuleTranslation> = {
  isDefault: false,
};
