import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ReviewEntityType } from 'app/shared/model/enumerations/review-entity-type.model';
import {
  getAllParamsFromUrlSearchParams,
  overrideFiltersStateWithQueryParams,
  overridePaginationStateWithQueryParams,
} from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { getSortState, JhiItemCount, JhiPagination, TextFormat, Translate } from 'react-jhipster';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { getEntities } from './review.reducer';

export const Review = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );

  const [loadEntities, setLoadEntities] = useState(false);

  const reviewList = useAppSelector(state => state.review.entities);
  const loading = useAppSelector(state => state.review.loading);
  const totalItems = useAppSelector(state => state.review.totalItems);
  const reviewEntityTypeValues = Object.keys(ReviewEntityType);

  const [reviewFilter, setReviewFilter] = useState(
    overrideFiltersStateWithQueryParams(
      {
        status: 'APPROVED',
        entityType: 'COURSE',
        entityId: undefined,
        type: 'GENERIC',
        subType: 'STAR_RATING',
      },
      props.location.search
    )
  );

  const getAllEntities = (newSearchParameter: string) => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        props: newSearchParameter,
      })
    );
  };

  const sortEntities = () => {
    const newSearchParams = buildNewSearchParams();
    const endUrl = buildEndUrl(newSearchParams);
    if (window.location.search !== endUrl) {
      window.history.replaceState(null, '', `${props.location.pathname}${endUrl}`);
      getAllEntities(newSearchParams);
    }
  };

  useEffect(() => {
    if (loadEntities === true) {
      sortEntities();
      setLoadEntities(false);
    }
  }, [loadEntities]);

  const buildNewSearchParams = () => {
    let newSearchParameter = '';
    Object.keys(reviewFilter).forEach(key => {
      if (key !== 'page' && key !== 'sort' && reviewFilter[key]) {
        newSearchParameter += `&${key}.equals=${reviewFilter[key]}`;
      }
    });
    return newSearchParameter;
  };

  const buildEndUrl = (searchParams: string) => {
    return `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}${searchParams}`;
  };

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get(SORT);

    const paramsJson = getAllParamsFromUrlSearchParams(params);
    unstable_batchedUpdates(() => {
      setReviewFilter({
        status: 'APPROVED',
        entityType: 'COURSE',
        entityId: undefined,
        type: 'GENERIC',
        subType: 'STAR_RATING',
        ...paramsJson,
      });
      if (page && sort) {
        const sortSplit = sort.split(',');
        setPaginationState({
          ...paginationState,
          activePage: +page,
          sort: sortSplit[0],
          order: sortSplit[1],
        });
      }
      setLoadEntities(true);
    });
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
    setLoadEntities(true);
  };

  const handlePagination = currentPage => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
    setLoadEntities(true);
  };

  const handleSyncList = () => {
    getAllEntities(buildNewSearchParams());
  };

  const handleFilterChange = e => {
    setReviewFilter({
      ...reviewFilter,
      [e.target.id]: e.target.value,
    });
    setLoadEntities(true);
  };

  const { match } = props;

  return (
    <div>
      <h2 id="review-heading" data-cy="ReviewHeading">
        <Translate contentKey="alippoApp.review.home.title">Reviews</Translate>
        <div className="d-flex justify-content-end">
          <Button className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="alippoApp.review.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="alippoApp.review.home.createLabel">Create new Review</Translate>
          </Link>
        </div>
      </h2>
      <Form>
        <Row>
          <Col sm={12} md={4}>
            <FormGroup>
              <Label for="status">Status</Label>
              <Input id="status" name="select" type="select" onChange={handleFilterChange} value={reviewFilter['status']}>
                <option value="PENDING">Pending</option>
                <option value="APPROVED">Approved</option>
                <option value="REVOKED">Revoked</option>
              </Input>
            </FormGroup>
          </Col>
          <Col sm={12} md={4}>
            <FormGroup>
              <Label for="entityType">Entity Type</Label>
              <Input id="entityType" name="select" type="select" onChange={handleFilterChange} value={reviewFilter['entityType']}>
                {reviewEntityTypeValues.map(reviewEntityType => (
                  <option value={reviewEntityType} key={reviewEntityType}>
                    {reviewEntityType}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col sm={12} md={4}>
            <FormGroup>
              <Label for="entityId">Entity Id</Label>
              <Input
                id="entityId"
                name="entity-id"
                placeholder="Enter entity id"
                type="number"
                onChange={handleFilterChange}
                value={reviewFilter['entityId']}
              />
            </FormGroup>
          </Col>
          <Col sm={12} md={4}>
            <FormGroup>
              <Label for="type">Review Type</Label>
              <Input id="type" name="select" type="select" onChange={handleFilterChange} value={reviewFilter['type']}>
                <option value="GENERIC">Generic</option>
                <option value="TESTIMONIAL">Testimonial</option>
              </Input>
            </FormGroup>
          </Col>
          <Col sm={12} md={4}>
            <FormGroup>
              <Label for="subType">Review Sub Type</Label>
              <Input id="subType" name="select" type="select" onChange={handleFilterChange} value={reviewFilter['subType']}>
                <option value="STAR_RATING">Star Rating</option>
                <option value="CUSTOMER_WORK">Customer Work</option>
                <option value="WHATSAPP_FEEDBACK">Whatsapp Feedback</option>
                <option value="ZOOM_SESSION">Zoom Session</option>
                <option value="INSTRUCTOR">Instructor</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </Form>
      <div className="table-responsive">
        {reviewList && reviewList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="alippoApp.review.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('entityType')}>
                  <Translate contentKey="alippoApp.review.entityType">Entity Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('entityId')}>
                  <Translate contentKey="alippoApp.review.entityId">Entity Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('type')}>
                  <Translate contentKey="alippoApp.review.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('subType')}>
                  <Translate contentKey="alippoApp.review.subType">Sub Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('status')}>
                  <Translate contentKey="alippoApp.review.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('displayName')}>
                  <Translate contentKey="alippoApp.review.displayName">Display Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('sortOrder')}>
                  <Translate contentKey="alippoApp.review.sortOrder">Sort Order</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="alippoApp.review.createdAt">Created At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updatedAt')}>
                  <Translate contentKey="alippoApp.review.updatedAt">Updated At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.review.rateOption">Rate Option</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.review.user">User</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {reviewList.map((review, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`${match.url}/${review.id}`} color="link" size="sm">
                      {review.id}
                    </Button>
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.ReviewEntityType.${review.entityType}`} />
                  </td>
                  <td>{review.entityId}</td>
                  <td>
                    <Translate contentKey={`alippoApp.ReviewType.${review.type}`} />
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.ReviewSubType.${review.subType}`} />
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.ReviewStatus.${review.status}`} />
                  </td>
                  <td>{review.displayName}</td>
                  <td>{review.sortOrder}</td>
                  <td>{review.createdAt ? <TextFormat type="date" value={review.createdAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{review.updatedAt ? <TextFormat type="date" value={review.updatedAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{review.rateOption ? <Link to={`rating-option/${review.rateOption.id}`}>{review.rateOption.id}</Link> : ''}</td>
                  <td>{review.user ? review.user.id : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${review.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${review.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${review.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="alippoApp.review.home.notFound">No Reviews found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={reviewList && reviewList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Review;
