import dayjs from 'dayjs';
import { IResDiscussion } from 'app/shared/model/res-discussion.model';
import { ICourse } from 'app/shared/model/course.model';
import { ICategory } from 'app/shared/model/category.model';
import { DiscussionType } from 'app/shared/model/enumerations/discussion-type.model';
import { DiscussionSubType } from 'app/shared/model/enumerations/discussion-sub-type.model';

export interface ICourseDiscussion {
  id?: number;
  discussionLink?: string | null;
  discussionWebLink?: string | null;
  joined?: number | null;
  disabled?: boolean | null;
  discussionType?: DiscussionType | null;
  createdAt?: string;
  updatedAt?: string;
  configPayload?: string | null;
  discussionSubType?: DiscussionSubType | null;
  vacant?: boolean | null;
  resources?: IResDiscussion[] | null;
  course?: ICourse | null;
  category?: ICategory | null;
  courseV2s?: ICourse[] | null;
}

export const defaultValue: Readonly<ICourseDiscussion> = {
  disabled: false,
  vacant: false,
};
