import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { ISku } from 'app/shared/model/sku.model';
import { getEntities as getSkus } from 'app/entities/sku/sku.reducer';
import { getEntity, updateEntity, createEntity, reset } from './sku-reservation.reducer';
import { ISkuReservation } from 'app/shared/model/sku-reservation.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ReservationStatus } from 'app/shared/model/enumerations/reservation-status.model';

export const SkuReservationUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const users = useAppSelector(state => state.userManagement.users);
  const skus = useAppSelector(state => state.sku.entities);
  const skuReservationEntity = useAppSelector(state => state.skuReservation.entity);
  const loading = useAppSelector(state => state.skuReservation.loading);
  const updating = useAppSelector(state => state.skuReservation.updating);
  const updateSuccess = useAppSelector(state => state.skuReservation.updateSuccess);
  const reservationStatusValues = Object.keys(ReservationStatus);
  const handleClose = () => {
    props.history.push('/sku-reservation' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getUsers({}));
    dispatch(getSkus({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.reservedAt = convertDateTimeToServer(values.reservedAt);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...skuReservationEntity,
      ...values,
      user: { id: values.user },
      sku: { id: values.sku },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          reservedAt: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          reservationStatus: 'RESERVED',
          ...skuReservationEntity,
          reservedAt: convertDateTimeFromServer(skuReservationEntity.reservedAt),
          createdAt: convertDateTimeFromServer(skuReservationEntity.createdAt),
          updatedAt: convertDateTimeFromServer(skuReservationEntity.updatedAt),
          user: skuReservationEntity?.user?.id,
          sku: skuReservationEntity?.sku?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.skuReservation.home.createOrEditLabel" data-cy="SkuReservationCreateUpdateHeading">
            <Translate contentKey="alippoApp.skuReservation.home.createOrEditLabel">Create or edit a SkuReservation</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="sku-reservation-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.skuReservation.reservationStatus')}
                id="sku-reservation-reservationStatus"
                name="reservationStatus"
                data-cy="reservationStatus"
                type="select"
              >
                {reservationStatusValues.map(reservationStatus => (
                  <option value={reservationStatus} key={reservationStatus}>
                    {translate('alippoApp.ReservationStatus' + reservationStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.skuReservation.reservedAt')}
                id="sku-reservation-reservedAt"
                name="reservedAt"
                data-cy="reservedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('alippoApp.skuReservation.createdAt')}
                id="sku-reservation-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.skuReservation.updatedAt')}
                id="sku-reservation-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField id="sku-reservation-user" name="user" data-cy="user" label={translate('alippoApp.skuReservation.user')} />
              <ValidatedField id="sku-reservation-sku" name="sku" data-cy="sku" label={translate('alippoApp.skuReservation.sku')} />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/sku-reservation" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SkuReservationUpdate;
