import dayjs from 'dayjs';
import { ISku } from 'app/shared/model/sku.model';
import { IResPlan } from 'app/shared/model/res-plan.model';
import { IPlanItem } from 'app/shared/model/plan-item.model';
import { IPlanTranslation } from 'app/shared/model/plan-translation.model';
import { PlanStatus } from 'app/shared/model/enumerations/plan-status.model';

export interface IPlan {
  id?: number;
  name?: string;
  planStatus?: PlanStatus;
  sortOrder?: number;
  createdAt?: string;
  updatedAt?: string;
  sku?: ISku | null;
  resources?: IResPlan[] | null;
  planItems?: IPlanItem[] | null;
  translations?: IPlanTranslation[] | null;
}

export const defaultValue: Readonly<IPlan> = {};
