import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Table } from 'reactstrap';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { SkuApplicationStatus } from 'app/shared/model/enumerations/sku-application-status.model';
import { ISkuApplicationStateTransition } from 'app/shared/model/sku-application-state-transition.model';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getEntity } from './sku-application.reducer';

export const SkuApplicationDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const [currentStatus, setCurrentStatus] = useState<SkuApplicationStatus>(null);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);
  const [stateTransitionsData, setStateTransitionsData] = useState<ISkuApplicationStateTransition[]>(null);

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const skuApplicationEntity = useAppSelector(state => state.skuApplication.entity);

  useEffect(() => {
    if (skuApplicationEntity && skuApplicationEntity.id) {
      const getSkuApplicationStateTransitions = async () => {
        const { data: skuApplicationStateTransitionsList }: { data: ISkuApplicationStateTransition[] } = await axios.get(
          `api/sku-application-state-transitions?skuApplicationId.equals=${skuApplicationEntity.id}&page=0&size=40`
        );

        if (skuApplicationStateTransitionsList.length > 0) {
          skuApplicationStateTransitionsList.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

          setCurrentStatus(skuApplicationStateTransitionsList.pop()?.newStatus ?? null);
        }
      };

      getSkuApplicationStateTransitions();
    }
  }, [skuApplicationEntity, showUpdateStatusModal]);

  const handleCloseModal = () => {
    setShowUpdateStatusModal(prev => !prev);
  };

  const handleUpdateStatusSubmit = async (values: { newStatus: string; comment: string }) => {
    const payload = {
      skuApplicationId: skuApplicationEntity.id,
      newStatus: values.newStatus,
      comment: values.comment,
    };

    try {
      const { data } = await axios.post('api/sku-application-status/update', payload);

      toast.success('Status updated successfully');
      setShowUpdateStatusModal(false);

      if (stateTransitionsData) {
        getTransitions();
      }
    } catch (e) {
      toast.error(e.response?.data?.detail);
      console.error(e);
    }
  };

  const getTransitions = async () => {
    if (skuApplicationEntity.id) {
      try {
        const apiUrl = `api/sku-application-state-transitions?skuApplicationId.equals=${skuApplicationEntity.id}&page=0&size=40`;
        const { data } = await axios.get<ISkuApplicationStateTransition[]>(apiUrl);
        if (data.length === 0) {
          toast.error('No resources available');
        } else {
          setStateTransitionsData(data);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <>
      <Row>
        <Col md="8">
          <h2 data-cy="skuApplicationDetailsHeading">
            <Translate contentKey="alippoApp.skuApplication.detail.title">SkuApplication</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="id">
                <Translate contentKey="global.field.id">ID</Translate>
              </span>
            </dt>
            <dd>{skuApplicationEntity.id}</dd>
            <dt>
              <span id="formData">
                <Translate contentKey="alippoApp.skuApplication.formData">Form Data</Translate>
              </span>
            </dt>
            <dd>{skuApplicationEntity.formData}</dd>
            <dt>
              <span id="createdAt">
                <Translate contentKey="alippoApp.skuApplication.createdAt">Created At</Translate>
              </span>
            </dt>
            <dd>
              {skuApplicationEntity.createdAt ? (
                <TextFormat value={skuApplicationEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <span id="updatedAt">
                <Translate contentKey="alippoApp.skuApplication.updatedAt">Updated At</Translate>
              </span>
            </dt>
            <dd>
              {skuApplicationEntity.updatedAt ? (
                <TextFormat value={skuApplicationEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <Translate contentKey="alippoApp.skuApplication.sku">Sku</Translate>
            </dt>
            <dd>{skuApplicationEntity.sku ? skuApplicationEntity.sku.id : ''}</dd>
            <dt>
              <Translate contentKey="alippoApp.skuApplication.user">User</Translate>
            </dt>
            <dd>{skuApplicationEntity.user ? skuApplicationEntity.user.id : ''}</dd>
            <dt>
              <Translate contentKey="alippoApp.skuApplication.form">Form</Translate>
            </dt>
            <dd>{skuApplicationEntity.form ? skuApplicationEntity.form.id : ''}</dd>
            <dt>
              <span>Current Status</span>
            </dt>
            <dd>{currentStatus}</dd>
            {currentStatus && (
              <Button color="dark" onClick={() => setShowUpdateStatusModal(true)}>
                Update status
              </Button>
            )}
          </dl>
          <dl>
            <dt>
              <span>Transitions</span>
            </dt>
            <Button type="button" onClick={getTransitions} color="primary">
              Get Transitions
            </Button>
            <dd>
              {stateTransitionsData && stateTransitionsData.length > 0 ? (
                <Table className="mt-3" responsive bordered>
                  <thead>
                    <tr>
                      <th className="hand">ID</th>
                      <th className="hand">Old Status</th>
                      <th className="hand">New Status</th>
                      <th className="hand">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stateTransitionsData.map((resource, i) => (
                      <tr key={`entity-${i}`} data-cy="entityTable">
                        <td>
                          <Link target="_blank" rel="noopener noreferrer" to={`/sku-application-state-transition/${resource.id}`}>
                            {resource.id}
                          </Link>
                        </td>
                        <td>{resource.oldStatus}</td>
                        <td>{resource.newStatus}</td>
                        <td>{resource.createdAt}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : null}
            </dd>
          </dl>
          <Button tag={Link} to="/sku-application" replace color="info" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/sku-application/${skuApplicationEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        </Col>
      </Row>

      {/* Update current status modal */}
      <Modal isOpen={showUpdateStatusModal} toggle={handleCloseModal}>
        <ModalHeader>Update status</ModalHeader>
        <ModalBody>
          <ValidatedForm onSubmit={handleUpdateStatusSubmit}>
            <div className="mb-3">
              <div className="mb-2">Current status</div>
              <div className="border p-2 rounded">{currentStatus}</div>
            </div>
            <ValidatedField label="New status" id="new-status" name="newStatus" data-cy="newStatus" type="select">
              {Object.keys(SkuApplicationStatus).map(skuApplicationStatus => (
                <option value={skuApplicationStatus} key={skuApplicationStatus}>
                  {skuApplicationStatus}
                </option>
              ))}
            </ValidatedField>
            <ValidatedField label="Comment" id="comment" name="comment" data-cy="comment" type="textarea" />
            <div className="d-flex w-100 justify-content-end">
              <Button className="mr-2" color="secondary" onClick={handleCloseModal}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <Button color="success" type="submit">
                Submit
              </Button>
            </div>
          </ValidatedForm>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SkuApplicationDetail;
