import dayjs from 'dayjs';
import { ITipTranslation } from 'app/shared/model/tip-translation.model';

export interface ITip {
  id?: number;
  name?: string | null;
  description?: string | null;
  createdAt?: string;
  updatedAt?: string;
  translations?: ITipTranslation[] | null;
}

export const defaultValue: Readonly<ITip> = {};
