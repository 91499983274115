import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './course-translation.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CourseTranslationDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const courseTranslationEntity = useAppSelector(state => state.courseTranslation.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="courseTranslationDetailsHeading">
          <Translate contentKey="alippoApp.courseTranslation.detail.title">CourseTranslation</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{courseTranslationEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.courseTranslation.name">Name</Translate>
            </span>
          </dt>
          <dd>{courseTranslationEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.courseTranslation.description">Description</Translate>
            </span>
          </dt>
          <dd>{courseTranslationEntity.description}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="alippoApp.courseTranslation.language">Language</Translate>
            </span>
          </dt>
          <dd>{courseTranslationEntity.language}</dd>
          <dt>
            <span id="isDefault">
              <Translate contentKey="alippoApp.courseTranslation.isDefault">Is Default</Translate>
            </span>
          </dt>
          <dd>{courseTranslationEntity.isDefault ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.courseTranslation.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {courseTranslationEntity.createdAt ? (
              <TextFormat value={courseTranslationEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.courseTranslation.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {courseTranslationEntity.updatedAt ? (
              <TextFormat value={courseTranslationEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.courseTranslation.course">Course</Translate>
          </dt>
          <dd>{courseTranslationEntity.course ? courseTranslationEntity.course.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/course-translation" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/course-translation/${courseTranslationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CourseTranslationDetail;
