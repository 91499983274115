import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import UserSegment from './user-segment';
import UserSegmentDetail from './user-segment-detail';
import UserSegmentUpdate from './user-segment-update';
import UserSegmentDeleteDialog from './user-segment-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UserSegmentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UserSegmentUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UserSegmentDetail} />
      <ErrorBoundaryRoute path={match.url} component={UserSegment} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={UserSegmentDeleteDialog} />
  </>
);

export default Routes;
