import React, { useState, useEffect, useMemo } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';

import { getEntities } from './user-extra.reducer';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overrideFiltersStateWithQueryParams, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

type UserExtrasFilterType = {
  certName: string | null;
  userId: string | null;
};

export const UserExtra = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );
  const [userExtrasFilter, setUserExtrasFilter] = useState<UserExtrasFilterType>(
    overrideFiltersStateWithQueryParams({ certName: null, userId: null } as UserExtrasFilterType, props.location.search)
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userExtraList = useAppSelector(state => state.userExtra.entities);
  const loading = useAppSelector(state => state.userExtra.loading);
  const totalItems = useAppSelector(state => state.userExtra.totalItems);

  const getAllEntities = () => {
    setIsLoading(true);

    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        props: buildSearchParamsString(),
      })
    ).then(() => setIsLoading(false));

    const params = buildSearchParamsString();

    const endUrl = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}${params}`;

    if (window.location.search !== endUrl) {
      window.history.replaceState(null, '', `${props.location.pathname}${endUrl}`);
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState, userExtrasFilter]);

  const buildSearchParamsString = () => {
    let paramsString = '';

    if (userExtrasFilter.certName) {
      paramsString += `&certName.contains=${userExtrasFilter.certName}`;
    }

    if (userExtrasFilter.userId) {
      paramsString += `&userId.equals=${userExtrasFilter.userId}`;
    }

    return paramsString;
  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    getAllEntities();
  };

  const handleFilterChange = e => {
    setUserExtrasFilter(prev => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const debouncedHandleFilterChange = useMemo(() => debounce(handleFilterChange, 500), []);

  const { match } = props;

  return (
    <div>
      <h2 id="user-extra-heading" data-cy="UserExtraHeading">
        <Translate contentKey="alippoApp.userExtra.home.title">User Extras</Translate>
      </h2>
      <div className="d-flex justify-content-end">
        <Button className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="alippoApp.userExtra.home.refreshListLabel">Refresh List</Translate>
        </Button>
        <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="alippoApp.userExtra.home.createLabel">Create new User Extra</Translate>
        </Link>
      </div>
      <div className="my-4">
        <Form>
          <Row md={3}>
            <Col>
              <FormGroup>
                <Label for="name">Cert Name</Label>
                <Input
                  id="certName"
                  name="certName"
                  placeholder="Enter cert name"
                  type="text"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={userExtrasFilter.certName}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="name">User ID</Label>
                <Input
                  id="userId"
                  name="userId"
                  placeholder="Enter User ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={userExtrasFilter.userId}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <h6 className="text-center mt-2" style={isLoading ? { opacity: 1 } : { opacity: 0 }}>
          Loading ...
        </h6>
      </div>
      <div className="table-responsive">
        {userExtraList && userExtraList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="alippoApp.userExtra.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('otp')}>
                  <Translate contentKey="alippoApp.userExtra.otp">Otp</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('otpTs')}>
                  <Translate contentKey="alippoApp.userExtra.otpTs">Otp Ts</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('verifiedTs')}>
                  <Translate contentKey="alippoApp.userExtra.verifiedTs">Verified Ts</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('certName')}>
                  <Translate contentKey="alippoApp.userExtra.certName">Cert Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('certNameTs')}>
                  <Translate contentKey="alippoApp.userExtra.certNameTs">Cert Name Ts</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('imageUrl')}>
                  <Translate contentKey="alippoApp.userExtra.imageUrl">Image Url</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('ipInfo')}>
                  <Translate contentKey="alippoApp.userExtra.ipInfo">Ip Info</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referrerCode')}>
                  <Translate contentKey="alippoApp.userExtra.referrerCode">Referrer Code</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('properties')}>
                  <Translate contentKey="alippoApp.userExtra.properties">Properties</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  <Translate contentKey="alippoApp.userExtra.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('interest')}>
                  <Translate contentKey="alippoApp.userExtra.interest">Interest</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('phone')}>
                  <Translate contentKey="alippoApp.userExtra.phone">Phone</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('firstAcquisitionInfo')}>
                  <Translate contentKey="alippoApp.userExtra.firstAcquisitionInfo">First Acquisition Info</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="alippoApp.userExtra.createdAt">Created At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updatedAt')}>
                  <Translate contentKey="alippoApp.userExtra.updatedAt">Updated At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.userExtra.user">User</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {userExtraList.map((userExtra, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`${match.url}/${userExtra.id}`} color="link" size="sm">
                      {userExtra.id}
                    </Button>
                  </td>
                  <td>{userExtra.otp}</td>
                  <td>{userExtra.otpTs ? <TextFormat type="date" value={userExtra.otpTs} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{userExtra.verifiedTs ? <TextFormat type="date" value={userExtra.verifiedTs} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{userExtra.certName}</td>
                  <td>{userExtra.certNameTs ? <TextFormat type="date" value={userExtra.certNameTs} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{userExtra.imageUrl}</td>
                  <td>{userExtra.ipInfo}</td>
                  <td>{userExtra.referrerCode}</td>
                  <td>{userExtra.properties}</td>
                  <td>{userExtra.description}</td>
                  <td>{userExtra.interest}</td>
                  <td>{userExtra.phone}</td>
                  <td>{userExtra.firstAcquisitionInfo}</td>
                  <td>{userExtra.createdAt ? <TextFormat type="date" value={userExtra.createdAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{userExtra.updatedAt ? <TextFormat type="date" value={userExtra.updatedAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{userExtra.user ? userExtra.user.id : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${userExtra.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${userExtra.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${userExtra.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="alippoApp.userExtra.home.notFound">No User Extras found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={userExtraList && userExtraList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default UserExtra;
