export enum ResMediaType {
  IMAGE = 'IMAGE',

  VIDEO = 'VIDEO',

  PDF = 'PDF',

  JSON = 'JSON',

  TEXT = 'TEXT',

  SRT = 'SRT',

  XLSX = 'XLSX',

  PPT = 'PPT',

  VIDEO_CONFERENCE = 'VIDEO_CONFERENCE',

  SUBTITLES = 'SUBTITLES',

  CHAT = 'CHAT',

  TRANSCRIPT = 'TRANSCRIPT',
}
