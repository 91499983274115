import dayjs from 'dayjs';
import { ICategory } from 'app/shared/model/category.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface ICategoryTranslation {
  id?: number;
  name?: string;
  description?: string | null;
  language?: Language;
  isDefault?: boolean;
  createdAt?: string;
  updatedAt?: string;
  category?: ICategory | null;
}

export const defaultValue: Readonly<ICategoryTranslation> = {
  isDefault: false,
};
