export enum OrderItemStatus {
  PENDING = 'PENDING',

  INITIATED = 'INITIATED',

  PAID = 'PAID',

  FAILED = 'FAILED',

  REVOKED = 'REVOKED',

  WAITLISTED = 'WAITLISTED',

  CANCELLED = 'CANCELLED',
}
