import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ISku } from 'app/shared/model/sku.model';
import { getEntities as getSkus } from 'app/entities/sku/sku.reducer';
import { getEntity, updateEntity, createEntity, reset } from './sku-attribute.reducer';
import { ISkuAttribute } from 'app/shared/model/sku-attribute.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { SkuAttributeType } from 'app/shared/model/enumerations/sku-attribute-type.model';
import { InputSelectionType } from 'app/shared/model/enumerations/input-selection-type.model';
import { UnitType } from 'app/shared/model/enumerations/unit-type.model';
import { UnitValue } from 'app/shared/model/enumerations/unit-value.model';

export const SkuAttributeUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locationState: any = location.state;

  let inheritedSku = null;
  if (locationState) {
    inheritedSku = locationState.sku;
  }

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const skus = useAppSelector(state => state.sku.entities);
  const skuAttributeEntity = useAppSelector(state => state.skuAttribute.entity);
  const loading = useAppSelector(state => state.skuAttribute.loading);
  const updating = useAppSelector(state => state.skuAttribute.updating);
  const updateSuccess = useAppSelector(state => state.skuAttribute.updateSuccess);
  const skuAttributeTypeValues = Object.keys(SkuAttributeType);
  const inputSelectionTypeValues = Object.keys(InputSelectionType);
  const unitTypeValues = Object.keys(UnitType);
  const unitValueValues = Object.keys(UnitValue);
  const handleClose = () => {
    if (skuAttributeEntity && skuAttributeEntity.id) {
      dispatch(reset());
      props.history.push('/sku/' + `${skuAttributeEntity.sku.id}`);
    } else {
      props.history.push('/sku-attribute' + props.location.search);
    }
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getSkus({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...skuAttributeEntity,
      ...values,
      sku: { id: values.sku },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
          sku: inheritedSku ? inheritedSku.id : null,
        }
      : {
          type: 'DENOMINATION',
          inputSelectionType: 'FREE_TEXT',
          unitType: 'WEIGHT',
          unitValue: 'GRAM',
          ...skuAttributeEntity,
          createdAt: convertDateTimeFromServer(skuAttributeEntity.createdAt),
          updatedAt: convertDateTimeFromServer(skuAttributeEntity.updatedAt),
          sku: skuAttributeEntity?.sku?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.skuAttribute.home.createOrEditLabel" data-cy="SkuAttributeCreateUpdateHeading">
            <Translate contentKey="alippoApp.skuAttribute.home.createOrEditLabel">Create or edit a SkuAttribute</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="sku-attribute-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.skuAttribute.active')}
                id="sku-attribute-active"
                name="active"
                data-cy="active"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.skuAttribute.name')}
                id="sku-attribute-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.skuAttribute.description')}
                id="sku-attribute-description"
                name="description"
                data-cy="description"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.skuAttribute.type')}
                id="sku-attribute-type"
                name="type"
                data-cy="type"
                type="select"
              >
                {skuAttributeTypeValues.map(skuAttributeType => (
                  <option value={skuAttributeType} key={skuAttributeType}>
                    {translate('alippoApp.SkuAttributeType' + skuAttributeType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.skuAttribute.inputSelectionType')}
                id="sku-attribute-inputSelectionType"
                name="inputSelectionType"
                data-cy="inputSelectionType"
                type="select"
              >
                {inputSelectionTypeValues.map(inputSelectionType => (
                  <option value={inputSelectionType} key={inputSelectionType}>
                    {translate('alippoApp.InputSelectionType' + inputSelectionType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.skuAttribute.unitType')}
                id="sku-attribute-unitType"
                name="unitType"
                data-cy="unitType"
                type="select"
              >
                {unitTypeValues.map(unitType => (
                  <option value={unitType} key={unitType}>
                    {translate('alippoApp.UnitType' + unitType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.skuAttribute.unitValue')}
                id="sku-attribute-unitValue"
                name="unitValue"
                data-cy="unitValue"
                type="select"
              >
                {unitValueValues.map(unitValue => (
                  <option value={unitValue} key={unitValue}>
                    {translate('alippoApp.UnitValue' + unitValue)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.skuAttribute.config')}
                id="sku-attribute-config"
                name="config"
                data-cy="config"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.skuAttribute.sortOrder')}
                id="sku-attribute-sortOrder"
                name="sortOrder"
                data-cy="sortOrder"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alippoApp.skuAttribute.createdAt')}
                id="sku-attribute-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.skuAttribute.updatedAt')}
                id="sku-attribute-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="sku-attribute-sku"
                name="sku"
                data-cy="sku"
                label={translate('alippoApp.skuAttribute.sku')}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              ></ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/sku-attribute" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SkuAttributeUpdate;
