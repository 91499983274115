export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  ANONYMOUS: 'ROLE_ANONYMOUS',
  INSTRUCTOR: 'ROLE_INSTRUCTOR',
  INFLUENCER: 'ROLE_INFLUENCER',
  SALES: 'ROLE_SALES',
  EXPERIENCE: 'ROLE_EXPERIENCE',
  CLASS_ADMIN: 'ROLE_CLASS_ADMIN',
  ANCHOR: 'ROLE_ANCHOR',
  OPERATION: 'ROLE_OPERATION',
  CATEGORY_SPECIALIST: 'ROLE_CATEGORY_SPECIALIST',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const APP_DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm:ss A';
