export enum ResMimeType {
  IMAGE_GIF = 'IMAGE_GIF',

  IMAGE_JPEG = 'IMAGE_JPEG',

  IMAGE_PNG = 'IMAGE_PNG',

  VIDEO_MP4 = 'VIDEO_MP4',

  APPLICATION_PDF = 'APPLICATION_PDF',

  TEXT_PLAIN = 'TEXT_PLAIN',

  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET',

  APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION',
}
