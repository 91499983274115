import { ConfigType } from 'app/shared/model/enumerations/config-type.model';

export interface IConfig {
  id?: number;
  configType?: ConfigType;
  configKey?: string | null;
  value?: string | null;
}

export const defaultValue: Readonly<IConfig> = {};
