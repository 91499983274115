import dayjs from 'dayjs';
import { ISkuApplication } from 'app/shared/model/sku-application.model';
import { SkuApplicationStatus } from 'app/shared/model/enumerations/sku-application-status.model';

export interface ISkuApplicationStateTransition {
  id?: number;
  oldStatus?: SkuApplicationStatus;
  newStatus?: SkuApplicationStatus;
  comment?: string | null;
  createdAt?: string;
  skuApplication?: ISkuApplication | null;
}

export const defaultValue: Readonly<ISkuApplicationStateTransition> = {};
