import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './location.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const LocationDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const locationEntity = useAppSelector(state => state.location.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="locationDetailsHeading">
          <Translate contentKey="alippoApp.location.detail.title">Location</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{locationEntity.id}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="alippoApp.location.code">Code</Translate>
            </span>
          </dt>
          <dd>{locationEntity.code}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.location.name">Name</Translate>
            </span>
          </dt>
          <dd>{locationEntity.name}</dd>
          <dt>
            <span id="level">
              <Translate contentKey="alippoApp.location.level">Level</Translate>
            </span>
          </dt>
          <dd>{locationEntity.level}</dd>
          <dt>
            <span id="area">
              <Translate contentKey="alippoApp.location.area">Area</Translate>
            </span>
          </dt>
          <dd>{locationEntity.area}</dd>
          <dt>
            <span id="addressLine1">
              <Translate contentKey="alippoApp.location.addressLine1">Address Line 1</Translate>
            </span>
          </dt>
          <dd>{locationEntity.addressLine1}</dd>
          <dt>
            <span id="postalCode">
              <Translate contentKey="alippoApp.location.postalCode">Postal Code</Translate>
            </span>
          </dt>
          <dd>{locationEntity.postalCode}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.location.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{locationEntity.createdAt ? <TextFormat value={locationEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.location.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{locationEntity.updatedAt ? <TextFormat value={locationEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="alippoApp.location.parent">Parent</Translate>
          </dt>
          <dd>{locationEntity.parent ? locationEntity.parent.code : ''}</dd>
        </dl>
        <Button tag={Link} to="/location" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/location/${locationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default LocationDetail;
