import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './res-course-section.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ResCourseSectionDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const resCourseSectionEntity = useAppSelector(state => state.resCourseSection.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="resCourseSectionDetailsHeading">
          <Translate contentKey="alippoApp.resCourseSection.detail.title">ResCourseSection</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.resCourseSection.name">Name</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionEntity.name}</dd>
          <dt>
            <span id="url">
              <Translate contentKey="alippoApp.resCourseSection.url">Url</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionEntity.url}</dd>
          <dt>
            <span id="resState">
              <Translate contentKey="alippoApp.resCourseSection.resState">Res State</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionEntity.resState}</dd>
          <dt>
            <span id="resType">
              <Translate contentKey="alippoApp.resCourseSection.resType">Res Type</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionEntity.resType}</dd>
          <dt>
            <span id="mediaType">
              <Translate contentKey="alippoApp.resCourseSection.mediaType">Media Type</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionEntity.mediaType}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.resCourseSection.description">Description</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionEntity.description}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="alippoApp.resCourseSection.language">Language</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionEntity.language}</dd>
          <dt>
            <span id="videoControlsConfig">
              <Translate contentKey="alippoApp.resCourseSection.videoControlsConfig">Video Controls Config</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionEntity.videoControlsConfig}</dd>
          <dt>
            <span id="aspectRatio">
              <Translate contentKey="alippoApp.resCourseSection.aspectRatio">Aspect Ratio</Translate>
            </span>
          </dt>
          <dd>{resCourseSectionEntity.aspectRatio}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.resCourseSection.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {resCourseSectionEntity.createdAt ? (
              <TextFormat value={resCourseSectionEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.resCourseSection.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {resCourseSectionEntity.updatedAt ? (
              <TextFormat value={resCourseSectionEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.resCourseSection.courseSection">Course Section</Translate>
          </dt>
          <dd>{resCourseSectionEntity.courseSection ? resCourseSectionEntity.courseSection.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/res-course-section" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/res-course-section/${resCourseSectionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ResCourseSectionDetail;
