import pick from 'lodash/pick';
import { IPaginationBaseState } from 'react-jhipster';
import { toast } from 'react-toastify';

/**
 * Removes fields with an 'id' field that equals ''.
 * This function was created to prevent entities to be sent to
 * the server with an empty id and thus resulting in a 500.
 *
 * @param entity Object to clean.
 */
export const cleanEntity = entity => {
  const keysToKeep = Object.keys(entity).filter(k => !(entity[k] instanceof Object) || (entity[k]['id'] !== '' && entity[k]['id'] !== -1));

  return pick(entity, keysToKeep);
};

export const handleCopyToClipboard = text => {
  navigator.clipboard.writeText(text);
  toast.success(`Copied ${text} to clipboard`);
};

/**
 * Simply map a list of element to a list a object with the element as id.
 *
 * @param idList Elements to map.
 * @returns The list of objects with mapped ids.
 */
export const mapIdList = (idList: ReadonlyArray<any>) => idList.filter((id: any) => id !== '').map((id: any) => ({ id }));

export const overridePaginationStateWithQueryParams = (paginationBaseState: IPaginationBaseState, locationSearch: string) => {
  const params = new URLSearchParams(locationSearch);
  const page = params.get('page');
  const sort = params.get('sort');
  if (page && sort) {
    const sortSplit = sort.split(',');
    paginationBaseState.activePage = +page;
    paginationBaseState.sort = sortSplit[0];
    paginationBaseState.order = sortSplit[1];
  }
  return paginationBaseState;
};
export const overrideFiltersStateWithQueryParams = (defaultParams: any, locationSearch: string) => {
  const params = new URLSearchParams(locationSearch);
  const paramsJson = getAllParamsFromUrlSearchParams(params);
  return {
    ...defaultParams,
    ...paramsJson,
  };
};

export const getAllParamsFromUrlSearchParams = (urlSearchParams: URLSearchParams) => {
  const paramsJson = {};
  urlSearchParams.forEach((value, key) => {
    paramsJson[getOriginalParamFromJhipsterParam(key)] = value;
  });
  return paramsJson;
};

export const buildSearchParamsPathFromEntity = entity => {
  let newSearchParameter = '';
  Object.keys(entity).forEach(key => {
    if (key !== 'page' && key !== 'sort' && entity[key]) {
      newSearchParameter += `&${key}.equals=${entity[key]}`;
    }
  });
  return newSearchParameter;
};

export const getOriginalParamFromJhipsterParam = (jhipsterParam: string) => {
  const paramSplitArray = jhipsterParam.split('.');
  switch (paramSplitArray.length) {
    case 2:
      return paramSplitArray[0];
    case 1:
      return jhipsterParam;
    default:
      return paramSplitArray[0];
  }
};
