export enum UnitValue {
  GRAM = 'GRAM',

  KILOGRAM = 'KILOGRAM',

  MILLILITRES = 'MILLILITRES',

  LITRES = 'LITRES',

  CENTIMETERS = 'CENTIMETERS',

  INCH = 'INCH',

  INR = 'INR',

  USD = 'USD',
}
