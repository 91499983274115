import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useMemo } from 'react';
import { JhiItemCount, JhiPagination, TextFormat, Translate, getSortState } from 'react-jhipster';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overrideFiltersStateWithQueryParams, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { getEntities } from './rule.reducer';
import { RuleType } from 'app/shared/model/enumerations/rule-type.model';
import { RuleBasis } from 'app/shared/model/enumerations/rule-basis.model';
import { RuleCurrency } from 'app/shared/model/enumerations/rule-currency.model';
import { debounce } from 'lodash';

type RulesFilterType = {
  ruleType: keyof typeof RuleType | 'NONE';
  ruleBasis: keyof typeof RuleBasis | 'NONE';
  ruleCurrency: keyof typeof RuleCurrency | 'NONE';
  code: string | null;
};

export const Rule = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );
  const [rulesFilter, setRulesFilter] = useState<RulesFilterType>(
    overrideFiltersStateWithQueryParams(
      {
        ruleType: 'NONE',
        ruleBasis: 'NONE',
        ruleCurrency: 'NONE',
        code: null,
      } as RulesFilterType,
      props.location.search
    )
  );
  const [rulesLoading, setRulesLoading] = useState(false);

  const ruleList = useAppSelector(state => state.rule.entities);
  const loading = useAppSelector(state => state.rule.loading);
  const totalItems = useAppSelector(state => state.rule.totalItems);

  const getAllEntities = () => {
    setRulesLoading(true);

    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        props: buildSearchParamsString(),
      })
    ).then(() => setRulesLoading(false));

    const params = buildSearchParamsString();

    const endUrl = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}${params}`;

    if (window.location.search !== endUrl) {
      window.history.replaceState(null, '', `${props.location.pathname}${endUrl}`);
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState, rulesFilter]);

  const buildSearchParamsString = () => {
    let paramsString = '';

    if (rulesFilter.ruleType && rulesFilter.ruleType !== 'NONE') {
      paramsString += `&ruleType.equals=${rulesFilter.ruleType}`;
    }

    if (rulesFilter.ruleBasis && rulesFilter.ruleBasis !== 'NONE') {
      paramsString += `&basis.equals=${rulesFilter.ruleBasis}`;
    }

    if (rulesFilter.ruleCurrency && rulesFilter.ruleCurrency !== 'NONE') {
      paramsString += `&ruleCurrency.equals=${rulesFilter.ruleCurrency}`;
    }

    if (rulesFilter.code) {
      paramsString += `&code.contains=${rulesFilter.code}`;
    }

    return paramsString;
  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    getAllEntities();
  };

  const handleFilterChange = e => {
    setRulesFilter(prev => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const debouncedHandleFilterChange = useMemo(() => debounce(handleFilterChange, 500), []);

  const { match } = props;

  return (
    <div>
      <h2 id="rule-heading" data-cy="RuleHeading">
        <Translate contentKey="alippoApp.rule.home.title">Rules</Translate>
      </h2>
      <div className="d-flex justify-content-end">
        <Button className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="alippoApp.rule.home.refreshListLabel">Refresh List</Translate>
        </Button>
        <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="alippoApp.rule.home.createLabel">Create new Rule</Translate>
        </Link>
      </div>
      <div className="my-4">
        <Form>
          <Row md={3}>
            <Col>
              <FormGroup>
                <Label for="ruleType">Rule Type</Label>
                <Input id="ruleType" name="ruleType" type="select" onChange={handleFilterChange} defaultValue={rulesFilter.ruleType}>
                  {(Object.keys(RuleType) as Array<keyof typeof RuleType>).map(status => (
                    <option value={status} key={status}>
                      {status}
                    </option>
                  ))}
                  <option value="NONE">-</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="ruleBasis">Rule Basis</Label>
                <Input id="ruleBasis" name="ruleBasis" type="select" onChange={handleFilterChange} defaultValue={rulesFilter.ruleBasis}>
                  {(Object.keys(RuleBasis) as Array<keyof typeof RuleBasis>).map(status => (
                    <option value={status} key={status}>
                      {status}
                    </option>
                  ))}
                  <option value="NONE">-</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="ruleCurrency">Rule Currency</Label>
                <Input
                  id="ruleCurrency"
                  name="ruleCurrency"
                  type="select"
                  onChange={handleFilterChange}
                  defaultValue={rulesFilter.ruleCurrency}
                >
                  {(Object.keys(RuleCurrency) as Array<keyof typeof RuleCurrency>).map(status => (
                    <option value={status} key={status}>
                      {status}
                    </option>
                  ))}
                  <option value="NONE">-</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="code">Code</Label>
                <Input
                  id="code"
                  name="code"
                  placeholder="Enter Code"
                  type="text"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={rulesFilter.code}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <h6 className="text-center mt-2" style={rulesLoading ? { opacity: 1 } : { opacity: 0 }}>
          Loading ...
        </h6>
      </div>
      <div className="table-responsive">
        {ruleList && ruleList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="alippoApp.rule.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="alippoApp.rule.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  <Translate contentKey="alippoApp.rule.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('ruleType')}>
                  <Translate contentKey="alippoApp.rule.ruleType">Rule Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('fromDateTime')}>
                  <Translate contentKey="alippoApp.rule.fromDateTime">From Date Time</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('duration')}>
                  <Translate contentKey="alippoApp.rule.duration">Duration</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('disabled')}>
                  <Translate contentKey="alippoApp.rule.disabled">Disabled</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('minCriteria')}>
                  <Translate contentKey="alippoApp.rule.minCriteria">Min Criteria</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('amount')}>
                  <Translate contentKey="alippoApp.rule.amount">Amount</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('basis')}>
                  <Translate contentKey="alippoApp.rule.basis">Basis</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('maxAmount')}>
                  <Translate contentKey="alippoApp.rule.maxAmount">Max Amount</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('sellingPrice')}>
                  <Translate contentKey="alippoApp.rule.sellingPrice">Selling Price</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('sellingPriceCurrencyCode')}>
                  <Translate contentKey="alippoApp.rule.sellingPriceCurrencyCode">Selling Price Currency Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('mrpPrice')}>
                  <Translate contentKey="alippoApp.rule.mrpPrice">Mrp Price</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('mrpPriceCurrencyCode')}>
                  <Translate contentKey="alippoApp.rule.mrpPriceCurrencyCode">Mrp Price Currency Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('ruleCurrency')}>
                  <Translate contentKey="alippoApp.rule.ruleCurrency">Rule Currency</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('currencyCode')}>
                  <Translate contentKey="alippoApp.rule.currencyCode">Currency Code</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('code')}>
                  <Translate contentKey="alippoApp.rule.code">Code</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('usageLimit')}>
                  <Translate contentKey="alippoApp.rule.usageLimit">Usage Limit</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('usagePerCustomer')}>
                  <Translate contentKey="alippoApp.rule.usagePerCustomer">Usage Per Customer</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('timesUsed')}>
                  <Translate contentKey="alippoApp.rule.timesUsed">Times Used</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('sortOrder')}>
                  <Translate contentKey="alippoApp.rule.sortOrder">Sort Order</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="alippoApp.rule.createdAt">Created At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updatedAt')}>
                  <Translate contentKey="alippoApp.rule.updatedAt">Updated At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('expiryPeriodInSeconds')}>
                  <Translate contentKey="alippoApp.rule.expiryPeriodInSeconds">Expiry Period In Seconds</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('config')}>
                  <Translate contentKey="alippoApp.rule.config">Config</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.rule.voucher">Voucher</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {ruleList.map((rule, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`${match.url}/${rule.id}`} color="link" size="sm">
                      {rule.id}
                    </Button>
                  </td>
                  <td>{rule.name}</td>
                  <td>{rule.description}</td>
                  <td>
                    <Translate contentKey={`alippoApp.RuleType.${rule.ruleType}`} />
                  </td>
                  <td>{rule.fromDateTime ? <TextFormat type="date" value={rule.fromDateTime} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{rule.duration}</td>
                  <td>{rule.disabled ? 'true' : 'false'}</td>
                  <td>{rule.minCriteria}</td>
                  <td>{rule.amount}</td>
                  <td>
                    <Translate contentKey={`alippoApp.RuleBasis.${rule.basis}`} />
                  </td>
                  <td>{rule.maxAmount}</td>
                  <td>{rule.sellingPrice}</td>
                  <td>
                    <Translate contentKey={`alippoApp.CurrencyCode.${rule.sellingPriceCurrencyCode}`} />
                  </td>
                  <td>{rule.mrpPrice}</td>
                  <td>
                    <Translate contentKey={`alippoApp.CurrencyCode.${rule.mrpPriceCurrencyCode}`} />
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.RuleCurrency.${rule.ruleCurrency}`} />
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.CurrencyCode.${rule.currencyCode}`} />
                  </td>
                  <td>{rule.code}</td>
                  <td>{rule.usageLimit}</td>
                  <td>{rule.usagePerCustomer}</td>
                  <td>{rule.timesUsed}</td>
                  <td>{rule.sortOrder}</td>
                  <td>{rule.createdAt ? <TextFormat type="date" value={rule.createdAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{rule.updatedAt ? <TextFormat type="date" value={rule.updatedAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{rule.expiryPeriodInSeconds}</td>
                  <td>{rule.config}</td>
                  <td>{rule.voucher ? <Link to={`voucher/${rule.voucher.id}`}>{rule.voucher.id}</Link> : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${rule.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${rule.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${rule.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="alippoApp.rule.home.notFound">No Rules found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={ruleList && ruleList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Rule;
