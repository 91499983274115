import dayjs from 'dayjs';
import { IOrderItem } from 'app/shared/model/order-item.model';
import { IAddress } from 'app/shared/model/address.model';
import { IUser } from 'app/shared/model/user.model';
import { ICart } from 'app/shared/model/cart.model';
import { PaymentGateway } from 'app/shared/model/enumerations/payment-gateway.model';

export interface IOrderInfo {
  id?: number;
  orderId?: string;
  orderDate?: string;
  pg?: PaymentGateway | null;
  secondaryPg?: PaymentGateway | null;
  pgOrderId?: string | null;
  pgResponse?: string | null;
  ipInfo?: string | null;
  platform?: string | null;
  platformVersion?: string | null;
  utm?: string | null;
  description?: string | null;
  deliveryFee?: number | null;
  createdAt?: string;
  updatedAt?: string;
  orderItems?: IOrderItem[] | null;
  billingAddress?: IAddress | null;
  user?: IUser | null;
  cart?: ICart | null;
  parentOrderInfo?: IOrderInfo | null;
  deliveryAddress?: IAddress | null;
}

export const defaultValue: Readonly<IOrderInfo> = {};
