export enum ConfigType {
  CHATWOOT_INBOX_INFO = 'CHATWOOT_INBOX_INFO',

  WEB_CONFIG = 'WEB_CONFIG',

  APP_CONFIG = 'APP_CONFIG',

  MISCELLANEOUS = 'MISCELLANEOUS',

  APP_FEATURE_PREVIEW_CONFIG = 'APP_FEATURE_PREVIEW_CONFIG',

  HOME_PAGE_SKELETON = 'HOME_PAGE_SKELETON',
}
