import dayjs from 'dayjs';
import { IResCourseSectionLesson } from 'app/shared/model/res-course-section-lesson.model';
import { IUserCourseSectionLesson } from 'app/shared/model/user-course-section-lesson.model';
import { ILesson } from 'app/shared/model/lesson.model';
import { ISection } from 'app/shared/model/section.model';
import { ICourseDiscussion } from 'app/shared/model/course-discussion.model';
import { ICourseSection } from 'app/shared/model/course-section.model';

export interface ICourseSectionLesson {
  id?: number;
  fromDateTime?: string | null;
  duration?: number | null;
  sortOrder?: number;
  isPreRecorded?: boolean | null;
  createdAt?: string;
  updatedAt?: string;
  configPayload?: string | null;
  resources?: IResCourseSectionLesson[] | null;
  userCourseSectionLessons?: IUserCourseSectionLesson[] | null;
  lesson?: ILesson | null;
  section?: ISection | null;
  associatedCourseSectionLesson?: ICourseSectionLesson | null;
  discussion?: ICourseDiscussion | null;
  courseSection?: ICourseSection | null;
}

export const defaultValue: Readonly<ICourseSectionLesson> = {
  isPreRecorded: false,
};
