import dayjs from 'dayjs';
import { ISku } from 'app/shared/model/sku.model';
import { IResProduct } from 'app/shared/model/res-product.model';
import { IProductTranslation } from 'app/shared/model/product-translation.model';

export interface IProduct {
  id?: number;
  name?: string;
  createdAt?: string;
  updatedAt?: string;
  sku?: ISku | null;
  resources?: IResProduct[] | null;
  translations?: IProductTranslation[] | null;
}

export const defaultValue: Readonly<IProduct> = {};
