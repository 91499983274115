import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CartItemSkuAttribute from './cart-item-sku-attribute';
import CartItemSkuAttributeDetail from './cart-item-sku-attribute-detail';
import CartItemSkuAttributeUpdate from './cart-item-sku-attribute-update';
import CartItemSkuAttributeDeleteDialog from './cart-item-sku-attribute-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CartItemSkuAttributeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CartItemSkuAttributeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CartItemSkuAttributeDetail} />
      <ErrorBoundaryRoute path={match.url} component={CartItemSkuAttribute} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={CartItemSkuAttributeDeleteDialog} />
  </>
);

export default Routes;
