import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './res-course.reducer';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import ReactJson from 'react-json-view';
import { ResMediaType } from 'app/shared/model/enumerations/res-media-type.model';

export const ResCourseDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const resCourseEntity = useAppSelector(state => state.resCourse.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="resCourseDetailsHeading">
          <Translate contentKey="alippoApp.resCourse.detail.title">ResCourse</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{resCourseEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.resCourse.name">Name</Translate>
            </span>
          </dt>
          <dd>{resCourseEntity.name}</dd>
          <dt>
            <span id="url">
              <Translate contentKey="alippoApp.resCourse.url">Url</Translate>
            </span>
          </dt>
          <dd>{resCourseEntity.url}</dd>
          <dt>
            <span id="resState">
              <Translate contentKey="alippoApp.resCourse.resState">Res State</Translate>
            </span>
          </dt>
          <dd>{resCourseEntity.resState}</dd>
          <dt>
            <span id="resType">
              <Translate contentKey="alippoApp.resCourse.resType">Res Type</Translate>
            </span>
          </dt>
          <dd>{resCourseEntity.resType}</dd>
          <dt>
            <span id="mediaType">
              <Translate contentKey="alippoApp.resCourse.mediaType">Media Type</Translate>
            </span>
          </dt>
          <dd>{resCourseEntity.mediaType}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.resCourse.description">Description</Translate>
            </span>
          </dt>
          <dd>
            {resCourseEntity.mediaType === ResMediaType.JSON ? (
              <div style={{ marginBottom: '1rem' }}>
                <ReactJson
                  src={JSON.parse(resCourseEntity.description ?? '{}')}
                  displayObjectSize={false}
                  displayDataTypes={false}
                  collapsed
                />
              </div>
            ) : (
              resCourseEntity.description
            )}
          </dd>
          <dt>
            <span id="language">
              <Translate contentKey="alippoApp.resCourse.language">Language</Translate>
            </span>
          </dt>
          <dd>{resCourseEntity.language}</dd>
          <dt>
            <span id="videoControlsConfig">
              <Translate contentKey="alippoApp.resCourse.videoControlsConfig">Video Controls Config</Translate>
            </span>
          </dt>
          <dd>{resCourseEntity.videoControlsConfig}</dd>
          <dt>
            <span id="aspectRatio">
              <Translate contentKey="alippoApp.resCourse.aspectRatio">Aspect Ratio</Translate>
            </span>
          </dt>
          <dd>{resCourseEntity.aspectRatio}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.resCourse.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {resCourseEntity.createdAt ? <TextFormat value={resCourseEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.resCourse.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {resCourseEntity.updatedAt ? <TextFormat value={resCourseEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.resCourse.res">Res</Translate>
          </dt>
          <dd>{resCourseEntity.res ? resCourseEntity.res.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.resCourse.course">Course</Translate>
          </dt>
          <dd>{resCourseEntity.course ? resCourseEntity.course.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/res-course" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/res-course/${resCourseEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/res-course/${resCourseEntity.id}/delete`} replace color="danger">
          <FontAwesomeIcon icon="trash" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.delete">Delete</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ResCourseDetail;
