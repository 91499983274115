import dayjs from 'dayjs';
import { ICourse } from 'app/shared/model/course.model';
import { ICategory } from 'app/shared/model/category.model';
import { IUser } from 'app/shared/model/user.model';
import { SlugEntityType } from 'app/shared/model/enumerations/slug-entity-type.model';
import { SlugRecordType } from 'app/shared/model/enumerations/slug-record-type.model';

export interface ISlug {
  id?: number;
  slug?: string;
  entityType?: SlugEntityType;
  recordType?: SlugRecordType;
  createdAt?: string;
  updatedAt?: string;
  course?: ICourse | null;
  category?: ICategory | null;
  user?: IUser | null;
  redirectSlug?: ISlug | null;
}

export const defaultValue: Readonly<ISlug> = {};
