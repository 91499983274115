import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ISection } from 'app/shared/model/section.model';
import { ICourse } from 'app/shared/model/course.model';
import { getEntity, updateEntity, createEntity, reset } from './course-section.reducer';
import { ICourseSection } from 'app/shared/model/course-section.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CourseSectionUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const locationState: any = location.state;

  let inheritedCourse = null;
  if (locationState) {
    inheritedCourse = locationState.course;
  }

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const courseSectionEntity = useAppSelector(state => state.courseSection.entity);
  const loading = useAppSelector(state => state.courseSection.loading);
  const updating = useAppSelector(state => state.courseSection.updating);
  const updateSuccess = useAppSelector(state => state.courseSection.updateSuccess);

  const handleClose = () => {
    if (courseSectionEntity && courseSectionEntity.id) {
      dispatch(reset());
      props.history.push('/course/' + `${courseSectionEntity.course.id}`);
    } else {
      props.history.push('/course-section' + props.location.search);
    }
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.fromDateTime = convertDateTimeToServer(values.fromDateTime);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...courseSectionEntity,
      ...values,
      course: { id: values.course },
      section: { id: values.section },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          fromDateTime: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
          course: inheritedCourse ? inheritedCourse.id : null,
        }
      : {
          ...courseSectionEntity,
          fromDateTime: convertDateTimeFromServer(courseSectionEntity.fromDateTime),
          createdAt: convertDateTimeFromServer(courseSectionEntity.createdAt),
          updatedAt: convertDateTimeFromServer(courseSectionEntity.updatedAt),
          section: courseSectionEntity?.section?.id,
          course: courseSectionEntity?.course?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.courseSection.home.createOrEditLabel" data-cy="CourseSectionCreateUpdateHeading">
            <Translate contentKey="alippoApp.courseSection.home.createOrEditLabel">Create or edit a CourseSection</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="course-section-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.courseSection.fromDateTime')}
                id="course-section-fromDateTime"
                name="fromDateTime"
                data-cy="fromDateTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('alippoApp.courseSection.duration')}
                id="course-section-duration"
                name="duration"
                data-cy="duration"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.courseSection.sortOrder')}
                id="course-section-sortOrder"
                name="sortOrder"
                data-cy="sortOrder"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alippoApp.courseSection.createdAt')}
                id="course-section-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.courseSection.updatedAt')}
                id="course-section-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="course-section-section"
                name="section"
                data-cy="section"
                label={translate('alippoApp.courseSection.section')}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="course-section-course"
                name="course"
                data-cy="course"
                label={translate('alippoApp.courseSection.course')}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/course-section" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CourseSectionUpdate;
