import React from 'react';
import { Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import Entities from 'app/entities';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import { AUTHORITIES } from 'app/config/constants';
import DiscussionSessionLogs from './modules/discussion-session-logs';

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => <div>loading ...</div>,
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <div>loading ...</div>,
});

const CustomerSupport = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/customer-support'),
  loading: () => <div>loading ...</div>,
});

const WebsiteInvalidator = Loadable({
  loader: () => import(/* webpackChunkName: "website-invalidator" */ 'app/modules/website-invalidator'),
  loading: () => <div>loading ...</div>,
});

const PlaceOrder = Loadable({
  loader: () => import(/* webpackChunkName: "place-order" */ 'app/modules/place-order'),
  loading: () => <div>loading ...</div>,
});

const InstructorChecklist = Loadable({
  loader: () => import(/* webpackChunkName: "instructor-checklist" */ 'app/modules/instructor-checklist'),
  loading: () => <div>loading ...</div>,
});

const UserJourney = Loadable({
  loader: () => import(/* webpackChunkName: "user-journey" */ 'app/modules/user-journey'),
  loading: () => <div>loading ...</div>,
});

const Routes = () => {
  return (
    <div className="view-routes">
      <Switch>
        <ErrorBoundaryRoute path="/login" component={Login} />
        <ErrorBoundaryRoute path="/logout" component={Logout} />
        <ErrorBoundaryRoute path="/account/register" component={Register} />
        <ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
        <ErrorBoundaryRoute path="/account/reset/request" component={PasswordResetInit} />
        <ErrorBoundaryRoute path="/account/reset/finish/:key?" component={PasswordResetFinish} />
        <ErrorBoundaryRoute path="/instructor-checklist" component={InstructorChecklist} />
        <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute path="/customer-support" component={CustomerSupport} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute path="/account" component={Account} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]} />
        <PrivateRoute path="/user-journey" component={UserJourney} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.OPERATION]} />
        <ErrorBoundaryRoute path="/" exact component={Home} />
        <PrivateRoute
          exact
          path="/website-invalidator"
          component={WebsiteInvalidator}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.OPERATION]}
        />
        <PrivateRoute
          exact
          path="/discussion-session-logs"
          component={DiscussionSessionLogs}
          hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.OPERATION]}
        />
        <PrivateRoute exact path="/place-order" component={PlaceOrder} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.OPERATION]} />
        <PrivateRoute path="/" component={Entities} hasAnyAuthorities={[AUTHORITIES.USER]} />
      </Switch>
    </div>
  );
};

export default Routes;
