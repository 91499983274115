import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PaymentSource from './payment-source';
import PaymentSourceDetail from './payment-source-detail';
import PaymentSourceUpdate from './payment-source-update';
import PaymentSourceDeleteDialog from './payment-source-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={PaymentSourceUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={PaymentSourceUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={PaymentSourceDetail} />
      <ErrorBoundaryRoute path={match.url} component={PaymentSource} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={PaymentSourceDeleteDialog} />
  </>
);

export default Routes;
