import dayjs from 'dayjs';
import { IVoucher } from 'app/shared/model/voucher.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface IVoucherTranslation {
  id?: number;
  name?: string;
  description?: string | null;
  language?: Language;
  isDefault?: boolean;
  createdAt?: string;
  updatedAt?: string;
  voucher?: IVoucher | null;
}

export const defaultValue: Readonly<IVoucherTranslation> = {
  isDefault: false,
};
