import dayjs from 'dayjs';
import { IPlan } from 'app/shared/model/plan.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface IPlanTranslation {
  id?: number;
  name?: string;
  description?: string | null;
  language?: Language;
  isDefault?: boolean;
  createdAt?: string;
  updatedAt?: string;
  plan?: IPlan | null;
}

export const defaultValue: Readonly<IPlanTranslation> = {
  isDefault: false,
};
