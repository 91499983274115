import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { byteSize, Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities } from './rule-ledger.reducer';
import { IRuleLedger } from 'app/shared/model/rule-ledger.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const RuleLedger = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );

  const ruleLedgerList = useAppSelector(state => state.ruleLedger.entities);
  const loading = useAppSelector(state => state.ruleLedger.loading);
  const totalItems = useAppSelector(state => state.ruleLedger.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const { match } = props;

  return (
    <div>
      <h2 id="rule-ledger-heading" data-cy="RuleLedgerHeading">
        <Translate contentKey="alippoApp.ruleLedger.home.title">Rule Ledgers</Translate>
        <div className="d-flex justify-content-end">
          <Button className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="alippoApp.ruleLedger.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="alippoApp.ruleLedger.home.createLabel">Create new Rule Ledger</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {ruleLedgerList && ruleLedgerList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="alippoApp.ruleLedger.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('ledgerId')}>
                  <Translate contentKey="alippoApp.ruleLedger.ledgerId">Ledger Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referredAmount')}>
                  <Translate contentKey="alippoApp.ruleLedger.referredAmount">Referred Amount</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referredBasis')}>
                  <Translate contentKey="alippoApp.ruleLedger.referredBasis">Referred Basis</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referredRuleCurrency')}>
                  <Translate contentKey="alippoApp.ruleLedger.referredRuleCurrency">Referred Rule Currency</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referredCurrencyCode')}>
                  <Translate contentKey="alippoApp.ruleLedger.referredCurrencyCode">Referred Currency Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referredAmountUsed')}>
                  <Translate contentKey="alippoApp.ruleLedger.referredAmountUsed">Referred Amount Used</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referredUsageLimit')}>
                  <Translate contentKey="alippoApp.ruleLedger.referredUsageLimit">Referred Usage Limit</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referredTimesUsed')}>
                  <Translate contentKey="alippoApp.ruleLedger.referredTimesUsed">Referred Times Used</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referredAmountStatus')}>
                  <Translate contentKey="alippoApp.ruleLedger.referredAmountStatus">Referred Amount Status</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referredFromDateTime')}>
                  <Translate contentKey="alippoApp.ruleLedger.referredFromDateTime">Referred From Date Time</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referredDuration')}>
                  <Translate contentKey="alippoApp.ruleLedger.referredDuration">Referred Duration</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referrerAmount')}>
                  <Translate contentKey="alippoApp.ruleLedger.referrerAmount">Referrer Amount</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referrerBasis')}>
                  <Translate contentKey="alippoApp.ruleLedger.referrerBasis">Referrer Basis</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referrerRuleCurrency')}>
                  <Translate contentKey="alippoApp.ruleLedger.referrerRuleCurrency">Referrer Rule Currency</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referrerCurrencyCode')}>
                  <Translate contentKey="alippoApp.ruleLedger.referrerCurrencyCode">Referrer Currency Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referrerAmountUsed')}>
                  <Translate contentKey="alippoApp.ruleLedger.referrerAmountUsed">Referrer Amount Used</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referrerUsageLimit')}>
                  <Translate contentKey="alippoApp.ruleLedger.referrerUsageLimit">Referrer Usage Limit</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referrerTimesUsed')}>
                  <Translate contentKey="alippoApp.ruleLedger.referrerTimesUsed">Referrer Times Used</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referrerAmountStatus')}>
                  <Translate contentKey="alippoApp.ruleLedger.referrerAmountStatus">Referrer Amount Status</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referrerFromDateTime')}>
                  <Translate contentKey="alippoApp.ruleLedger.referrerFromDateTime">Referrer From Date Time</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('referrerDuration')}>
                  <Translate contentKey="alippoApp.ruleLedger.referrerDuration">Referrer Duration</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('expiryAt')}>
                  <Translate contentKey="alippoApp.ruleLedger.expiryAt">Expiry At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('metadata')}>
                  <Translate contentKey="alippoApp.ruleLedger.metadata">Metadata</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="alippoApp.ruleLedger.createdAt">Created At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updatedAt')}>
                  <Translate contentKey="alippoApp.ruleLedger.updatedAt">Updated At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.ruleLedger.referredUser">Referred User</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.ruleLedger.referrerUser">Referrer User</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.ruleLedger.referredRule">Referred Rule</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.ruleLedger.referrerRule">Referrer Rule</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.ruleLedger.orderInfo">Order Info</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.ruleLedger.task">Task</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {ruleLedgerList.map((ruleLedger, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`${match.url}/${ruleLedger.id}`} color="link" size="sm">
                      {ruleLedger.id}
                    </Button>
                  </td>
                  <td>{ruleLedger.ledgerId}</td>
                  <td>{ruleLedger.referredAmount}</td>
                  <td>
                    <Translate contentKey={`alippoApp.RuleBasis.${ruleLedger.referredBasis}`} />
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.RuleCurrency.${ruleLedger.referredRuleCurrency}`} />
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.CurrencyCode.${ruleLedger.referredCurrencyCode}`} />
                  </td>
                  <td>{ruleLedger.referredAmountUsed}</td>
                  <td>{ruleLedger.referredUsageLimit}</td>
                  <td>{ruleLedger.referredTimesUsed}</td>
                  <td>
                    <Translate contentKey={`alippoApp.RuleLedgerAmountStatus.${ruleLedger.referredAmountStatus}`} />
                  </td>
                  <td>
                    {ruleLedger.referredFromDateTime ? (
                      <TextFormat type="date" value={ruleLedger.referredFromDateTime} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{ruleLedger.referredDuration}</td>
                  <td>{ruleLedger.referrerAmount}</td>
                  <td>
                    <Translate contentKey={`alippoApp.RuleBasis.${ruleLedger.referrerBasis}`} />
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.RuleCurrency.${ruleLedger.referrerRuleCurrency}`} />
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.CurrencyCode.${ruleLedger.referrerCurrencyCode}`} />
                  </td>
                  <td>{ruleLedger.referrerAmountUsed}</td>
                  <td>{ruleLedger.referrerUsageLimit}</td>
                  <td>{ruleLedger.referrerTimesUsed}</td>
                  <td>
                    <Translate contentKey={`alippoApp.RuleLedgerAmountStatus.${ruleLedger.referrerAmountStatus}`} />
                  </td>
                  <td>
                    {ruleLedger.referrerFromDateTime ? (
                      <TextFormat type="date" value={ruleLedger.referrerFromDateTime} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{ruleLedger.referrerDuration}</td>
                  <td>{ruleLedger.expiryAt ? <TextFormat type="date" value={ruleLedger.expiryAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{ruleLedger.metadata}</td>
                  <td>{ruleLedger.createdAt ? <TextFormat type="date" value={ruleLedger.createdAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{ruleLedger.updatedAt ? <TextFormat type="date" value={ruleLedger.updatedAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{ruleLedger.referredUser ? ruleLedger.referredUser.id : ''}</td>
                  <td>{ruleLedger.referrerUser ? ruleLedger.referrerUser.id : ''}</td>
                  <td>
                    {ruleLedger.referredRule ? <Link to={`rule/${ruleLedger.referredRule.id}`}>{ruleLedger.referredRule.name}</Link> : ''}
                  </td>
                  <td>
                    {ruleLedger.referrerRule ? <Link to={`rule/${ruleLedger.referrerRule.id}`}>{ruleLedger.referrerRule.name}</Link> : ''}
                  </td>
                  <td>{ruleLedger.orderInfo ? <Link to={`order-info/${ruleLedger.orderInfo.id}`}>{ruleLedger.orderInfo.id}</Link> : ''}</td>
                  <td>{ruleLedger.task ? <Link to={`task/${ruleLedger.task.id}`}>{ruleLedger.task.id}</Link> : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${ruleLedger.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${ruleLedger.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${ruleLedger.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="alippoApp.ruleLedger.home.notFound">No Rule Ledgers found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={ruleLedgerList && ruleLedgerList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default RuleLedger;
