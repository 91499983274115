import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { ISku } from 'app/shared/model/sku.model';
import { ReservationStatus } from 'app/shared/model/enumerations/reservation-status.model';

export interface ISkuReservation {
  id?: number;
  reservationStatus?: ReservationStatus | null;
  reservedAt?: string | null;
  createdAt?: string;
  updatedAt?: string;
  user?: IUser | null;
  sku?: ISku | null;
}

export const defaultValue: Readonly<ISkuReservation> = {};
