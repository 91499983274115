import dayjs from 'dayjs';
import { IResReview } from 'app/shared/model/res-review.model';
import { IRatingOption } from 'app/shared/model/rating-option.model';
import { IUser } from 'app/shared/model/user.model';
import { ReviewEntityType } from 'app/shared/model/enumerations/review-entity-type.model';
import { ReviewType } from 'app/shared/model/enumerations/review-type.model';
import { ReviewSubType } from 'app/shared/model/enumerations/review-sub-type.model';
import { ReviewStatus } from 'app/shared/model/enumerations/review-status.model';

export interface IReview {
  id?: number;
  entityType?: ReviewEntityType;
  entityId?: number;
  type?: ReviewType;
  subType?: ReviewSubType;
  status?: ReviewStatus;
  displayName?: string | null;
  sortOrder?: number | null;
  createdAt?: string;
  updatedAt?: string;
  resources?: IResReview[] | null;
  rateOption?: IRatingOption | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IReview> = {};
