import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './lesson-translation.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const LessonTranslationDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const { match } = props;
  const handleDeleteResource = () => {
    props.history.replace(`${match.url}/delete`);
  };

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const lessonTranslationEntity = useAppSelector(state => state.lessonTranslation.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="lessonTranslationDetailsHeading">
          <Translate contentKey="alippoApp.lessonTranslation.detail.title">LessonTranslation</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{lessonTranslationEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.lessonTranslation.name">Name</Translate>
            </span>
          </dt>
          <dd>{lessonTranslationEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.lessonTranslation.description">Description</Translate>
            </span>
          </dt>
          <dd>{lessonTranslationEntity.description}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="alippoApp.lessonTranslation.language">Language</Translate>
            </span>
          </dt>
          <dd>{lessonTranslationEntity.language}</dd>
          <dt>
            <span id="isDefault">
              <Translate contentKey="alippoApp.lessonTranslation.isDefault">Is Default</Translate>
            </span>
          </dt>
          <dd>{lessonTranslationEntity.isDefault ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.lessonTranslation.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {lessonTranslationEntity.createdAt ? (
              <TextFormat value={lessonTranslationEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.lessonTranslation.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {lessonTranslationEntity.updatedAt ? (
              <TextFormat value={lessonTranslationEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.lessonTranslation.lesson">Lesson</Translate>
          </dt>
          <dd>{lessonTranslationEntity.lesson ? lessonTranslationEntity.lesson.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/lesson-translation" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/lesson-translation/${lessonTranslationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
        &nbsp;
        <Button onClick={handleDeleteResource} color="danger" data-cy="entityDeleteButton">
          <FontAwesomeIcon icon="trash" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.delete">Delete</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default LessonTranslationDetail;
