import dayjs from 'dayjs';
import { ICourseDiscussion } from 'app/shared/model/course-discussion.model';
import { IResCategory } from 'app/shared/model/res-category.model';
import { ICategoryTranslation } from 'app/shared/model/category-translation.model';
import { ICourse } from 'app/shared/model/course.model';

export interface ICategory {
  id?: number;
  name?: string;
  description?: string | null;
  sortOrder?: number;
  createdAt?: string;
  updatedAt?: string;
  properties?: string | null;
  isDiscoverable?: boolean | null;
  isTopic?: boolean | null;
  categoryDiscussions?: ICourseDiscussion[] | null;
  resources?: IResCategory[] | null;
  parentCategory?: ICategory | null;
  translations?: ICategoryTranslation[] | null;
  courses?: ICourse[] | null;
}

export const defaultValue: Readonly<ICategory> = {
  isDiscoverable: false,
  isTopic: false,
};
