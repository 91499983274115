import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ResCourseSection from './res-course-section';
import ResCourseSectionDetail from './res-course-section-detail';
import ResCourseSectionUpdate from './res-course-section-update';
import ResCourseSectionDeleteDialog from './res-course-section-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ResCourseSectionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ResCourseSectionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ResCourseSectionDetail} />
      <ErrorBoundaryRoute path={match.url} component={ResCourseSection} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ResCourseSectionDeleteDialog} />
  </>
);

export default Routes;
