import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import SkuReservation from './sku-reservation';
import SkuReservationDetail from './sku-reservation-detail';
import SkuReservationUpdate from './sku-reservation-update';
import SkuReservationDeleteDialog from './sku-reservation-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SkuReservationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SkuReservationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SkuReservationDetail} />
      <ErrorBoundaryRoute path={match.url} component={SkuReservation} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={SkuReservationDeleteDialog} />
  </>
);

export default Routes;
