import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { JhiItemCount, JhiPagination, TextFormat, Translate, getSortState } from 'react-jhipster';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ISkuApplicationStateTransition } from 'app/shared/model/sku-application-state-transition.model';
import { overrideFiltersStateWithQueryParams, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import axios from 'axios';
import { debounce } from 'lodash';
import { getEntities } from './sku-application.reducer';

type SkuApplicationsFilterType = {
  skuId: number | null;
  userId: number | null;
  formId: number | null;
};

export const SkuApplication = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );
  const [skuApplicationsFilter, setSkuApplicationsFilter] = useState<SkuApplicationsFilterType>(
    overrideFiltersStateWithQueryParams({ skuId: null } as SkuApplicationsFilterType, props.location.search)
  );
  const [skuApplicationsLoading, setSkuApplicationsLoading] = useState(false);
  const [skuApplicationStateTransitions, setSkuApplicationStateTransitions] =
    useState<Record<number, Array<ISkuApplicationStateTransition>>>(null);

  const skuApplicationList = useAppSelector(state => state.skuApplication.entities);
  const loading = useAppSelector(state => state.skuApplication.loading);
  const totalItems = useAppSelector(state => state.skuApplication.totalItems);

  const getAllEntities = () => {
    setSkuApplicationsLoading(true);

    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        props: buildSearchParamsString(),
      })
    ).then(() => setSkuApplicationsLoading(false));

    const params = buildSearchParamsString();

    const endUrl = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}${params}`;

    if (window.location.search !== endUrl) {
      window.history.replaceState(null, '', `${props.location.pathname}${endUrl}`);
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState, skuApplicationsFilter]);

  useEffect(() => {
    if (skuApplicationList && skuApplicationList.length > 0) {
      const getSkuApplicationStateTransitions = async () => {
        const skuApplicationIds = skuApplicationList.map(skuApplication => skuApplication.id);

        const { data: skuApplicationStateTransitionsList }: { data: ISkuApplicationStateTransition[] } = await axios.get(
          `api/sku-application-state-transitions?skuApplicationId.in=${skuApplicationIds}&page=0&size=600`
        );

        const skuApplicationIdTransitionsMap: Record<number, Array<ISkuApplicationStateTransition>> = {};

        skuApplicationStateTransitionsList?.forEach(skuApplicationStateTransition => {
          if (!skuApplicationIdTransitionsMap[skuApplicationStateTransition.skuApplication.id]) {
            skuApplicationIdTransitionsMap[skuApplicationStateTransition.skuApplication.id] = [];
          }

          skuApplicationIdTransitionsMap[skuApplicationStateTransition.skuApplication.id].push(skuApplicationStateTransition);
        });

        setSkuApplicationStateTransitions(skuApplicationIdTransitionsMap);
      };

      getSkuApplicationStateTransitions();
    }
  }, [skuApplicationList]);

  const buildSearchParamsString = () => {
    let paramsString = '';

    if (skuApplicationsFilter.skuId) {
      paramsString += `&skuId.equals=${skuApplicationsFilter.skuId}`;
    }

    if (skuApplicationsFilter.userId) {
      paramsString += `&userId.equals=${skuApplicationsFilter.userId}`;
    }

    if (skuApplicationsFilter.formId) {
      paramsString += `&formId.equals=${skuApplicationsFilter.formId}`;
    }

    return paramsString;
  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    getAllEntities();
  };

  const handleFilterChange = e => {
    setSkuApplicationsFilter(prev => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const debouncedHandleFilterChange = useMemo(() => debounce(handleFilterChange, 500), []);

  const getCurrentApplicationStatus = (transitions: ISkuApplicationStateTransition[]) => {
    transitions.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());

    return transitions.pop()?.newStatus;
  };

  const { match } = props;

  return (
    <div>
      <h2 id="sku-application-heading" data-cy="SkuApplicationHeading">
        <Translate contentKey="alippoApp.skuApplication.home.title">Sku Applications</Translate>
      </h2>
      <div className="d-flex justify-content-end">
        <Button className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="alippoApp.skuApplication.home.refreshListLabel">Refresh List</Translate>
        </Button>
        <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="alippoApp.skuApplication.home.createLabel">Create new Sku Application</Translate>
        </Link>
      </div>
      <div className="my-4">
        <Form>
          <Row md={3}>
            <Col>
              <FormGroup>
                <Label for="name">Sku ID</Label>
                <Input
                  id="skuId"
                  name="skuId"
                  placeholder="Enter Sku ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={skuApplicationsFilter.skuId}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="name">User ID</Label>
                <Input
                  id="userId"
                  name="userId"
                  placeholder="Enter User ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={skuApplicationsFilter.userId}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="name">Form ID</Label>
                <Input
                  id="formId"
                  name="formId"
                  placeholder="Enter Form ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={skuApplicationsFilter.formId}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <h6 className="text-center mt-2" style={skuApplicationsLoading ? { opacity: 1 } : { opacity: 0 }}>
          Loading ...
        </h6>
      </div>
      <div className="table-responsive">
        {skuApplicationList && skuApplicationList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="alippoApp.skuApplication.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('formData')}>
                  <Translate contentKey="alippoApp.skuApplication.formData">Form Data</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <div>Current Status</div>
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="alippoApp.skuApplication.createdAt">Created At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updatedAt')}>
                  <Translate contentKey="alippoApp.skuApplication.updatedAt">Updated At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.skuApplication.sku">Sku</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.skuApplication.user">User</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.skuApplication.form">Form</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {skuApplicationList.map((skuApplication, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`${match.url}/${skuApplication.id}`} color="link" size="sm">
                      {skuApplication.id}
                    </Button>
                  </td>
                  <td>{skuApplication.formData}</td>
                  <td>
                    {skuApplicationStateTransitions &&
                      skuApplicationStateTransitions[skuApplication.id] &&
                      getCurrentApplicationStatus(skuApplicationStateTransitions[skuApplication.id])}
                  </td>
                  <td>
                    {skuApplication.createdAt ? <TextFormat type="date" value={skuApplication.createdAt} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>
                    {skuApplication.updatedAt ? <TextFormat type="date" value={skuApplication.updatedAt} format={APP_DATE_FORMAT} /> : null}
                  </td>
                  <td>{skuApplication.sku ? <Link to={`sku/${skuApplication.sku.id}`}>{skuApplication.sku.id}</Link> : ''}</td>
                  <td>{skuApplication.user ? skuApplication.user.id : ''}</td>
                  <td>{skuApplication.form ? <Link to={`form/${skuApplication.form.id}`}>{skuApplication.form.id}</Link> : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${skuApplication.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${skuApplication.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${skuApplication.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="alippoApp.skuApplication.home.notFound">No Sku Applications found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={skuApplicationList && skuApplicationList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SkuApplication;
