import dayjs from 'dayjs';
import { ISkuAttributeVariant } from 'app/shared/model/sku-attribute-variant.model';
import { ISku } from 'app/shared/model/sku.model';
import { SkuAttributeType } from 'app/shared/model/enumerations/sku-attribute-type.model';
import { InputSelectionType } from 'app/shared/model/enumerations/input-selection-type.model';
import { UnitType } from 'app/shared/model/enumerations/unit-type.model';
import { UnitValue } from 'app/shared/model/enumerations/unit-value.model';

export interface ISkuAttribute {
  id?: number;
  active?: boolean;
  name?: string;
  description?: string;
  type?: SkuAttributeType;
  inputSelectionType?: InputSelectionType;
  unitType?: UnitType;
  unitValue?: UnitValue;
  config?: string | null;
  sortOrder?: number;
  createdAt?: string;
  updatedAt?: string;
  variants?: ISkuAttributeVariant[] | null;
  sku?: ISku | null;
}

export const defaultValue: Readonly<ISkuAttribute> = {
  active: false,
};
