import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Tip from './tip';
import TipDetail from './tip-detail';
import TipUpdate from './tip-update';
import TipDeleteDialog from './tip-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={TipUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={TipUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={TipDetail} />
      <ErrorBoundaryRoute path={match.url} component={Tip} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={TipDeleteDialog} />
  </>
);

export default Routes;
