import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './cart-item.reducer';
import axios from 'axios';
import { ICourse } from 'app/shared/model/course.model';

export const CartItemDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [courseName, setCourseName] = useState(null);

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const cartItemEntity = useAppSelector(state => state.cartItem.entity);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      const { data: courseData } = await axios.get<ICourse>(`api/courses/${cartItemEntity.course.id}`);

      setCourseName(courseData.name);
    };

    if (cartItemEntity.course) {
      fetchCourseDetails();
    }
  }, [cartItemEntity]);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="cartItemDetailsHeading">
          <Translate contentKey="alippoApp.cartItem.detail.title">CartItem</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{cartItemEntity.id}</dd>
          <dt>
            <span id="sellingPrice">
              <Translate contentKey="alippoApp.cartItem.sellingPrice">Selling Price</Translate>
            </span>
          </dt>
          <dd>{cartItemEntity.sellingPrice}</dd>
          <dt>
            <span id="sellingPriceCurrencyCode">
              <Translate contentKey="alippoApp.cartItem.sellingPriceCurrencyCode">Selling Price Currency Code</Translate>
            </span>
          </dt>
          <dd>{cartItemEntity.sellingPriceCurrencyCode}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.cartItem.description">Description</Translate>
            </span>
          </dt>
          <dd>{cartItemEntity.description}</dd>
          <dt>
            <span id="metaData">
              <Translate contentKey="alippoApp.cartItem.metaData">Meta Data</Translate>
            </span>
          </dt>
          <dd>{cartItemEntity.metaData}</dd>
          <dt>
            <span id="available">
              <Translate contentKey="alippoApp.cartItem.available">Available</Translate>
            </span>
          </dt>
          <dd>{`${cartItemEntity.available}`}</dd>
          <dt>
            <span id="platform">
              <Translate contentKey="alippoApp.cartItem.platform">Platform</Translate>
            </span>
          </dt>
          <dd>{cartItemEntity.platform}</dd>
          <dt>
            <span id="platformVersion">
              <Translate contentKey="alippoApp.cartItem.platformVersion">Platform Version</Translate>
            </span>
          </dt>
          <dd>{cartItemEntity.platformVersion}</dd>
          <dt>
            <span id="utm">
              <Translate contentKey="alippoApp.cartItem.utm">Utm</Translate>
            </span>
          </dt>
          <dd>{cartItemEntity.utm}</dd>
          <dt>
            <span id="unavailabilityReason">
              <Translate contentKey="alippoApp.cartItem.unavailabilityReason">Unavailability Reason</Translate>
            </span>
          </dt>
          <dd>{cartItemEntity.unavailabilityReason}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.cartItem.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{cartItemEntity.createdAt ? <TextFormat value={cartItemEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.cartItem.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{cartItemEntity.updatedAt ? <TextFormat value={cartItemEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="availabilityUpdatedAt">
              <Translate contentKey="alippoApp.cartItem.availabilityUpdatedAt">Availability Updated At</Translate>
            </span>
          </dt>
          <dd>
            {cartItemEntity.availabilityUpdatedAt ? (
              <TextFormat value={cartItemEntity.availabilityUpdatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.cartItem.course">Course</Translate>
          </dt>
          <dd>{cartItemEntity.course ? `${cartItemEntity.course.id} ${courseName ? '(' + courseName + ')' : ''}` : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.cartItem.sku">Sku</Translate>
          </dt>
          <dd>{cartItemEntity.sku ? cartItemEntity.sku.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.cartItem.cart">Cart</Translate>
          </dt>
          <dd>{cartItemEntity.cart ? cartItemEntity.cart.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/cart-item" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/cart-item/${cartItemEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CartItemDetail;
