import dayjs from 'dayjs';
import { IProduct } from 'app/shared/model/product.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface IProductTranslation {
  id?: number;
  name?: string;
  description?: string | null;
  language?: Language;
  isDefault?: boolean;
  createdAt?: string;
  updatedAt?: string;
  product?: IProduct | null;
}

export const defaultValue: Readonly<IProductTranslation> = {
  isDefault: false,
};
