import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './discussion-member.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const DiscussionMemberDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const discussionMemberEntity = useAppSelector(state => state.discussionMember.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="discussionMemberDetailsHeading">
          <Translate contentKey="alippoApp.discussionMember.detail.title">DiscussionMember</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{discussionMemberEntity.id}</dd>
          <dt>
            <span id="joinedOn">
              <Translate contentKey="alippoApp.discussionMember.joinedOn">Joined On</Translate>
            </span>
          </dt>
          <dd>
            {discussionMemberEntity.joinedOn ? (
              <TextFormat value={discussionMemberEntity.joinedOn} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="hasMutedGroup">
              <Translate contentKey="alippoApp.discussionMember.hasMutedGroup">Has Muted Group</Translate>
            </span>
          </dt>
          <dd>{discussionMemberEntity.hasMutedGroup ? 'true' : 'false'}</dd>
          <dt>
            <span id="leaveReason">
              <Translate contentKey="alippoApp.discussionMember.leaveReason">Leave Reason</Translate>
            </span>
          </dt>
          <dd>{discussionMemberEntity.leaveReason}</dd>
          <dt>
            <span id="removed">
              <Translate contentKey="alippoApp.discussionMember.removed">Removed</Translate>
            </span>
          </dt>
          <dd>{discussionMemberEntity.removed ? 'true' : 'false'}</dd>
          <dt>
            <span id="nickName">
              <Translate contentKey="alippoApp.discussionMember.nickName">Nick Name</Translate>
            </span>
          </dt>
          <dd>{discussionMemberEntity.nickName}</dd>
          <dt>
            <span id="color">
              <Translate contentKey="alippoApp.discussionMember.color">Color</Translate>
            </span>
          </dt>
          <dd>{discussionMemberEntity.color}</dd>
          <dt>
            <span id="blocked">
              <Translate contentKey="alippoApp.discussionMember.blocked">Blocked</Translate>
            </span>
          </dt>
          <dd>{discussionMemberEntity.blocked ? 'true' : 'false'}</dd>
          <dt>
            <span id="admin">
              <Translate contentKey="alippoApp.discussionMember.admin">Admin</Translate>
            </span>
          </dt>
          <dd>{discussionMemberEntity.admin ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.discussionMember.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {discussionMemberEntity.createdAt ? (
              <TextFormat value={discussionMemberEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.discussionMember.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {discussionMemberEntity.updatedAt ? (
              <TextFormat value={discussionMemberEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.discussionMember.user">User</Translate>
          </dt>
          <dd>{discussionMemberEntity.user ? discussionMemberEntity.user.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.discussionMember.removedByUser">Removed By User</Translate>
          </dt>
          <dd>{discussionMemberEntity.removedByUser ? discussionMemberEntity.removedByUser.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.discussionMember.discussion">Discussion</Translate>
          </dt>
          <dd>{discussionMemberEntity.discussion ? discussionMemberEntity.discussion.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/discussion-member" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/discussion-member/${discussionMemberEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default DiscussionMemberDetail;
