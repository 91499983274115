import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { JhiItemCount, JhiPagination, Translate, getSortState } from 'react-jhipster';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ConfigType } from 'app/shared/model/enumerations/config-type.model';
import { overrideFiltersStateWithQueryParams, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { debounce } from 'lodash';
import { getEntities } from './config.reducer';

type ConfigsFilterType = {
  configType: keyof typeof ConfigType | 'NONE';
  configKey: string | null;
};

export const Config = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );
  const [configsFilter, setConfigsFilter] = useState<ConfigsFilterType>(
    overrideFiltersStateWithQueryParams(
      {
        configType: 'NONE',
        configKey: null,
      } as ConfigsFilterType,
      props.location.search
    )
  );
  const [configsLoading, setConfigsLoading] = useState(false);

  const configList = useAppSelector(state => state.config.entities);
  const loading = useAppSelector(state => state.config.loading);
  const totalItems = useAppSelector(state => state.config.totalItems);

  const getAllEntities = () => {
    setConfigsLoading(false);

    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        props: buildSearchParamsString(),
      })
    ).then(() => setConfigsLoading(false));

    const params = buildSearchParamsString();

    const endUrl = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}${params}`;

    if (window.location.search !== endUrl) {
      window.history.replaceState(null, '', `${props.location.pathname}${endUrl}`);
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState, configsFilter]);

  const buildSearchParamsString = () => {
    let paramsString = '';

    if (configsFilter.configType && configsFilter.configType !== 'NONE') {
      paramsString += `&configType.equals=${configsFilter.configType}`;
    }

    if (configsFilter.configKey) {
      paramsString += `&configKey.contains=${configsFilter.configKey}`;
    }

    return paramsString;
  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    getAllEntities();
  };

  const handleFilterChange = e => {
    setConfigsFilter(prev => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const debouncedHandleFilterChange = useMemo(() => debounce(handleFilterChange, 500), []);

  const { match } = props;

  return (
    <div>
      <h2 id="config-heading" data-cy="ConfigHeading">
        <Translate contentKey="alippoApp.config.home.title">Configs</Translate>
      </h2>
      <div className="d-flex justify-content-end">
        <Button className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="alippoApp.config.home.refreshListLabel">Refresh List</Translate>
        </Button>
        <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="alippoApp.config.home.createLabel">Create new Config</Translate>
        </Link>
      </div>
      <div className="my-4">
        <Form>
          <Row md={3}>
            <Col>
              <FormGroup>
                <Label for="configType">Config Type</Label>
                <Input
                  id="configType"
                  name="configType"
                  type="select"
                  onChange={handleFilterChange}
                  defaultValue={configsFilter.configType}
                >
                  {(Object.keys(ConfigType) as Array<keyof typeof ConfigType>).map(status => (
                    <option value={status} key={status}>
                      {status}
                    </option>
                  ))}
                  <option value="NONE">-</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="configKey">Config Key</Label>
                <Input
                  id="configKey"
                  name="configKey"
                  placeholder="Enter Config Key"
                  type="text"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={configsFilter.configKey}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <h6 className="text-center mt-2" style={configsLoading ? { opacity: 1 } : { opacity: 0 }}>
          Loading ...
        </h6>
      </div>
      <div className="table-responsive">
        {configList && configList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="alippoApp.config.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('configType')}>
                  <Translate contentKey="alippoApp.config.configType">Config Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('configKey')}>
                  <Translate contentKey="alippoApp.config.configKey">Config Key</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('value')}>
                  <Translate contentKey="alippoApp.config.value">Value</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {configList.map((config, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`${match.url}/${config.id}`} color="link" size="sm">
                      {config.id}
                    </Button>
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.ConfigType.${config.configType}`} />
                  </td>
                  <td>{config.configKey}</td>
                  <td>
                    <div className="clamp-text" data-clamp-size="3">
                      {config.value}
                    </div>
                  </td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${config.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${config.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${config.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="alippoApp.config.home.notFound">No Configs found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={configList && configList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Config;
