import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { byteSize, Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities } from './res-user.reducer';
import { IResUser } from 'app/shared/model/res-user.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overrideFiltersStateWithQueryParams, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

type ResUserFilterType = {
  userId: number | null;
};

export const ResUser = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );
  const [resUserFilter] = useState<ResUserFilterType>(
    overrideFiltersStateWithQueryParams(
      {
        userId: null,
      } as ResUserFilterType,
      props.location.search
    )
  );
  const [resUsersLoading, setResUsersLoading] = useState(false);

  const resUserList = useAppSelector(state => state.resUser.entities);
  const loading = useAppSelector(state => state.resUser.loading);
  const totalItems = useAppSelector(state => state.resUser.totalItems);

  const getAllEntities = () => {
    setResUsersLoading(true);

    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        props: buildSearchParamsString(),
      })
    ).then(() => setResUsersLoading(false));

    const params = buildSearchParamsString();

    const endUrl = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}${params}`;

    if (window.location.search !== endUrl) {
      window.history.replaceState(null, '', `${props.location.pathname}${endUrl}`);
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState, resUserFilter]);

  const buildSearchParamsString = () => {
    let paramsString = '';

    if (resUserFilter.userId) {
      paramsString += `&userId.equals=${resUserFilter.userId}`;
    }

    return paramsString;
  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    getAllEntities();
  };

  const { match } = props;

  return (
    <div>
      <h2 id="res-user-heading" data-cy="ResUserHeading">
        <Translate contentKey="alippoApp.resUser.home.title">Res Users</Translate>
      </h2>
      <div className="d-flex justify-content-end">
        <Button className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="alippoApp.resUser.home.refreshListLabel">Refresh List</Translate>
        </Button>
        <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="alippoApp.resUser.home.createLabel">Create new Res User</Translate>
        </Link>
      </div>
      <h6 className="text-center my-4" style={resUsersLoading ? { opacity: 1 } : { opacity: 0 }}>
        Loading ...
      </h6>
      <div className="table-responsive">
        {resUserList && resUserList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="alippoApp.resUser.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="alippoApp.resUser.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('url')}>
                  <Translate contentKey="alippoApp.resUser.url">Url</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('resState')}>
                  <Translate contentKey="alippoApp.resUser.resState">Res State</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('resType')}>
                  <Translate contentKey="alippoApp.resUser.resType">Res Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('resSubType')}>
                  <Translate contentKey="alippoApp.resUser.resSubType">Res Sub Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('mediaType')}>
                  <Translate contentKey="alippoApp.resUser.mediaType">Media Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  <Translate contentKey="alippoApp.resUser.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('language')}>
                  <Translate contentKey="alippoApp.resUser.language">Language</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('videoControlsConfig')}>
                  <Translate contentKey="alippoApp.resUser.videoControlsConfig">Video Controls Config</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('aspectRatio')}>
                  <Translate contentKey="alippoApp.resUser.aspectRatio">Aspect Ratio</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="alippoApp.resUser.createdAt">Created At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updatedAt')}>
                  <Translate contentKey="alippoApp.resUser.updatedAt">Updated At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.resUser.user">User</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {resUserList.map((resUser, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`${match.url}/${resUser.id}`} color="link" size="sm">
                      {resUser.id}
                    </Button>
                  </td>
                  <td>{resUser.name}</td>
                  <td>{resUser.url}</td>
                  <td>
                    <Translate contentKey={`alippoApp.ResState.${resUser.resState}`} />
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.ResType.${resUser.resType}`} />
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.ResSubType.${resUser.resSubType}`} />
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.ResMediaType.${resUser.mediaType}`} />
                  </td>
                  <td>{resUser.description}</td>
                  <td>
                    <Translate contentKey={`alippoApp.Language.${resUser.language}`} />
                  </td>
                  <td>{resUser.videoControlsConfig}</td>
                  <td>{resUser.aspectRatio}</td>
                  <td>{resUser.createdAt ? <TextFormat type="date" value={resUser.createdAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{resUser.updatedAt ? <TextFormat type="date" value={resUser.updatedAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{resUser.user ? resUser.user.id : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${resUser.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${resUser.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${resUser.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="alippoApp.resUser.home.notFound">No Res Users found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={resUserList && resUserList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ResUser;
