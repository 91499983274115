import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './product-translation.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ProductTranslationDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const productTranslationEntity = useAppSelector(state => state.productTranslation.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="productTranslationDetailsHeading">
          <Translate contentKey="alippoApp.productTranslation.detail.title">ProductTranslation</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{productTranslationEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.productTranslation.name">Name</Translate>
            </span>
          </dt>
          <dd>{productTranslationEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.productTranslation.description">Description</Translate>
            </span>
          </dt>
          <dd>{productTranslationEntity.description}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="alippoApp.productTranslation.language">Language</Translate>
            </span>
          </dt>
          <dd>{productTranslationEntity.language}</dd>
          <dt>
            <span id="isDefault">
              <Translate contentKey="alippoApp.productTranslation.isDefault">Is Default</Translate>
            </span>
          </dt>
          <dd>{productTranslationEntity.isDefault ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.productTranslation.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {productTranslationEntity.createdAt ? (
              <TextFormat value={productTranslationEntity.createdAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.productTranslation.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {productTranslationEntity.updatedAt ? (
              <TextFormat value={productTranslationEntity.updatedAt} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.productTranslation.product">Product</Translate>
          </dt>
          <dd>{productTranslationEntity.product ? productTranslationEntity.product.name : ''}</dd>
        </dl>
        <Button tag={Link} to="/product-translation" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/product-translation/${productTranslationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ProductTranslationDetail;
