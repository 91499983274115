export enum WhatsappMessageStatus {
  CREATED = 'CREATED',

  SENT = 'SENT',

  DELIVERED = 'DELIVERED',

  READ = 'READ',

  DELETED = 'DELETED',

  FAILED = 'FAILED',
}
