import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import PlanItem from './plan-item';
import PlanItemDetail from './plan-item-detail';
import PlanItemUpdate from './plan-item-update';
import PlanItemDeleteDialog from './plan-item-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={PlanItemUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={PlanItemUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={PlanItemDetail} />
      <ErrorBoundaryRoute path={match.url} component={PlanItem} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={PlanItemDeleteDialog} />
  </>
);

export default Routes;
