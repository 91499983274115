import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ICourse } from 'app/shared/model/course.model';
import { getEntities as getCourses } from 'app/entities/course/course.reducer';
import { IPlan } from 'app/shared/model/plan.model';
import { getEntities as getPlans } from 'app/entities/plan/plan.reducer';
import { IProduct } from 'app/shared/model/product.model';
import { getEntities as getProducts } from 'app/entities/product/product.reducer';
import { IVoucher } from 'app/shared/model/voucher.model';
import { getEntities as getVouchers } from 'app/entities/voucher/voucher.reducer';
import { getEntity, updateEntity, createEntity, reset } from './sku.reducer';
import { ISku } from 'app/shared/model/sku.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { SkuType } from 'app/shared/model/enumerations/sku-type.model';
import { SkuStatus } from 'app/shared/model/enumerations/sku-status.model';

export const SkuUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const courses = useAppSelector(state => state.course.entities);
  const plans = useAppSelector(state => state.plan.entities);
  const products = useAppSelector(state => state.product.entities);
  const vouchers = useAppSelector(state => state.voucher.entities);
  const skuEntity = useAppSelector(state => state.sku.entity);
  const loading = useAppSelector(state => state.sku.loading);
  const updating = useAppSelector(state => state.sku.updating);
  const updateSuccess = useAppSelector(state => state.sku.updateSuccess);
  const skuTypeValues = Object.keys(SkuType);
  const skuStatusValues = Object.keys(SkuStatus);
  const handleClose = () => {
    props.history.push('/sku' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getCourses({}));
    dispatch(getPlans({}));
    dispatch(getProducts({}));
    dispatch(getVouchers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.earlyAccessEndDate = convertDateTimeToServer(values.earlyAccessEndDate);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...skuEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          earlyAccessEndDate: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          type: 'COURSE',
          status: 'DRAFT',
          ...skuEntity,
          earlyAccessEndDate: convertDateTimeFromServer(skuEntity.earlyAccessEndDate),
          createdAt: convertDateTimeFromServer(skuEntity.createdAt),
          updatedAt: convertDateTimeFromServer(skuEntity.updatedAt),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.sku.home.createOrEditLabel" data-cy="SkuCreateUpdateHeading">
            <Translate contentKey="alippoApp.sku.home.createOrEditLabel">Create or edit a Sku</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="sku-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField label={translate('alippoApp.sku.type')} id="sku-type" name="type" data-cy="type" type="select">
                {skuTypeValues.map(skuType => (
                  <option value={skuType} key={skuType}>
                    {translate('alippoApp.SkuType' + skuType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField label={translate('alippoApp.sku.status')} id="sku-status" name="status" data-cy="status" type="select">
                {skuStatusValues.map(skuStatus => (
                  <option value={skuStatus} key={skuStatus}>
                    {translate('alippoApp.SkuStatus' + skuStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.sku.earlyAccessEnabled')}
                id="sku-earlyAccessEnabled"
                name="earlyAccessEnabled"
                data-cy="earlyAccessEnabled"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.sku.earlyAccessEndDate')}
                id="sku-earlyAccessEndDate"
                name="earlyAccessEndDate"
                data-cy="earlyAccessEndDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField label={translate('alippoApp.sku.atp')} id="sku-atp" name="atp" data-cy="atp" check type="checkbox" />
              <ValidatedField
                label={translate('alippoApp.sku.createdAt')}
                id="sku-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.sku.updatedAt')}
                id="sku-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/sku" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SkuUpdate;
