import dayjs from 'dayjs';
import { IResRule } from 'app/shared/model/res-rule.model';
import { IVoucher } from 'app/shared/model/voucher.model';
import { RuleType } from 'app/shared/model/enumerations/rule-type.model';
import { RuleBasis } from 'app/shared/model/enumerations/rule-basis.model';
import { CurrencyCode } from 'app/shared/model/enumerations/currency-code.model';
import { RuleCurrency } from 'app/shared/model/enumerations/rule-currency.model';

export interface IRule {
  id?: number;
  name?: string;
  description?: string | null;
  ruleType?: RuleType;
  fromDateTime?: string | null;
  duration?: number | null;
  disabled?: boolean | null;
  minCriteria?: number | null;
  amount?: number | null;
  basis?: RuleBasis | null;
  maxAmount?: number | null;
  sellingPrice?: number | null;
  sellingPriceCurrencyCode?: CurrencyCode | null;
  mrpPrice?: number | null;
  mrpPriceCurrencyCode?: CurrencyCode | null;
  ruleCurrency?: RuleCurrency | null;
  currencyCode?: CurrencyCode | null;
  code?: string | null;
  usageLimit?: number | null;
  usagePerCustomer?: number | null;
  timesUsed?: number | null;
  sortOrder?: number;
  createdAt?: string;
  updatedAt?: string;
  expiryPeriodInSeconds?: number | null;
  config?: string | null;
  resources?: IResRule[] | null;
  voucher?: IVoucher | null;
}

export const defaultValue: Readonly<IRule> = {
  disabled: false,
};
