import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ISku } from 'app/shared/model/sku.model';
import { getEntities as getSkus } from 'app/entities/sku/sku.reducer';
import { ILocation } from 'app/shared/model/location.model';
import { getEntities as getLocations } from 'app/entities/location/location.reducer';
import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntity, updateEntity, createEntity, reset } from './inventory.reducer';
import { IInventory } from 'app/shared/model/inventory.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CurrencyCode } from 'app/shared/model/enumerations/currency-code.model';

export const InventoryUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const skus = useAppSelector(state => state.sku.entities);
  const locations = useAppSelector(state => state.location.entities);
  const users = useAppSelector(state => state.userManagement.users);
  const inventoryEntity = useAppSelector(state => state.inventory.entity);
  const loading = useAppSelector(state => state.inventory.loading);
  const updating = useAppSelector(state => state.inventory.updating);
  const updateSuccess = useAppSelector(state => state.inventory.updateSuccess);
  const currencyCodeValues = Object.keys(CurrencyCode);
  const handleClose = () => {
    props.history.push('/inventory' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getSkus({}));
    dispatch(getLocations({}));
    dispatch(getUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.fromDateTime = convertDateTimeToServer(values.fromDateTime);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...inventoryEntity,
      ...values,
      sku: { id: values.sku },
      serviceability: locations.find(it => it.id.toString() === values.serviceability.toString()),
      supplier: { id: values.supplier },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          fromDateTime: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          sellingPriceCurrencyCode: 'INR',
          groupSellingPriceCurrencyCode: 'INR',
          mrpPriceCurrencyCode: 'INR',
          ...inventoryEntity,
          fromDateTime: convertDateTimeFromServer(inventoryEntity.fromDateTime),
          createdAt: convertDateTimeFromServer(inventoryEntity.createdAt),
          updatedAt: convertDateTimeFromServer(inventoryEntity.updatedAt),
          sku: inventoryEntity?.sku?.id,
          serviceability: inventoryEntity?.serviceability?.id,
          supplier: inventoryEntity?.supplier?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.inventory.home.createOrEditLabel" data-cy="InventoryCreateUpdateHeading">
            <Translate contentKey="alippoApp.inventory.home.createOrEditLabel">Create or edit a Inventory</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="inventory-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.inventory.quantity')}
                id="inventory-quantity"
                name="quantity"
                data-cy="quantity"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.inventory.fromDateTime')}
                id="inventory-fromDateTime"
                name="fromDateTime"
                data-cy="fromDateTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('alippoApp.inventory.duration')}
                id="inventory-duration"
                name="duration"
                data-cy="duration"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.inventory.sellingPrice')}
                id="inventory-sellingPrice"
                name="sellingPrice"
                data-cy="sellingPrice"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.inventory.sellingPriceCurrencyCode')}
                id="inventory-sellingPriceCurrencyCode"
                name="sellingPriceCurrencyCode"
                data-cy="sellingPriceCurrencyCode"
                type="select"
              >
                {currencyCodeValues.map(currencyCode => (
                  <option value={currencyCode} key={currencyCode}>
                    {translate('alippoApp.CurrencyCode' + currencyCode)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.inventory.groupSellingPrice')}
                id="inventory-groupSellingPrice"
                name="groupSellingPrice"
                data-cy="groupSellingPrice"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.inventory.groupSellingPriceCurrencyCode')}
                id="inventory-groupSellingPriceCurrencyCode"
                name="groupSellingPriceCurrencyCode"
                data-cy="groupSellingPriceCurrencyCode"
                type="select"
              >
                {currencyCodeValues.map(currencyCode => (
                  <option value={currencyCode} key={currencyCode}>
                    {translate('alippoApp.CurrencyCode' + currencyCode)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.inventory.mrpPrice')}
                id="inventory-mrpPrice"
                name="mrpPrice"
                data-cy="mrpPrice"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.inventory.mrpPriceCurrencyCode')}
                id="inventory-mrpPriceCurrencyCode"
                name="mrpPriceCurrencyCode"
                data-cy="mrpPriceCurrencyCode"
                type="select"
              >
                {currencyCodeValues.map(currencyCode => (
                  <option value={currencyCode} key={currencyCode}>
                    {translate('alippoApp.CurrencyCode' + currencyCode)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.inventory.createdAt')}
                id="inventory-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.inventory.updatedAt')}
                id="inventory-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField id="inventory-sku" name="sku" data-cy="sku" label={translate('alippoApp.inventory.sku')} />
              <ValidatedField
                id="inventory-serviceability"
                name="serviceability"
                data-cy="serviceability"
                label={translate('alippoApp.inventory.serviceability')}
                type="select"
              >
                <option value="" key="0" />
                {locations
                  ? locations.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.code}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="inventory-supplier"
                name="supplier"
                data-cy="supplier"
                label={translate('alippoApp.inventory.supplier')}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/inventory" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default InventoryUpdate;
