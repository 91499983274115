import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';

export const EntitiesMenu = props => (
  <NavDropdown
    icon="th-list"
    name={translate('global.menu.entities.main')}
    id="entity-menu"
    data-cy="entity"
    style={{ maxHeight: '80vh', overflow: 'auto' }}
  >
    <>{/* to avoid warnings when empty */}</>
    <MenuItem icon="asterisk" to="/address">
      <Translate contentKey="global.menu.entities.address" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/banner">
      <Translate contentKey="global.menu.entities.banner" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/beneficiary-info">
      <Translate contentKey="global.menu.entities.beneficiaryInfo" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/biz-card">
      <Translate contentKey="global.menu.entities.bizCard" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cart">
      <Translate contentKey="global.menu.entities.cart" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cart-item">
      <Translate contentKey="global.menu.entities.cartItem" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/category">
      <Translate contentKey="global.menu.entities.category" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/category-translation">
      <Translate contentKey="global.menu.entities.categoryTranslation" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/course">
      <Translate contentKey="global.menu.entities.course" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/course-discussion">
      <Translate contentKey="global.menu.entities.courseDiscussion" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/course-section">
      <Translate contentKey="global.menu.entities.courseSection" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/course-section-lesson">
      <Translate contentKey="global.menu.entities.courseSectionLesson" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/course-section-tip">
      <Translate contentKey="global.menu.entities.courseSectionTip" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/course-translation">
      <Translate contentKey="global.menu.entities.courseTranslation" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/config">
      <Translate contentKey="global.menu.entities.config" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/device">
      <Translate contentKey="global.menu.entities.device" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/feedback">
      <Translate contentKey="global.menu.entities.feedback" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/inventory">
      <Translate contentKey="global.menu.entities.inventory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/lesson">
      <Translate contentKey="global.menu.entities.lesson" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/lesson-translation">
      <Translate contentKey="global.menu.entities.lessonTranslation" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/location">
      <Translate contentKey="global.menu.entities.location" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/order-info">
      <Translate contentKey="global.menu.entities.orderInfo" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/order-item">
      <Translate contentKey="global.menu.entities.orderItem" />
    </MenuItem>

    <MenuItem icon="asterisk" to="/order-item-plan">
      <Translate contentKey="global.menu.entities.orderItemPlan" />
    </MenuItem>

    <MenuItem icon="asterisk" to="/payment">
      <Translate contentKey="global.menu.entities.payment" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/payment-source">
      <Translate contentKey="global.menu.entities.paymentSource" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/payment-state-transition">
      <Translate contentKey="global.menu.entities.paymentStateTransition" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/plan">
      <Translate contentKey="global.menu.entities.plan" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/plan-item">
      <Translate contentKey="global.menu.entities.planItem" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/plan-translation">
      <Translate contentKey="global.menu.entities.planTranslation" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/product">
      <Translate contentKey="global.menu.entities.product" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/product-translation">
      <Translate contentKey="global.menu.entities.productTranslation" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/rating-option">
      <Translate contentKey="global.menu.entities.ratingOption" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-biz-card">
      <Translate contentKey="global.menu.entities.resBizCard" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-category">
      <Translate contentKey="global.menu.entities.resCategory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-course">
      <Translate contentKey="global.menu.entities.resCourse" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-course-section">
      <Translate contentKey="global.menu.entities.resCourseSection" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-course-section-lesson">
      <Translate contentKey="global.menu.entities.resCourseSectionLesson" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-course-section-tip">
      <Translate contentKey="global.menu.entities.resCourseSectionTip" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-order-item">
      <Translate contentKey="global.menu.entities.resOrderItem" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-plan">
      <Translate contentKey="global.menu.entities.resPlan" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-product">
      <Translate contentKey="global.menu.entities.resProduct" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-review">
      <Translate contentKey="global.menu.entities.resReview" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-rule">
      <Translate contentKey="global.menu.entities.resRule" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-user-course-section-lesson">
      <Translate contentKey="global.menu.entities.resUserCourseSectionLesson" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/review">
      <Translate contentKey="global.menu.entities.review" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/rule">
      <Translate contentKey="global.menu.entities.rule" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/rule-ledger">
      <Translate contentKey="global.menu.entities.ruleLedger" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/rule-translation">
      <Translate contentKey="global.menu.entities.ruleTranslation" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/section">
      <Translate contentKey="global.menu.entities.section" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/section-translation">
      <Translate contentKey="global.menu.entities.sectionTranslation" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/seo-metadata">
      <Translate contentKey="global.menu.entities.seoMetadata" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/sku">
      <Translate contentKey="global.menu.entities.sku" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/slug">
      <Translate contentKey="global.menu.entities.slug" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/suggestion">
      <Translate contentKey="global.menu.entities.suggestion" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/tenant">
      <Translate contentKey="global.menu.entities.tenant" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/tenant-extra">
      <Translate contentKey="global.menu.entities.tenantExtra" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/tip">
      <Translate contentKey="global.menu.entities.tip" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/tip-translation">
      <Translate contentKey="global.menu.entities.tipTranslation" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/sku-attribute">
      <Translate contentKey="global.menu.entities.skuAttribute" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/sku-attribute-variant">
      <Translate contentKey="global.menu.entities.skuAttributeVariant" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/voucher">
      <Translate contentKey="global.menu.entities.voucher" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/voucher-translation">
      <Translate contentKey="global.menu.entities.voucherTranslation" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-course-section-lesson">
      <Translate contentKey="global.menu.entities.userCourseSectionLesson" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-extra">
      <Translate contentKey="global.menu.entities.userExtra" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-topic">
      <Translate contentKey="global.menu.entities.userTopic" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/sku-reservation">
      <Translate contentKey="global.menu.entities.skuReservation" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-discussion">
      <Translate contentKey="global.menu.entities.resDiscussion" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/discussion-member">
      <Translate contentKey="global.menu.entities.discussionMember" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/user-segment">
      <Translate contentKey="global.menu.entities.userSegment" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/sku-segment">
      <Translate contentKey="global.menu.entities.skuSegment" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/whatsapp-log">
      <Translate contentKey="global.menu.entities.whatsappLog" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/form">
      <Translate contentKey="global.menu.entities.form" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/sku-application">
      <Translate contentKey="global.menu.entities.skuApplication" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/sku-application-state-transition">
      <Translate contentKey="global.menu.entities.skuApplicationStateTransition" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/message-template">
      <Translate contentKey="global.menu.entities.messageTemplate" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-user">
      <Translate contentKey="global.menu.entities.resUser" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res">
      <Translate contentKey="global.menu.entities.res" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/task">
      <Translate contentKey="global.menu.entities.task" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/step">
      <Translate contentKey="global.menu.entities.step" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-task">
      <Translate contentKey="global.menu.entities.resTask" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/res-step">
      <Translate contentKey="global.menu.entities.resStep" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cluster">
      <Translate contentKey="global.menu.entities.cluster" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/course-cluster">
      <Translate contentKey="global.menu.entities.courseCluster" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/order-item-sku-attribute">
      <Translate contentKey="global.menu.entities.orderItemSkuAttribute" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cart-item-sku-attribute">
      <Translate contentKey="global.menu.entities.cartItemSkuAttribute" />
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
