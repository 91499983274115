import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { ICourseDiscussion } from 'app/shared/model/course-discussion.model';
import { GroupLeaveReason } from 'app/shared/model/enumerations/group-leave-reason.model';

export interface IDiscussionMember {
  id?: number;
  joinedOn?: string | null;
  hasMutedGroup?: boolean | null;
  leaveReason?: GroupLeaveReason | null;
  removed?: boolean | null;
  nickName?: string | null;
  color?: string | null;
  blocked?: boolean | null;
  admin?: boolean | null;
  createdAt?: string;
  updatedAt?: string;
  user?: IUser | null;
  removedByUser?: IUser | null;
  discussion?: ICourseDiscussion | null;
}

export const defaultValue: Readonly<IDiscussionMember> = {
  hasMutedGroup: false,
  removed: false,
  blocked: false,
  admin: false,
};
