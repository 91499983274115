import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IFeedback {
  id?: number;
  rating?: number | null;
  feedback?: string | null;
  createdAt?: string;
  updatedAt?: string;
  user?: IUser | null;
}

export const defaultValue: Readonly<IFeedback> = {};
