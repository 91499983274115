import { Translate, ValidatedField } from 'react-jhipster';
import React from 'react';
import type { MouseEvent } from 'react';
import { Button, Row, Col, FormText, Modal, ModalBody, Input, Label } from 'reactstrap';
import { useState } from 'react';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';
import { toast } from 'react-toastify';

type Props<Entity> = {
  value: Record<string, unknown>;
  id: string;
  label: string;
  cyDataKey: string;
  entityShape: Entity;
  entityKey: keyof Entity;
  handleGetUpdatedEntity: (newValue: Entity) => void;
};

const JsonEntryEditor = <Entity extends Record<string, any>>(props: Props<Entity>) => {
  const { value, label, id, cyDataKey, entityShape, entityKey, handleGetUpdatedEntity } = props;

  const [showUploaderModal, seShowUploaderModal] = useState(false);
  const [enteredConfig, setEnteredConfig] = useState<string>('');

  const openJsonUploader = () => {
    seShowUploaderModal(true);
  };

  const closeJsonUploader = () => {
    seShowUploaderModal(false);
  };

  const handleSubmitJsonValue = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      if (enteredConfig) {
        const validatedJson = JSON.parse(enteredConfig);

        const updatedEntityShape = {
          ...entityShape,
          [entityKey]: JSON.stringify(validatedJson),
        } as Entity;

        handleGetUpdatedEntity(updatedEntityShape);
        toast.success('Properties uploaded successfully!');
      }
    } catch (err) {
      console.error(err);
      toast.error('Invalid JSON!');
    } finally {
      closeJsonUploader();
    }
  };

  const handleEditJsonValue: ReactJsonViewProps['onEdit'] = ({ updated_src }) => {
    try {
      if (updated_src) {
        const validatedJsonString = JSON.stringify(updated_src);

        const updatedEntityShape = {
          ...entityShape,
          [entityKey]: validatedJsonString,
        } as Entity;

        handleGetUpdatedEntity(updatedEntityShape);
      }
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong! Contact tech team!');
    }
  };

  return (
    <>
      <label htmlFor={id}>{label}</label>
      <Button className="d-block mb-2" color="info" size="sm" onClick={openJsonUploader}>
        <FontAwesomeIcon icon={faUpload} /> Upload
      </Button>

      <div style={{ marginBottom: '1rem' }}>
        <ReactJson
          src={value}
          onEdit={handleEditJsonValue}
          onAdd={handleEditJsonValue}
          onDelete={handleEditJsonValue}
          displayObjectSize={false}
          displayDataTypes={false}
          collapsed
          groupArraysAfterLength={300}
        />
      </div>

      <Modal isOpen={showUploaderModal} toggle={closeJsonUploader}>
        <ModalBody>
          <>
            <Label htmlFor={id}>{label}</Label>
            <Input
              id={id}
              type="textarea"
              name="jsonValue"
              placeholder="Please add properties JSON..."
              style={{ minHeight: '15rem' }}
              data-cy={cyDataKey}
              value={enteredConfig}
              onChange={e => setEnteredConfig(e.target.value)}
            />
            <div className="d-flex w-100 justify-content-end mt-4">
              <Button className="mr-2" color="secondary" onClick={closeJsonUploader}>
                <Translate contentKey="entity.action.cancel">Cancel</Translate>
              </Button>
              <Button color="success" type="button" onClick={handleSubmitJsonValue} disabled={!enteredConfig.length}>
                Save
              </Button>
            </div>
          </>
        </ModalBody>
      </Modal>
    </>
  );
};

export default JsonEntryEditor;
