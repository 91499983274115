import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, reset } from 'app/entities/course-discussion/course-discussion.reducer';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { Link, useLocation } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { LogTypeMasterSchema } from './models';
import { IConfig } from 'app/shared/model/config.model';
import { Form } from '@rjsf/bootstrap-4';
import validator from '@rjsf/validator-ajv8';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { IChangeEvent } from '@rjsf/core';
import { randomUUID } from 'crypto';

export default function DiscussionSessionLogs() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();
  const locationState: any = location.state;
  const dispatch = useAppDispatch();
  const [sessionLogs, setSessionLogs] = useState<any[]>(null);
  const [isLoadingSessionLogs, setIsLoadingSessionLogs] = useState<boolean>(false);
  const [pageState, setPageState] = useState<any>(null);
  const [showAddSessionLogModal, setShowAddSessionLogModal] = useState<boolean>(false);
  const [showDeleteSessionLogModal, setShowDeleteSessionLogModal] = useState<boolean>(false);
  const [logTypeToAdd, setLogTypeToAdd] = useState<string>(null);
  const [isLoadingLogTypeMasterSchema, setIsLoadingLogTypeMasterSchema] = useState<boolean>(false);
  const [logTypeMasterSchema, setLogTypeMasterSchema] = useState<LogTypeMasterSchema>(null);
  const [typeSchemas, setTypeSchemas] = useState<{ [type: string]: any }>(null);
  const [typeConverters, setTypeConverters] = useState<{ [type: string]: (input: any) => any }>(null);
  const [uiSchemas, setUiSchemas] = useState<{ [type: string]: (input: any) => any }>(null);
  const [currentSchema, setCurrentSchema] = useState<RJSFSchema>(null);
  const [currentUiSchema, setCurrentUiSchema] = useState<UiSchema>(null);
  const [activeTab, setActiveTab] = useState<string>('1');
  const [currentConvertedSessionLog, setCurrentConvertedSessionLog] = useState<any>(null);
  const [currentFormData, setCurrentFormData] = useState<any>(null);
  const [isFormReadyToSubmit, setIsFormReadyToSubmit] = useState<boolean>(false);
  const [isProcessingSubmit, setIsProcessingSubmit] = useState<boolean>(false);
  const [isProcessingDelete, setIsProcessingDelete] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>(null);
  const [currentMessageId, setCurrentMessageId] = useState<string>(null);

  let inheritedDiscussion = null;
  if (locationState) {
    inheritedDiscussion = locationState.discussion;
  }
  useEffect(() => {
    dispatch(reset());
    fetchLogTypeMasterData();
  }, []);

  const courseDiscussionEntity = useAppSelector(state => state.courseDiscussion.entity);

  const handleFetchDiscussion = (values: Record<string, string>) => {
    const discussionId = values.discussionId;
    setIsLoading(true);
    dispatch(getEntity(discussionId));
    setIsLoading(false);
  };

  const refreshSessionLogs = async () => {
    const discussionId = courseDiscussionEntity?.id;
    setIsLoadingSessionLogs(true);
    try {
      const page = 0;
      const size = 40;
      const apiUrl = `api/inappchat/group/${discussionId}/session-logs?page=${page}&size=${size}`;
      const { data } = await axios.get(apiUrl);
      if (data.length === 0) {
        toast.error('No more session logs available');
      } else {
        setSessionLogs(data.content);
        setPageState({ ...data, content: null });
      }
    } catch (err) {
      toast.error('Some error occurred');
    }
    setIsLoadingSessionLogs(false);
  };
  const fetchSessionLogsNextPage = async () => {
    const discussionId = courseDiscussionEntity?.id;
    setIsLoadingSessionLogs(true);
    try {
      let page = 0;
      let size = 1;
      if (pageState && !pageState.last) {
        page = pageState.number + 1;
        size = pageState.size;
      }
      const apiUrl = `api/inappchat/group/${discussionId}/session-logs?page=${page}&size=${size}`;
      const { data } = await axios.get(apiUrl);
      if (data.length === 0) {
        toast.error('No more session logs available');
      } else {
        setSessionLogs(sessionLogs.concat(data.content));
        setPageState({ ...data, content: null });
      }
    } catch (err) {
      toast.error('Some error occurred');
    }
    setIsLoadingSessionLogs(false);
  };

  const fetchLogTypeMasterData = async () => {
    if (logTypeMasterSchema === null) {
      setIsLoadingLogTypeMasterSchema(true);
      try {
        const apiUrl = `api/configs?configKey.equals=sessionLogMasterData`;
        const { data }: { data: IConfig[] } = await axios.get(apiUrl);
        if (data && data.length > 0) {
          setLogTypeMasterSchema(JSON.parse(data[0].value));
        } else {
          toast.error('Could not find the conversion schema config');
        }
      } catch (err) {
        toast.error('Some error occurred');
      }
      setIsLoadingLogTypeMasterSchema(false);
    }
  };

  useEffect(() => {
    if (logTypeMasterSchema) {
      const schemaMap = {};
      logTypeMasterSchema.typeSchemas?.forEach(item => {
        schemaMap[item.type] = item.schema;
      });
      setTypeSchemas(schemaMap);
      const typeSchemaMappers = {};
      logTypeMasterSchema.typeMappers?.forEach(item => {
        // eslint-disable-next-line @typescript-eslint/no-implied-eval
        typeSchemaMappers[item.type] = new Function('return ' + item.mapperFunction)();
      });
      setTypeConverters(typeSchemaMappers);
      const uiSchemasMap = {};
      logTypeMasterSchema.uiSchemas?.forEach(item => {
        uiSchemasMap[item.type] = item.schema;
      });
      setUiSchemas(uiSchemasMap);
    }
  }, [logTypeMasterSchema]);

  const addSessionLog = () => {
    setCurrentMessageId(null);
    setShowAddSessionLogModal(true);
  };
  const closeAddSessionLogModal = () => {
    setShowAddSessionLogModal(false);
  };
  const setLogType = value => {
    setLogTypeToAdd(value);
  };

  const handleSubmitAddSessionLog = async () => {
    if (currentConvertedSessionLog) {
      const discussionId = courseDiscussionEntity?.id;
      setIsProcessingSubmit(true);
      setSubmitError(null);
      try {
        const apiUrl = `api/inappchat/group/${discussionId}/session-log?messageId=${currentMessageId ?? ''}`;
        const res = await axios.post(apiUrl, currentConvertedSessionLog);
        if (res.status >= 200 && res.status <= 299) {
          toast.success('Added session log successfully');
          setShowAddSessionLogModal(false);
        } else {
          toast.error('Failed to add session log');
          setSubmitError(res.statusText + '\n' + JSON.stringify(res.data) ?? '');
        }
      } catch (err) {
        toast.error('Some error occurred');
        setSubmitError(JSON.stringify(err));
      }
      setIsProcessingSubmit(false);
    }
  };

  useEffect(() => {
    if (!showAddSessionLogModal) {
      resetForm();
    }
  }, [showAddSessionLogModal]);

  const resetForm = () => {
    setCurrentFormData(null);
    setCurrentSchema(null);
    setCurrentUiSchema(null);
    setCurrentConvertedSessionLog(null);
    setActiveTab('1');
    setLogTypeToAdd(null);
    setSubmitError(null);
    setIsFormReadyToSubmit(false);
    setIsLoading(false);
    setIsLoadingLogTypeMasterSchema(false);
    setIsLoadingSessionLogs(false);
    setIsProcessingSubmit(false);
    setLogTypeToAdd(null);
    setCurrentMessageId(null);
  };

  useEffect(() => {
    if (logTypeToAdd) {
      if (typeSchemas && typeSchemas[logTypeToAdd] && typeConverters[logTypeToAdd]) {
        setCurrentSchema(typeSchemas[logTypeToAdd]);
        setCurrentUiSchema(uiSchemas[logTypeToAdd] ?? {});
      } else {
        toast.error('No schema/mapper found for type ' + logTypeToAdd);
      }
    }
  }, [logTypeToAdd]);

  useEffect(() => {
    if (activeTab === '2') {
      if (currentFormData) {
        setCurrentConvertedSessionLog(typeConverters[logTypeToAdd](currentFormData));
      }
    }
  }, [activeTab]);

  function handleFormDataChanged(data: IChangeEvent<any, RJSFSchema, any>, id?: string): void {
    setCurrentFormData(data.formData);
  }
  function handleFormSubmit(): void {
    if (currentFormData && typeConverters[logTypeToAdd](currentFormData)) {
      setCurrentConvertedSessionLog(typeConverters[logTypeToAdd](currentFormData));
      setIsFormReadyToSubmit(true);
    } else {
      toast.error('Form data is invalid');
    }
  }

  function handleFormEdit(): void {
    setIsFormReadyToSubmit(false);
  }

  function handleLogViewClicked(log: any): void {
    setCurrentFormData(JSON.parse(log.sessionLogBusinessPayload));
    setLogTypeToAdd(log.sessionLogBusinessType);
    setCurrentMessageId(log.messageId);
    setShowAddSessionLogModal(true);
  }

  function handleLogEditClicked(log: any): void {
    setCurrentFormData(JSON.parse(log.sessionLogBusinessPayload));
    setLogTypeToAdd(log.sessionLogBusinessType);
    setCurrentMessageId(log.messageId);
    setShowAddSessionLogModal(true);
  }

  function handleLogDeleteClicked(log: any): void {
    setCurrentMessageId(log.messageId);
    setShowDeleteSessionLogModal(true);
  }

  function handleDeleteLogModalClose(): void {
    setCurrentMessageId(null);
    setShowDeleteSessionLogModal(false);
  }

  async function handleDeleteSessionLog() {
    if (currentMessageId) {
      const discussionId = courseDiscussionEntity?.id;
      setIsProcessingDelete(true);
      try {
        const apiUrl = `api/inappchat/group/${discussionId}/session-log?messageId=${currentMessageId}`;
        const res = await axios.delete(apiUrl);
        if (res.status >= 200 && res.status <= 299) {
          toast.success('Deleted session log successfully');
        } else {
          toast.error('Failed to delete session log');
        }
      } catch (err) {
        toast.error('Some error occurred');
      }
      setIsProcessingDelete(false);
      setShowDeleteSessionLogModal(false);
    }
  }

  return (
    <Container>
      <h1>{translate('discussionSessionLogs.title')}</h1>
      <ValidatedForm
        onSubmit={handleFetchDiscussion}
        defaultValues={{
          discussionId: courseDiscussionEntity?.id ?? inheritedDiscussion?.id,
        }}
      >
        <ValidatedField
          label={translate('discussionSessionLogs.discussionIdFieldLabel')}
          id="discussion-id"
          name="discussionId"
          data-cy="discussionId"
          type="number"
          validate={{
            required: { value: true, message: translate('discussionSessionLogs.requiredMessage') },
          }}
        />

        <Button type="submit" color="primary" disabled={isLoading}>
          {translate('discussionSessionLogs.submit')}
        </Button>
      </ValidatedForm>
      {courseDiscussionEntity?.id && (
        <div style={{ paddingTop: 10 }}>
          <Table responsive>
            <thead>
              <tr>
                <th className="hand">
                  <Translate contentKey="alippoApp.courseDiscussion.id">ID</Translate>
                </th>
                <th className="hand">
                  <Translate contentKey="alippoApp.courseDiscussion.disabled">Disabled</Translate>
                </th>
                <th className="hand">
                  <Translate contentKey="alippoApp.courseDiscussion.createdAt">Created At</Translate>
                </th>
                <th className="hand">
                  <Translate contentKey="alippoApp.courseDiscussion.updatedAt">Updated At</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr data-cy="entityTable">
                <td>{courseDiscussionEntity.id}</td>
                <td>{courseDiscussionEntity.disabled ? 'true' : 'false'}</td>
                <td>
                  {courseDiscussionEntity.createdAt ? (
                    <TextFormat type="date" value={courseDiscussionEntity.createdAt} format={APP_DATE_FORMAT} />
                  ) : null}
                </td>
                <td>
                  {courseDiscussionEntity.updatedAt ? (
                    <TextFormat type="date" value={courseDiscussionEntity.updatedAt} format={APP_DATE_FORMAT} />
                  ) : null}
                </td>
              </tr>
            </tbody>
          </Table>
          <h3 style={{ paddingTop: 10 }}>
            Session Logs{' '}
            <Button color="primary" onClick={refreshSessionLogs} disabled={isLoadingSessionLogs}>
              <FontAwesomeIcon icon="sync" />
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              color="primary"
              onClick={fetchSessionLogsNextPage}
              disabled={isLoadingSessionLogs || pageState?.last}
            >
              Next page
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              className="rounded-circle"
              color="success"
              onClick={addSessionLog}
              disabled={isLoadingSessionLogs}
            >
              <FontAwesomeIcon icon="plus" />
            </Button>
          </h3>
          {sessionLogs && (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand">Video playback time</th>
                  <th className="hand">Is chapter</th>
                  <th className="hand">Chapter name</th>
                  <th className="hand">Business type</th>
                </tr>
              </thead>
              <tbody>
                {sessionLogs.map((log, index) => (
                  <tr key={index} data-cy="entityTable">
                    <td>{log.videoPlaybackTime}</td>
                    <td>{log.isChapter}</td>
                    <td>{log.chapterName}</td>
                    <td>{log.sessionLogBusinessType}</td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} color="info" size="sm" onClick={() => handleLogViewClicked(log)}>
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} color="primary" size="sm" onClick={() => handleLogEditClicked(log)}>
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                        <Button tag={Link} color="danger" size="sm" onClick={() => handleLogDeleteClicked(log)}>
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              {pageState && (
                <tfoot>
                  <hr />
                  <div>
                    <b>Page:</b> {pageState.number}
                  </div>
                  <div>
                    <b>Total pages:</b> {pageState.totalPages}
                  </div>
                  <div>
                    <b>Total items:</b> {pageState.totalElements}
                  </div>
                </tfoot>
              )}
            </Table>
          )}
        </div>
      )}

      <Modal isOpen={showAddSessionLogModal} toggle={closeAddSessionLogModal}>
        <ModalBody>
          <>
            <h3>Add session log</h3>
            <Input
              id="logType"
              type="select"
              name="logType"
              placeholder="Select log type"
              onChange={e => setLogType(e.target.value)}
              value={logTypeToAdd ?? 'select'}
            >
              <option value="select" disabled>
                Select
              </option>
              {logTypeMasterSchema?.types.map(type => (
                <option key={type}> {type}</option>
              ))}
            </Input>
            {currentMessageId && (
              <Row style={{ paddingTop: 10, color: 'brown' }}>
                <Col>
                  <p>
                    <b>You are editing message: </b>
                    {currentMessageId}
                  </p>
                </Col>
              </Row>
            )}
            {currentSchema && (
              <div>
                <hr />
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        setActiveTab('1');
                      }}
                    >
                      Form
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={() => {
                        setActiveTab('2');
                      }}
                    >
                      Preview
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Form
                      readonly={isFormReadyToSubmit || isProcessingSubmit}
                      schema={currentSchema}
                      validator={validator}
                      uiSchema={currentUiSchema}
                      formData={currentFormData}
                      onChange={handleFormDataChanged}
                      onSubmit={handleFormSubmit}
                    >
                      <Button
                        className="primary"
                        type="submit"
                        disabled={
                          currentFormData === null ||
                          currentFormData === undefined ||
                          Object.keys(currentFormData).length === 0 ||
                          isFormReadyToSubmit ||
                          isProcessingSubmit
                        }
                      >
                        Save
                      </Button>
                      <Button
                        className="primary"
                        style={{ marginLeft: 10 }}
                        disabled={isFormReadyToSubmit == null || !isFormReadyToSubmit || isProcessingSubmit}
                        onClick={handleFormEdit}
                      >
                        Edit
                      </Button>
                    </Form>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col>
                        <pre>{JSON.stringify(currentConvertedSessionLog, null, 2)}</pre>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            )}
            {submitError && <p style={{ color: 'red' }}>{submitError}</p>}
            <div className="d-flex w-100 justify-content-end mt-4">
              <Button
                color="success"
                type="button"
                onClick={handleSubmitAddSessionLog}
                disabled={
                  logTypeToAdd === null ||
                  logTypeToAdd === undefined ||
                  isLoadingLogTypeMasterSchema ||
                  logTypeMasterSchema === null ||
                  !isFormReadyToSubmit ||
                  isProcessingSubmit
                }
              >
                Submit
              </Button>
            </div>
          </>
        </ModalBody>
      </Modal>
      <Modal isOpen={showDeleteSessionLogModal} toggle={handleDeleteLogModalClose}>
        <ModalHeader toggle={handleDeleteLogModalClose}>
          <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this Session Log?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleDeleteLogModalClose} disabled={!currentMessageId || isProcessingDelete}>
            <FontAwesomeIcon icon="ban" />
            &nbsp;
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button color="danger" onClick={handleDeleteSessionLog} disabled={!currentMessageId || isProcessingDelete}>
            <FontAwesomeIcon icon="trash" />
            &nbsp;
            <Translate contentKey="entity.action.delete">Delete</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}
