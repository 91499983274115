import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './order-info.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const OrderInfoDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const orderInfoEntity = useAppSelector(state => state.orderInfo.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="orderInfoDetailsHeading">
          <Translate contentKey="alippoApp.orderInfo.detail.title">OrderInfo</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{orderInfoEntity.id}</dd>
          <dt>
            <span id="orderId">
              <Translate contentKey="alippoApp.orderInfo.orderId">Order Id</Translate>
            </span>
          </dt>
          <dd>{orderInfoEntity.orderId}</dd>
          <dt>
            <span id="orderDate">
              <Translate contentKey="alippoApp.orderInfo.orderDate">Order Date</Translate>
            </span>
          </dt>
          <dd>
            {orderInfoEntity.orderDate ? <TextFormat value={orderInfoEntity.orderDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="pg">
              <Translate contentKey="alippoApp.orderInfo.pg">Pg</Translate>
            </span>
          </dt>
          <dd>{orderInfoEntity.pg}</dd>
          <dt>
            <span id="secondaryPg">
              <Translate contentKey="alippoApp.orderInfo.secondaryPg">Secondary Pg</Translate>
            </span>
          </dt>
          <dd>{orderInfoEntity.secondaryPg}</dd>
          <dt>
            <span id="pgOrderId">
              <Translate contentKey="alippoApp.orderInfo.pgOrderId">Pg Order Id</Translate>
            </span>
          </dt>
          <dd>{orderInfoEntity.pgOrderId}</dd>
          <dt>
            <span id="pgResponse">
              <Translate contentKey="alippoApp.orderInfo.pgResponse">Pg Response</Translate>
            </span>
          </dt>
          <dd>{orderInfoEntity.pgResponse}</dd>
          <dt>
            <span id="ipInfo">
              <Translate contentKey="alippoApp.orderInfo.ipInfo">Ip Info</Translate>
            </span>
          </dt>
          <dd>{orderInfoEntity.ipInfo}</dd>
          <dt>
            <span id="platform">
              <Translate contentKey="alippoApp.orderInfo.platform">Platform</Translate>
            </span>
          </dt>
          <dd>{orderInfoEntity.platform}</dd>
          <dt>
            <span id="platformVersion">
              <Translate contentKey="alippoApp.orderInfo.platformVersion">Platform Version</Translate>
            </span>
          </dt>
          <dd>{orderInfoEntity.platformVersion}</dd>
          <dt>
            <span id="utm">
              <Translate contentKey="alippoApp.orderInfo.utm">Utm</Translate>
            </span>
          </dt>
          <dd>{orderInfoEntity.utm}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.orderInfo.description">Description</Translate>
            </span>
          </dt>
          <dd>{orderInfoEntity.description}</dd>
          <dt>
            <span id="deliveryFee">
              <Translate contentKey="alippoApp.orderInfo.deliveryFee">Delivery Fee</Translate>
            </span>
          </dt>
          <dd>{orderInfoEntity.deliveryFee}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.orderInfo.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {orderInfoEntity.createdAt ? <TextFormat value={orderInfoEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.orderInfo.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {orderInfoEntity.updatedAt ? <TextFormat value={orderInfoEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.orderInfo.billingAddress">Billing Address</Translate>
          </dt>
          <dd>{orderInfoEntity.billingAddress ? orderInfoEntity.billingAddress.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.orderInfo.user">User</Translate>
          </dt>
          <dd>{orderInfoEntity.user ? orderInfoEntity.user.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.orderInfo.cart">Cart</Translate>
          </dt>
          <dd>{orderInfoEntity.cart ? orderInfoEntity.cart.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.orderInfo.parentOrderInfo">Parent Order Info</Translate>
          </dt>
          <dd>{orderInfoEntity.parentOrderInfo ? orderInfoEntity.parentOrderInfo.id : ''}</dd>
          <dt>
            <Translate contentKey="alippoApp.orderInfo.deliveryAddress">Delivery Address</Translate>
          </dt>
          <dd>{orderInfoEntity.deliveryAddress ? orderInfoEntity.deliveryAddress.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/order-info" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/order-info/${orderInfoEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default OrderInfoDetail;
