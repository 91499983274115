import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Res from './res';
import ResDetail from './res-detail';
import ResUpdate from './res-update';
import ResDeleteDialog from './res-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ResUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ResUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ResDetail} />
      <ErrorBoundaryRoute path={match.url} component={Res} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ResDeleteDialog} />
  </>
);

export default Routes;
