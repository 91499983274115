import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ResRule from './res-rule';
import ResRuleDetail from './res-rule-detail';
import ResRuleUpdate from './res-rule-update';
import ResRuleDeleteDialog from './res-rule-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ResRuleUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ResRuleUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ResRuleDetail} />
      <ErrorBoundaryRoute path={match.url} component={ResRule} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ResRuleDeleteDialog} />
  </>
);

export default Routes;
