import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './res-rule.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ResRuleDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const resRuleEntity = useAppSelector(state => state.resRule.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="resRuleDetailsHeading">
          <Translate contentKey="alippoApp.resRule.detail.title">ResRule</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{resRuleEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.resRule.name">Name</Translate>
            </span>
          </dt>
          <dd>{resRuleEntity.name}</dd>
          <dt>
            <span id="url">
              <Translate contentKey="alippoApp.resRule.url">Url</Translate>
            </span>
          </dt>
          <dd>{resRuleEntity.url}</dd>
          <dt>
            <span id="resState">
              <Translate contentKey="alippoApp.resRule.resState">Res State</Translate>
            </span>
          </dt>
          <dd>{resRuleEntity.resState}</dd>
          <dt>
            <span id="resType">
              <Translate contentKey="alippoApp.resRule.resType">Res Type</Translate>
            </span>
          </dt>
          <dd>{resRuleEntity.resType}</dd>
          <dt>
            <span id="mediaType">
              <Translate contentKey="alippoApp.resRule.mediaType">Media Type</Translate>
            </span>
          </dt>
          <dd>{resRuleEntity.mediaType}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.resRule.description">Description</Translate>
            </span>
          </dt>
          <dd>{resRuleEntity.description}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="alippoApp.resRule.language">Language</Translate>
            </span>
          </dt>
          <dd>{resRuleEntity.language}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.resRule.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{resRuleEntity.createdAt ? <TextFormat value={resRuleEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.resRule.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{resRuleEntity.updatedAt ? <TextFormat value={resRuleEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="alippoApp.resRule.rule">Rule</Translate>
          </dt>
          <dd>{resRuleEntity.rule ? resRuleEntity.rule.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/res-rule" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/res-rule/${resRuleEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ResRuleDetail;
