import dayjs from 'dayjs';
import { ISkuAttribute } from 'app/shared/model/sku-attribute.model';
import { ISkuAttributeVariant } from 'app/shared/model/sku-attribute-variant.model';
import { ICartItem } from 'app/shared/model/cart-item.model';

export interface ICartItemSkuAttribute {
  id?: number;
  value?: string;
  metadata?: string | null;
  createdAt?: string;
  updatedAt?: string;
  skuAttribute?: ISkuAttribute | null;
  skuAttributeVariant?: ISkuAttributeVariant | null;
  cartItem?: ICartItem | null;
}

export const defaultValue: Readonly<ICartItemSkuAttribute> = {};
