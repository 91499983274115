import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './task.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const TaskDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const taskEntity = useAppSelector(state => state.task.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="taskDetailsHeading">
          <Translate contentKey="alippoApp.task.detail.title">Task</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{taskEntity.id}</dd>
          <dt>
            <span id="taskId">
              <Translate contentKey="alippoApp.task.taskId">Task Id</Translate>
            </span>
          </dt>
          <dd>{taskEntity.taskId}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="alippoApp.task.status">Status</Translate>
            </span>
          </dt>
          <dd>{taskEntity.status}</dd>
          <dt>
            <span id="input">
              <Translate contentKey="alippoApp.task.input">Input</Translate>
            </span>
          </dt>
          <dd>{taskEntity.input}</dd>
          <dt>
            <span id="additionalInput">
              <Translate contentKey="alippoApp.task.additionalInput">Additional Input</Translate>
            </span>
          </dt>
          <dd>{taskEntity.additionalInput}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.task.description">Description</Translate>
            </span>
          </dt>
          <dd>{taskEntity.description}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.task.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{taskEntity.createdAt ? <TextFormat value={taskEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.task.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{taskEntity.updatedAt ? <TextFormat value={taskEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="alippoApp.task.user">User</Translate>
          </dt>
          <dd>{taskEntity.user ? taskEntity.user.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/task" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/task/${taskEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TaskDetail;
