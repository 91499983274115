export enum CourseStatus {
  DRAFT = 'DRAFT',

  IN_STOCK = 'IN_STOCK',

  OUT_OF_STOCK = 'OUT_OF_STOCK',

  COMPLETED = 'COMPLETED',

  PRE_STOCK = 'PRE_STOCK',
}
