import dayjs from 'dayjs';
import { MessageTemplateType } from 'app/shared/model/enumerations/message-template-type.model';

export interface IMessageTemplate {
  id?: number;
  type?: MessageTemplateType;
  active?: boolean;
  templateName?: string;
  version?: number;
  template?: string;
  createdAt?: string;
  updatedAt?: string;
}

export const defaultValue: Readonly<IMessageTemplate> = {
  active: false,
};
