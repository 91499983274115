import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRes } from 'app/shared/model/res.model';
import { getEntities as getRes } from 'app/entities/res/res.reducer';
import { IStep } from 'app/shared/model/step.model';
import { getEntities as getSteps } from 'app/entities/step/step.reducer';
import { getEntity, updateEntity, createEntity, reset } from './res-step.reducer';
import { IResStep } from 'app/shared/model/res-step.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ResState } from 'app/shared/model/enumerations/res-state.model';
import { ResType } from 'app/shared/model/enumerations/res-type.model';

export const ResStepUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const res = useAppSelector(state => state.res.entities);
  const steps = useAppSelector(state => state.step.entities);
  const resStepEntity = useAppSelector(state => state.resStep.entity);
  const loading = useAppSelector(state => state.resStep.loading);
  const updating = useAppSelector(state => state.resStep.updating);
  const updateSuccess = useAppSelector(state => state.resStep.updateSuccess);
  const resStateValues = Object.keys(ResState);
  const resTypeValues = Object.keys(ResType);
  const handleClose = () => {
    props.history.push('/res-step' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getRes({}));
    dispatch(getSteps({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...resStepEntity,
      ...values,
      res: mapIdList(values.res),
      step: steps.find(it => it.id.toString() === values.step.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          resState: 'AVAILABLE',
          resType: 'EBOOK',
          ...resStepEntity,
          createdAt: convertDateTimeFromServer(resStepEntity.createdAt),
          updatedAt: convertDateTimeFromServer(resStepEntity.updatedAt),
          res: resStepEntity?.res?.map(e => e.id.toString()),
          step: resStepEntity?.step?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.resStep.home.createOrEditLabel" data-cy="ResStepCreateUpdateHeading">
            <Translate contentKey="alippoApp.resStep.home.createOrEditLabel">Create or edit a ResStep</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="res-step-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.resStep.resState')}
                id="res-step-resState"
                name="resState"
                data-cy="resState"
                type="select"
              >
                {resStateValues.map(resState => (
                  <option value={resState} key={resState}>
                    {translate('alippoApp.ResState' + resState)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resStep.resType')}
                id="res-step-resType"
                name="resType"
                data-cy="resType"
                type="select"
              >
                {resTypeValues.map(resType => (
                  <option value={resType} key={resType}>
                    {translate('alippoApp.ResType' + resType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.resStep.additionalOutput')}
                id="res-step-additionalOutput"
                name="additionalOutput"
                data-cy="additionalOutput"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.resStep.visible')}
                id="res-step-visible"
                name="visible"
                data-cy="visible"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.resStep.description')}
                id="res-step-description"
                name="description"
                data-cy="description"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.resStep.createdAt')}
                id="res-step-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.resStep.updatedAt')}
                id="res-step-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField label={translate('alippoApp.resStep.res')} id="res-step-res" data-cy="res" type="select" multiple name="res">
                <option value="" key="0" />
                {res
                  ? res.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="res-step-step" name="step" data-cy="step" label={translate('alippoApp.resStep.step')} type="select">
                <option value="" key="0" />
                {steps
                  ? steps.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/res-step" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ResStepUpdate;
