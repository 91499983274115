import React, { useState, useEffect, FormEvent, useMemo } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';

import { getEntities as getCourses } from './course.reducer';
import { getEntities as getCategories } from 'app/entities/category/category.reducer';
import { ICourse } from 'app/shared/model/course.model';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overrideFiltersStateWithQueryParams, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CourseStatus } from 'app/shared/model/enumerations/course-status.model';
import { CourseLevel } from 'app/shared/model/enumerations/course-level.model';

type CourseFilterType = {
  name: string;
  courseStatus: keyof typeof CourseStatus | 'NONE';
  courseLevel: keyof typeof CourseLevel | 'NONE';
  categoryId: string | 'NONE';
  skuId: number | null;
  applicationFormId: number | null;
};

export const Course = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );
  const [courseFilter, setCourseFilter] = useState<CourseFilterType>(
    overrideFiltersStateWithQueryParams(
      {
        name: '',
        courseStatus: 'NONE',
        courseLevel: 'NONE',
        categoryId: 'NONE',
        skuId: null,
        applicationFormId: null,
      } as CourseFilterType,
      props.location.search
    )
  );
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const courseList = useAppSelector(state => state.course.entities);
  const loading = useAppSelector(state => state.course.loading);
  const totalItems = useAppSelector(state => state.course.totalItems);
  const categories = useAppSelector(state => state.category.entities);

  const { match } = props;

  const getAllEntities = () => {
    setIsLoading(true);

    dispatch(
      getCourses({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        props: buildSearchParamsString(),
      })
    ).then(() => setIsLoading(false));

    const params = buildSearchParamsString();

    const endUrl = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}${params}`;

    if (window.location.search !== endUrl) {
      window.history.replaceState(null, '', `${props.location.pathname}${endUrl}`);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(getCategories({ size: 100, page: 0, sort: 'asc' })).then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    getAllEntities();
  }, [paginationState, courseFilter]);

  const buildSearchParamsString = () => {
    let paramsString = '';

    if (courseFilter.name) {
      paramsString += `&name.contains=${courseFilter.name}`;
    }

    if (courseFilter.courseStatus && courseFilter.courseStatus !== 'NONE') {
      paramsString += `&courseStatus.equals=${courseFilter.courseStatus}`;
    }

    if (courseFilter.courseLevel && courseFilter.courseLevel !== 'NONE') {
      paramsString += `&courseLevel.equals=${courseFilter.courseLevel}`;
    }

    if (courseFilter.categoryId && courseFilter.categoryId !== 'NONE') {
      paramsString += `&categoryId.equals=${courseFilter.categoryId}`;
    }

    if (courseFilter.skuId && courseFilter.skuId !== null) {
      paramsString += `&skuId.equals=${courseFilter.skuId}`;
    }

    if (courseFilter.applicationFormId && courseFilter.applicationFormId !== null) {
      paramsString += `&applicationFormId.equals=${courseFilter.applicationFormId}`;
    }

    return paramsString;
  };

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    getAllEntities();
  };

  const handleFilterChange = e => {
    setCourseFilter(prev => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const debouncedHandleFilterChange = useMemo(() => debounce(handleFilterChange, 500), []);

  const handleGoToCourseById = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const formData = new FormData(ev.target as HTMLFormElement);
    const courseId = formData.get('courseId') as string;

    try {
      const apiUrl = `api/courses/${courseId}`;
      const { data } = await axios.get<ICourse>(apiUrl);
      if (data.id) {
        history.push(`/course/${data.id}`);
      }
    } catch (e) {
      console.error(e);
      toast.error('Invalid Course ID');
    }
  };

  return (
    <div>
      <h2 id="course-heading" data-cy="CourseHeading">
        <Translate contentKey="alippoApp.course.home.title">Courses</Translate>
      </h2>
      <div className="d-flex justify-content-end">
        <Button className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="alippoApp.course.home.refreshListLabel">Refresh List</Translate>
        </Button>
        <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="alippoApp.course.home.createLabel">Create new Course</Translate>
        </Link>
      </div>
      <div className="my-5">
        <Form className="layout-wrapper mx-auto" onSubmit={handleGoToCourseById}>
          <FormGroup className="d-flex">
            <Input className="mr-3" id="courseId" name="courseId" placeholder="Enter Course ID" type="number" />
            <Button type="submit">Go</Button>
          </FormGroup>
        </Form>
      </div>
      <div className="my-4">
        <Form>
          <Row xs={1} sm={2} md={3}>
            <Col>
              <FormGroup>
                <Label for="name">Course Name</Label>
                <Input
                  id="name"
                  name="name"
                  placeholder="Enter course name"
                  type="text"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={courseFilter.name}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="courseStatus">Course Status</Label>
                <Input
                  id="courseStatus"
                  name="courseStatus"
                  type="select"
                  onChange={handleFilterChange}
                  defaultValue={courseFilter.courseStatus}
                >
                  {(Object.keys(CourseStatus) as Array<keyof typeof CourseStatus>).map(status => (
                    <option value={status} key={status}>
                      {status}
                    </option>
                  ))}
                  <option value="NONE">-</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="courseLevel">Course Level</Label>
                <Input
                  id="courseLevel"
                  name="courseLevel"
                  type="select"
                  onChange={handleFilterChange}
                  defaultValue={courseFilter.courseLevel}
                >
                  {(Object.keys(CourseLevel) as Array<keyof typeof CourseLevel>).map(level => (
                    <option value={level} key={level}>
                      {level}
                    </option>
                  ))}
                  <option value="NONE">-</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="categoryId">Category</Label>
                <Input id="categoryId" name="categoryId" type="select" onChange={handleFilterChange} value={courseFilter.categoryId}>
                  {categories
                    ? categories.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.name}
                        </option>
                      ))
                    : null}
                  <option value="NONE">-</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="name">Sku ID</Label>
                <Input
                  id="skuId"
                  name="skuId"
                  placeholder="Enter Sku ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={courseFilter.skuId}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="name">Application Form ID</Label>
                <Input
                  id="applicationFormId"
                  name="applicationFormId"
                  placeholder="Enter Application Form ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={courseFilter.applicationFormId}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <h6 className="text-center mt-2" style={isLoading ? { opacity: 1 } : { opacity: 0 }}>
          Loading ...
        </h6>
      </div>
      <div className="table-responsive">
        {courseList && courseList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="alippoApp.course.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="alippoApp.course.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  <Translate contentKey="alippoApp.course.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('sellingPrice')}>
                  <Translate contentKey="alippoApp.course.sellingPrice">Selling Price</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('sellingPriceCurrencyCode')}>
                  <Translate contentKey="alippoApp.course.sellingPriceCurrencyCode">Selling Price Currency Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('mrpPrice')}>
                  <Translate contentKey="alippoApp.course.mrpPrice">Mrp Price</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('mrpPriceCurrencyCode')}>
                  <Translate contentKey="alippoApp.course.mrpPriceCurrencyCode">Mrp Price Currency Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('fromDateTime')}>
                  <Translate contentKey="alippoApp.course.fromDateTime">From Date Time</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('duration')}>
                  <Translate contentKey="alippoApp.course.duration">Duration</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('courseStatus')}>
                  <Translate contentKey="alippoApp.course.courseStatus">Course Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('courseLevel')}>
                  <Translate contentKey="alippoApp.course.courseLevel">Course Level</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('sortOrder')}>
                  <Translate contentKey="alippoApp.course.sortOrder">Sort Order</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="alippoApp.course.createdAt">Created At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updatedAt')}>
                  <Translate contentKey="alippoApp.course.updatedAt">Updated At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('configPayload')}>
                  <Translate contentKey="alippoApp.course.configPayload">Config Payload</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('seoConfigPayload')}>
                  <Translate contentKey="alippoApp.course.seoConfigPayload">Seo Config Payload</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('batchIndex')}>
                  <Translate contentKey="alippoApp.course.batchIndex">Batch Index</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.course.sku">Sku</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.course.applicationForm">Application Form</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.course.parentCourse">Parent Course</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {courseList.map((course, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`${match.url}/${course.id}`} color="link" size="sm">
                      {course.id}
                    </Button>
                  </td>
                  <td>{course.name}</td>
                  <td>{course.description}</td>
                  <td>{course.sellingPrice}</td>
                  <td>
                    <Translate contentKey={`alippoApp.CurrencyCode.${course.sellingPriceCurrencyCode}`} />
                  </td>
                  <td>{course.mrpPrice}</td>
                  <td>
                    <Translate contentKey={`alippoApp.CurrencyCode.${course.mrpPriceCurrencyCode}`} />
                  </td>
                  <td>{course.fromDateTime ? <TextFormat type="date" value={course.fromDateTime} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{course.duration}</td>
                  <td>
                    <Translate contentKey={`alippoApp.CourseStatus.${course.courseStatus}`} />
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.CourseLevel.${course.courseLevel}`} />
                  </td>
                  <td>{course.sortOrder}</td>
                  <td>{course.createdAt ? <TextFormat type="date" value={course.createdAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{course.updatedAt ? <TextFormat type="date" value={course.updatedAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{course.configPayload?.slice(0, 50).concat('...')}</td>
                  <td>{course.seoConfigPayload?.slice(0, 50).concat('...')}</td>
                  <td>{course.batchIndex}</td>
                  <td>{course.sku ? <Link to={`sku/${course.sku.id}`}>{course.sku.id}</Link> : ''}</td>
                  <td>{course.applicationForm ? <Link to={`form/${course.applicationForm.id}`}>{course.applicationForm.id}</Link> : ''}</td>
                  <td>{course.parentCourse ? <Link to={`course/${course.parentCourse.id}`}>{course.parentCourse.id}</Link> : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${course.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${course.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${course.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="alippoApp.course.home.notFound">No Courses found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={courseList && courseList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Course;
