import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ResBizCard from './res-biz-card';
import ResBizCardDetail from './res-biz-card-detail';
import ResBizCardUpdate from './res-biz-card-update';
import ResBizCardDeleteDialog from './res-biz-card-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ResBizCardUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ResBizCardUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ResBizCardDetail} />
      <ErrorBoundaryRoute path={match.url} component={ResBizCard} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ResBizCardDeleteDialog} />
  </>
);

export default Routes;
