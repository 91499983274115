import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ICourseSection } from 'app/shared/model/course-section.model';
import { getEntities as getCourseSections } from 'app/entities/course-section/course-section.reducer';
import { ITip } from 'app/shared/model/tip.model';
import { getEntities as getTips } from 'app/entities/tip/tip.reducer';
import { getEntity, updateEntity, createEntity, reset } from './course-section-tip.reducer';
import { ICourseSectionTip } from 'app/shared/model/course-section-tip.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CourseSectionTipUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const courseSections = useAppSelector(state => state.courseSection.entities);
  const tips = useAppSelector(state => state.tip.entities);
  const courseSectionTipEntity = useAppSelector(state => state.courseSectionTip.entity);
  const loading = useAppSelector(state => state.courseSectionTip.loading);
  const updating = useAppSelector(state => state.courseSectionTip.updating);
  const updateSuccess = useAppSelector(state => state.courseSectionTip.updateSuccess);
  const handleClose = () => {
    props.history.push('/course-section-tip' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getCourseSections({}));
    dispatch(getTips({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...courseSectionTipEntity,
      ...values,
      courseSection: courseSections.find(it => it.id.toString() === values.courseSection.toString()),
      tip: tips.find(it => it.id.toString() === values.tip.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          ...courseSectionTipEntity,
          createdAt: convertDateTimeFromServer(courseSectionTipEntity.createdAt),
          updatedAt: convertDateTimeFromServer(courseSectionTipEntity.updatedAt),
          courseSection: courseSectionTipEntity?.courseSection?.id,
          tip: courseSectionTipEntity?.tip?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.courseSectionTip.home.createOrEditLabel" data-cy="CourseSectionTipCreateUpdateHeading">
            <Translate contentKey="alippoApp.courseSectionTip.home.createOrEditLabel">Create or edit a CourseSectionTip</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="course-section-tip-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.courseSectionTip.sortOrder')}
                id="course-section-tip-sortOrder"
                name="sortOrder"
                data-cy="sortOrder"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alippoApp.courseSectionTip.createdAt')}
                id="course-section-tip-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.courseSectionTip.updatedAt')}
                id="course-section-tip-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="course-section-tip-courseSection"
                name="courseSection"
                data-cy="courseSection"
                label={translate('alippoApp.courseSectionTip.courseSection')}
                type="select"
              >
                <option value="" key="0" />
                {courseSections
                  ? courseSections.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="course-section-tip-tip"
                name="tip"
                data-cy="tip"
                label={translate('alippoApp.courseSectionTip.tip')}
                type="select"
              >
                <option value="" key="0" />
                {tips
                  ? tips.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/course-section-tip" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CourseSectionTipUpdate;
