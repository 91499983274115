import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import UserTopic from './user-topic';
import UserTopicDetail from './user-topic-detail';
import UserTopicUpdate from './user-topic-update';
import UserTopicDeleteDialog from './user-topic-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={UserTopicUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={UserTopicUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={UserTopicDetail} />
      <ErrorBoundaryRoute path={match.url} component={UserTopic} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={UserTopicDeleteDialog} />
  </>
);

export default Routes;
