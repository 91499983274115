import dayjs from 'dayjs';
import { ICourseCluster } from 'app/shared/model/course-cluster.model';

export interface ICluster {
  id?: number;
  name?: string | null;
  metaData?: string | null;
  active?: boolean;
  createdAt?: string;
  updatedAt?: string;
  courseClusters?: ICourseCluster[] | null;
}

export const defaultValue: Readonly<ICluster> = {
  active: false,
};
