import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import CourseDiscussion from './course-discussion';
import CourseDiscussionDetail from './course-discussion-detail';
import CourseDiscussionUpdate from './course-discussion-update';
import CourseDiscussionDeleteDialog from './course-discussion-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CourseDiscussionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CourseDiscussionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CourseDiscussionDetail} />
      <ErrorBoundaryRoute path={match.url} component={CourseDiscussion} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={CourseDiscussionDeleteDialog} />
  </>
);

export default Routes;
