import React, { useState, useEffect, useMemo } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { Translate, TextFormat, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities } from './order-info.reducer';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overrideFiltersStateWithQueryParams, overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { IOrderItem } from 'app/shared/model/order-item.model';
import { PaymentGateway } from 'app/shared/model/enumerations/payment-gateway.model';
import { debounce } from 'lodash';

type OrderInfosFilterType = {
  userId: number | null;
  orderId: number | null;
  pg: keyof typeof PaymentGateway | 'NONE';
  cartId: number | null;
};

export const OrderInfo = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(
      {
        activePage: 1,
        itemsPerPage: ITEMS_PER_PAGE,
        sort: 'orderDate',
        order: DESC,
      },
      props.location.search
    )
  );
  const [orderInfosFilter, setOrderInfosFilter] = useState<OrderInfosFilterType>(
    overrideFiltersStateWithQueryParams(
      {
        orderId: null,
        pg: 'NONE',
        cartId: null,
      } as OrderInfosFilterType,
      props.location.search
    )
  );
  const [orderDetailsList, setOrderDetailsList] = useState(null);
  const [orderInfosLoading, setOrderInfosLoading] = useState(false);

  const orderInfoList = useAppSelector(state => state.orderInfo.entities);
  const loading = useAppSelector(state => state.orderInfo.loading);
  const totalItems = useAppSelector(state => state.orderInfo.totalItems);

  const getAllEntities = () => {
    setOrderInfosLoading(true);

    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        props: buildSearchParamsString(),
      })
    ).then(() => setOrderInfosLoading(false));

    const params = buildSearchParamsString();

    const endUrl = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}${params}`;

    if (window.location.search !== endUrl) {
      window.history.replaceState(null, '', `${props.location.pathname}${endUrl}`);
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState, orderInfosFilter]);

  const buildSearchParamsString = () => {
    let paramsString = '';

    if (orderInfosFilter.userId) {
      paramsString += `&userId.equals=${orderInfosFilter.userId}`;
    }

    if (orderInfosFilter.orderId) {
      paramsString += `&orderId.equals=${orderInfosFilter.orderId}`;
    }

    if (orderInfosFilter.pg && orderInfosFilter.pg !== 'NONE') {
      paramsString += `&pg.equals=${orderInfosFilter.pg}`;
    }

    if (orderInfosFilter.cartId) {
      paramsString += `&cartId.equals=${orderInfosFilter.cartId}`;
    }

    return paramsString;
  };

  useEffect(() => {
    if (orderInfoList && orderInfoList.length > 0) {
      const getAllOrders = async () => {
        try {
          const orderInfoIds = orderInfoList.map(orderInfo => orderInfo.id);

          const { data: orderItems }: { data: IOrderItem[] } = await axios.get(`api/order-items?orderInfoId.in=${orderInfoIds}&size=500`);

          const courseIds = orderItems.map(orderItem => orderItem.course?.id);

          const { data: courseItems } = await axios.get(`api/courses?id.in=${courseIds}&size=500`);

          const filteredOrderItems = {};

          orderItems.forEach(orderItem => {
            if (!filteredOrderItems[orderItem.orderInfo.id]) {
              filteredOrderItems[orderItem.orderInfo.id] = [];
            }

            filteredOrderItems[orderItem.orderInfo.id].push({
              orderItemId: orderItem.id,
              orderItemStatus: orderItem.orderItemStatus,
              courseId: orderItem.course?.id,
              courseName: courseItems.find(course => course.id === orderItem.course?.id)?.name,
            });
          });

          setOrderDetailsList(filteredOrderItems);
        } catch (err) {
          console.error(err);
        }
      };

      getAllOrders();
    }
  }, [orderInfoList]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    getAllEntities();
  };

  const handleFilterChange = e => {
    setOrderInfosFilter(prev => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const debouncedHandleFilterChange = useMemo(() => debounce(handleFilterChange, 500), []);

  const { match } = props;

  return (
    <div>
      <h2 id="order-info-heading" data-cy="OrderInfoHeading">
        <Translate contentKey="alippoApp.orderInfo.home.title">Order Infos</Translate>
      </h2>
      <div className="d-flex justify-content-end">
        <Button className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="alippoApp.orderInfo.home.refreshListLabel">Refresh List</Translate>
        </Button>
        <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="alippoApp.orderInfo.home.createLabel">Create new Order Info</Translate>
        </Link>
      </div>
      <div className="my-4">
        <Form>
          <Row md={3}>
            <Col>
              <FormGroup>
                <Label for="orderId">Order ID</Label>
                <Input
                  id="orderId"
                  name="orderId"
                  placeholder="Enter Order ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={orderInfosFilter.orderId}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="pg">Payment Gateway</Label>
                <Input id="pg" name="pg" type="select" onChange={handleFilterChange} defaultValue={orderInfosFilter.pg}>
                  {(Object.keys(PaymentGateway) as Array<keyof typeof PaymentGateway>).map(status => (
                    <option value={status} key={status}>
                      {status}
                    </option>
                  ))}
                  <option value="NONE">-</option>
                </Input>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="userId">User ID</Label>
                <Input
                  id="userId"
                  name="userId"
                  placeholder="Enter User ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={orderInfosFilter.userId}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="cartId">Cart ID</Label>
                <Input
                  id="cartId"
                  name="cartId"
                  placeholder="Enter Cart ID"
                  type="number"
                  onChange={debouncedHandleFilterChange}
                  defaultValue={orderInfosFilter.cartId}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
        <h6 className="text-center mt-2" style={orderInfosLoading ? { opacity: 1 } : { opacity: 0 }}>
          Loading ...
        </h6>
      </div>
      <div className="table-responsive">
        {orderInfoList && orderInfoList.length > 0 ? (
          <Table responsive style={{ whiteSpace: 'nowrap' }}>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="alippoApp.orderInfo.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('orderId')}>
                  <Translate contentKey="alippoApp.orderInfo.orderId">Order Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <div>Order Item(s)</div>
                </th>
                <th>
                  <div>Status</div>
                </th>
                <th>
                  <div>Course ID(s)</div>
                </th>
                <th>
                  <div>Course Name(s)</div>
                </th>
                <th className="hand" onClick={sort('orderDate')}>
                  <Translate contentKey="alippoApp.orderInfo.orderDate">Order Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('pg')}>
                  <Translate contentKey="alippoApp.orderInfo.pg">Pg</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('secondaryPg')}>
                  <Translate contentKey="alippoApp.orderInfo.secondaryPg">Secondary Pg</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('pgOrderId')}>
                  <Translate contentKey="alippoApp.orderInfo.pgOrderId">Pg Order Id</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('pgResponse')}>
                  <Translate contentKey="alippoApp.orderInfo.pgResponse">Pg Response</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('ipInfo')}>
                  <Translate contentKey="alippoApp.orderInfo.ipInfo">Ip Info</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('platform')}>
                  <Translate contentKey="alippoApp.orderInfo.platform">Platform</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('platformVersion')}>
                  <Translate contentKey="alippoApp.orderInfo.platformVersion">Platform Version</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('utm')}>
                  <Translate contentKey="alippoApp.orderInfo.utm">Utm</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  <Translate contentKey="alippoApp.orderInfo.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('deliveryFee')}>
                  <Translate contentKey="alippoApp.orderInfo.deliveryFee">Delivery Fee</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="alippoApp.orderInfo.createdAt">Created At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updatedAt')}>
                  <Translate contentKey="alippoApp.orderInfo.updatedAt">Updated At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.orderInfo.billingAddress">Billing Address</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.orderInfo.user">User</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.orderInfo.cart">Cart</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.orderInfo.parentOrderInfo">Parent Order Info</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.orderInfo.deliveryAddress">Delivery Address</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {orderInfoList.map((orderInfo, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`${match.url}/${orderInfo.id}`} color="link" size="sm">
                      {orderInfo.id}
                    </Button>
                  </td>
                  <td>{orderInfo.orderId}</td>
                  <td>
                    {orderDetailsList &&
                      orderDetailsList[orderInfo.id] &&
                      orderDetailsList[orderInfo.id].map(orderDetail => (
                        <Link
                          target="_blank"
                          rel="noopener noreferrer"
                          to={`/order-item/${orderDetail.orderItemId}`}
                          key={orderDetail.orderItemId}
                          className="d-block"
                        >
                          {orderDetail.orderItemId}
                        </Link>
                      ))}
                  </td>
                  <td>
                    {orderDetailsList &&
                      orderDetailsList[orderInfo.id] &&
                      orderDetailsList[orderInfo.id].map((orderDetail, index) => (
                        <div key={`order-item-${index}`}>{orderDetail.orderItemStatus}</div>
                      ))}
                  </td>
                  <td>
                    {orderDetailsList &&
                      orderDetailsList[orderInfo.id] &&
                      orderDetailsList[orderInfo.id].map((orderDetail, index) => (
                        <div key={`course-item-${index}`}>{orderDetail.courseId}</div>
                      ))}
                  </td>
                  <td>
                    {orderDetailsList &&
                      orderDetailsList[orderInfo.id] &&
                      orderDetailsList[orderInfo.id].map((orderDetail, index) => (
                        <div key={`course-item-${index}`}>{orderDetail.courseName}</div>
                      ))}
                  </td>
                  <td>{orderInfo.orderDate ? <TextFormat type="date" value={orderInfo.orderDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>
                    <Translate contentKey={`alippoApp.PaymentGateway.${orderInfo.pg}`} />
                  </td>
                  <td>
                    <Translate contentKey={`alippoApp.PaymentGateway.${orderInfo.secondaryPg}`} />
                  </td>
                  <td>{orderInfo.pgOrderId}</td>
                  <td>{orderInfo.pgResponse}</td>
                  <td style={{ whiteSpace: 'normal' }} className="clamp-text" data-clamp-size="3">
                    {orderInfo.ipInfo}
                  </td>
                  <td>{orderInfo.platform}</td>
                  <td>{orderInfo.platformVersion}</td>
                  <td>{orderInfo.utm}</td>
                  <td>{orderInfo.description}</td>
                  <td>{orderInfo.deliveryFee}</td>
                  <td>{orderInfo.createdAt ? <TextFormat type="date" value={orderInfo.createdAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{orderInfo.updatedAt ? <TextFormat type="date" value={orderInfo.updatedAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>
                    {orderInfo.billingAddress ? (
                      <Link to={`address/${orderInfo.billingAddress.id}`}>{orderInfo.billingAddress.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{orderInfo.user ? orderInfo.user.id : ''}</td>
                  <td>{orderInfo.cart ? <Link to={`cart/${orderInfo.cart.id}`}>{orderInfo.cart.id}</Link> : ''}</td>
                  <td>
                    {orderInfo.parentOrderInfo ? (
                      <Link to={`order-info/${orderInfo.parentOrderInfo.id}`}>{orderInfo.parentOrderInfo.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {orderInfo.deliveryAddress ? (
                      <Link to={`address/${orderInfo.deliveryAddress.id}`}>{orderInfo.deliveryAddress.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${orderInfo.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${orderInfo.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${orderInfo.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="alippoApp.orderInfo.home.notFound">No Order Infos found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={orderInfoList && orderInfoList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default OrderInfo;
