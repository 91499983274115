import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './res-review.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ResReviewDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const resReviewEntity = useAppSelector(state => state.resReview.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="resReviewDetailsHeading">
          <Translate contentKey="alippoApp.resReview.detail.title">ResReview</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{resReviewEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.resReview.name">Name</Translate>
            </span>
          </dt>
          <dd>{resReviewEntity.name}</dd>
          <dt>
            <span id="url">
              <Translate contentKey="alippoApp.resReview.url">Url</Translate>
            </span>
          </dt>
          <dd>{resReviewEntity.url}</dd>
          <dt>
            <span id="resState">
              <Translate contentKey="alippoApp.resReview.resState">Res State</Translate>
            </span>
          </dt>
          <dd>{resReviewEntity.resState}</dd>
          <dt>
            <span id="resType">
              <Translate contentKey="alippoApp.resReview.resType">Res Type</Translate>
            </span>
          </dt>
          <dd>{resReviewEntity.resType}</dd>
          <dt>
            <span id="mediaType">
              <Translate contentKey="alippoApp.resReview.mediaType">Media Type</Translate>
            </span>
          </dt>
          <dd>{resReviewEntity.mediaType}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.resReview.description">Description</Translate>
            </span>
          </dt>
          <dd>{resReviewEntity.description}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="alippoApp.resReview.language">Language</Translate>
            </span>
          </dt>
          <dd>{resReviewEntity.language}</dd>
          <dt>
            <span id="videoControlsConfig">
              <Translate contentKey="alippoApp.resReview.videoControlsConfig">Video Controls Config</Translate>
            </span>
          </dt>
          <dd>{resReviewEntity.videoControlsConfig}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.resReview.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>
            {resReviewEntity.createdAt ? <TextFormat value={resReviewEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.resReview.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>
            {resReviewEntity.updatedAt ? <TextFormat value={resReviewEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <Translate contentKey="alippoApp.resReview.review">Review</Translate>
          </dt>
          <dd>{resReviewEntity.review ? resReviewEntity.review.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/res-review" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/res-review/${resReviewEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/res-review/${resReviewEntity.id}/delete`} replace color="danger">
          <FontAwesomeIcon icon="trash" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.delete">Delete</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ResReviewDetail;
