export enum ResType {
  EBOOK = 'EBOOK',

  LIVE = 'LIVE',

  RECORDING = 'RECORDING',

  CERTIFICATE = 'CERTIFICATE',

  THUMBNAIL = 'THUMBNAIL',

  COLOR = 'COLOR',

  BANNER = 'BANNER',

  SEO = 'SEO',

  CONFIG = 'CONFIG',

  SHOWCASE = 'SHOWCASE',

  AMA = 'AMA',

  DELIVERY_KIT = 'DELIVERY_KIT',

  QUIZ = 'QUIZ',

  QUERY_ASSISTANT = 'QUERY_ASSISTANT',

  ASSET = 'ASSET',

  ASSET_DESCRIBED = 'ASSET_DESCRIBED',

  INSTA_POST = 'INSTA_POST',

  BROWSING_HISTORY = 'BROWSING_HISTORY',

  FORM_SUBMISSION = 'FORM_SUBMISSION',

  MEDIA = 'MEDIA',
}
