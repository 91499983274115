import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IRule } from 'app/shared/model/rule.model';
import { IOrderInfo } from 'app/shared/model/order-info.model';
import { ITask } from 'app/shared/model/task.model';
import { RuleBasis } from 'app/shared/model/enumerations/rule-basis.model';
import { RuleCurrency } from 'app/shared/model/enumerations/rule-currency.model';
import { CurrencyCode } from 'app/shared/model/enumerations/currency-code.model';
import { RuleLedgerAmountStatus } from 'app/shared/model/enumerations/rule-ledger-amount-status.model';

export interface IRuleLedger {
  id?: number;
  ledgerId?: string | null;
  referredAmount?: number | null;
  referredBasis?: RuleBasis | null;
  referredRuleCurrency?: RuleCurrency | null;
  referredCurrencyCode?: CurrencyCode | null;
  referredAmountUsed?: number | null;
  referredUsageLimit?: number | null;
  referredTimesUsed?: number | null;
  referredAmountStatus?: RuleLedgerAmountStatus | null;
  referredFromDateTime?: string | null;
  referredDuration?: number | null;
  referrerAmount?: number | null;
  referrerBasis?: RuleBasis | null;
  referrerRuleCurrency?: RuleCurrency | null;
  referrerCurrencyCode?: CurrencyCode | null;
  referrerAmountUsed?: number | null;
  referrerUsageLimit?: number | null;
  referrerTimesUsed?: number | null;
  referrerAmountStatus?: RuleLedgerAmountStatus | null;
  referrerFromDateTime?: string | null;
  referrerDuration?: number | null;
  expiryAt?: string | null;
  metadata?: string | null;
  createdAt?: string;
  updatedAt?: string;
  referredUser?: IUser | null;
  referrerUser?: IUser | null;
  referredRule?: IRule | null;
  referrerRule?: IRule | null;
  orderInfo?: IOrderInfo | null;
  task?: ITask | null;
}

export const defaultValue: Readonly<IRuleLedger> = {};
