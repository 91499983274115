import React from 'react';
import { faCog, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

interface SectionElementProps {
  title: string;
  icon: IconDefinition;
  route: string;
}

function SectionElement(props: SectionElementProps) {
  const history = useHistory();

  const handleClick = () => {
    history.push(props.route);
  };

  return (
    <Button onClick={handleClick} color="primary" className="d-flex flex-column align-items-center p-3 m-2">
      <FontAwesomeIcon icon={props.icon} size="lg" />
      <p className="mb-0 mt-2">{props.title}</p>
    </Button>
  );
}

export default SectionElement;
