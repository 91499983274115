import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { JhiItemCount, JhiPagination, TextFormat, Translate, getSortState } from 'react-jhipster';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Table } from 'reactstrap';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { getEntities } from './cart-item.reducer';

export const CartItem = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE, 'id'), props.location.search)
  );

  const cartItemList = useAppSelector(state => state.cartItem.entities);
  const loading = useAppSelector(state => state.cartItem.loading);
  const totalItems = useAppSelector(state => state.cartItem.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const { match } = props;

  return (
    <div>
      <h2 id="cart-item-heading" data-cy="CartItemHeading">
        <Translate contentKey="alippoApp.cartItem.home.title">Cart Items</Translate>
        <div className="d-flex justify-content-end">
          <Button className="mr-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="alippoApp.cartItem.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="alippoApp.cartItem.home.createLabel">Create new Cart Item</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {cartItemList && cartItemList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="alippoApp.cartItem.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('sellingPrice')}>
                  <Translate contentKey="alippoApp.cartItem.sellingPrice">Selling Price</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('sellingPriceCurrencyCode')}>
                  <Translate contentKey="alippoApp.cartItem.sellingPriceCurrencyCode">Selling Price Currency Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('description')}>
                  <Translate contentKey="alippoApp.cartItem.description">Description</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('metaData')}>
                  <Translate contentKey="alippoApp.cartItem.metaData">Meta Data</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('available')}>
                  <Translate contentKey="alippoApp.cartItem.available">Available</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('platform')}>
                  <Translate contentKey="alippoApp.cartItem.platform">Platform</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('platformVersion')}>
                  <Translate contentKey="alippoApp.cartItem.platformVersion">Platform Version</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('utm')}>
                  <Translate contentKey="alippoApp.cartItem.utm">Utm</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('unavailabilityReason')}>
                  <Translate contentKey="alippoApp.cartItem.unavailabilityReason">Unavailability Reason</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdAt')}>
                  <Translate contentKey="alippoApp.cartItem.createdAt">Created At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updatedAt')}>
                  <Translate contentKey="alippoApp.cartItem.updatedAt">Updated At</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('availabilityUpdatedAt')}>
                  <Translate contentKey="alippoApp.cartItem.availabilityUpdatedAt">Availability Updated At</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.cartItem.course">Course</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.cartItem.sku">Sku</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="alippoApp.cartItem.cart">Cart</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {cartItemList.map((cartItem, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`${match.url}/${cartItem.id}`} color="link" size="sm">
                      {cartItem.id}
                    </Button>
                  </td>
                  <td>{cartItem.sellingPrice}</td>
                  <td>
                    <Translate contentKey={`alippoApp.CurrencyCode.${cartItem.sellingPriceCurrencyCode}`} />
                  </td>
                  <td>{cartItem.description}</td>
                  <td>{cartItem.metaData}</td>
                  <td>{cartItem.available ? 'true' : 'false'}</td>
                  <td>{cartItem.platform}</td>
                  <td>{cartItem.platformVersion}</td>
                  <td>{cartItem.utm}</td>
                  <td>
                    <Translate contentKey={`alippoApp.CartItemUnavailabilityReason.${cartItem.unavailabilityReason}`} />
                  </td>
                  <td>{cartItem.createdAt ? <TextFormat type="date" value={cartItem.createdAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{cartItem.updatedAt ? <TextFormat type="date" value={cartItem.updatedAt} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>
                    {cartItem.availabilityUpdatedAt ? (
                      <TextFormat type="date" value={cartItem.availabilityUpdatedAt} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{cartItem.course ? <Link to={`course/${cartItem.course.id}`}>{cartItem.course.id}</Link> : ''}</td>
                  <td>{cartItem.sku ? <Link to={`sku/${cartItem.sku.id}`}>{cartItem.sku.id}</Link> : ''}</td>
                  <td>{cartItem.cart ? <Link to={`cart/${cartItem.cart.id}`}>{cartItem.cart.id}</Link> : ''}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${cartItem.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${cartItem.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${cartItem.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="alippoApp.cartItem.home.notFound">No Cart Items found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={cartItemList && cartItemList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default CartItem;
