import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MessageTemplate from './message-template';
import MessageTemplateDetail from './message-template-detail';
import MessageTemplateUpdate from './message-template-update';
import MessageTemplateDeleteDialog from './message-template-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MessageTemplateUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MessageTemplateUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MessageTemplateDetail} />
      <ErrorBoundaryRoute path={match.url} component={MessageTemplate} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={MessageTemplateDeleteDialog} />
  </>
);

export default Routes;
