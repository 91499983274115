import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './res-user.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ResUserDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const resUserEntity = useAppSelector(state => state.resUser.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="resUserDetailsHeading">
          <Translate contentKey="alippoApp.resUser.detail.title">ResUser</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{resUserEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.resUser.name">Name</Translate>
            </span>
          </dt>
          <dd>{resUserEntity.name}</dd>
          <dt>
            <span id="url">
              <Translate contentKey="alippoApp.resUser.url">Url</Translate>
            </span>
          </dt>
          <dd>{resUserEntity.url}</dd>
          <dt>
            <span id="resState">
              <Translate contentKey="alippoApp.resUser.resState">Res State</Translate>
            </span>
          </dt>
          <dd>{resUserEntity.resState}</dd>
          <dt>
            <span id="resType">
              <Translate contentKey="alippoApp.resUser.resType">Res Type</Translate>
            </span>
          </dt>
          <dd>{resUserEntity.resType}</dd>
          <dt>
            <span id="resSubType">
              <Translate contentKey="alippoApp.resUser.resSubType">Res Sub Type</Translate>
            </span>
          </dt>
          <dd>{resUserEntity.resSubType}</dd>
          <dt>
            <span id="mediaType">
              <Translate contentKey="alippoApp.resUser.mediaType">Media Type</Translate>
            </span>
          </dt>
          <dd>{resUserEntity.mediaType}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.resUser.description">Description</Translate>
            </span>
          </dt>
          <dd>{resUserEntity.description}</dd>
          <dt>
            <span id="language">
              <Translate contentKey="alippoApp.resUser.language">Language</Translate>
            </span>
          </dt>
          <dd>{resUserEntity.language}</dd>
          <dt>
            <span id="videoControlsConfig">
              <Translate contentKey="alippoApp.resUser.videoControlsConfig">Video Controls Config</Translate>
            </span>
          </dt>
          <dd>{resUserEntity.videoControlsConfig}</dd>
          <dt>
            <span id="aspectRatio">
              <Translate contentKey="alippoApp.resUser.aspectRatio">Aspect Ratio</Translate>
            </span>
          </dt>
          <dd>{resUserEntity.aspectRatio}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.resUser.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{resUserEntity.createdAt ? <TextFormat value={resUserEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.resUser.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{resUserEntity.updatedAt ? <TextFormat value={resUserEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="alippoApp.resUser.user">User</Translate>
          </dt>
          <dd>{resUserEntity.user ? resUserEntity.user.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/res-user" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/res-user/${resUserEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ResUserDetail;
