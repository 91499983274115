import dayjs from 'dayjs';
import { ICourse } from 'app/shared/model/course.model';
import { BannerType } from 'app/shared/model/enumerations/banner-type.model';
import { ResMediaType } from 'app/shared/model/enumerations/res-media-type.model';

export interface IBanner {
  id?: number;
  name?: string;
  bannerUrl?: string;
  bannerType?: BannerType;
  mediaType?: ResMediaType;
  description?: string | null;
  htmlUrl?: string | null;
  courseIds?: string | null;
  videoControlsConfig?: string | null;
  aspectRatio?: number | null;
  sortOrder?: number;
  createdAt?: string;
  updatedAt?: string;
  course?: ICourse | null;
  category?: ICourse | null;
}

export const defaultValue: Readonly<IBanner> = {};
