import dayjs from 'dayjs';
import { IDevice } from 'app/shared/model/device.model';
import { IUser } from 'app/shared/model/user.model';
import { TopicType } from 'app/shared/model/enumerations/topic-type.model';

export interface IUserTopic {
  id?: number;
  topicName?: string | null;
  type?: TopicType | null;
  active?: boolean | null;
  subscriptionDate?: string | null;
  revocationDate?: string | null;
  metadata?: string | null;
  createdAt?: string;
  updatedAt?: string;
  device?: IDevice | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IUserTopic> = {
  active: false,
};
