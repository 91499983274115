import dayjs from 'dayjs';

import { APP_DATE_TIME_FORMAT, APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? dayjs(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertToLocalDateTime = date => (date ? dayjs(date).format(APP_DATE_TIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? dayjs(date).toDate() : null);

export const displayDefaultDateTime = () => dayjs().startOf('milliseconds').format(APP_LOCAL_DATETIME_FORMAT);
