import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ISkuAttribute } from 'app/shared/model/sku-attribute.model';
import { getEntities as getSkuAttributes } from 'app/entities/sku-attribute/sku-attribute.reducer';
import { getEntity, updateEntity, createEntity, reset } from './sku-attribute-variant.reducer';
import { ISkuAttributeVariant } from 'app/shared/model/sku-attribute-variant.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const SkuAttributeVariantUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const locationState: any = location.state;

  let inheritedSkuAttribute = null;
  if (locationState) {
    inheritedSkuAttribute = locationState.skuAttribute;
  }

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const skuAttributes = useAppSelector(state => state.skuAttribute.entities);
  const skuAttributeVariantEntity = useAppSelector(state => state.skuAttributeVariant.entity);
  const loading = useAppSelector(state => state.skuAttributeVariant.loading);
  const updating = useAppSelector(state => state.skuAttributeVariant.updating);
  const updateSuccess = useAppSelector(state => state.skuAttributeVariant.updateSuccess);

  const handleClose = () => {
    if (skuAttributeVariantEntity && skuAttributeVariantEntity.id) {
      dispatch(reset());
      props.history.push('/sku-attribute/' + `${skuAttributeVariantEntity.skuAttribute.id}`);
    } else {
      props.history.push('/sku-attribute-variant' + props.location.search);
    }
  };
  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getSkuAttributes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...skuAttributeVariantEntity,
      ...values,
      skuAttribute: { id: values.skuAttribute },
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
          skuAttribute: inheritedSkuAttribute ? inheritedSkuAttribute.id : null,
        }
      : {
          ...skuAttributeVariantEntity,
          createdAt: convertDateTimeFromServer(skuAttributeVariantEntity.createdAt),
          updatedAt: convertDateTimeFromServer(skuAttributeVariantEntity.updatedAt),
          skuAttribute: skuAttributeVariantEntity?.skuAttribute?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.skuAttributeVariant.home.createOrEditLabel" data-cy="SkuAttributeVariantCreateUpdateHeading">
            <Translate contentKey="alippoApp.skuAttributeVariant.home.createOrEditLabel">Create or edit a SkuAttributeVariant</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="sku-attribute-variant-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.skuAttributeVariant.value')}
                id="sku-attribute-variant-value"
                name="value"
                data-cy="value"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.skuAttributeVariant.valueLabel')}
                id="sku-attribute-variant-valueLabel"
                name="valueLabel"
                data-cy="valueLabel"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.skuAttributeVariant.isDefault')}
                id="sku-attribute-variant-isDefault"
                name="isDefault"
                data-cy="isDefault"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.skuAttributeVariant.isAdditive')}
                id="sku-attribute-variant-isAdditive"
                name="isAdditive"
                data-cy="isAdditive"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('alippoApp.skuAttributeVariant.config')}
                id="sku-attribute-variant-config"
                name="config"
                data-cy="config"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.skuAttributeVariant.sortOrder')}
                id="sku-attribute-variant-sortOrder"
                name="sortOrder"
                data-cy="sortOrder"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alippoApp.skuAttributeVariant.createdAt')}
                id="sku-attribute-variant-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.skuAttributeVariant.updatedAt')}
                id="sku-attribute-variant-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="sku-attribute-variant-skuAttribute"
                name="skuAttribute"
                data-cy="skuAttribute"
                label={translate('alippoApp.skuAttributeVariant.skuAttribute')}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/sku-attribute-variant" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SkuAttributeVariantUpdate;
