import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IUserSegment {
  id?: number;
  name?: string;
  active?: boolean;
  source?: string | null;
  metadata?: string | null;
  createdAt?: string;
  updatedAt?: string;
  user?: IUser;
}

export const defaultValue: Readonly<IUserSegment> = {
  active: false,
};
