import dayjs from 'dayjs';
import { ISkuAttribute } from 'app/shared/model/sku-attribute.model';

export interface ISkuAttributeVariant {
  id?: number;
  value?: string;
  valueLabel?: string;
  isDefault?: boolean;
  isAdditive?: boolean;
  config?: string | null;
  sortOrder?: number;
  createdAt?: string;
  updatedAt?: string;
  skuAttribute?: ISkuAttribute | null;
}

export const defaultValue: Readonly<ISkuAttributeVariant> = {
  isDefault: false,
  isAdditive: false,
};
