import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Table } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import { getEntity } from './category.reducer';
import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import axios from 'axios';
import { IReview } from 'app/shared/model/review.model';
import { IResCategory } from 'app/shared/model/res-category.model';

export const CategoryDetail = (props: RouteComponentProps<{ id: string }>) => {
  const [categoryReviewsData, setCategoryReviewsData] = useState<IReview[]>(null);
  const [resourcesData, setResourcesData] = useState<IResCategory[]>(null);
  const [categoryTranslationData, setCategoryTranslationData] = useState(null);
  const [subCategoriesData, setSubCategoriesData] = useState(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const getResCategories = async () => {
    if (categoryEntity.id) {
      try {
        const apiUrl = `api/res-categories?categoryId.equals=${categoryEntity.id}`;
        const { data } = await axios.get<IResCategory[]>(apiUrl);
        if (data.length === 0) {
          toast.error('No Resources available');
        } else {
          setResourcesData(data);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getCategoryReviews = async () => {
    if (categoryEntity.id) {
      const apiUrl = `api/reviews?entityId.equals=${categoryEntity.id}&page=0&size=40&sort=id,asc&entityType.equals=CATEGORY`;
      const { data } = await axios.get<IReview[]>(apiUrl);
      if (data.length === 0) {
        toast.error('No reviews available');
      }
      setCategoryReviewsData(data);
    }
  };

  const getCategoryTranslations = async () => {
    if (categoryEntity.id) {
      try {
        const apiUrl = `api/category-translations?categoryId.equals=${categoryEntity.id}`;
        const res: any = await axios.get(apiUrl);
        const translations = res.data;
        if (translations.length === 0) {
          toast.error('No translations available');
        } else {
          setCategoryTranslationData(
            translations.map(e => {
              return {
                id: e.id,
                name: e.name,
                language: e.language,
                isDefault: e.isDefault,
                url: `/category-translation/${e.id}`,
              };
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getSubCategories = async () => {
    if (categoryEntity.id) {
      try {
        const apiUrl = `api/categories?parentCategoryId.equals=${categoryEntity.id}`;
        const res: any = await axios.get(apiUrl);
        const translations = res.data;
        if (translations.length === 0) {
          toast.error('No sub categories available');
        } else {
          setSubCategoriesData(
            translations.map(e => {
              return {
                id: e.id,
                name: e.name,
                isDiscoverable: e.isDiscoverable,
                isTopic: e.isTopic,
                url: `/category/${e.id}`,
              };
            })
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const categoryEntity = useAppSelector(state => state.category.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="categoryDetailsHeading">
          <Translate contentKey="alippoApp.category.detail.title">Category</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{categoryEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="alippoApp.category.name">Name</Translate>
            </span>
          </dt>
          <dd>{categoryEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="alippoApp.category.description">Description</Translate>
            </span>
          </dt>
          <dd>{categoryEntity.description}</dd>
          <dt>
            <span id="sortOrder">
              <Translate contentKey="alippoApp.category.sortOrder">Sort Order</Translate>
            </span>
          </dt>
          <dd>{categoryEntity.sortOrder}</dd>
          <dt>
            <span id="createdAt">
              <Translate contentKey="alippoApp.category.createdAt">Created At</Translate>
            </span>
          </dt>
          <dd>{categoryEntity.createdAt ? <TextFormat value={categoryEntity.createdAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedAt">
              <Translate contentKey="alippoApp.category.updatedAt">Updated At</Translate>
            </span>
          </dt>
          <dd>{categoryEntity.updatedAt ? <TextFormat value={categoryEntity.updatedAt} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="properties">
              <Translate contentKey="alippoApp.category.properties">Properties</Translate>
            </span>
          </dt>
          <dd>{categoryEntity.properties}</dd>
          <dt>
            <span id="isDiscoverable">
              <Translate contentKey="alippoApp.category.isDiscoverable">Is Discoverable</Translate>
            </span>
          </dt>
          <dd>{categoryEntity.isDiscoverable ? 'true' : 'false'}</dd>
          <dt>
            <span id="isTopic">
              <Translate contentKey="alippoApp.category.isTopic">Is Topic</Translate>
            </span>
          </dt>
          <dd>{categoryEntity.isTopic ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="alippoApp.category.parentCategory">Parent Category</Translate>
          </dt>
          <dd>{categoryEntity.parentCategory ? categoryEntity.parentCategory.name : ''}</dd>
          <dt>Category Reviews</dt>
          <Button onClick={getCategoryReviews} className="mr-2" color="primary">
            <span>Get Category Reviews</span>
          </Button>
          <Link to={{ pathname: '/review/new', state: { category: categoryEntity } }}>
            <Button className="rounded-circle" color="success">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Link>
          <dd>
            {categoryReviewsData && categoryReviewsData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">
                      <Translate contentKey="alippoApp.review.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.review.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.review.subType">Sub Type</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.review.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand">
                      <Translate contentKey="alippoApp.review.sortOrder">Sort Order</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th>
                      <Translate contentKey="alippoApp.review.rateOption">Rate Option</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th>
                      <Translate contentKey="alippoApp.review.user">User</Translate> <FontAwesomeIcon icon="sort" />
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {categoryReviewsData.map((resource: IReview & { url: string }, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>
                        <Link target="_blank" rel="noopener noreferrer" to={`/review/${resource.id}`}>
                          {resource.id}
                        </Link>
                      </td>
                      <td>{resource.type}</td>
                      <td>{resource.subType}</td>
                      <td>{resource.status}</td>
                      <td>{resource.sortOrder}</td>
                      <td>{resource.rateOption?.value}</td>
                      <td>{resource.user.id}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <dl>
          <dt>Resources</dt>
          <Button onClick={getResCategories} color="primary">
            <span>Get Resources</span>
          </Button>
          <dd>
            {resourcesData && resourcesData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">ID</th>
                    <th className="hand">Name</th>
                    <th className="hand">Description</th>
                    <th className="hand">Res State</th>
                    <th className="hand">Res Type</th>
                    <th className="hand">Media Type</th>
                  </tr>
                </thead>
                <tbody>
                  {resourcesData.map((resource, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>
                        <a target="_blank" rel="noopener noreferrer" href={`/res-category/${resource.id}`}>
                          {resource.id}
                        </a>
                      </td>
                      <td>{resource.name}</td>
                      <td>{resource.description}</td>
                      <td>{resource.resState}</td>
                      <td>{resource.resType}</td>
                      <td>{resource.mediaType}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <dl>
          <dt>Category Translations</dt>
          <Button onClick={getCategoryTranslations} className="mr-2" color="primary">
            <span>Get Category Translations</span>
          </Button>
          <Link to={{ pathname: '/category-translation/new', state: { category: categoryEntity } }}>
            <Button className="rounded-circle" color="success">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </Link>
          <dd>
            {categoryTranslationData && categoryTranslationData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">Category Translation ID</th>
                    <th className="hand">Category Translation Name</th>
                    <th className="hand">Default?</th>
                    <th className="hand">Category Translation Language</th>
                  </tr>
                </thead>
                <tbody>
                  {categoryTranslationData.map((translation, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>
                        <Link target="_blank" rel="noopener noreferrer" to={translation.url}>
                          {translation.id}
                        </Link>
                      </td>
                      <td>{translation.name}</td>
                      <td>{translation.isDefault ? 'Yes' : 'No'}</td>
                      <td>{translation.language}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <dl>
          <dt>Sub Categories</dt>
          <Button onClick={getSubCategories} className="mr-2" color="primary">
            <span>Get Sub Categories</span>
          </Button>
          <dd>
            {subCategoriesData && subCategoriesData.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">Category ID</th>
                    <th className="hand">Category Name</th>
                    <th className="hand">Discoverable?</th>
                    <th className="hand">Topic?</th>
                  </tr>
                </thead>
                <tbody>
                  {subCategoriesData.map((translation, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>
                        <Link target="_blank" rel="noopener noreferrer" to={translation.url}>
                          {translation.id}
                        </Link>
                      </td>
                      <td>{translation.name}</td>
                      <td>{translation.isDiscoverable ? 'Yes' : 'No'}</td>
                      <td>{translation.isTopic ? 'Yes' : 'No'}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </dd>
        </dl>
        <dl>
          <dt>Category Slugs</dt>
          <a href={`/slug?page=1&sort=id,asc&categoryId.equals=${categoryEntity.id}`}>
            <Button type="button" color="primary">
              See Slugs
            </Button>
          </a>
        </dl>
        <Button tag={Link} to="/category" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/category/${categoryEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CategoryDetail;
