import dayjs from 'dayjs';
import { ICourse } from 'app/shared/model/course.model';
import { Language } from 'app/shared/model/enumerations/language.model';

export interface ICourseTranslation {
  id?: number;
  name?: string;
  description?: string | null;
  language?: Language;
  isDefault?: boolean;
  createdAt?: string;
  updatedAt?: string;
  course?: ICourse | null;
}

export const defaultValue: Readonly<ICourseTranslation> = {
  isDefault: false,
};
