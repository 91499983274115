import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities as getSkus } from 'app/entities/sku/sku.reducer';
import { getEntities as getCourseDiscussions } from 'app/entities/course-discussion/course-discussion.reducer';
import { getEntities as getForms } from 'app/entities/form/form.reducer';
import { getEntities as getCourses } from 'app/entities/course/course.reducer';
import { getEntities as getCategories } from 'app/entities/category/category.reducer';
import { createEntity, getEntity, reset, updateEntity } from './course.reducer';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { handleCopyToClipboard, mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { CurrencyCode } from 'app/shared/model/enumerations/currency-code.model';
import { CourseStatus } from 'app/shared/model/enumerations/course-status.model';
import { CourseLevel } from 'app/shared/model/enumerations/course-level.model';
import JsonEntryEditor from 'app/components/json-entry-editor';
import { nullifyEmptyString } from 'app/shared/util/utils';

export const CourseUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const forms = useAppSelector(state => state.form.entities);
  const courses = useAppSelector(state => state.course.entities);
  const categories = useAppSelector(state => state.category.entities);
  const courseEntity = useAppSelector(state => state.course.entity);
  const loading = useAppSelector(state => state.course.loading);
  const updating = useAppSelector(state => state.course.updating);
  const updateSuccess = useAppSelector(state => state.course.updateSuccess);
  const currencyCodeValues = Object.keys(CurrencyCode);
  const courseStatusValues = Object.keys(CourseStatus);
  const courseLevelValues = Object.keys(CourseLevel);
  const handleClose = () => {
    if (courseEntity && courseEntity.id) {
      handleCopyToClipboard(courseEntity.id.toString());
      props.history.push('/course/' + `${courseEntity.id}`);
    } else {
      props.history.push('/course' + props.location.search);
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          fromDateTime: displayDefaultDateTime(),
          createdAt: displayDefaultDateTime(),
          updatedAt: displayDefaultDateTime(),
        }
      : {
          sellingPriceCurrencyCode: CurrencyCode.INR,
          mrpPriceCurrencyCode: CurrencyCode.INR,
          courseStatus: CourseStatus.DRAFT,
          courseLevel: CourseLevel.BEGINNER,
          ...courseEntity,
          fromDateTime: convertDateTimeFromServer(courseEntity.fromDateTime),
          createdAt: convertDateTimeFromServer(courseEntity.createdAt),
          updatedAt: convertDateTimeFromServer(courseEntity.updatedAt),
          sku: courseEntity?.sku?.id,
          applicationForm: courseEntity?.applicationForm?.id,
          parentCourse: courseEntity?.parentCourse?.id,
          categories: courseEntity?.categories?.map(e => e.id.toString()),
          discussions: courseEntity?.discussions?.map(e => e.id.toString())?.join(','),
        };

  const [courseData, setCourseData] = useState<any>(defaultValues());

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }

    dispatch(getSkus({}));
    dispatch(getCourseDiscussions({}));
    dispatch(getForms({}));
    dispatch(getCourses({}));
    dispatch(getCategories({ size: 100, page: 0, sort: 'asc' }));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (courseEntity) {
      setCourseData(defaultValues());
    }
  }, [courseEntity]);

  const saveEntity = values => {
    values.fromDateTime = convertDateTimeToServer(values.fromDateTime);
    values.createdAt = convertDateTimeToServer(values.createdAt);
    values.updatedAt = convertDateTimeToServer(values.updatedAt);

    const entity = {
      ...courseEntity,
      ...values,
      discussions: values.discussions
        ?.trim()
        .replaceAll(' ', '')
        .split(',')
        .filter(it => it !== null && it !== undefined && it !== '')
        .map(id => {
          return { id: parseInt(id, 10) };
        }),
      categories: mapIdList(values.categories),
      sku: { id: values.sku },
      applicationForm: { id: values.applicationForm },
      parentCourse: courses.find(it => it.id.toString() === values.parentCourse.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const handleGetUpdatedEntity = newValues => {
    setCourseData(prev => ({
      ...prev,
      ...newValues,
    }));
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="alippoApp.course.home.createOrEditLabel" data-cy="CourseCreateUpdateHeading">
            <Translate contentKey="alippoApp.course.home.createOrEditLabel">Create or edit a Course</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={courseData} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="course-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('alippoApp.course.name')}
                id="course-name"
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.course.description')}
                id="course-description"
                name="description"
                data-cy="description"
                type="textarea"
              />
              <ValidatedField
                label={translate('alippoApp.course.sellingPrice')}
                id="course-sellingPrice"
                name="sellingPrice"
                data-cy="sellingPrice"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alippoApp.course.sellingPriceCurrencyCode')}
                id="course-sellingPriceCurrencyCode"
                name="sellingPriceCurrencyCode"
                data-cy="sellingPriceCurrencyCode"
                type="select"
              >
                {currencyCodeValues.map(currencyCode => (
                  <option value={currencyCode} key={currencyCode}>
                    {translate('alippoApp.CurrencyCode' + currencyCode)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.course.mrpPrice')}
                id="course-mrpPrice"
                name="mrpPrice"
                data-cy="mrpPrice"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alippoApp.course.mrpPriceCurrencyCode')}
                id="course-mrpPriceCurrencyCode"
                name="mrpPriceCurrencyCode"
                data-cy="mrpPriceCurrencyCode"
                type="select"
              >
                {currencyCodeValues.map(currencyCode => (
                  <option value={currencyCode} key={currencyCode}>
                    {translate('alippoApp.CurrencyCode' + currencyCode)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.course.fromDateTime')}
                id="course-fromDateTime"
                name="fromDateTime"
                data-cy="fromDateTime"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('alippoApp.course.duration')}
                id="course-duration"
                name="duration"
                data-cy="duration"
                type="text"
              />
              <ValidatedField
                label={translate('alippoApp.course.courseStatus')}
                id="course-courseStatus"
                name="courseStatus"
                data-cy="courseStatus"
                type="select"
              >
                {courseStatusValues.map(courseStatus => (
                  <option value={courseStatus} key={courseStatus}>
                    {translate('alippoApp.CourseStatus' + courseStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.course.courseLevel')}
                id="course-courseLevel"
                name="courseLevel"
                data-cy="courseLevel"
                type="select"
              >
                {courseLevelValues.map(courseLevel => (
                  <option value={courseLevel} key={courseLevel}>
                    {translate('alippoApp.CourseLevel' + courseLevel)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.course.sortOrder')}
                id="course-sortOrder"
                name="sortOrder"
                data-cy="sortOrder"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('alippoApp.course.createdAt')}
                id="course-createdAt"
                name="createdAt"
                data-cy="createdAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('alippoApp.course.updatedAt')}
                id="course-updatedAt"
                name="updatedAt"
                data-cy="updatedAt"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <JsonEntryEditor
                entityShape={courseData}
                entityKey="configPayload"
                value={JSON.parse(nullifyEmptyString(courseData?.configPayload) ?? '{}')}
                label={translate('alippoApp.course.configPayload')}
                id="course-configPayload"
                cyDataKey="configPayload"
                handleGetUpdatedEntity={handleGetUpdatedEntity}
              />
              <JsonEntryEditor
                entityShape={courseData}
                entityKey="seoConfigPayload"
                value={JSON.parse(nullifyEmptyString(courseData?.seoConfigPayload) ?? '{}')}
                label={translate('alippoApp.course.seoConfigPayload')}
                id="course-seoConfigPayload"
                cyDataKey="seoConfigPayload"
                handleGetUpdatedEntity={handleGetUpdatedEntity}
              />
              <ValidatedField
                label={translate('alippoApp.course.batchIndex')}
                id="course-batchIndex"
                name="batchIndex"
                data-cy="batchIndex"
                type="text"
              />
              <ValidatedField id="course-sku" name="sku" data-cy="sku" label={translate('alippoApp.course.sku')} disabled />
              <ValidatedField
                label={translate('alippoApp.course.applicationForm')}
                id="applicationForm"
                data-cy="applicationForm"
                name="applicationForm"
              />
              <ValidatedField
                id="course-parentCourse"
                name="parentCourse"
                data-cy="parentCourse"
                label={translate('alippoApp.course.parentCourse')}
                type="select"
              >
                <option value="" key="0" />
                {courses
                  ? courses.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.course.category')}
                id="course-category"
                data-cy="category"
                type="select"
                multiple
                name="categories"
              >
                <option value="" key="0" />
                {categories
                  ? categories.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('alippoApp.course.discussion')}
                id="course-discussion"
                data-cy="discussion"
                name="discussions"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/course" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CourseUpdate;
